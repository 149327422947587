//Event, Allowed file types*, Size in bytes, Width in pixels, Height in pixels, Width == Height, React setState
//*https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers

import { t } from 'i18next';
import { toast } from 'react-toastify';

export default function FileUploadSingle(props) {
  //Get file (always returns array of items so we select the first element)

  try {
    const file = props.file;
    // const file = props.file || props.uploadEvent.target.files[0];

    //Check file types
    let typeCheck = false;
    props.imageTypes.forEach(element => {
      if (file.type === element) {
        typeCheck = true;
      }
    });

    if (!typeCheck) {
      return toast.error(t("business_extra_allowed_filetype_invalid"));

    }

    //Check the maximum allowed file size in bytes
    if (file.size > (props.maxSize * 1000)) {
      //File is bigger than allowed size
      return toast.error(t("business_extra_allowed_filesize2") + " " + props.maxSize + " Kb.");
    }

    // CHECK DIMENTION

    //Initialize file
    new Promise((resolve, reject) => {

      //Read file in Base64 format
      const reader = new FileReader();
      reader.readAsDataURL(file);

      //Read succeeded...
      reader.onload = (event) => {
        //Initialize image
        let image = new Image();
        image.src = event.target.result;

        //Check image conditions
        image.onload = function () {
          //Check for resolution
          if (this.width > props.maxWidth || this.height > props.maxHeight) {
            toast.error(t("business_extra_allowed_resolution") + " " + props.maxWidth + "x" + props.maxHeight + ".");
            return;
          }
          //Save image in react state
          props.reactSetState(event.target.result);
          // SAVE IMAGE IN BACKEND
          props.mutateCompanyLogo.mutate({ logo: event.target.result })
        };

        //Finish promise
        resolve(event.target.result);
      };

      //Read failed...
      reader.onerror = (errorMessage) => {
        //Finish promise
        reject(errorMessage);
      };
    });
  }

  catch (err) {
    console.log(err)
  }
}

export async function FileUploadSingleConditionalsCheck(props) {
  //Get file (always returns array of items so we select the first element)
  try {
    var isOk = true;
    const file = props.file;
    // const file = props.file || props.uploadEvent.target.files[0];

    //Check file types
    let typeCheck = false;
    props.imageTypes.forEach(element => {
      if (file.type === element) {
        typeCheck = true;
      }
    });

    if (!typeCheck) {
      toast.error(t("business_extra_allowed_filetype_invalid"));
      console.error("Invalid file type");
      isOk = false;
      return false;
    }

    //Check the maximum allowed file size in bytes
    if (file.size > (props.maxSize * 1000)) {
      //File is bigger than allowed size
      toast.error(t("business_extra_allowed_filesize2") + " " + props.maxSize + " Kb.");
      console.error("Invalid file size");
      isOk = false;
      return false;
    }

    // CHECK DIMENTION
    // //Initialize file
    // await new Promise((resolve, reject) => {

    //   //Read file in Base64 format
    //   const reader = new FileReader();
    //   reader.readAsDataURL(file);

    //   //Read succeeded...
    //   reader.onload = (event) => {
    //     //Initialize image
    //     let image = new Image();
    //     image.src = event.target.result;

    //     //Check image conditions
    //     image.onload = function () {
    //       //Check for resolution
    //       if (this.width > props.maxWidth || this.height > props.maxHeight) {
    //         toast.error(t("business_extra_allowed_resolution") + " " + props.maxWidth + "x" + props.maxHeight + ".");
    //         console.error("Invalid width or height");
    //         isOk = false;
    //         return false;
    //       }
    //     };

    //     //Finish promise
    //     resolve(isOk);
    //   };

    //   //Read failed...
    //   reader.onerror = (errorMessage) => {
    //     //Finish promise
    //     reject(errorMessage);
    //     isOk = false;
    //     return false;
    //   };
    // });
    return isOk
  }

  catch (err) {
    console.log(err)
    return false;
  }
}
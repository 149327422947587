import { useMutation } from "@tanstack/react-query"
import axios from "axios"
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore"

const useEditCompanyLogo = (props) => {
    const userStore = useUserStore();
    var logoUrl = "";

    return useMutation({
        mutationKey: ["mutateCompanyLogo"],
        mutationFn: (propsFn) => {
            logoUrl = propsFn.logoUrl;
            return axios.post("company/logo/update2", {
                CompanyId: userStore.companyData.id,
                UserId: userStore.userData.id,
                LogoUrl: propsFn.logoUrl,
            })
        },
        retry: 2,
        onSuccess: (res) => handleRequestSuccess({
            response: res, hasAlert: true, isSubmit: true, callback: () => {
                if (props.setLogo) {
                    props.setLogo(logoUrl)
                }
            }
        }),
        onError: (res) => handleRequestError({ response: res, hasAlert: true, isSubmit: true, }),
    })
}



export default useEditCompanyLogo
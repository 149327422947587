import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";

const useEditAccountAvatar = (props) => {
    const userStore = useUserStore();
    var logoUrl = null;

    return useMutation({
        mutationKey: ["mutateAccountAvatar"],
        mutationFn: (propsFn) => {
            logoUrl = propsFn.logoUrl;
            return axios.post("account/logo/update", {
                UserId: userStore.userData.id,
                LogoUrl: propsFn.logoUrl
            })
        },
        retry: 2,
        onSuccess: (res) => handleRequestSuccess({
            response: res, hasAlert: true, isSubmit: true, callback: () => {
                props.setPreview(logoUrl)
            }
        }),
        onError: (res) => handleRequestError({ response: res, hasAlert: true, isSubmit: true })
    })
}

export default useEditAccountAvatar;
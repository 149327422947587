import { useUserStore } from 'components/utils/stores/userStore';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/animations/loading';
// import "~slick-carousel/slick/slick.css";
// import "~slick-carousel/slick/slick-theme.css";


export default function LogoutPage(props) {
    const userStore = useUserStore();
    const navigate = useNavigate();

    useEffect(() => {
        userStore.logout({ navigate });
    }, [])

    return (
        <div>
            <div className="d-flex justify-content-center"><Loading text={null} big fullscreen /></div>
        </div>
    );
}
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";

const useGetRoleOptions = (props) => {
    return useQuery({
        queryKey: ["getRoleOptions"],
        queryFn: () => axios.get("v2/generic/catalogues/Roles"),
        refetchOnWindowFocus: false,
        retry: 2,
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {
                var newItems = [];
                var data = res.data.instance;
                Array.prototype.forEach.call(data, function (item, i) {
                    return newItems = [...newItems, { id: item.id, label: item.name, value: item.id, data: item }];
                });

                props.setData(newItems);

            }
        }),
        onError: (res) => handleRequestError({ response: res })
    })
}

export default useGetRoleOptions;
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";
import { usePlansStore } from "components/utils/stores/plansStore";
import { useUserStore } from "components/utils/stores/userStore";

const useGetPlans = () => {
    const plansStore = usePlansStore();
    const userStore = useUserStore();
    return useQuery({
        queryKey: ["getPlans"],
        queryFn: () => axios.get("v2/generic/catalogues/PlansActive"),
        retry: 2,
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {

                var data = res.data.instance;
                var newItems = [];
                var selectedItem = [];
                // set raw data
                plansStore.setData(data);
                // set options data
                (data).forEach(item => {
                    if (!item.isSelectable) { return }
                    if (item.titleTranslations === null) { return }
                    if (item.titleTranslations === undefined) { return }
                    Array.prototype.forEach.call(item.titleTranslations, function (itemTranslation, i) {
                        if (userStore.clientData.language.id === itemTranslation.languageId) {
                            var selectedText = itemTranslation.text;
                            return selectedItem = { id: item.id, value: item.id, label: selectedText, sort: item.accountTypeID, data: item };
                        }
                    });
                    if (selectedItem.length !== 0) {
                        newItems = [...newItems, (selectedItem)];
                        selectedItem = [];
                    }
                });

                newItems.sort((a, b) => a.sort > b.sort ? 1 : -1)

                plansStore.setOptions(newItems)
            }
        }),
        onError: (res) => handleRequestError({ response: res })
    })
}
export default useGetPlans;
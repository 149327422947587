import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';
import JobfairsContextProvider from "./hooks/context/JobfairsStateContext";

const AppWrapper = ({ children }) => {
  const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });
  return (
    <div>
      <JobfairsContextProvider>
        <QueryClientProvider client={queryClient}>
          <ToastContainer
            position="top-center"
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            theme="colored" />
          {children}
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </JobfairsContextProvider>
    </div>
  );
}

export default AppWrapper;
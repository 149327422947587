import * as React from 'react';

function AlertMessage(props) {
  return (
    <div>
      {
        !props.message
          ?
          null
          :
          <div className={"my-4 alert alert-" + props.message[1]}>
            {
              props.disabled === undefined ?
                <span className='alert-close icon-cross' onClick={() => props.setMessage(false)}></span>
                : null
            }
            {
              props.html ?
                <div dangerouslySetInnerHTML={{ __html: "<div>" + props.message[0] + "</div>" }}></div>
                :
                <div>{props.message[0]}</div>
            }
          </div>
      }
    </div>
  );
}
export default AlertMessage;
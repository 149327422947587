import { Empty, Table } from "antd";
import Panel from "components/modules/panel/panel";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import TablePanel from "./table-panel";

export default function InsightHotTalentPanel(props) {
    const userStore = useUserStore();

    const data = {
        items: [
            {
                "sortIndex": 1,
                "jobName": "Audit Consultant",
                "totalApplicants": 6,
                "lastRateDate": "2023-11-17",
                "reactivateCandidates": <button className="btn btn-primary" onClick={() => handleReactivateClick(1)}>{t("business_dashboard_panel_candidate_hot_talent_button_reactivate")}</button>
            },
            {
                "sortIndex": 2,
                "jobName": "Support Assistant",
                "totalApplicants": 2,
                "lastRateDate": "2023-10-11",
                "reactivateCandidates": <button className="btn btn-primary" onClick={() => handleReactivateClick(2)}>{t("business_dashboard_panel_candidate_hot_talent_button_reactivate")}</button>
            },
            {
                "sortIndex": 3,
                "jobName": "Accountant",
                "totalApplicants": 12,
                "lastRateDate": "2022-03-24",
                "reactivateCandidates": <button className="btn btn-primary" onClick={() => handleReactivateClick(3)}>{t("business_dashboard_panel_candidate_hot_talent_button_reactivate")}</button>
            }
        ]
    }

    const handleReactivateClick = (sortIndex) => {
        if (window.confirm(`Are you sure you want to reactivate candidates for this job?`)) {

        }
    }
    const columns = [
        {
            title: t("business_dashboard_panel_hot_talent_table_head_jobname"),
            dataIndex: 'jobName',
            key: 'jobName',
        },
        {
            title: t("business_dashboard_panel_hot_talent_table_head_amount"),
            dataIndex: 'totalApplicants',
            key: 'totalApplicants',
        },
        {
            title: t("business_dashboard_panel_hot_talent_table_head_update_date"),
            dataIndex: 'lastRateDate',
            key: 'lastRateDate',
        },
        {
            title: t("business_dashboard_panel_candidate_hot_talent_table_head_reactivate"),
            dataIndex: 'reactivateCandidates',
            key: 'reactivateCandidates',
        },
    ]

    return <>
        {
            !data ? null :
                !data.items ? null :
                    <TablePanel
                        title={<div className="d-flex justify-content-between flex-row w-full">
                            {
                                data.items.length === 0 ? <div>{props.title || t("business_dashboard_panel_hot_talent_title")}</div> :
                                    <div>
                                        {props.title || t("business_dashboard_panel_hot_talent_title")} {data.total > 0 ? `(${data.amount}/${data.total})` : null} {data.percentage ? ` - ${data.percentage}%` : null}
                                    </div>
                            }
                        </div>}
                        columns={columns}
                        data={{
                            items: data.items
                        }}

                    />
            // <div className={`${'row gx-1'}`}>
            //     <div className={`${'col gx-2'}`}>
            //         <Panel
            //             bodyStyle={{ padding: 0 }}
            //             title={<div className="d-flex justify-content-between flex-row w-full">
            //                 {
            //                     data.items.length === 0 ? <div>{t("business_dashboard_panel_hot_talent_title")}</div> :
            //                         <div>
            //                             {t("business_dashboard_panel_hot_talent_title")} {data.total > 0 ? `(${data.amount}/${data.total})` : null} {data.percentage ? ` - ${data.percentage}%` : null}
            //                         </div>
            //                 }
            //             </div>}
            //         >
            //             <>
            //                 {/* <div className="d-flex justify-content-end">
            //                     <div className="btn btn-primary">{t("business_dashboard_panel_hot_talent_alert")}</div>
            //                 </div> */}
            //                 <Table locale={{
            //                     emptyText: <>
            //                         <Empty description={t("business_extra_no_data")} />
            //                     </>
            //                 }} className="mb-3" pagination={false} columns={columns} dataSource={data.items} />

            //             </>
            //         </Panel>
            //     </div>
            // </div>
        }
    </>
}
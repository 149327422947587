import { Collapse } from 'antd';

export default function AccordionContent(props) {

    const { Panel } = Collapse;

    return (
        <div className='my-2'>
            <Collapse accordion expandIconPosition="end" className={`bg-white ${props.className}`}>
                <Panel header={props.title}>
                    {props.content || props.children}
                </Panel>
            </Collapse>
        </div>
    )
}
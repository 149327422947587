
import { Button, Form, Input, Select } from 'antd';
import PrefixIcon from 'components/forms/input/prefix-icon';
import useRegisterStepsHandler from 'components/requests/account/useRegisterStepsHandler';
import useGetCompanySizeOptions from 'components/requests/data/useGetCompanySizeOptions';
import useGetCountryOptions from 'components/requests/data/useGetCountryOptions';
import useGetSectorsOptions from 'components/requests/data/useGetSectorsOptions';
import { useUserStore } from 'components/utils/stores/userStore';
import { t } from 'i18next';
import produce from 'immer';
import { useEffect, useState } from 'react';
import AlertMessage from '../../components/forms/alerts/alert-message';
import InputPhone from 'components/forms/input/input-ant-phone';
import { handleTranslationLabel } from 'components/utils/methods/AppMethods';

export default function RegisterStep4(props) {
    // # STORES
    const userStore = useUserStore();

    // # CLIENT STATES
    const [form] = Form.useForm();
    const [formObjectStep4, setFormObjectStep4] = useState({
        phone: "",
        size: null,
        sector: null,

    });
    const editPhone = (val) => editFormItemStep4("phone", val)
    const [message, setMessage] = useState(null);
    const [allSectors, setSectors] = useState(false);
    const [allSizes, setSizes] = useState(false);
    const [allCountries, setCountries] = useState(false);

    // # UTILS
    const editFormItemStep4 = (key, val, setInit) => {
        if (setInit) {
            form.setFieldsValue({
                [key]: val
            });
        }
        setFormObjectStep4(produce(draft => {
            draft[key] = val;
        }))
    }

    // # SERVER STATES
    const { refetch: refetchCompanySizeOptions } = useGetCompanySizeOptions({ setData: setSizes });
    const { refetch: refetchCountryOptions } = useGetCountryOptions({ setData: setCountries });
    const { refetch: refetchSectorsOptions } = useGetSectorsOptions({ setData: setSectors });
    const mutateRegisterStepsHandler = useRegisterStepsHandler({
        companyId: props.formObject.companyId,
        companyPhone: formObjectStep4.phone,
        companyEmail: props.formObject.companyEmail,
        companySizeId: formObjectStep4.size,
        companySectorId: formObjectStep4.sector,
        companyCountryId: props.formObject.country,
        companyPostalcode: props.formObject.zipCode,
        companyCity: props.formObject.city,
        companyAddress1: props.formObject.address1,
        companyAddress2: props.formObject.address2,
        userId: props.formObject.userId,
        setMessage: setMessage,
        step: props.currentStep,
        setStep: props.setStep
    })


    const doneSvg = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="7" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M8 0C3.58182 0 0 3.58182 0 8C0 12.4182 3.58182 16 8 16C12.4182 16 16 12.4182 16 8C16 3.58182 12.4182 0 8 0ZM11.4676 6.64727C11.5315 6.57429 11.5801 6.48927 11.6106 6.39721C11.6411 6.30516 11.6529 6.20794 11.6453 6.11126C11.6377 6.01459 11.6108 5.92041 11.5662 5.83428C11.5217 5.74814 11.4604 5.67179 11.3859 5.6097C11.3114 5.54762 11.2252 5.50106 11.1324 5.47277C11.0397 5.44448 10.9422 5.43503 10.8457 5.44497C10.7493 5.4549 10.6558 5.48403 10.5707 5.53064C10.4857 5.57725 10.4108 5.6404 10.3505 5.71636L7.22327 9.46836L5.60509 7.84946C5.46793 7.71698 5.28422 7.64367 5.09353 7.64533C4.90284 7.64699 4.72043 7.72347 4.58559 7.85831C4.45074 7.99316 4.37426 8.17557 4.3726 8.36625C4.37094 8.55694 4.44425 8.74065 4.57673 8.87782L6.75855 11.0596C6.83001 11.1311 6.91556 11.1868 7.00976 11.2233C7.10395 11.2598 7.20473 11.2763 7.30565 11.2717C7.40657 11.2672 7.50544 11.2416 7.59594 11.1967C7.68643 11.1518 7.76658 11.0885 7.83127 11.0109L11.4676 6.64727Z" fill="#18A89B" />
    </svg>;

    const currentSvg = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="8" fill="#F19023" />
    </svg>;

    const nextSvg = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="8" fill="#1B6A63" />
    </svg>;

    useEffect(() => {
        refetchCompanySizeOptions();
        refetchCountryOptions();
        refetchSectorsOptions();
        form.setFieldsValue({
            "companyName": props.formObject.companyName,
            "companyVat": props.formObject.companyVat,
        })
    }, [])


    // update translations on change
    useEffect(() => {
        setSectors(handleTranslationLabel({ list: allSectors, langId: userStore.clientData.language.id }));
        setSizes(handleTranslationLabel({ list: allSizes, langId: userStore.clientData.language.id }));
    }, [userStore.clientData.language])


    const submitAccount = (event) => {
        mutateRegisterStepsHandler.mutate();
    }

    return (
        <div className="  d-flex hero-form hero-form-steps">
            <div className="bg-darkgreen bg-hero hero-form-left">
                <div className="container py-10">
                    <div className="d-flex justify-content-center align-items-start flex-column mt-3 ml-2">
                        <h1 className='color-white'>{t("discard_register_hero_title_steps")}</h1>
                        <div className="mt-3 register-steps">
                            <div>
                                <span>
                                    {doneSvg}
                                </span>
                                <span>
                                    {t("discard_register_step_account")}
                                </span>
                            </div>
                            <div>
                                <span>
                                    {doneSvg}
                                </span>
                                <span>
                                    {t("business_main_account_info")}
                                </span>
                            </div>
                            <div>
                                <span>
                                    {currentSvg}
                                </span>
                                <span>
                                    {t("discard_register_step_company")}
                                </span>
                            </div>
                            <div>
                                <span>
                                    {nextSvg}
                                </span>
                                <span>
                                    {t("discard_register_step_plan")}
                                </span>
                            </div>
                            <div>
                                <span>
                                    {nextSvg}
                                </span>
                                <span>
                                    {t("discard_register_step_complete")}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-hero hero-form-right">
                <div className="container py-10 ">
                    <div className="px-5 py-3">
                        <div className="d-flex justify-content-between">
                            <div>
                                <h1 className='title'>{t("discard_register_step_company")}</h1>
                                <div className='subtitle'>{t("business_registration_subtitle")}</div>
                            </div>
                            <span className="color-grey">
                                3/5
                            </span>
                        </div>
                        {
                            !message
                                ?
                                null
                                :
                                <AlertMessage message={message} setMessage={setMessage} />
                        }
                        <Form
                            name="form-register-4"
                            className='mt-8 mx-auto'
                            form={form}
                            onFinish={() => submitAccount()}
                            layout='vertical'
                        >
                            <Form.Item
                                label={t("business_main_company_name")}
                                name="companyName"
                            >
                                <Input
                                    disabled
                                    value={props.formObject.companyName}
                                />
                            </Form.Item>
                            <Form.Item
                                label={t("business_main_vatnumber")}
                                name="companyVat"
                            >
                                <Input
                                    disabled
                                    value={props.formObject.companyVat}
                                />
                            </Form.Item>
                            <Form.Item
                                label={t("business_main_phone")}
                                name="phone"
                            >
                                <InputPhone
                                    name="phone"
                                    onChange={editPhone}
                                />
                            </Form.Item>
                            <Form.Item
                                label={t("business_main_email")}
                                rules={[{ required: true, message: false, type: "email" }]}
                                name="email"
                            >
                                <Input
                                    prefix={<PrefixIcon value="icon-mail fs-small" />}
                                    placeholder={t("business_extra_placeholder_email_business")}
                                    onChange={(e) => props.editFormItem("companyEmail", e.target.value)}
                                    type="mail"
                                />
                            </Form.Item>
                            <div className="d-flex flex-wrap justify-content-between ">
                                <Form.Item
                                    label={t("business_main_industry")}
                                    name="sector"
                                    rules={[{ required: true, message: false }]}
                                    className="w-50 mobile-friendly pr-2"
                                >
                                    <Select
                                        placeholder={t("-")}
                                        options={allSectors}
                                        onSelect={(value, option) => editFormItemStep4("sector", option.id)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={t("business_main_company_size")}
                                    name="size"
                                    rules={[{ required: true, message: false }]}
                                    className="w-50 mobile-friendly"
                                >
                                    <Select
                                        placeholder={t("-")}
                                        options={allSizes}
                                        // use sortindex as id because else its not valid
                                        onSelect={(value, option) => editFormItemStep4("size", option.data.sortIndex)}
                                    />
                                </Form.Item>
                            </div>
                            <Form.Item
                                label={t("business_main_country")}
                                rules={[{ required: true, message: false }]}
                                name="country"
                            >
                                <Select
                                    showSearch
                                    optionFilterProp='label'
                                    placeholder={t("-")}
                                    options={allCountries}
                                    onSelect={(value, option) => props.editFormItem("country", option.id)}
                                />
                            </Form.Item>
                            <div className="d-flex flex-wrap justify-content-between ">
                                <Form.Item
                                    label={t("business_main_city")}
                                    name="city"
                                    rules={[{ required: true, message: false }]}
                                    className="w-50 mobile-friendly pr-2"
                                >
                                    <Input
                                        placeholder={t("business_main_city")}
                                        onChange={(e) => props.editFormItem("city", e.target.value)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={t("business_main_zipcode")}
                                    name="zipCode"
                                    rules={[{ required: true, message: false }]}
                                    className="w-50 mobile-friendly pr-0"
                                >
                                    <Input
                                        placeholder={t("business_main_zipcode")}
                                        onChange={(e) => props.editFormItem("zipCode", e.target.value)}
                                    />
                                </Form.Item>
                            </div>
                            <Form.Item
                                label={t("discard_register_step_company_adres1")}
                                name="address1"
                                rules={[{ required: true, message: false }]}
                            >
                                <Input
                                    placeholder={t("discard_register_step_company_adres1")}
                                    onChange={(e) => props.editFormItem("address1", e.target.value)}
                                />
                            </Form.Item>
                            <Form.Item
                                label={t("discard_register_step_company_adres2")}
                                name="address2"
                            >
                                <Input
                                    placeholder={t("discard_register_step_company_adres2")}
                                    onChange={(e) => props.editFormItem("address2", e.target.value)}
                                />
                            </Form.Item>
                            <div className='mt-4 d-inline-block'>
                                <Button
                                    htmlType='submit'
                                    className='btn btn-primary'
                                    loading={mutateRegisterStepsHandler.isLoading}
                                >
                                    {t("business_main_next")} <span className="icon-arrow-right default pl-2" />
                                </Button>
                            </div>
                        </Form>

                    </div>
                </div>
            </div>
        </div>
    );
}
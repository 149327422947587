import Helmet from 'react-helmet';
import Moment from 'moment';
import chalk from 'chalk';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import envir from 'env.json'
import Pako from 'pako';
import produce from 'immer';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import moment from 'moment';

// MYMETHODS: Methods that can be used in other platforms and are independant
export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

// Handle list of permissions and the states of them
// toCheckItems = [[value, setState, Block navigate if false], ...]
export function handleAllPermissions(allPermissions, toCheckItems) {
    var isValidPermissions = isValid([allPermissions], [undefined, null])
    if (!isValidPermissions) { return }
    console.log(allPermissions)
    console.log(toCheckItems)
    toCheckItems.map(permission => {
        hasPermission(allPermissions, permission[0], permission[1], permission[2]);
    })
    return
}

// Returns date in string format UTC -> regular format given in properties
export function getDateInFormat(date, format) {
    var dateFormat = moment(date);
    var local = dateFormat.utc().local();
    return local.format(format);
}

export function isValid(toCheck, checkFor) {
    try {
        if (toCheck === null) { return false }
        if (toCheck === undefined) { return false }
        if (toCheck.length <= 0) { return false }

        if (checkFor === null) { return false }
        if (checkFor === undefined) { return false }
        if (checkFor.length <= 0) { return false }

        var result = true;

        for (let i = 0; i < toCheck.length; i++) {
            if (result === false) {
                break;
            } else {
                for (let y = 0; y < checkFor.length; y++) {
                    if (result === false) { break; }
                    if (toCheck[i] === checkFor[y]) {
                        result = false;
                        throw false;
                    }
                }
            }

        }
        return result;
    }
    catch (err) {
        return false;
        console.log(err);
    }
}

export function hasPermission(permissions, permissionText, setHasPermission, navigate) {
    var result = false;
    if (permissions === undefined) { return }
    if (permissionText === undefined) { return }
    if (permissions === null) { return }
    if (permissionText === null) { return }
    if (permissions.length === 0) { return }

    permissions.map(permissionItem => {
        if (permissionItem.includes(permissionText)) {
            result = true;
            return;
        }
    })
    if (setHasPermission === undefined) {
        return result;
    } else {
        setHasPermission(result);
        if (result === false && navigate !== undefined) {
            console.log("navigate triggered")
            navigate("/")
        }
    }


}

// SEO TRANSLATION HANDLER 
export function handleDynamicSeoItem(text, items) {
    items.map(item => {
        while (text.includes(item[0])) {
            if (text.includes(item[0])) {
                text = text.replace(item[0], item[1]);
            }
        }
    })
    return text;
}

export function setPageSeo(title, description, keywords) {
    return <Helmet
        title={title}
        meta={[
            {
                name: `description`,
                content: description
            },
            {
                name: `keywords`,
                content: keywords
            },
            {
                property: "og:title",
                content: title
            },
            {
                property: "og:description",
                content: description
            },
            {
                property: "twitter:title",
                content: title
            },
            {
                property: "twitter:description",
                content: description
            },
        ]}
    ></Helmet>
}

// Returns date in string format UTC -> regular format given in properties
export function getDate(date, format) {
    var dateFormat = new Moment(date);
    var local = dateFormat.utc(dateFormat).local();
    return local.format(format);
}

// handle compressed data - unpack and return it
export function handleCompressedData(res) {
    try {
        // Decode base64 (convert ascii to binary)
        var strData = atob(res.data);

        // Convert binary string to character-number array
        var charData = strData.split('').map(function (x) { return x.charCodeAt(0); });

        // Turn number array into byte-array
        var binData = new Uint8Array(charData);

        // Pako magic
        var strData = Pako.inflate(binData, { to: 'string' });

        // Convert gunzipped byteArray back to ascii string:
        // var strData = String.fromCharCode.apply(null, [...new Uint16Array(data)]);
        // var item = JSON.parse(pako.inflate(res.data, { to: 'string' }))
        // var item = ungzip(res.data, { windowBits: '16+MAX_WBITS' })
        var jsonData = (JSON.parse(strData));
        return jsonData;

    } catch (error) {
        throw Error(`handleCompressedData: ${error}`)
    }

}

// PROPS
// - key
// - value
// - setDefault
// - form
// - setFormObject
export function handleEditFormObjectItem(props) {
    try {
        if (props.setDefault) {
            props.form.setFieldsValue({
                [props.key]: props.value
            });
        }
        props.setFormObject(produce(draft => {
            draft[props.key] = props.value;
        }))

    } catch (error) {
        console.log(chalk.red("Something went wrong in handleEditFormObjectItem"), error)
    }

}

const project = "WiSE Business";
const getSimpleApiRoute = (route) => route.replace(envir.Variables.WiseApiUrl, "");

// handler for common HTTPS status errors - used for debugging and showing general alerts
// PROPS
// - t
// - status
// - route
// - response
// - hasAlert?
// - isSubmit?
// - submitMessage? when 200 and isSubmit, then it will show users this message instead of default
// - hideDebugLogs? hide debug logs
export function handleDebugLogsAndAlerts(props) {
    try {

        const errorConnection = t("business_extra_general_error1");
        const errorSomething = t("business_extra_general_error2");
        const logChanges = t("business_extra_save_succes");
        const { response, status, hasAlert, isSubmit, hideDebugLogs, submitMessage, route } = props;

        const apiStatus = response?.response?.data?.status || null;
        const apiResult = response?.response?.data || null;
        const method = response?.config?.method || null;
        const body = response?.config?.data ? JSON.parse(response.config.data) : null;

        const responseObject = {
            method,
            route,
            body,
            status,
            apiStatus,
            apiResult,
            hasAlert,
            isSubmit,
            project,
            log: ""
        };

        let methodLog = "";

        switch (status) {
            case 200:
                if (isSubmit && hasAlert) {
                    if (submitMessage) {
                        toast.success(submitMessage);
                    } else {
                        toast.success(logChanges);
                    }
                }
                break;
            case 298:
                break; // compressed data
            case 204:
            case 290:
                methodLog = "There was no data found in the request";
                responseObject.log = methodLog;
                break;
            case 400:
                if (hasAlert) {
                    toast.error(errorSomething);
                }
                methodLog = "This request had a backend error, check the server tab and call or use the debug logs";
                responseObject.log = methodLog;
                break;
            case 404:
                if (hasAlert) {
                    toast.error(errorSomething);
                }
                methodLog = "This request route was not found";
                responseObject.log = methodLog;
                break;
            case 500:
                if (hasAlert) {
                    toast.error(errorSomething);
                }
                methodLog = "This request had a SERVER error, please contact support";
                responseObject.log = methodLog;
                break;
            default:
                if (hasAlert) {
                    toast.error(errorConnection);
                }
                methodLog = "Something went wrong";
                responseObject.log = methodLog;
                break;
        }

        if (!hideDebugLogs) {
            console.log(chalk.dim.italic(`${responseObject.log} \n\n`), responseObject);
        }
    } catch (error) {
        console.error(`Something went wrong in handleDebugLogsAndAlerts: ${error}`)
    }
}

// Generally handles the following of a request: status, error logs, alerts
// PROPS
// - t
// - response
// - isSubmit?
// - submitMessage? - custom submitmessage for an alert to show to user
// - hasAlert?
// - setMessage? - sets absolute message in form
// - customCallback? - do stuff in handleError 
export function handleRequestError(props) {
    try {
        const { response, t, hasAlert, isSubmit, submitMessage, customCallback } = props;
        const apiStatus = !response.response || !response.response.data || response.response.data === "" ? null : response.response.data.status;
        let status = null;
        let route = null;

        if (response.request) {
            route = response.request.responseURL;
        }

        if (response.status) {
            status = parseInt(response.status);
        } else {
            if (!response.response) {
                return;
            }
            status = parseInt(response.response.status);
        }

        console.log(chalk.bold(`🛑 ${status} (${apiStatus || "Unknown"}):`), getSimpleApiRoute(route));

        // status can be string or something unexpected - if so then use the status code given by backend
        if (status === undefined || typeof status !== "number") {
            if (!response.response || !response.response.status) { return console.log(chalk.red("handleError: unexpected status errors")) }
            status = response.response.status
        }

        // steps: we want it to show debug logs, but catch custom status code in customCallback, sometimes we want to send a custom toast message,
        // this is why we do it seperately because if you THROW the custom toast msg then the next handler wont happen
        // 1. Show me only debug messages
        handleDebugLogsAndAlerts({
            t,
            status,
            route,
            response,
            // dont give hasAlert & isSubmit - we dont want to show alerts yet - because the call can expect custom ones in customCallback
        });

        // 2. CustomCallback - do stuff depending on "special" (api) status code
        // - we do general alert handling afterwards because you can throw toast messages in customCallback in order to not show the general alerts
        if (customCallback) {
            customCallback(response);
        }

        // 3. General alerts 
        handleDebugLogsAndAlerts({
            t,
            status,
            route,
            response,
            hasAlert: props.hasAlertError !== undefined ? props.hasAlertError : props.hasAlert,
            isSubmit,
            submitMessage,
            hideDebugLogs: true,
        });
    } catch (error) {
        if (error) {
            throw new Error(`Something went wrong in the handleRequestError method - \n${error}`);
        } else {
            throw new Error(`Something went wrong in the handleRequestError method without given error message (^)`);
        }
    }
}

// Generally handles the following of a request: status, error logs, alerts, does something with data requested ("callback"/"customCallback")
// PROPS
// - t
// - response
// - isSubmit?
// - hasAlert?
// - callback -> function that does something with data
// - customCallback? - do stuff in handleError 
export function handleRequestSuccess(props) {
    try {
        const { response, hasAlert, isSubmit, callback, customCallback, submitMessage } = props;
        let route = null;
        let status = null;

        if (response.request) {
            route = response.request.responseURL;
        }

        if (response) {
            if (!response.status) {
                return;
            }
            status = parseInt(response.status);
        } else {
            if (!response.response) {
                return;
            }
            status = parseInt(response.response.status);
        }

        const apiStatus = response.data?.status ? parseInt(response.data.status) : null;
        const apiResult = response.data?.instance || null;
        const method = response.config?.method || null;
        const body = response.config?.data ? JSON.parse(response.config.data) : null;

        const responseObject = {
            method,
            route,
            body,
            status,
            apiStatus,
            apiResult,
            hasAlert,
            isSubmit,
            callback: typeof callback === 'function' ? callback.toString() : null,
            customCallback: customCallback ? customCallback.toString() : null,
            project,
            log: ""
        };

        console.log(chalk.bold(`✅ ${status} (${apiStatus}):`), getSimpleApiRoute(route));

        // Give https status to handler it shows general alerts
        // We handle api status & logs below (because it depends on the app and is custom stuff) so we hide logs here
        handleDebugLogsAndAlerts({
            t: props.t,
            status,
            route,
            response,
            hasAlert,
            isSubmit,
            submitMessage,
            hideDebugLogs: true,
        });

        // !!! HANDLE ALL ACCEPTABLE API STATUSES & DEBUG LOGGING THAT ARE 200-299 -> else it will throw an error
        // in short (for react-query):
        // - for isSuccess means the following cases is true 
        // - for onSuccess means the following cases is true 
        let methodLog = "";
        if (typeof callback === 'function') {
            if (status === 298) {
                // OK but - result data is compressed - will need uncompressing in callback
                methodLog = "COMPRESSED - Callback found & was triggered";
                responseObject.log = methodLog;
                callback();
            } else {
                switch (apiStatus) {
                    case 200:
                        // OK - do callback in success handler
                        methodLog = "Callback found & was triggered";
                        responseObject.log = methodLog;
                        callback();
                        break;
                    case 290:
                        // OK but - no callback, you'll have to catch this status in customCallback
                        if (customCallback) {
                            methodLog = "No main callback used (used custom) - no data";
                            responseObject.log = methodLog;
                        } else {
                            methodLog = "No callback / customCallback used - no data";
                            responseObject.log = methodLog;
                        }
                        break;
                    default:
                        // NOT OK - not a caught api status while its 200 HTTP status
                        console.error(responseObject);
                        throw new Error(`Caught - Status of api call was unexpected so an error was thrown`);
                }
            }
        } else {
            if (customCallback) {
                methodLog = "No main callback found (found custom)";
                responseObject.log = methodLog;
            } else {
                methodLog = "No callback / customCallback used";
                responseObject.log = methodLog;
            }
        }

        // do custom callback when available/given
        if (customCallback !== undefined) {
            customCallback(response);
        }
        console.log(responseObject);
    } catch (error) {
        if (error) {
            throw new Error(`Caught - Something went wrong in the handleRequestSuccess method - \n${error}`);
        } else {
            console.log(error);
            throw new Error(`Caught - Something went wrong in the handleRequestSuccess method without given error message (^)`);
        }
    }
}

export function handleRequestSettled(props) {
    const successCodes = [
        200, // OK
        290, // No data
        298 // Compressed data
    ]
    // Seperate success from error 
    if (props === undefined) {
        handleRequestError(props);
    }
    else if (successCodes.includes(props.response.status)) {
        handleRequestSuccess(props);
    } else {
        handleRequestError(props);
    }
}

// PROPS
// - key || queryKey
// - route
// - method?
// - body?
// - header?
// - enabled?
// - hasAlert?
// - hasAlertError?
// - isSubmit?
// - callback?
// - customCallback?
// - refetchOnWindowFocus?
// - select?
// - enabled?

export function useMyQuery(props) {

    const handleDebugRequest = (res) => handleRequestSettled({
        response: res,
        hasAlertError: props.hasAlertError !== undefined ? props.hasAlertError : false,
        hasAlert: props.hasAlert !== undefined ? props.hasAlert : false,
        isSubmit: props.isSubmit !== undefined ? props.isSubmit : false,
        callback: props.callback !== undefined ? props.callback : undefined,
        customCallback: props.customCallback !== undefined ? props.customCallback : undefined
    });

    return useQuery({
        queryKey: [props.key || props.queryKey || "myKey"],
        queryFn: () => {
            if (props.method.toUpperCase() === "POST") {
                return axios.post(props.route, props.body || {}, props.header || null)
                    .then(res => {
                        handleDebugRequest(res);
                        return res;
                    })

            } else {
                return axios.get(props.route)
                    .then(res => {
                        handleDebugRequest(res);
                        return res;
                    })
            }
        },
        refetchOnWindowFocus: props.refetchOnWindowFocus || false,
        // debug logs when failed - onError will be depricated in v5 of react-query so will need to find another way to debug log this
        onError: (error) => handleDebugRequest(error),
        enabled: props.enabled !== undefined ? props.enabled : false,
        retry: 3,
        select: (res) => !res ? res : res.data && res.data.status === 290 ? null : res.error ? res : props.select !== undefined ? props.select : !res && !res.data.instance ? null : res.data.instance,
    })
}


export function useMyMutation(props) {
    const handleDebugRequest = (res) =>
        handleRequestSettled({
            response: res,
            hasAlertError: props.hasAlertError,
            hasAlert: props.hasAlert !== undefined ? props.hasAlert : false,
            isSubmit: props.isSubmit !== undefined ? props.isSubmit : false,
            callback: props.callback !== undefined ? props.callback : undefined,
            customCallback: props.customCallback !== undefined ? props.customCallback : undefined
        });

    return useMutation({
        mutationKey: [props.key || props.queryKey || "myKey"],
        mutationFn: (propsFn) => {
            var myRoute = props.route;
            if (propsFn) {
                Object.entries(propsFn).map(key => {
                    console.log(key)
                    myRoute = myRoute.replace(`:replace_${key[0]}`, key[1])
                })
            }
            if (props.method.toUpperCase() === "GET") {
                return axios.get(myRoute, props.headers || null)
                    .then(res => {
                        handleDebugRequest(res);
                        return res;
                    })
            } else {

                return axios.post(myRoute, props.body)
                    .then(res => {
                        handleDebugRequest(res);
                        return res;
                    })
            }
        },
        onError: (error) => handleDebugRequest(error),
        retry: props.retry || false,
    });
}
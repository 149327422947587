import { Modal } from "antd";
import LineChart from "components/layouts/charts/linechart";
import NoData from "components/layouts/no-data/nodata";
import Panel, { defaultModalContent } from "components/modules/panel/panel";
import { t } from "i18next";
import moment from "moment";
import { useState } from "react";

export default function ScoreEvolutionPanel(props) {
    // # CLIENT STATES
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(defaultModalContent);


    return <>
        <Modal
            closable
            onCancel={() => setIsModalOpen(false)}
            title={modalContent.titleKey ? t(modalContent.titleKey) : modalContent.title}
            open={isModalOpen}
            okButtonProps={{ style: { display: "none" } }}
            cancelButtonProps={{ style: { display: "none" } }}
        >
            {typeof modalContent.children === "function" ? modalContent.children() : modalContent.children}
        </Modal>
        <Panel
            {...props.panelProps}
            title={<>{t("business_dashboard_panel_evolution_average_score_title")}</>}
            setIsModalOpen={setIsModalOpen}
            setModalContent={setModalContent}
        >
            {
                !props.data || !props.data[0].data ? <NoData wise="sad" className="my-5" /> :
                    <LineChart
                        xLabel={t("business_dashboard_date")}
                        yLabel={t("business_dashboard_score")}
                        maxYscale={"100"}
                        minYscale={"0"}
                        data={() => {
                            var data = props.data;
                            var newObjects = [];

                            data.forEach(elementType => {
                                var newData = [];
                                if (!elementType.data) { return }
                                (elementType.data).sort((a, b) => { return a.sortIndex - b.sortIndex }).forEach((element) => {
                                    // var date = getDateInFormat(element.adjustedDate, "MMM YY");
                                    const month = moment().month(element.monthIndex).format('MMM');
                                    const year = moment().year(element.yearIndex).format('YY');
                                    const date = `${month} ${year}`;
                                    newData.push({ x: date, y: element.score })
                                });
                                newObjects.push({ id: elementType.id, data: newData })
                            });
                            return newObjects;
                        }} />
            }
        </Panel>
    </>
}


export const howToImproveScoreEvolutionModalContent = {
    titleKey: "business_dashboard_panel_average_score_how_it_works_title",
    children: () => <>
        <p>
            {t("business_dashboard_panel_average_score_how_it_works_content")}
        </p>
    </>
};

import { Button, Form, Input, Select } from 'antd';
import useRegisterStepsHandler from 'components/requests/account/useRegisterStepsHandler';
import useGetUserFunctionOptions from 'components/requests/data/useGetUserFunctionOptions';
import i18next, { t } from 'i18next';
import produce from 'immer';
import { useEffect, useState } from 'react';
import AlertMessage from '../../components/forms/alerts/alert-message';
import InputPhone from 'components/forms/input/input-ant-phone';

export default function RegisterStep2(props) {
    // # UTILS
    const updateCompanyFormItem = (key, value) => setFormObjectStep2(
        produce((draft) => {
            draft[key] = value;
        })
    );
    const editPhone = (val) => updateCompanyFormItem("phone", val);

    // # CLIENT STATES
    const [formObjectStep2, setFormObjectStep2] = useState({
        firstName: "",
        lastName: "",
        jobPosition: null,
        phone: "",
    });
    const [allFunctions, setAllFunctions] = useState(false);
    const [message, setMessage] = useState(null);

    // # SERVER STATES
    const { isLoading: userFunctionsIsLoading } = useGetUserFunctionOptions({ setData: setAllFunctions });
    const mutateRegisterStepsHandler = useRegisterStepsHandler({
        formObject: props.formObject,
        formObjectStep2: formObjectStep2,
        userId: props.formObject.userId,
        setMessage: setMessage,
        step: props.currentStep,
        setStep: props.setStep
    })

    const doneSvg = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="7" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M8 0C3.58182 0 0 3.58182 0 8C0 12.4182 3.58182 16 8 16C12.4182 16 16 12.4182 16 8C16 3.58182 12.4182 0 8 0ZM11.4676 6.64727C11.5315 6.57429 11.5801 6.48927 11.6106 6.39721C11.6411 6.30516 11.6529 6.20794 11.6453 6.11126C11.6377 6.01459 11.6108 5.92041 11.5662 5.83428C11.5217 5.74814 11.4604 5.67179 11.3859 5.6097C11.3114 5.54762 11.2252 5.50106 11.1324 5.47277C11.0397 5.44448 10.9422 5.43503 10.8457 5.44497C10.7493 5.4549 10.6558 5.48403 10.5707 5.53064C10.4857 5.57725 10.4108 5.6404 10.3505 5.71636L7.22327 9.46836L5.60509 7.84946C5.46793 7.71698 5.28422 7.64367 5.09353 7.64533C4.90284 7.64699 4.72043 7.72347 4.58559 7.85831C4.45074 7.99316 4.37426 8.17557 4.3726 8.36625C4.37094 8.55694 4.44425 8.74065 4.57673 8.87782L6.75855 11.0596C6.83001 11.1311 6.91556 11.1868 7.00976 11.2233C7.10395 11.2598 7.20473 11.2763 7.30565 11.2717C7.40657 11.2672 7.50544 11.2416 7.59594 11.1967C7.68643 11.1518 7.76658 11.0885 7.83127 11.0109L11.4676 6.64727Z" fill="#18A89B" />
    </svg>;

    const currentSvg = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="8" fill="#F19023" />
    </svg>;

    const nextSvg = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="8" fill="#1B6A63" />
    </svg>;


    useEffect(() => {
        setMessage(false);
    }, [i18next.language]);

    const submitAccount = (event) => {
        mutateRegisterStepsHandler.mutate();
    }

    const submitAccountRecruiter = (event) => {
        props.setStep(3);
    }

    var companyForm = <>
        <Form
            className='mt-8'
            layout='vertical'
            name="form-register-2-company"
            onFinish={(e) => submitAccount(e)}
        >
            <Form.Item
                name="firstName"
                label={t("business_main_firstname")}
                rules={[{ required: true, message: false }]}
            >
                <Input
                    onChange={(e) => updateCompanyFormItem("firstName", e.target.value)}
                    placeholder={t("business_main_firstname")}
                />
            </Form.Item>
            <Form.Item
                name="lastName"
                label={t("business_main_lastname")}
                rules={[{ required: true, message: false }]}
            >
                <Input
                    onChange={(e) => updateCompanyFormItem("lastName", e.target.value)}
                    placeholder={t("business_main_lastname")}
                />
            </Form.Item>
            <Form.Item
                name="jobPosition"
                label={t("discard_register_select_jobposition")}
                rules={[{ required: true, message: false }]}
            >
                <Select
                    placeholder={t("-")}
                    onSelect={(value, option) => updateCompanyFormItem("jobPosition", option)}
                    options={allFunctions}
                    loading={userFunctionsIsLoading}
                />
            </Form.Item>
            <Form.Item
                name="phone"
                label={t("business_main_phone")}
                rules={[{ required: true, message: false }, { min: 7, message: false }]}
            >
                <InputPhone
                    name="phone"
                    required
                    onChange={editPhone}
                />
            </Form.Item>
            <div className="mt-2 d-inline-block">
                <Button
                    htmlType='submit'
                    className='btn btn-primary mb-2'
                    onClick={(e) => setMessage(null)}
                    loading={mutateRegisterStepsHandler.isLoading ? true : false}
                >
                    {t("business_main_next")} <span className="icon-arrow-right default pl-2" />
                </Button>
            </div>
        </Form>
    </>


    var recruiterForm = <>

        <Form
            className='mt-8'
            layout='vertical'
            name="form-register-2-recruiter"
            onFinish={(e) => submitAccountRecruiter(e)}
        >
            <Form.Item
                name="firstName"
                label={t("business_main_firstname")}
                rules={[{ required: true, message: false }]}
            >
                <Input
                    onChange={(e) => props.editFormItem("firstName", e.target.value)}
                    placeholder={t("business_main_firstname")}
                />
            </Form.Item>
            <Form.Item
                name="lastName"
                label={t("business_main_lastname")}
                rules={[{ required: true, message: false }]}
            >
                <Input
                    onChange={(e) => props.editFormItem("lastName", e.target.value)}
                    placeholder={t("business_main_lastname")}
                />
            </Form.Item>
            <div className="mt-2 d-inline-block">
                <Button
                    htmlType='submit'
                    className='btn btn-primary mb-2'
                    onClick={(e) => setMessage(null)}
                >
                    {t("business_main_next")} <span className="icon-arrow-right default pl-2" />
                </Button>
            </div>
        </Form>
    </>


    return (
        <div className="  d-flex hero-form hero-form-steps">
            <div className="bg-darkgreen bg-hero hero-form-left">
                <div className="container py-10">
                    <div className="d-flex justify-content-center align-items-start flex-column mt-3 ml-2">
                        <h1 className='color-white'>{t("discard_register_hero_title_steps")}</h1>
                        <div className="mt-3 register-steps">
                            <div>
                                <span>
                                    {doneSvg}
                                </span>
                                <span>
                                    {t("discard_register_step_account")}
                                </span>
                            </div>
                            <div>
                                <span>
                                    {currentSvg}
                                </span>
                                <span>
                                    {
                                        props.formObject.registerType !== "company" ?
                                            t("discard_register_step_general_info") :
                                            t("business_main_account_info")
                                    }
                                </span>
                            </div>
                            <div>
                                <span>
                                    {nextSvg}
                                </span>
                                <span>
                                    {
                                        props.formObject.registerType !== "company" ?
                                            t("discard_register_step_private_info") :
                                            t("discard_register_step_company")
                                    }
                                </span>
                            </div>
                            {
                                props.formObject.registerType !== "company" ? null :
                                    <div>
                                        <span>
                                            {nextSvg}
                                        </span>
                                        <span>
                                            {t("discard_register_step_plan")}
                                        </span>
                                    </div>
                            }
                            <div>
                                <span>
                                    {nextSvg}
                                </span>
                                <span>
                                    {t("discard_register_step_complete")}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-hero hero-form-right">
                <div className="container py-10 ">
                    <div className="px-5 py-3">
                        <div className="d-flex justify-content-between">
                            <div>
                                <h1 className='title'>{t("business_main_account_info")}</h1>
                                <div className='subtitle'>{t("business_registration_subtitle")}</div>
                            </div>
                            <span className="color-grey">
                                {
                                    props.formObject.registerType === "company" ?
                                        "2/5" :
                                        "2/4"
                                }
                            </span>
                        </div>
                        {
                            !message
                                ?
                                null
                                :
                                <AlertMessage message={message} setMessage={setMessage} />
                        }
                        {
                            props.formObject.registerType === "company" ?
                                companyForm :
                                recruiterForm
                        }

                    </div>
                </div>
            </div>
        </div>
    );
}
import { Modal } from "antd";
import Panel from "components/modules/panel/panel";
import Rating from "components/modules/rating/rating";
import { atmosphereAgencyIcon, candidateIcon, feedbackAgencyIcon, informationAgencyIcon, puzzleAgencyIcon, shakeHandsAgencyIcon, speedAgencyIcon, thumbsUpAgencyIcon } from "components/utils/icons/iconsDashboard";
import { t } from 'i18next';
import { useState } from "react";

/**VINCE */
export default function SoloScorePanel(props) {
    // # CLIENT STATES

    // # UTILS
    const dataItems = {
        jobMatch: {
            icon: puzzleAgencyIcon,
            title: t("business_dashboard_agency_match_score_panel_title")
        },
        help: {
            icon: shakeHandsAgencyIcon,
            title: t("business_dashboard_agency_help_score_panel_title")
        },
        nps: {
            icon: thumbsUpAgencyIcon,
            title: t("business_dashboard_agency_recommend_score_panel_title")
        },
        feedback: {
            icon: feedbackAgencyIcon,
            title: t("business_dashboard_agency_feedback_score_panel_title")
        },
        speed: {
            icon: speedAgencyIcon,
            title: t("business_dashboard_agency_speed_score_panel_title")
        },
        atmosphere: {
            icon: atmosphereAgencyIcon,
            title: t("business_dashboard_agency_atmosphere_score_panel_title")
        },
        information: {
            icon: informationAgencyIcon,
            title: t("business_dashboard_agency_information_score_panel_title")
        },
    }

    if (!dataItems[props.category]) return null

    const demoRatingDing = parseInt(Math.floor(Math.random() * 10) + 1);

    return <>
        <Panel
            {...props.panelProps}
            title={dataItems[props.category] ? dataItems[props.category].title : null}
        >
            {
                <div className="my-2 mx-2">
                    <div className={"mb-1 mb-2 flex flex-column align-items-center"}>
                        <span className="mb-3 flex flex align-items-center flex-row"
                        >
                            {/* <span className="fs-medium fw-semi-bold mr-2" style={{ marginBottom: -2 }}>{!props.data || (props.data && !props.data[props.category]) ? 0 : (props.data[props.category] / 10).toFixed(1)}</span> */}
                            <span className="fs-medium fw-semi-bold mr-2" style={{ marginBottom: -2 }}>{demoRatingDing}</span>
                            <span>{dataItems[props.category].icon}</span>
                        </span>
                        <Rating rating={demoRatingDing} noamount />
                        {/* <Rating rating={!props.data || (props.data && !props.data[props.category]) ? 0 : props.data[props.category] / 10} noamount /> */}
                    </div>
                </div>
            }
        </Panel>
    </>
}
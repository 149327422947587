import { Empty, Select, Table } from "antd";
import AccordionContent from "components/layouts/accordions/accordionContent";
import DashboardLayout from "components/layouts/dashboard/dashboard-layout";
import CandidateBrandExperiencePanel from "components/layouts/dashboard/panels/candidate-brand-experience-panel";
import MessagesPanel from "components/layouts/dashboard/panels/messages-panel";
import ScoreEvolutionPanel from "components/layouts/dashboard/panels/score-evolution-panel";
import { useGetDbGeneralData } from "components/requests/dashboard/getDbGeneralData";
import useGetDbMessages from "components/requests/dashboard/getDbMessages";
import { useGetDbScoreEvolution } from "components/requests/dashboard/getDbScoreEvolution";
import { useUserStore } from "components/utils/stores/userStore";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { useGetDbExperienceScale } from "components/requests/dashboard/getDbExperienceScale";
import ExperienceScalePanel from "components/layouts/dashboard/panels/experience-scale-panel";
import Rating from "components/modules/rating/rating";

export default function DeepDiveDashboardPage(props) {
    // # STORES
    const userStore = useUserStore();
    const { t } = useTranslation()
    const [filterUserId, setFilterUserId] = useState(null);
    const [filterPhaseId, setFilterPhaseId] = useState(null);

    // # SERVER STATES
    // base data
    const { data: dbGeneralData, isLoading: dbGeneralDataIsLoading, isRefetching: dbGeneralDataIsRefetching, isError: dbGeneralDataIsError, refetch } = useGetDbGeneralData({ enabled: true }); // company data (number of ratings, rating, companyname, planname)
    const { data: dbExperienceScaleFeedback, isLoading: dbExperienceScaleFeedbackIsLoading, isError: dbExperienceFeedbackIsError, refetch: dbExperienceScaleFeedbackRefetch, isRefetching: dbExperienceScaleFeedbackIsRefetching } = useGetDbExperienceScale({ category: "Feedback", key: "get-BC_Feedback", enabled: true });
    const { data: dbExperienceScaleInformation, isLoading: dbExperienceScaleInformationIsLoading, isError: dbExperienceScaleInformationIsError, refetch: dbExperienceScaleInformationRefetch, isRefetching: dbExperienceScaleInformationIsRefetching } = useGetDbExperienceScale({ filterUserId, filterPhaseId, category: "Information", key: "get-BC_Information", enabled: true });
    const { data: dbExperienceScaleSpeed, isLoading: dbExperienceScaleSpeedIsLoading, isError: dbExperienceScaleSpeedIsError, refetch: dbExperienceScaleSpeedRefetch, isRefetching: dbExperienceScaleSpeedIsRefetching } = useGetDbExperienceScale({ filterUserId, filterPhaseId, category: "Speed", key: "get-BC_Speed", enabled: true });
    const { data: dbExperienceScaleAtmosphere, isLoading: dbExperienceScaleAtmosphereIsLoading, isError: dbExperienceScaleAtmosphereIsError, refetch: dbExperienceScaleAtmosphereRefetch, isRefetching: dbExperienceScaleAtmosphereIsRefetching } = useGetDbExperienceScale({ filterUserId, filterPhaseId, category: "Atmosphere", key: "get-BC_Atmosphere", enabled: true });
    const { data: dbExperienceScaleAttractiveness, isLoading: dbExperienceScaleAttractivenessIsLoading, isError: dbExperienceScaleAttractivenessIsError, refetch: dbExperienceScaleAttractivenessRefetch, isRefetching: dbExperienceScaleAttractivenessIsRefetching } = useGetDbExperienceScale({ filterUserId, filterPhaseId, category: "Attractiveness", key: "get-BC_Attractiveness", enabled: true });
    const { data: dbExperienceScaleJobAttractiveness, isLoading: dbExperienceScaleJobAttractivenessIsLoading, isError: dbExperienceScaleJobAttractivenessIsError, refetch: dbExperienceScaleJobAttractivenessRefetch, isRefetching: dbExperienceScaleJobAttractivenessIsRefetching } = useGetDbExperienceScale({ filterUserId, filterPhaseId, category: "JobAttractiveness", key: "get-BC_JobAttractiveness", enabled: true });
    const { data: dbExperienceScaleBrandOnline, isLoading: dbExperienceScaleBrandOnlineIsLoading, isError: dbExperienceScaleBrandOnlineIsError, refetch: dbExperienceScaleBrandOnlineRefetch, isRefetching: dbExperienceScaleBrandOnlineIsRefetching } = useGetDbExperienceScale({ filterUserId, filterPhaseId, category: "Brand", key: "get-BC_BrandOnline", enabled: true });
    const { data: dbExperienceScaleSalary, isLoading: dbExperienceScaleSalaryIsLoading, isError: dbExperienceScaleSalaryIsError, refetch: dbExperienceScaleSalaryRefetch, isRefetching: dbExperienceScaleSalaryIsRefetching } = useGetDbExperienceScale({ filterUserId, filterPhaseId, category: "Salary", key: "get-BC_Salary", enabled: true });
    const { data: dbExperienceScaleRecurrency, isLoading: dbExperienceScaleRecurrencyIsLoading, isError: dbExperienceScaleRecurrencyIsError, refetch: dbExperienceScaleRecurrencyRefetch, isRefetching: dbExperienceScaleRecurrencyIsRefetching } = useGetDbExperienceScale({ filterUserId, filterPhaseId, category: "Recurrency", key: "get-BC_Recurrency", enabled: true });


    return <>
        <DashboardLayout data={dbGeneralData} type={"Deep dive"}>
            <>
                <div className="row">
                    <div className="flex flex-row align-items-start justify-content-end mb-4">
                        <div className="flex flex-row align-items-center flex-nowrap" style={{ gap: 5 }}>
                            <Select
                                defaultValue={"all"}
                                style={{ minWidth: 200 }}
                                options={[
                                    {
                                        label: "All categories",
                                        value: "all",
                                    },
                                    {
                                        label: "Sales",
                                        value: "sales",
                                    },
                                    {
                                        label: "Operations",
                                        value: "operations",
                                    },
                                    {
                                        label: "IT",
                                        value: "it",
                                    },
                                    {
                                        label: "Finance",
                                        value: "finance",
                                    },

                                ]}>
                            </Select>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col gx-2">
                        <ExperienceScalePanel
                            data={[
                                // {
                                //     id: "BC_General",
                                //     label: t("business_dashboard_panel_experience_scale_filter_all"),
                                //     data: dbExperienceScaleGeneral,
                                // },
                                // {
                                //     id: "BC_CandidateExperience",
                                //     label: t("business_dashboard_panel_experience_scale_filter_candidate_experience"),
                                //     data: dbExperienceScaleCandidateExperience,
                                // },
                                {
                                    id: "Atmosphere",
                                    label: t("business_dashboard_panel_experience_scale_filter_atmosphere"),
                                    data: dbExperienceScaleAtmosphere,
                                },
                                {
                                    id: "Information",
                                    label: t("business_dashboard_panel_experience_scale_filter_information"),
                                    data: dbExperienceScaleInformation,
                                },
                                {
                                    id: "Speed",
                                    label: t("business_dashboard_panel_experience_scale_filter_speed"),
                                    data: dbExperienceScaleSpeed,
                                },
                                {
                                    id: "Feedback",
                                    label: t("business_dashboard_panel_experience_scale_filter_feedback"),
                                    data: dbExperienceScaleFeedback,
                                },
                                {
                                    id: "Salary",
                                    label: t("business_dashboard_panel_experience_scale_filter_salary"),
                                    data: dbExperienceScaleSalary,
                                },
                                {
                                    id: "Recurrency",
                                    label: t("business_dashboard_panel_experience_scale_filter_recurrency"),
                                    data: dbExperienceScaleRecurrency,
                                },
                                // {
                                //     id: "EmployerBrand",
                                //     label: t("business_dashboard_panel_experience_scale_filter_employerbrand"),
                                //     data: dbExperienceScaleEmployerBrand,
                                // },
                                {
                                    id: "Attractiveness",
                                    label: t("business_dashboard_panel_experience_scale_filter_attractiveness"),
                                    data: dbExperienceScaleAttractiveness,
                                },
                                {
                                    id: "JobAttractiveness",
                                    label: t("business_dashboard_panel_experience_scale_filter_jobattractiveness"),
                                    data: dbExperienceScaleJobAttractiveness,
                                },
                                {
                                    id: "Brand",
                                    label: t("business_dashboard_panel_experience_scale_filter_branonline"),
                                    data: dbExperienceScaleBrandOnline,
                                },
                            ]}
                            // isError={dbExperienceScaleGeneralIsError || dbExperienceFeedbackIsError}
                            isError={dbExperienceFeedbackIsError}
                            // isLoading={dbExperienceScaleGeneralIsLoading || dbExperienceScaleFeedbackIsLoading}
                            isLoading={dbExperienceScaleFeedbackIsLoading}
                            isRefetching={dbExperienceScaleFeedbackIsRefetching}
                        />
                    </div>
                </div>
            </>
        </DashboardLayout>
    </>
}
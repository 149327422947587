import { Divider, Timeline } from "antd";
import InputCopy from "components/forms/input/input-copy";
import ScrollToTop from "components/layouts/navigation/scrollToTop";
import { useGetDbExperienceScores } from "components/requests/dashboard/getDbExperienceScores";
import { useGetDbGeneralData } from "components/requests/dashboard/getDbGeneralData";
import { useGetDbMilestoneData } from "components/requests/dashboard/getDbMilestoneData";
import { useGetDbScoreEvolution } from "components/requests/dashboard/getDbScoreEvolution";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import { useGetCompanyShareData } from "pages/company/company-share";
import { ArrowLeftCircle, ChevronLeft } from "react-feather";
import { Link } from "react-router-dom";
import DashboardLayout from "../dashboard-layout";
import ExperienceEvolutionBarchartPanel from "../panels/experience-evolution-barchart-panel";
import MilestonesPanel from "../panels/milestones-panel";
import RatingAmountPanel, { settingsRatingAmountModalContent } from "../panels/rating-amount-panel";
import SoloScorePanel from "../panels/solo-score-panel";
import { useEffect, useState } from "react";
import Message from "components/modules/message/message";
import Panel from "components/modules/panel/panel";
import useGetDbMessages from "components/requests/dashboard/getDbMessages";
import { getDateInFormat } from "components/utils/methods/MyMethods";
import { dateFormat } from "components/utils/methods/AppMethods";
import MessagesPanel from "../panels/messages-panel";

export default function DashboardAgency(props) {
    // # STORES
    const ceKey = props.ceKey || null;
    const userStore = useUserStore();
    const [ceKeyData, setCeKeyData] = useState();
    const [isWeeklyChart, setIsWeeklyChart] = useState(false);

    // # SERVER STATES
    // base data
    const { data: dbGeneralData, isLoading: dbGeneralDataIsLoading, isRefetching: dbGeneralDataIsRefetching, isError: dbGeneralDataIsError } = useGetDbGeneralData({ enabled: true, ceKey: ceKey }); // company data (number of ratings, rating, companyname, planname)
    // const { data: dbMilestoneData, isLoading: dbMilestoneDataIsLoading, isRefetching: dbMilestoneDataIsRefetching, isError: dbMilestoneDataIsError } = useGetDbMilestoneData(); // milestone data
    const { data: dbExperienceScores, isLoading: dbExperienceScoresIsLoading, isRefetching: dbExperienceScoresIsRefetching, isError: dbExperienceScoresIsError } = useGetDbExperienceScores({ enabled: true, ceKey: ceKey }); // experience score data
    const { data: dbScoreEvolutionGeneral, isLoading: dbScoreEvolutionGeneralIsLoading, isRefetching: dbScoreEvolutionGeneralIsRefetching, isError: dbScoreEvolutionGeneralIsError, refetch } = useGetDbScoreEvolution({ enabled: true, ceKey: ceKey, useDivisions: true, isWeekNotation: isWeeklyChart }); // experience score data
    // const { data: dbCeKeys, isLoading: dbCeKeysIsLoading, isError: dbCeKeysIsError } = useGetCompanyShareData();
    const { data: dbMessages, isLoading: dbMessagesIsLoading, isError: dbMessagesIsError, isRefetching: dbMessagesIsRefetching } = useGetDbMessages();

    const showRatingSettings = userStore.flagData.toggleScorePraiseVisible || userStore.flagData.toggleScoreStarVisible /* || userStore.flagData.toggleScoreTextVisible */;
    const ratingsOptions = !showRatingSettings ? null : [{
        element: <>{t("business_dashboard_panel_option_settings")}</>,
        modalContent: settingsRatingAmountModalContent
    }];

    // useEffect(() => {
    //     if (!dbCeKeys) { return }
    //     if (!dbCeKeys || !dbCeKeys.urlAgencyKeys || dbCeKeys.urlAgencyKeys.length === 0) { return }
    //     setCeKeyData(dbCeKeys.urlAgencyKeys.find(item => item.ceKey === ceKey));
    // }, [dbCeKeys, ceKey])

    useEffect(() => {
        refetch()
    }, [isWeeklyChart]);

    return <>
        <DashboardLayout
            data={dbGeneralData}
            hasBack={ceKey ? true : false}
            title={ceKeyData && dbGeneralData ? `${dbGeneralData.companyName} & ${ceKeyData.agencyName}` : null}
            type={ceKey ? t("business_dashboard_type_cekey") : t("business_dashboard_type_agency")}
        >
            <ScrollToTop />
            {
                ceKey && <>
                    {
                        ceKeyData && <>
                            <div className="mb-4 mt-2 d-block">
                                <p className="mb-2 mr-2 font-special">{t("business_dashboard_cekey_your_link_label")}</p>
                                <InputCopy
                                    value={ceKeyData.urlSurvey}
                                />
                            </div>
                            <Divider className="mb-8" />
                        </>
                    }
                </>
            }
            {/* ### BASE ###  */}
            <section className="row mb-10">
                <div className="col-3">
                    <RatingAmountPanel
                        data={dbGeneralData}
                        panelProps={{
                            isLoading: dbGeneralDataIsLoading || dbGeneralDataIsRefetching, isError: dbGeneralDataIsError,
                            options: ceKey ? [] : ratingsOptions
                        }}
                    />
                    {/* {
                        ceKey ? null :
                            <MilestonesPanel
                                data={dbMilestoneData}
                                panelProps={{ isLoading: dbMilestoneDataIsLoading || dbMilestoneDataIsRefetching, isError: dbMilestoneDataIsError }}
                            />
                    } */}
                </div>
                <div className="col gx-5">

                    <div className="mb-4" >
                        <Divider orientation="left" className="mt-0">
                            <h3 className="mb-0">{t("business_dashboard_agency_title_scores_appreciation")}</h3>
                        </Divider>
                        <section id="agency-section-appreciation" className="row gx-2">
                            <div className="col-4">
                                <SoloScorePanel
                                    category={'bR_JobMatch'}
                                    data={!dbExperienceScores ? null : dbExperienceScores[0]}
                                    panelProps={{
                                        isLoading: dbExperienceScoresIsLoading || dbExperienceScoresIsRefetching, isError: dbExperienceScoresIsError,
                                    }}
                                />

                            </div>
                            <div className="col-4">
                                <SoloScorePanel
                                    category={'bR_Help'}
                                    data={!dbExperienceScores ? null : dbExperienceScores[0]}
                                    panelProps={{
                                        isLoading: dbExperienceScoresIsLoading || dbExperienceScoresIsRefetching, isError: dbExperienceScoresIsError,
                                    }}
                                />
                            </div>
                            <div className="col-4">
                                <SoloScorePanel
                                    category={'bR_NPS'}
                                    data={!dbExperienceScores ? null : dbExperienceScores[0]}
                                    panelProps={{
                                        isLoading: dbExperienceScoresIsLoading || dbExperienceScoresIsRefetching, isError: dbExperienceScoresIsError,
                                    }}
                                />

                            </div>
                        </section>
                    </div>
                    <div>
                        <Divider orientation="left">
                            <h3 className="mb-0">{t("business_dashboard_agency_title_scores_service")}</h3>
                        </Divider>
                        <div id="agency-section-experience" className="row gx-2">

                            <div className="col-3">
                                <SoloScorePanel
                                    category={'bR_Feedback'}
                                    data={!dbExperienceScores ? null : dbExperienceScores[0]}
                                    panelProps={{
                                        isLoading: dbExperienceScoresIsLoading || dbExperienceScoresIsRefetching, isError: dbExperienceScoresIsError,
                                    }}
                                />
                            </div>
                            <div className="col-3">
                                <SoloScorePanel
                                    category={'bR_Speed'}
                                    data={!dbExperienceScores ? null : dbExperienceScores[0]}
                                    panelProps={{
                                        isLoading: dbExperienceScoresIsLoading || dbExperienceScoresIsRefetching, isError: dbExperienceScoresIsError,
                                    }}
                                />
                            </div>
                            <div className="col-3">
                                <SoloScorePanel
                                    category={'bR_Atmosphere'}
                                    data={!dbExperienceScores ? null : dbExperienceScores[0]}
                                    panelProps={{
                                        isLoading: dbExperienceScoresIsLoading || dbExperienceScoresIsRefetching, isError: dbExperienceScoresIsError,
                                    }}
                                />
                            </div>
                            <div className="col-3">
                                <SoloScorePanel
                                    category={'bR_Information'}
                                    data={!dbExperienceScores ? null : dbExperienceScores[0]}
                                    panelProps={{
                                        isLoading: dbExperienceScoresIsLoading || dbExperienceScoresIsRefetching, isError: dbExperienceScoresIsError,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* ### APPLICANT EXPERIENCE ###  */}
            <section>
                <h1 className="title mb-4">{t("business_dashboard_section_experience_title")}</h1>
                <>
                    <div className="row">
                        <div className="col gx-2">
                            <ExperienceEvolutionBarchartPanel
                                data={dbScoreEvolutionGeneral}
                                setIsWeeklyChart={setIsWeeklyChart}
                                isWeeklyChart={isWeeklyChart}
                                panelProps={{
                                    isLoading: dbScoreEvolutionGeneralIsLoading || dbScoreEvolutionGeneralIsRefetching, isError: dbScoreEvolutionGeneralIsError,
                                }}
                            />

                        </div>
                    </div>
                    {/* {
                        ceKey ? null :
                            <div className="row">
                                <div className="col gx-2">
                                    <MessagesPanel
                                        data={dbMessages}
                                        panelProps={{
                                            isLoading: dbMessagesIsLoading || dbMessagesIsRefetching, isError: dbMessagesIsError,
                                        }}
                                    />
                                </div>
                            </div>
                    } */}
                    {/* {
                        !dbExperienceScaleGeneralAgency ? null :
                            <div className="row">
                                <div className="col gx-2">
                                    <ExperienceScalePanel
                                        data={[
                                            {
                                                id: "BR_General",
                                                label: t("business_dashboard_panel_experience_scale_filter_all"),
                                                data: dbExperienceScaleGeneralAgency,
                                            },
                                        ]}
                                        isError={dbExperienceScaleGeneralAgencyIsError}
                                        isLoading={dbExperienceScaleGeneralAgencyIsLoading}
                                    />
                                </div>
                            </div>
                    } */}
                </>
            </section>
        </DashboardLayout>
    </>
}
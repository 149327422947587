import { Timeline, Divider } from "antd"
import NoData from "components/layouts/no-data/nodata"
import Message from "components/modules/message/message"
import Panel from "components/modules/panel/panel"
import { dateFormat } from "components/utils/methods/AppMethods"
import { getDate, getDateInFormat } from "components/utils/methods/MyMethods"
import { t } from "i18next"
import { Fragment, useEffect, useState } from "react"
import { CornerDownRight, MinusCircle, PlayCircle, PlusCircle, XCircle } from "react-feather"

export default function MessagesPanel(props) {
    const dateFormat = "MMMM 'YY";

    const [dates, setDates] = useState();

    useEffect(() => {
        if (!props.data || props.data.length <= 0) {
            return
        }
        var dates = [];
        props.data.forEach(element => {
            if (getDateInFormat(element.createdOn, dateFormat) && dates.filter(date => date.label === getDateInFormat(element.createdOn, dateFormat)).length <= 0) {
                dates.push({ raw: element, label: getDateInFormat(element.createdOn, dateFormat) });
            }
        });
        setDates(dates)
    }, [props.data])

    return <>
        <Panel
            {...props.panelProps}
            scroll
            scrollHeight={400}
            title={t("business_dashboard_panel_messages_title")}
        >
            <div className="d-inline-block" style={{ width: "100%" }}>
                {
                    !dates || dates.length <= 0 ? <NoData wise="sad" className="my-4" center /> :
                        dates.sort((a, b) => a.raw.createdOn > b.raw.createdOn ? -1 : 1).map(date => {
                            var positiveItems = [];
                            var negativeItems = [];
                            props.data.filter(element => getDateInFormat(element.createdOn, dateFormat) === date.label).map(element => {
                                if (element.isPositive) {
                                    positiveItems.push(element)
                                } else {
                                    negativeItems.push(element)
                                }
                            })
                            return <>
                                <Divider orientationMargin="20" orientation="middle"><h2 className="fw-regular color-darkgrey mb-0" style={{ fontSize: 22 }}>  {date.label}</h2></Divider>

                                <div className="flex flex-row flex-wrap justify-content-center mb-5" style={{ width: "100%" }}>
                                    <div className="w-50 px-2">
                                        <div className=" my-4 d-flex flex-row flex-wrap align-items-center justify-content-center">
                                            <PlusCircle color="green" className="mx-3" size={50} />
                                            <h3 style={{ color: "#008000" }} className="fw-medium color-darkgrey mb-0">{t("business_main_positive")} {`(${positiveItems.filter(item => item.feedback.trim() !== 0).length})`}</h3>
                                        </div>

                                        {
                                            positiveItems && positiveItems.length > 0 ? positiveItems.sort((a, b) => a.createdOn > b.createdOn ? 1 : -1).map((message, i) => message.feedback && message.feedback.trim().length > 0 &&
                                                <Message key={i} message={message.feedback} from={`${t("business_main_anonymous_user")} - ${getDateInFormat(message.createdOn, `hh:mmu DD/MM/YYYY`)}`} />) :
                                                <NoData className="py-4" wise="smile" text={t("business_dashboard_panel_messages_none")} />
                                        }


                                    </div>
                                    <div className="w-50 b px-2">
                                        <div className=" my-4 d-flex flex-row flex-wrap align-items-center justify-content-center">
                                            <MinusCircle color="red" className="mx-3" size={50} />
                                            <h3 style={{ color: "#FF0000" }} className="fw-medium color-darkgrey mb-0">{t("business_main_negative")} {`(${negativeItems.filter(item => item.feedback.trim() !== 0).length})`}</h3>
                                        </div>
                                        {
                                            negativeItems && negativeItems.length > 0 ? negativeItems.sort((a, b) => a.createdOn > b.createdOn ? 1 : -1).map((message, i) => message.feedback && message.feedback.trim().length > 0 &&
                                                <Message key={i} message={message.feedback} from={`${t("business_main_anonymous_user")} - ${getDateInFormat(message.createdOn, `hh:mmu DD/MM/YYYY`)}`} />) :
                                                <NoData className="py-4" wise="smile" text={t("business_dashboard_panel_messages_none")} />
                                        }

                                    </div>
                                </div>
                            </>
                        })
                }
            </div>
        </Panel>
    </>
}
export function DemoMessagesPanel() {
    const dateFormat = "MMMM 'YY";

    const [dates, setDates] = useState();

    const data = [
        {
            "talentUserJobQuestionId": "5f70bfa3-5243-42f7-b1e3-f00bd420c28e",
            "feedback": "In de vacature staat heel veel tekst terwijl de werkelijke inhoud van de functie niet voldoende naar voren kwam. Mogelijk komt dit door het karakter van deze specifieke functie. Maar ondanks geheimhoudingsplicht rondom functieinhoud zou ik het duidelijker proberen te maken. ",
            "isPositive": false,
            "createdOn": "2024-07-09T18:26:27.6486804"
        },
        {
            "talentUserJobQuestionId": "d0f29602-f915-46ae-8d07-032a58be25ad",
            "feedback": "Het was een fijne manier van solliciteren. \nIk voelde meteen een fijne klik en kreeg direct duidelijke informatie over de job.\nKoen",
            "isPositive": true,
            "createdOn": "2024-06-21T11:26:36.7782646"
        },
        {
            "talentUserJobQuestionId": "65952d5e-1e21-40c2-a017-ad37160038ac",
            "feedback": "Niet meteen. Alles ging vlot.",
            "isPositive": true,
            "createdOn": "2024-09-05T13:06:57.88"
        },
        {
            "talentUserJobQuestionId": "b01587bf-f54e-4234-b92b-99b3821c0171",
            "feedback": "Het online solliciteren verliep niet zo vlot.... zoveel info dubbel die eigenlijk al in mijn CV staat en op LinkedIN...",
            "isPositive": false,
            "createdOn": "2024-07-23T11:25:34.2274341"
        },
        {
            "talentUserJobQuestionId": "90bf7404-cd74-4f46-98a2-a8d93c5bf34e",
            "feedback": "Ik wil jullie bedanken voor de fijne sollicitatie ervaring die ik heb gehad. De tips die jullie mij gaven over mijn carrière en loopbaan heb ik erg gewaardeerd!",
            "isPositive": true,
            "createdOn": "2024-06-21T22:26:35.9992007"
        },
        {
            "talentUserJobQuestionId": "54c4b6ff-4df1-4b61-b95e-75b96d63bfff",
            "feedback": "It was a pleasant way of applying. I got quick feedback that I could learn a lot from. Thanks! Regards, Sam.",
            "isPositive": true,
            "createdOn": "2024-06-21T19:26:35.7816212"
        },
        {
            "talentUserJobQuestionId": "6681c716-900a-4893-bf75-b2f8a5ceef3e",
            "feedback": "I enjoyed the conversation with HR and line manager during the interview process. I fully expressed my desire for this position and the high degree of compatibility with my own experience and the abilities. I will continue to pay attention to job posting and apply for it that interest me.",
            "isPositive": true,
            "createdOn": "2024-06-21T22:26:35.8116904"
        },
        {
            "talentUserJobQuestionId": "45abf735-9daf-4f06-b8d9-278927789471",
            "feedback": "Hello\nI just want to let you know that the group application session was very valuable for me and makes the difference!\nHope to hear back from you soon.\nRegards, Jason",
            "isPositive": true,
            "createdOn": "2024-06-21T13:26:37.2033179"
        }
    ];

    useEffect(() => {
        if (!data || data.length <= 0) {
            return
        }
        var dates = [];
        data.forEach(element => {
            if (getDateInFormat(element.createdOn, dateFormat) && dates.filter(date => date.label === getDateInFormat(element.createdOn, dateFormat)).length <= 0) {
                dates.push({ raw: element, label: getDateInFormat(element.createdOn, dateFormat) });
            }
        });
        setDates(dates)
    }, [])

    return <>
        <Panel
            scroll
            scrollHeight={400}
            title={t("business_dashboard_panel_messages_title")}
        >
            <div className="d-inline-block" style={{ width: "100%" }}>
                {
                    !dates || dates.length <= 0 ? <NoData wise="sad" className="my-4" center /> :
                        dates.sort((a, b) => a.raw.createdOn > b.raw.createdOn ? -1 : 1).map(date => {
                            var positiveItems = [];
                            var negativeItems = [];
                            data.filter(element => getDateInFormat(element.createdOn, dateFormat) === date.label).map(element => {
                                if (element.isPositive) {
                                    positiveItems.push(element)
                                } else {
                                    negativeItems.push(element)
                                }
                            })
                            return <>
                                <Divider orientationMargin="20" orientation="middle"><h2 className="fw-regular color-darkgrey mb-0" style={{ fontSize: 22 }}>  {date.label}</h2></Divider>

                                <div className="flex flex-row flex-wrap justify-content-center mb-5" style={{ width: "100%" }}>
                                    <div className="w-50 px-2">
                                        <div className=" my-4 d-flex flex-row flex-wrap align-items-center justify-content-center">
                                            <PlusCircle color="green" className="mx-3" size={50} />
                                            <h3 style={{ color: "#008000" }} className="fw-medium color-darkgrey mb-0">{t("business_main_positive")} {`(${positiveItems.filter(item => item.feedback.trim() !== 0).length})`}</h3>
                                        </div>

                                        {
                                            positiveItems && positiveItems.length > 0 ? positiveItems.sort((a, b) => a.createdOn > b.createdOn ? 1 : -1).map((message, i) => message.feedback && message.feedback.trim().length > 0 &&
                                                <Message key={i} message={message.feedback} from={`${t("business_main_anonymous_user")} - ${getDateInFormat(message.createdOn, `hh:mmu DD/MM/YYYY`)}`} />) :
                                                <NoData className="py-4" wise="smile" text={t("business_dashboard_panel_messages_none")} />
                                        }


                                    </div>
                                    <div className="w-50 b px-2">
                                        <div className=" my-4 d-flex flex-row flex-wrap align-items-center justify-content-center">
                                            <MinusCircle color="red" className="mx-3" size={50} />
                                            <h3 style={{ color: "#FF0000" }} className="fw-medium color-darkgrey mb-0">{t("business_main_negative")} {`(${negativeItems.filter(item => item.feedback.trim() !== 0).length})`}</h3>
                                        </div>
                                        {
                                            negativeItems && negativeItems.length > 0 ? negativeItems.sort((a, b) => a.createdOn > b.createdOn ? 1 : -1).map((message, i) => message.feedback && message.feedback.trim().length > 0 &&
                                                <Message key={i} message={message.feedback} from={`${t("business_main_anonymous_user")} - ${getDateInFormat(message.createdOn, `hh:mmu DD/MM/YYYY`)}`} />) :
                                                <NoData className="py-4" wise="smile" text={t("business_dashboard_panel_messages_none")} />
                                        }

                                    </div>
                                </div>
                            </>
                        })
                }
            </div>
        </Panel>
    </>
}
// export default function MessagesPanel(props) {
//     const dateFormat = "MMMM 'YY"

//     return <>
//         <Panel
//             {...props.panelProps}
//             scroll
//             scrollHeight={400}
//             title={t("business_dashboard_panel_messages_title")}
//         >
//             <div className="d-inline-block" style={{ width: "100%" }}>
//                 {
//                     !props.data || props.data.length <= 0 ? <NoData wise="sad" className="my-4" center /> :
//                         <div className="flex flex-row flex-wrap justify-content-center" style={{ width: "100%" }}>
//                             <div className="w-50 px-2">
//                                 <div className="block text-center my-4">
//                                     <PlusCircle color="green" size={50} />
//                                 </div>
//                                 {
//                                     props.data && <Timeline
//                                         id="feedback-timeline"
//                                         mode="left"
//                                         style={{ maxWidth: 900 }}
//                                         className="mx-10 my-4"
//                                         items={
//                                             props.data && props.data.filter((message) => Boolean(message.isPositive) === true).sort((a, b) => a.createdOn > b.createdOn ? -1 : 1).map((message, i) => {
//                                                 if (message.feedback.trim().length === 0) { return }
//                                                 const prevItemDate = i === 0 ? null : props.data.filter((message) => Boolean(message.isPositive) === true).sort((a, b) => a.createdOn > b.createdOn ? -1 : 1)[i - 1].createdOn;
//                                                 return {
//                                                     color: message.replyText ? 'green' : 'gray',
//                                                     label: prevItemDate && getDateInFormat(prevItemDate, dateFormat) === getDateInFormat(message.createdOn, dateFormat) ? null : getDateInFormat(message.createdOn, dateFormat),
//                                                     children: <p style={{ wordBreak: "break-word", display: "inline" }}>{message.feedback}</p>,
//                                                 }
//                                             })
//                                         }
//                                     />
//                                 }

//                             </div>
//                             <div className="w-50 b px-2">
//                                 <div className="block text-center my-4">
//                                     <MinusCircle color="red" size={50} />
//                                 </div>
//                                 {
//                                     props.data && <Timeline
//                                         id="feedback-timeline"
//                                         mode="left"
//                                         style={{ maxWidth: 900 }}
//                                         className="mx-10 my-4"
//                                         items={
//                                             props.data && props.data.filter((message) => Boolean(message.isPositive) === false).sort((a, b) => a.createdOn > b.createdOn ? -1 : 1).map((message, i) => {
//                                                 if (message.feedback.trim().length === 0) { return }
//                                                 const prevItemDate = i === 0 ? null : props.data.filter((message) => Boolean(message.isPositive) === false).sort((a, b) => a.createdOn > b.createdOn ? -1 : 1)[i - 1].createdOn;
//                                                 return {
//                                                     color: message.replyText ? 'green' : 'gray',
//                                                     label: prevItemDate && getDateInFormat(prevItemDate, dateFormat) === getDateInFormat(message.createdOn, dateFormat) ? null : getDateInFormat(message.createdOn, dateFormat),
//                                                     children: <p style={{ wordBreak: "break-word", display: "inline" }}>{message.feedback}</p>,
//                                                 }
//                                             })
//                                         }
//                                     />

//                                 }
//                             </div>
//                         </div>
//                 }
//             </div>
//         </Panel>
//     </>
// }

import { AutoComplete, Button, Form, Input, Select, Spin, Tag } from 'antd';
import useRegisterRecruiterStepsHandler from 'components/requests/account/useRegisterRecruiterStepsHandler';
import useRegisterStepsHandler from 'components/requests/account/useRegisterStepsHandler';
import useGetCountryOptions from 'components/requests/data/useGetCountryOptions';
import useGetSuggestions from 'components/requests/data/useGetSuggestions';
import { useUserStore } from 'components/utils/stores/userStore';
import i18next, { t } from 'i18next';
import { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import AlertMessage from '../../components/forms/alerts/alert-message';
import { CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'
import InputPhone from 'components/forms/input/input-ant-phone';

export default function RegisterStep3(props) {
    // # STORES
    const userStore = useUserStore();

    // # UTILS
    const suggestionThreshold = 0;
    const updatePhone = (val) => props.editFormItem("phone", val)

    // # CLIENT STATES
    const [form] = Form.useForm();
    const [message, setMessage] = useState(null);
    const [doNextStep, setDoNextStep] = useState(false);
    const [allCountries, setCountries] = useState(false);
    // ant design autocomplete issue! - property value needs to be unique (unique key does not work - https://github.com/ant-design/ant-design/issues/11909)
    // so for it to be visually ok in the input, i put the text it has to use in a state and give it to the component, that way the not proper value wont show
    const [suggestionInput, setSuggestionInput] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [isBlocked, setBlocked] = useState(false);

    // # SERVER STATES    
    const { } = useGetCountryOptions({ setData: setCountries });
    const mutateSuggestions = useGetSuggestions({ setData: setSuggestions })
    const mutateRegisterStepsHandler = useRegisterStepsHandler({
        companyname: props.formObject.companyName,
        companyvat: props.formObject.companyVat,
        companyReferralcode: props.formObject.referrence,
        editFormItem: props.editFormItem,
        setCompanyId: props.setCompanyId,
        userId: props.formObject.userId,
        setMessage: setMessage,
        step: props.currentStep,
        setStep: props.setStep,
        setSideStepData: props.setSideStepData,
        setExistingData: props.setExistingData
    })
    const mutateRegisterRecruiterStepsHandler = useRegisterRecruiterStepsHandler({
        referenceCode: props.formObject.referrence,
        setMessage: setMessage,
        step: doNextStep ? 3 : 2,
        setStep: props.setStep,
        email: props.formObject.registerEmail,
        password: props.formObject.password,
        firstName: props.formObject.firstName,
        lastName: props.formObject.lastName,
        vatNumber: props.formObject.companyVat,
        adress1: props.formObject.address1,
        adress2: props.formObject.address2,
        zipCode: props.formObject.zipCode,
        country: props.formObject.country,
        city: props.formObject.city,
        phone: props.formObject.phone,
        setDoNextStep: setDoNextStep
    })

    const doneSvg = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="7" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M8 0C3.58182 0 0 3.58182 0 8C0 12.4182 3.58182 16 8 16C12.4182 16 16 12.4182 16 8C16 3.58182 12.4182 0 8 0ZM11.4676 6.64727C11.5315 6.57429 11.5801 6.48927 11.6106 6.39721C11.6411 6.30516 11.6529 6.20794 11.6453 6.11126C11.6377 6.01459 11.6108 5.92041 11.5662 5.83428C11.5217 5.74814 11.4604 5.67179 11.3859 5.6097C11.3114 5.54762 11.2252 5.50106 11.1324 5.47277C11.0397 5.44448 10.9422 5.43503 10.8457 5.44497C10.7493 5.4549 10.6558 5.48403 10.5707 5.53064C10.4857 5.57725 10.4108 5.6404 10.3505 5.71636L7.22327 9.46836L5.60509 7.84946C5.46793 7.71698 5.28422 7.64367 5.09353 7.64533C4.90284 7.64699 4.72043 7.72347 4.58559 7.85831C4.45074 7.99316 4.37426 8.17557 4.3726 8.36625C4.37094 8.55694 4.44425 8.74065 4.57673 8.87782L6.75855 11.0596C6.83001 11.1311 6.91556 11.1868 7.00976 11.2233C7.10395 11.2598 7.20473 11.2763 7.30565 11.2717C7.40657 11.2672 7.50544 11.2416 7.59594 11.1967C7.68643 11.1518 7.76658 11.0885 7.83127 11.0109L11.4676 6.64727Z" fill="#18A89B" />
    </svg>;

    const currentSvg = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="8" fill="#F19023" />
    </svg>;

    const nextSvg = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="8" fill="#1B6A63" />
    </svg>;

    useEffect(() => {
        setMessage(false);
    }, [i18next.language]);

    function onSuggestionInput(e) {
        setSuggestionInput(e);
        props.editFormItem("companyName", e)
        if ((e).length <= suggestionThreshold) {
            setSuggestions([]);
            return;
        }
        form.setFieldsValue({ "companyName": e });
        mutateSuggestions.mutate({ input: e, type: "CompanyName" });
    }

    useEffect(() => {
        if (!doNextStep) { return }
        if (doNextStep) {
            mutateRegisterRecruiterStepsHandler.mutate();
        }
    }, [doNextStep])

    var companyForm = <>
        <Form
            form={form}
            name="form-register-3-company"
            className='mt-8'
            layout='vertical'
            onFinishFailed={(err) => console.error(err)}
            onFinish={() => mutateRegisterStepsHandler.mutate()}
        >
            <Form.Item
                name="companyName"
                label={t("business_main_company_name")}
                rules={[{ required: true, message: false }]}
            >
                <AutoComplete
                    notFoundContent={mutateSuggestions.isLoading ? <Spin /> : null}
                    placeholder={t("business_main_company_name")}
                    onChange={(e) => { setBlocked(false); onSuggestionInput(e); }}
                    options={mutateSuggestions.isLoading ? [] : suggestions}
                    value={suggestionInput} // this is essensial!! - check comment at state (above)
                    onSelect={(value, option) => {
                        form.setFieldsValue({ "companyVat": option.extra });
                        setSuggestionInput(option.actualValue)
                        props.editFormItem("companyVat", option.extra);
                        props.editFormItem("companyName", option.actualValue);
                        if (option.extra) {
                            setTimeout(() => {
                                setBlocked(true);
                            }, 50);
                        }
                    }}
                    disabled={isBlocked}
                    required
                />
                {
                    !isBlocked ? null :
                        <div className="mt-2">
                            <Tag closable onClose={(e) => {
                                e.preventDefault();
                                setBlocked(false);
                                setSuggestions([]);
                                setSuggestionInput("");
                                props.editFormItem("companyVat", "")
                                form.resetFields();
                            }}>
                                {t("business_main_clear")}
                            </Tag>

                            <Tag icon={<CheckCircleOutlined />} color="success">
                                <span className='font-special fs-small'>
                                    {t("business_registration_company_known")}
                                </span>
                            </Tag>
                        </div>
                }
            </Form.Item>
            <Form.Item
                name="companyVat"
                label={t("business_main_vatnumber")}
                rules={[{
                    required: true, message: false
                },
                {
                    message: false, pattern: /^(\D*\d){2,}/,
                }
                ]}
            >
                {
                    isBlocked ?
                        <Input
                            placeholder={t("business_main_vatnumber")}
                            value={props.formObject.companyVat}
                            disabled
                        />
                        :
                        <Input
                            placeholder={t("business_main_vatnumber")}
                            onChange={(e) => props.editFormItem("companyVat", e.target.value)}
                        />
                }
            </Form.Item>

            {/* {
                !props.formObject.companyName ? null :
                    isBlocked ?
                        <Tag icon={<CheckCircleOutlined />} className='large mb-2' color="success">
                            {t("business_registration_company_known")}
                        </Tag>
                        :
                        <Tag icon={<MinusCircleOutlined />} className='large mb-2 color-darkgrey'>
                            {t("business_registration_company_unknown")}
                        </Tag>
            } */}

            <div className="mt-2">
                <Button
                    htmlType='submit'
                    className='btn btn-primary mb-2'
                    loading={mutateRegisterStepsHandler.isLoading}
                >
                    {t("business_main_next")} <span className="icon-arrow-right default pl-2" />
                </Button>
            </div>
        </Form>
    </>

    var recruiterForm = <>
        <Form
            className='mt-8'
            layout='vertical'
            onFinish={(e) => mutateRegisterRecruiterStepsHandler.mutate()}
            name="form-register-3-recruiter"
        >
            <Form.Item
                name="companyVat"
                label={<>
                    {t("business_main_vatnumber")}:
                    <span className="icon-info grey icon fs-small ml-1" data-tip={t("business_tooltip_registration_freelance_vatnumber")} />
                    <ReactTooltip place='bottom' className='tooltip' />
                </>}
                rules={[{ required: true, message: false }]}
            >
                <Input
                    onChange={(e) => props.editFormItem("companyVat", e.target.value)}
                    placeholder={t("business_main_vatnumber")}
                />
            </Form.Item>
            <Form.Item
                name="phone"
                label={t("business_main_phone")}
                rules={[{ required: true, message: false }, { min: 7, message: false }]}
            >
                <InputPhone
                    name="phone"
                    required
                    onChange={updatePhone}
                />
            </Form.Item>
            <hr className='mb-4 mt-2' />
            <Form.Item
                name="country"
                label={t("business_main_country")}
                rules={[{ required: true, message: false }]}
            >
                <Select
                    showSearch
                    optionFilterProp='label'
                    onSelect={(value, option) => props.editFormItem("country", option.id)}
                    placeholder={t("business_main_country")}
                    options={allCountries}
                />
            </Form.Item>
            <Form.Item
                name="city"
                label={t("business_main_city")}
                rules={[{ required: true, message: false }]}
            >
                <Input
                    onChange={(e) => props.editFormItem("city", e.target.value)}
                    placeholder={t("business_main_city")}
                />
            </Form.Item>
            <Form.Item
                name="zipCode"
                label={t("business_main_zipcode")}
                rules={[{ required: true, message: false }]}
            >
                <Input
                    onChange={(e) => props.editFormItem("zipCode", e.target.value)}
                    placeholder={t("business_main_zipcode")}
                />
            </Form.Item>
            <Form.Item
                name="address1"
                label={t("discard_register_step_company_adres1")}
                rules={[{ required: true, message: false }]}
            >
                <Input
                    onChange={(e) => props.editFormItem("address1", e.target.value)}
                    placeholder={t("discard_register_step_company_adres1")}
                />
            </Form.Item>
            <Form.Item
                name="address2"
                label={t("discard_register_step_company_adres2")}
            >
                <Input
                    onChange={(e) => props.editFormItem("address2", e.target.value)}
                    placeholder={t("discard_register_step_company_adres2")}
                />
            </Form.Item>
            <div className="mt-2 d-inline-block">
                <Button
                    htmlType='submit'
                    className='btn btn-primary mb-2'
                    onClick={(e) => setMessage(null)}
                    loading={mutateRegisterRecruiterStepsHandler.isLoading}
                >
                    {t("business_main_next")} <span className="icon-arrow-right default pl-2" />
                </Button>
            </div>
        </Form>
    </>

    return (
        <div className="  d-flex hero-form hero-form-steps">
            <div className="bg-darkgreen bg-hero hero-form-left">
                <div className="container py-10">
                    <div className="d-flex justify-content-center align-items-start flex-column mt-3 ml-2">
                        <h1 className='color-white'>{t("discard_register_hero_title_steps")}</h1>
                        <div className="mt-3 register-steps">
                            <div>
                                <span>
                                    {doneSvg}
                                </span>
                                <span>
                                    {t("discard_register_step_account")}
                                </span>
                            </div>
                            <div>
                                <span>
                                    {doneSvg}
                                </span>
                                <span>
                                    {
                                        props.formObject.registerType !== "company" ?
                                            t("discard_register_step_general_info") :
                                            t("business_main_account_info")
                                    }
                                </span>
                            </div>
                            <div>
                                <span>
                                    {currentSvg}
                                </span>
                                <span>
                                    {
                                        props.formObject.registerType !== "company" ?
                                            t("discard_register_step_private_info") :
                                            t("discard_register_step_company")
                                    }
                                </span>
                            </div>
                            {
                                props.formObject.registerType !== "company" ? null :
                                    <div>
                                        <span>
                                            {nextSvg}
                                        </span>
                                        <span>
                                            {t("discard_register_step_plan")}
                                        </span>
                                    </div>
                            }
                            <div>
                                <span>
                                    {nextSvg}
                                </span>
                                <span>
                                    {t("discard_register_step_complete")}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-hero hero-form-right">
                <div className="container py-10 ">
                    <div className="px-5 py-3">
                        <div className="d-flex justify-content-between">
                            {
                                props.formObject.registerType === "company" ?
                                    <div>
                                        <h1 className='title'>{t("discard_register_step_company")}</h1>
                                        <div className='subtitle'>{t("business_registration_subtitle")}</div>
                                    </div> :
                                    <div>
                                        <h1 className='title'>{t("discard_register_step_private_info")}</h1>
                                        <div className='subtitle'>{t("business_registration_subtitle")}</div>
                                    </div>
                            }
                            <span className="color-grey">
                                {
                                    props.formObject.registerType === "company" ?
                                        "3/5" :
                                        "3/4"
                                }
                            </span>
                        </div>
                        {
                            !message ? null :
                                <AlertMessage message={message} setMessage={setMessage} />
                        }
                        {
                            props.formObject.registerType === "company" ?
                                companyForm :
                                recruiterForm
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
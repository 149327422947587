import { InfoCircleFilled, QuestionCircleFilled } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Divider, Form, Input, Select, Spin, Tabs } from "antd";
import axios from "axios";
import chalk from 'chalk';
import Loading from "components/animations/loading";
import InputHtml from "components/forms/input/input-html";
import GuestRedirectLayout from "components/layouts/page-layouts/guest-redirect";
import useEditAgencySectors from "components/requests/company/useEditAgencySectors";
import useGetCompanyPromises from "components/requests/company/useGetCompanyPromises";
import useGetPromisesContent from "components/requests/data/useGetPromisesContent";
import useGetPromisesLanguages from "components/requests/data/useGetPromisesLanguages";
import useGetSectorsOptions from "components/requests/data/useGetSectorsOptions";
import { useUserStore } from "components/utils/stores/userStore";
import i18next, { t } from "i18next";
import $ from 'jquery';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import AlertMessage from "../../components/forms/alerts/alert-message";
import NoData from "../../components/layouts/no-data/nodata";
import { handleRequestError, handleRequestSuccess, hasPermission } from "../../components/utils/methods/MyMethods";
import envir from '../../env.json';
import LayoutCompany from "./layout-company";
import { useLanguagesStore } from "components/utils/stores/languagesStore";

export default function CompanyRecruitmentPage(props) {
    // # STORES
    const userStore = useUserStore();
    const languagesStore = useLanguagesStore();

    // # UTILS
    const navigate = useNavigate();
    const threshold = 5;
    const maxChar = 1250; // ALSO CHANGE IN INPUT HTML COMPONENT!!
    const objectCore = "E7E19A35-3C26-4863-9D44-366502A0E65C";
    const objectTips = "F94D3683-46C8-4CAA-8B66-BE0B6F36F0C8";

    // # CLIENT STATES
    const [tabs, setTabs] = useState([]);
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [allTags, setAllTags] = useState([])
    const [allCurrentTags, setAllCurrentTags] = useState([])

    const [message, setMessage] = useState(null);
    const [promiseContent, setPromiseContent] = useState(null); // CONTENT - TOOLTIPS
    const [currentPromiseCore, setPromiseCore] = useState([]);
    const [currentPromiseTips, setPromiseTips] = useState([]);
    const [currentPromises, setPromises] = useState([]); // DATA - COMPANY
    const [currentLangs, setLangs] = useState([]);
    const [cCounter, setCounter] = useState(0);
    const [tipsVideo, setTipsVideo] = useState("");
    const [coreVideo, setCoreVideo] = useState("");
    // PERMISSIONS
    const [PM_AgencySpecializedEdit, setPM_AgencySpecializedEdit] = useState(false);
    const [PM_AgencyPromiseEdit, setPM_AgencyPromiseEdit] = useState(false);
    const [PM_AgencyPromiseView, setPM_AgencyPromiseView] = useState(false);
    const [PM_PlanView, setPM_PlanView] = useState(false);
    const [PM_PromiseView, setPM_PromiseView] = useState(false);
    const [PM_ProcedureView, setPM_ProcedureView] = useState(false);
    const [PM_RecruitmentHistoryView, setPM_RecruitmentHistoryView] = useState(false);

    // # SERVER STATES
    const { refetch: companyPromisesRefetch, isRefetching: companyPromisesIsFetching, isLoading: companyPromisesIsLoading } = useGetCompanyPromises({
        setCoreVideo: setCoreVideo,
        setTipsVideo: setTipsVideo,
        setPromiseCore: setPromiseCore,
        setPromiseTips: setPromiseTips,
        setPromises,
        category: envir.Variables.PromiseTag_Agency
    })
    const mutateAgencyPromises = useEditAgencyPromises({
        setCounter, cCounter, coreVideo, tipsVideo, currentPromiseCore, currentPromiseTips, maxChar
    })
    const { isLoading: agencySectorsIsLoading, isRefetching: agencySectorsIsRefetching, refetch: agencySectorsRefetch } = useGetAgencySectors({ setData: setAllCurrentTags, form: form }); // get business agency tags
    const { isLoading: sectorsIsLoading } = useGetSectorsOptions({ setData: setAllTags }); // get all business option tags
    const { refetch: promiseContentRefetch } = useGetPromisesContent({ setData: setPromiseContent, category: envir.Variables.PromiseTag_Agency }) // 
    const { refetch: promisesLanguagesRefetch } = useGetPromisesLanguages({ setData: setLangs })
    const mutateAgencySectors = useEditAgencySectors({ tags: allCurrentTags, getTags: agencySectorsRefetch });


    useEffect(() => {
        if (!userStore.companyData) { return }
        if (!userStore.companyData === null) {
            navigate("/");
        }

        if (userStore.companyData.subscriptionData.int1 === 10 && userStore.companyData.subscriptionData.guid1 === "0e63167e-848d-42aa-9365-864318aa89d0") {
            navigate("/");
        }
    }, [userStore.companyData]);

    // START AT TOP OF PAGE ON LOAD
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    function getPromiseData() {
        console.log(chalk.bgBrightBlue("Function getPromiseData"))
        setPromises([]);
        setLangs([])
        setTimeout(() => {

            if (!userStore.clientData.checkedRememberMe) { return }
            if (!userStore.companyData) { return }
            promiseContentRefetch();
            promisesLanguagesRefetch();
            companyPromisesRefetch();
            agencySectorsRefetch()
        }, 200);
    }

    useEffect(() => {
        if (!userStore.userData) { return }
        if (!userStore.userData.permissionsData) { return }
        hasPermission(userStore.userData.permissionsData, "PM_EDIT_BUSINESS_DETAILS", setPM_AgencySpecializedEdit);
        hasPermission(userStore.userData.permissionsData, "PM_EDIT_VALUES", setPM_AgencyPromiseEdit);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_VALUES", setPM_AgencyPromiseView);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_SUBSCRIPTIONS", setPM_PlanView);

        // PERMISSION_COMPANY_HANDLER
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_VALUES", setPM_PromiseView);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_PROCEDURES", setPM_ProcedureView);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_RECRUITHISTORY", setPM_RecruitmentHistoryView);
    }, [userStore.userData]);


    // handle promise language tabs & content and put it in a state used by tabs component
    useEffect(() => {
        if (currentLangs.length === 0) { return }
        if (!promiseContent) { return }
        if (!languagesStore.data) { return }
        if (companyPromisesIsFetching || companyPromisesIsLoading) { return }

        // handle promise videos defaults
        form2.setFieldsValue({
            "video-core": currentPromises.length === 0 ? "" : currentPromises.filter(promise => promise.valueTypeId.toLowerCase() === objectCore.toLowerCase())[0].videoUrl,
            "video-tips": currentPromises.length === 0 ? "" : currentPromises.filter(promise => promise.valueTypeId.toLowerCase() === objectTips.toLowerCase())[0].videoUrl
        })
        newTabsHandler();
    }, [currentLangs, currentPromises, promiseContent, languagesStore.data])

    function newTabsHandler() {

        var newItems = [];
        currentLangs.map((lang, i) => {
            return newItems.push({
                key: i + 1,
                forceRender: true,
                label: <>{`${lang.name}`}</>,
                children: <div className="mt-3 mx-3">
                    {
                        promiseContent === null ? <Loading text={null} /> :
                            promiseContent === undefined ? "null" :
                                (promiseContent).map((subitem, ie) => {
                                    return <div key={ie} className="mb-4">
                                        <div className="item-info d-flex align-items-end mb-3">
                                            <div>
                                                {subitem.title}
                                            </div>
                                            <div className="ml-2">
                                                <ReactTooltip className="tooltip" place="bottom" html />
                                                <span className="mr-1" data-tip={subitem.suggestions}>
                                                    <QuestionCircleFilled className="color-darkgrey" />
                                                </span>
                                                <span className="mr-1" data-tip={subitem.examples}>
                                                    <InfoCircleFilled className="color-darkgrey" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="item-edit">
                                            {
                                                // RENDER CORRECT INPUT DEPENING ON LOOP ITEM  
                                                (subitem.typeId).toUpperCase() === (objectCore).toUpperCase() ?
                                                    <InputHtml
                                                        // CORE VALUES
                                                        setItem={setPromiseCore}
                                                        currentValues={currentPromiseCore}
                                                        defaultValue={currentPromiseCore}
                                                        currentLang={lang.id}
                                                        currentObject={objectCore}
                                                        currentLangISO={lang.isoCode}
                                                        disabled={!PM_AgencyPromiseEdit}
                                                    /> :
                                                    (subitem.typeId).toUpperCase() === (objectTips).toUpperCase() ?
                                                        <InputHtml
                                                            // TIPS
                                                            setItem={setPromiseTips}
                                                            currentValues={currentPromiseTips}
                                                            defaultValue={currentPromiseTips}
                                                            currentLang={lang.id}
                                                            currentObject={objectTips}
                                                            currentLangISO={lang.isoCode}
                                                            disabled={!PM_AgencyPromiseEdit}
                                                        /> :
                                                        null
                                            }
                                        </div>
                                    </div>
                                })
                    }
                </div>
            })
        })
        setTabs(newItems);
    }

    return (
        <GuestRedirectLayout>
            <LayoutCompany
                title={t("business_company_agency_title")}
                subtitle={t("business_company_agency_subtitle")}
                showPlan
            >
                <>

                    {
                        !message ? null :
                            <AlertMessage message={message} setMessage={setMessage} />
                    }
                    <div className="mt-3">
                        {
                            !PM_AgencySpecializedEdit ? <div className="my-3 mb-5 card"> <NoData wise="cross" text={t("business_extra_permission_required")} /></div> :
                                <div className="card mb-5">
                                    {
                                        <Form
                                            name="form-recruiter-sectors"
                                            layout="vertical"
                                            form={form}
                                            onFinish={() => mutateAgencySectors.mutate()}
                                        >
                                            <Form.Item
                                                layout="vertical"
                                                label={t("business_company_agency_panel_sectors_specialised")}
                                                name="sectors"
                                            >
                                                <Select
                                                    loading={agencySectorsIsLoading || agencySectorsIsRefetching}
                                                    mode="multiple"
                                                    size={"large"}
                                                    disabled={!PM_AgencySpecializedEdit}
                                                    notFoundContent={sectorsIsLoading ? <Spin /> : null}
                                                    placeholder="-"
                                                    value={allCurrentTags}
                                                    onSelect={(value, option) => setAllCurrentTags([...allCurrentTags, option])}
                                                    onDeselect={(value) => setAllCurrentTags(allCurrentTags.filter(item => item.id !== value))}
                                                    style={{ width: '100%' }}
                                                    options={allTags}
                                                />
                                            </Form.Item>
                                            <Button
                                                htmlType="submit"
                                                loading={mutateAgencySectors.isLoading}
                                                className="btn btn-primary"
                                            >
                                                {t("business_main_save")}
                                            </Button>
                                        </Form>
                                    }
                                </div>
                        }
                        <p className="subtitle">{t("business_company_agency_panel_promise_description")}</p>
                        {
                            !PM_AgencyPromiseView ? <div className="card my-3 mt-5"> <NoData wise="cross" text={t("business_extra_permission_required")} /></div> :

                                companyPromisesIsFetching || companyPromisesIsLoading ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                                    <div className="  card">
                                        <Form
                                            name="form-company-recruitment-promises"
                                            layout="vertical"
                                            form={form2}
                                            onFinish={() => mutateAgencyPromises.mutate()}
                                        >
                                            <>

                                                {
                                                    currentLangs.length <= 0 ? null :

                                                        <div className="my-4 mx-4">
                                                            <p>{t("business_company_promise_videos_description")}</p>
                                                            <Form.Item
                                                                name="video-core"
                                                                label={t("business_company_promise_core_video_label")}
                                                            >
                                                                <Input
                                                                    name="video-core"
                                                                    allowClear
                                                                    disabled={!PM_AgencyPromiseEdit}
                                                                    onChange={(e) => setCoreVideo(e.target.value)}
                                                                    placeholder={t("business_company_promise_core_video_placeholder")}
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="video-tips"
                                                                label={t("business_company_promise_tips_video_label")}
                                                            >
                                                                <Input
                                                                    name="video-tips"
                                                                    allowClear
                                                                    disabled={!PM_AgencyPromiseEdit}
                                                                    onChange={(e) => setTipsVideo(e.target.value)}
                                                                    placeholder={t("business_company_promise_tips_video_placeholder")}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                }
                                                <Divider className="my-8" />
                                                <div className="mx-4">
                                                    <p>{t("business_company_promise_description")}</p>
                                                    {
                                                        tabs.length === 0 ? null :
                                                            <Tabs
                                                                onChange={() => newTabsHandler()}
                                                                defaultActiveKey="1"
                                                                items={tabs}
                                                                destroyInactiveTabPane={true}
                                                            />
                                                    }
                                                    {
                                                        !PM_AgencyPromiseEdit ? null :
                                                            <div className="container">
                                                                <div className="btns-right container mt-4" >
                                                                    <div className="mr-2 mb-2">
                                                                        <Button
                                                                            htmlType="submit"
                                                                            className="btn-primary btn"
                                                                            loading={mutateAgencyPromises.isLoading || mutateAgencySectors.isLoading}
                                                                        >
                                                                            {t("business_main_save")}
                                                                        </Button>
                                                                    </div>
                                                                    <div className="mr-2 mb-2">
                                                                        <div onClick={() => getPromiseData()} className="btn-secondary btn d-inline-block">{t("business_main_cancel")}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            </>
                                        </Form>
                                    </div>
                        }
                    </div>
                </>
            </LayoutCompany>
        </GuestRedirectLayout>
    );
}

const useEditAgencyPromises = (props) => {
    const userStore = useUserStore();
    var maxCharBool = false;

    if (props.currentPromiseCore.translations !== undefined) {
        (props.currentPromiseCore.translations).map((item, i) => {
            var length = $(item.value).contents().filter(function () {
                return (this.localName != "script" && this.localName != "style") ? true : false;
            }).text().replace(/\s+/g, "").length;
            if (length > props.maxChar) {
                maxCharBool = true;
                return toast.warn(t("business_extra_character_limit_1000"));
            }
        })
    }

    if (props.currentPromiseTips.translations !== undefined) {
        (props.currentPromiseTips.translations).map((item, i) => {
            var length = $(item.value).contents().filter(function () {
                return (this.localName != "script" && this.localName != "style") ? true : false;
            }).text().replace(/\s+/g, "").length;
            if (length > props.maxChar) {
                maxCharBool = true;
                return toast.warn(t("business_extra_character_limit_1000"));
            }
        })
    }
    return useMutation({
        mutationKey: ["mutateAgencyPromises"],
        mutationFn: () => axios.post(`v2/update/companies/${userStore.companyData.id}/promise`, {
            CompanyId: userStore.companyData.id,
            UserId: userStore.userData.id,
            Types: [
                // CERTAIN INPUT FIELD (ex. social values)
                {
                    TypeId: "E7E19A35-3C26-4863-9D44-366502A0E65C",
                    Translations:
                        !props.currentPromiseCore.translations ? null :
                            ((props.currentPromiseCore.translations).map((item, i) => {
                                return ({
                                    LanguageId: item.languageId,
                                    // ObjectId: item.objectId,
                                    Text: item.value === undefined ? item.text : item.value
                                });
                            })),
                    VideoUrl: props.coreVideo
                },

                {
                    TypeId: "F94D3683-46C8-4CAA-8B66-BE0B6F36F0C8",
                    Translations:
                        !props.currentPromiseTips.translations ? null :
                            ((props.currentPromiseTips.translations).map((item, i) => {
                                return ({
                                    LanguageId: item.languageId,
                                    // ObjectId: item.objectId,
                                    Text: item.value === undefined ? item.text : item.value
                                });
                            })),
                    VideoUrl: props.tipsVideo
                }
            ]
        }),
        retry: 2,
        onSuccess: (res) => handleRequestSuccess({
            response: res, isSubmit: true, hasError: true, callback: () => {
                props.setCounter(props.cCounter + 1)
            }
        }),
        onError: (res) => handleRequestError({ response: res, isSubmit: true, hasError: true, }),
    })
}

const useGetAgencySectors = (props) => {
    const userStore = useUserStore();
    return useQuery({
        queryKey: ["getAgencySectors"],
        queryFn: () => axios.post("company/agency/sectors2", {
            CompanyId: userStore.companyData.id,
            Iso: userStore.clientData.language.isoCode,
            LanguageId: userStore.clientData.language.id
        }),
        refetchOnWindowFocus: false,
        retry: 2,
        enabled: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {
                var data = res.data.instance;
                let allIndustries = [];
                var selectedIndustry = [];
                if (data !== null) {
                    (data).forEach(industry => {
                        if (industry.sectorTranslations === null) { return }
                        if (industry.sectorTranslations === undefined) { return }
                        Array.prototype.forEach.call(industry.sectorTranslations, function (industryTranslation, i) {
                            if (userStore.clientData.language.id === industryTranslation.languageId) {
                                return selectedIndustry = {
                                    id: industry.sectorId,
                                    value: industry.sectorId,
                                    label: industryTranslation.text,
                                    translations: industry.sectorTranslations,
                                    data: industry
                                };
                            }
                        });
                        if (selectedIndustry.length !== 0) {
                            allIndustries = [...allIndustries, (selectedIndustry)];
                            selectedIndustry = [];
                        }
                    });
                }
                props.setData(allIndustries)
                if (props.form) {
                    console.log("set form", allIndustries, props.form)
                    props.form.setFieldsValue({ "sectors": allIndustries })
                }
            }
        }),
        onError: (res) => handleRequestError({ response: res }),
    })
}
import produce from "immer";
import create from "zustand";
import chalk from 'chalk'


// this store is used to keep all the registered/guest user data. The store is actively used in the client / frontend side and should keep track of changes
export const useUserStore = create((set) => ({
    userData: null, // user login - data of user
    companyData: null, // user login - business data 
    flagData: null, // user login - flag data (allow stuff) 
    clientData: { // client data that keeps track of important things
        language: {
            id: ("92403FDF-9260-45F2-B200-075175EA1485").toLowerCase(),
            isoCode: 'nl',
            text: "Nederlands"
        },
        loggedIn: false,
        acceptedCookies: false,
        checkedRememberMe: false,
        showMobileMenuOverlay: false,
    },
    toggleFlagDataItem(key) {
        set((state) => produce(state, draft => {
            draft.flagData[key] = !state.flagData[key];
        }))

    },
    replaceData(key, data) {
        set((state) => produce(state, draft => {
            draft[key] = data;
        }))
    },
    toggleClientDataMobileMenuOverlay() {
        set((state) => produce(state, draft => {
            draft.clientData.showMobileMenuOverlay = !state.clientData.showMobileMenuOverlay;
        }))
    },
    changeClientDataItem(key, value) {
        set((state) => produce(state, draft => {
            draft.clientData[key] = value;
        }))
    },
    changeClientLanguage(props) {
        set((state) => produce(state, draft => {
            if (props.id && props.isoCode && props.text) {
                draft.clientData.language.id = props.id;
                draft.clientData.language.isoCode = props.isoCode;
                draft.clientData.language.text = props.text;
            } else {
                console.log(chalk.red("Method store changeClientLanguage: Did not give all properties"))
            }
        }))
    },
    changeClientLoggedIn(value) {
        set((state) => produce(state, draft => {
            draft.clientData.loggedIn = value;
        }))
    },
    logout(props) {
        set((state) => produce(state, draft => {
            draft.userData = null;
            draft.companyData = null;
            draft.clientData.loggedIn = false;
            if (props.navigate) {
                props.navigate("/login")
            }
            localStorage.removeItem("cToken")
        }))

    }
}))
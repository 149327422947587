
import { ResponsivePie, ResponsivePieCanvas } from '@nivo/pie'
import { Segmented, Tag } from 'antd';
import { useEffect, useState } from "react";


export default function PieChart(props) {
    const [data, setData] = useState(null);
    const [allData, setAllData] = useState(null);
    const [labels, setLabels] = useState(null);
    const [activeLabel, setActiveLabel] = useState(null);

    useEffect(() => {
        if (typeof props.data === "function") {
            var result = props.data();

            if (props.hasLabels) {
                var resLabels = [];
                result.forEach((element) => {
                    let eleProp = element.labelProp;
                    if (!resLabels.includes(eleProp)) {
                        resLabels.push(eleProp);
                    }
                });
                setLabels(resLabels);
                setActiveLabel(resLabels[0]);
                setAllData(result);
            }
            setData(result);
        } else {
            setData(props.data);
        }
    }, [props.data]);

    useEffect(() => {
        if (props.hasLabels && allData) {
            var newData = [];
            allData.forEach((element) => {
                if (element.labelProp === activeLabel) {
                    newData.push(element);
                }
            });
            setData(newData);
        }
    }, [activeLabel]);

    return (
        <>
            {!data ? null : (
                <div className={`mb-10 ${props.className || ""}`}>
                    {!props.hasLabels || !labels ? null : (
                        <div className='flex justify-center my-4'>
                            <Segmented
                                className='border-white-700 border'
                                size='large'
                                options={labels}
                                onChange={(value) => setActiveLabel(value.toString())}
                            />
                        </div>
                    )}
                    <div style={{ height: props.height || "50vh", width: props.width || "50vw", minWidth: props.minWidth || 700 }}>
                        <ResponsivePie
                            margin={props.margin || { top: 40, right: 200, bottom: 40, left: 80 }}
                            data={data}
                            // colors={"nivo"}
                            colors={{ datum: 'data.color' }}
                            activeOuterRadiusOffset={12}
                            borderWidth={0.5}
                            enableArcLinkLabels={props.enableArcLinkLabels !== undefined ? props.enableArcLinkLabels : true}
                            // colors={['hsl(0, 84%, 47%)', 'hsl(0, 71%, 69%)']}
                            // colorBy="index"
                            arcLinkLabelsThickness={2}
                            // arcLinkLabelsColor={{ from: 'color' }}
                            arcLinkLabelsTextOffset={5}
                            arcLabelsRadiusOffset={0.55}
                            arcLinkLabelsSkipAngle={10}
                            arcLabelsSkipAngle={10}
                            onClick={props.onClick || null}
                            legends={props.legends || [
                                {
                                    anchor: 'right',
                                    direction: 'column',
                                    translateX: 120,
                                    translateY: 0,
                                    itemsSpacing: 0,
                                    itemWidth: 100,
                                    itemHeight: 30,
                                    itemTextColor: '#999',
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 1,
                                    symbolSize: 18,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: '#000'
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />

                    </div>
                </div>
            )}
        </>
    );
}
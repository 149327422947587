import { Select, Divider } from 'antd';
import PieChart from "components/layouts/charts/piechart";
import DashboardLayout from 'components/layouts/dashboard/dashboard-layout';
import InsightHotTalentPanel from 'components/layouts/dashboard/panels/insight-hot-talent-panel';
import TablePanel from 'components/layouts/dashboard/panels/table-panel';
import ScrollToTop from "components/layouts/navigation/scrollToTop";
import GuestRedirectLayout from "components/layouts/page-layouts/guest-redirect";
import Panel from "components/modules/panel/panel";
import { useGetDbGeneralData } from "components/requests/dashboard/getDbGeneralData";
import useGetDbRecruitmentData from "components/requests/dashboard/getDbRecruitmentData";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import { useEffect, useState } from "react";
import Loading from "../../components/animations/loading";
import Rating from "../../components/modules/rating/rating";
import { useLocation } from "react-router-dom";
import { atmospherDbIcon, clapGreenIcon, feedbackDbIcon, infoDbIcon, salaryDbIcon } from "../../assets/icons/iconsDashboard";

export default function CandidateInsightDashboardPage(props) {
    const userStore = useUserStore();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const RowDivider = () => <Divider className="my-1" />

    const [isPremiumPlan, setIsPremiumPlan] = useState(null);
    const { data: dbGeneralData, isLoading: dbGeneralDataIsLoading, isError: dbGeneralDataIsError } = useGetDbGeneralData(); // company data (number of ratings, rating, companyname, planname)
    // const { data: dbMessages, isLoading: dbMessagesIsLoading, isError: dbMessagesIsError, isRefetching: dbMessagesIsRefetching } = useGetDbMessages();
    const { data: dbRecruitmentData, isLoading: dbRecruitmentDataIsLoading, isError: dbRecruitmentDataIsError, isRefetching: dbRecruitmentDataIsRefetching } = useGetDbRecruitmentData();
    const defaultPieProps = {
        className: "mb-0 d-flex align-items-center justify-content-center",
        enableArcLinkLabels: false,
        margin: { top: 15, right: 15, bottom: 15, left: 15 },
        legends: [],
        minWidth: 200,
        width: "200px",
        height: "200px"
    };

    useEffect(() => {
        if (!userStore.flagData || !userStore.flagData.toggleDashboardAdvanced) { return setIsPremiumPlan(false) }
        setIsPremiumPlan(userStore.flagData.toggleDashboardAdvanced)
    }, [userStore.flagData])

    return (
        <GuestRedirectLayout>
            <DashboardLayout
                data={dbGeneralData}
                title={`${(dbGeneralData && dbGeneralData.companyName) || ''}`}
                type={t("business_main_candidate_insight_dashboard")}
                noContainer
            >
                <ScrollToTop />
                <main className="mt-3">

                    <div className="">
                        <div className="container page-content mt-0 pt-4">
                            <div className="d-flex flex-row flex-wrap align-items-center justify-content-end mb-3">
                                {
                                    userStore.companyData.isDemo && <Select
                                        defaultValue={"Marketing"}
                                        style={{ minWidth: 250 }}
                                        options={
                                            [
                                                {

                                                    label: "Marketing",
                                                    value: "Marketing",
                                                },
                                                {
                                                    value: "Finance",
                                                    label: "Finance",
                                                },
                                                {
                                                    value: "Administration",
                                                    label: "Administration",
                                                },
                                            ]
                                        }
                                    />
                                }
                            </div>

                            <section>
                                <div className="row gx-1">
                                    <div className="col-sm">
                                        <Panel
                                            title={<>{t("business_dashboard_panel_total_rating_amount_title")}</>}
                                        >
                                            {
                                                !dbGeneralData ? <Loading center /> :
                                                    <div style={{
                                                        minHeight: "185px"
                                                    }} className="flex align-items-center justify-content-center flex-column mb-3">
                                                        <h2 style={{ fontSize: "3rem" }} className="mb-2">{dbGeneralData && dbGeneralData.totalCount ? dbGeneralData.totalCount : "-"}</h2>
                                                        <p className="mb-1">{t("business_dashboard_total_score")}</p>
                                                        <Rating rating={!dbGeneralData ? 0 : !dbGeneralData.scoreGeneral ? null : dbGeneralData.scoreGeneral / 10} noamount />
                                                    </div>
                                            }
                                        </Panel>
                                    </div>
                                    <div className="col-sm">
                                        <Panel
                                            isLoading={dbRecruitmentDataIsLoading}
                                            isError={dbRecruitmentDataIsError}
                                            title={t("business_candidate_dashboard_panel_company_attractiveness_title")}

                                        ><>
                                                <PieChart
                                                    data={demoData.pies.company}
                                                    {...defaultPieProps}
                                                />
                                            </>
                                        </Panel>
                                    </div>
                                    <div className="col-sm">
                                        <Panel
                                            isLoading={dbRecruitmentDataIsLoading}
                                            isError={dbRecruitmentDataIsError}
                                            title={t("business_candidate_dashboard_panel_job_attractiveness_title")}

                                        ><>
                                                <PieChart
                                                    data={demoData.pies.job}
                                                    {...defaultPieProps}
                                                />
                                            </>
                                        </Panel>
                                    </div>
                                </div>
                            </section>
                            <section>
                                <InsightHotTalentPanel />
                                <div className="row gx-1">
                                    <div className="col-sm">
                                        <TablePanel
                                            title={t("business_dashboard_panel_candidate_differentiators_title")}
                                            columns={[
                                                {
                                                    title: t("business_dashboard_panel_candidate_differentiators_category_name_head"),
                                                    dataIndex: 'categoryName',
                                                    key: 'categoryName',
                                                },
                                                {
                                                    title: t("business_dashboard_panel_candidate_differentiators_based_on_head"),
                                                    dataIndex: 'basedOnPercentage',
                                                    key: 'basedOnPercentage',
                                                },
                                            ]}
                                            data={{
                                                items: demoDifferentiatorDataItems.sort((a, b) => b.basedOnPercentage - a.basedOnPercentage).map((item, i) => {
                                                    return {
                                                        sortIndex: i + 1,
                                                        basedOnPercentage: item.basedOnPercentage + "%",
                                                        categoryName: item.categoryName
                                                    }
                                                })
                                            }}
                                            itemsLimited={3}

                                        />
                                    </div>
                                    <div className="col-sm">
                                        <TablePanel
                                            title={t("business_dashboard_panel_candidate_differentiators_missing_title")}
                                            itemsLimited={3}
                                            columns={[
                                                {
                                                    title: t("business_dashboard_panel_candidate_differentiators_category_name_head"),
                                                    dataIndex: 'categoryName',
                                                    key: 'categoryName',
                                                },
                                                {
                                                    title: t("business_dashboard_panel_candidate_differentiators_based_on_head"),
                                                    dataIndex: 'basedOnPercentage',
                                                    key: 'basedOnPercentage',
                                                },
                                            ]}
                                            data={{
                                                items: demoDifferentiatorMissingDataItems.sort((a, b) => b.basedOnPercentage - a.basedOnPercentage).map((item, i) => {
                                                    return {
                                                        sortIndex: i + 1,
                                                        basedOnPercentage: item.basedOnPercentage + "%",
                                                        categoryName: item.categoryName
                                                    }
                                                })
                                            }}

                                        />
                                    </div>
                                </div>
                            </section>
                            <section>
                                <Panel

                                    title={<>
                                        <span className='mr-2'>{clapGreenIcon}</span>
                                        <span>{t("business_dashboard_panel_candidate_appliciant_experience_title")}</span>
                                    </>}
                                >
                                    <div className="my-3 flex flex-column justify-content-center align-items-center">
                                        <div style={{ minWidth: "800px" }}>
                                            <RatingRow
                                                text={t("business_dashboard_panel_candidate_appliciant_experience_attractiveness")}
                                                icon={atmospherDbIcon}
                                                amount={10}
                                            />
                                            <RowDivider />
                                            <RatingRow
                                                text={t("business_dashboard_panel_candidate_appliciant_experience_description")}
                                                icon={infoDbIcon}
                                                amount={10}
                                            />
                                            <RowDivider />
                                            <RatingRow
                                                text={t("business_dashboard_panel_candidate_appliciant_experience_feedback")}
                                                icon={feedbackDbIcon}
                                                amount={10}
                                            />
                                            <RowDivider />
                                            <RatingRow
                                                text={t("business_dashboard_panel_candidate_appliciant_experience_salary")}
                                                icon={salaryDbIcon}
                                                amount={10}
                                            />
                                        </div>

                                    </div>
                                </Panel>
                            </section>
                        </div>
                    </div>

                </main>
            </DashboardLayout>
        </GuestRedirectLayout>
    );
}


const RatingRow = (props) => {
    return <>
        <div className="flex align-items-center flex-row justify-content-between my-1" style={{ width: "100%" }}>
            <div className="rating-row-label">
                <span className="mr-2">
                    {props.icon}
                </span>
                <span className="mr-2 fs-small">
                    {props.text}
                </span>
            </div>
            <div className="rating-row-value">
                <ClapAmount amount={props.amount || 0} className={"mr-3"} />
            </div>
        </div>
    </>
}

function ClapAmount(props) {

    return (
        <>
            <div id="rating-claps" className={`flex items-center flex-row ${props.className || ''}`} >
                <span className="mr-1">{clapGreenIcon}</span>
                <span style={{ marginBottom: "-1px" }} className="fw-semi-bold color-lime font-secondary">{props.amount || "-"}</span>
            </div>
        </>
    );
}


const demoDifferentiatorDataItems = [
    {
        "categoryName": "Job, responsibilities & tasks",
        "basedOnPercentage": 35,
    },
    {
        "categoryName": "Salary & benefits",
        "basedOnPercentage": 18,
    },
    {
        "categoryName": "Flexibility & hybrid working",
        "basedOnPercentage": 12,
    },
    {
        "categoryName": "Career opportunities & challenges",
        "basedOnPercentage": 0,
    },
    {
        "categoryName": "Market you operate in, technical challenges or customers",
        "basedOnPercentage": 0,
    },
    {
        "categoryName": "Culture & atmosphere",
        "basedOnPercentage": 0,
    },
    {
        "categoryName": "Team & colleagues ",
        "basedOnPercentage": 0,
    },
]

const demoDifferentiatorMissingDataItems = [
    {
        "categoryName": "Job, responsibilities & tasks",
        "basedOnPercentage": 23,
    },
    {
        "categoryName": "Salary & benefits",
        "basedOnPercentage": 52,
    },
    {
        "categoryName": "Flexibility & hybrid working",
        "basedOnPercentage": 12,
    },
    {
        "categoryName": "Career opportunities & challenges",
        "basedOnPercentage": 11,
    },
    {
        "categoryName": "Market you operate in, technical challenges or customers",
        "basedOnPercentage": 1,
    },
    {
        "categoryName": "Culture & atmosphere",
        "basedOnPercentage": 0,
    },
    {
        "categoryName": "Team & colleagues ",
        "basedOnPercentage": 0,
    },
]


const demoPieData = (a, b, c, d, e) => {
    const total = a + b + c + d + e;
    return [
        {
            "id": "Perfect",
            "label": "Perfect (0.00%)",
            "value": a,
            "sort": 5,
            "color": "hsl(105, 100%, 43%)",
            "total": total
        },
        {
            "id": "Positief",
            "label": "Positief (0.00%)",
            "value": b,
            "sort": 4,
            "color": "hsl(105, 100%, 67%)",
            "total": total
        },
        {
            "id": "Middelmatig",
            "label": "Middelmatig (0.00%)",
            "value": c,
            "sort": 3,
            "color": "hsl(50, 100%, 67%)",
            "total": total
        },
        {
            "id": "Negatief",
            "label": "Negatief (0.00%)",
            "value": d,
            "sort": 2,
            "color": "hsl(0, 71%, 69%)",
            "total": total
        },
        {
            "id": "Onvoldoende",
            "label": "Onvoldoende (0.00%)",
            "value": e,
            "sort": 1,
            "color": "hsl(0, 84%, 47%)",
            "total": total
        }
    ]
};

const demoData = {
    totalApplicants: 101,
    satisfiedApplicants: 74,
    category: {
        contact: 33,
        info: 78,
        feedback: 40,
        salary: 12,
    },
    pies: {
        company: demoPieData(70, 10, 5, 7, 7),
        job: demoPieData(11, 17, 11, 22, 45),
    },
    messages: [
        {
            "tipId": "7171de13-d157-40d8-967b-050291ef8f5f",
            "tipText": "Smooth and efficient application process. Quick response from the team. Appreciate it!",
            "tipUpdatedOn": "2023-03-23T17:06:20.783723",
            "isPositive": true
        },
        {
            "tipId": "827d2cb7-59eb-4b3a-9a2a-2a8c1f4166c1",
            "tipText": "Great experience with the interview process. Professional and friendly team.",
            "tipUpdatedOn": "2023-04-15T09:45:30.123456",
            "isPositive": true
        },
        {
            "tipId": "a6e73e02-4819-4b68-918d-9823d0bf3489",
            "tipText": "User-friendly platform, made it easy to track my application status.",
            "tipUpdatedOn": "2023-05-02T14:30:15.987654",
            "isPositive": true
        },
        {
            "tipId": "4c1aef0d-3d67-4d8f-b5c1-8a84d16e4c82",
            "tipText": "Communication during the hiring process was lacking. Expected more updates.",
            "tipUpdatedOn": "2023-06-10T12:15:45.789012",
            "isPositive": false
        },
        {
            "tipId": "6a71b217-af6e-4b6f-b9c4-3c8564ebf65e",
            "tipText": "Unclear job description. Felt unsure about the role's responsibilities.",
            "tipUpdatedOn": "2023-07-05T18:30:00.654321",
            "isPositive": false
        },
        {
            "tipId": "98a8a7b6-5872-4963-b23d-987d54fe7bb3",
            "tipText": "Impressed with the company's commitment to diversity and inclusion in hiring practices.",
            "tipUpdatedOn": "2023-08-20T09:00:12.345678",
            "isPositive": true
        },
        {
            "tipId": "b8ef3c9d-f674-4c14-8bb5-10ec1e56f5fe",
            "tipText": "Interview process was too long. Could be more streamlined.",
            "tipUpdatedOn": "2023-09-14T15:45:30.987654",
            "isPositive": false
        },
        {
            "tipId": "c9979e3a-c836-48ea-ba55-2465b90de0ac",
            "tipText": "Company's commitment to sustainability aligns with my values. Great focus on environmental responsibility.",
            "tipUpdatedOn": "2023-10-02T11:11:11.111111",
            "isPositive": true
        },
        {
            "tipId": "e3c1a5b8-2df2-4a1c-98a7-789d45601234",
            "tipText": "Application interface needs improvement. A bit confusing to navigate.",
            "tipUpdatedOn": "2023-11-05T14:00:00.987654",
            "isPositive": false
        },
        {
            "tipId": "2e489d07-91c4-4eaf-bf4d-152a83f90d67",
            "tipText": "Received a prompt reply from the company, addressing all my queries. Appreciate the clear communication.",
            "tipUpdatedOn": "2023-12-20T08:30:45.543210",
            "isPositive": true
        },
        {
            "tipId": "f7b1b4d3-2fb9-4c5e-ae5b-7a803f6e986c",
            "tipText": "January applicant here! The hiring process was a breeze. Kudos to the team!",
            "tipUpdatedOn": "2023-01-10T13:20:30.654321",
            "isPositive": true
        },
        {
            "tipId": "31e73a8b-865a-4b42-96a2-986d547a89c2",
            "tipText": "Had multiple rounds of interviews in February. The team was thorough and professional.",
            "tipUpdatedOn": "2023-02-05T11:45:00.987654",
            "isPositive": true
        },
        {
            "tipId": "4d52b3e5-8a49-4ed2-8ea1-756c39e95a86",
            "tipText": "March applicant here. The job description matched the role perfectly. Thank you!",
            "tipUpdatedOn": "2023-03-12T16:30:15.876543",
            "isPositive": true
        },
        {
            "tipId": "ecb148a1-7a3b-4cc0-b7a9-9a30f865712e",
            "tipText": "Applied in April. The company's commitment to diversity is evident in their hiring process. Impressive!",
            "tipUpdatedOn": "2023-04-02T10:10:10.101010",
            "isPositive": true
        },
        {
            "tipId": "92f7ebfc-1a3b-4a11-8ee0-4b871f89f87a",
            "tipText": "May applicant here. The communication was clear, and the process was transparent.",
            "tipUpdatedOn": "2023-05-18T14:45:30.876543",
            "isPositive": true
        },
        {
            "tipId": "a8c09b31-55c3-4d1b-82de-3bcf4e685d3c",
            "tipText": "June applicant. The application interface needs improvement, but overall a positive experience.",
            "tipUpdatedOn": "2023-06-05T12:00:00.987654",
            "isPositive": true
        },
        {
            "tipId": "1f586f32-6c44-4c1a-bbfe-f6d0c8d17e61",
            "tipText": "July applicant here. The interview process was rigorous but fair. Appreciate the challenge!",
            "tipUpdatedOn": "2023-07-10T09:30:45.876543",
            "isPositive": true
        },
        {
            "tipId": "7a534cfb-9db4-4b0c-97fc-3e2e76ee5f24",
            "tipText": "Multiple interviews in August. The team was responsive and provided helpful feedback.",
            "tipUpdatedOn": "2023-08-12T15:15:15.151515",
            "isPositive": true
        },
        {
            "tipId": "93b8e10e-f5a7-4e53-9632-f872356f35bf",
            "tipText": "September applicant here. The company's commitment to sustainability is commendable.",
            "tipUpdatedOn": "2023-09-05T11:11:11.111111",
            "isPositive": true
        },
        {
            "tipId": "be98f077-9b92-42bf-8e7c-ebc1e1e8f26f",
            "tipText": "October applicant. The application process could be more streamlined for better user experience.",
            "tipUpdatedOn": "2023-10-20T14:00:00.987654",
            "isPositive": false
        },
        {
            "tipId": "b4b7c164-622d-401c-b37c-9e845e36935f",
            "tipText": "November applicant here. The interviewers were attentive and asked relevant questions.",
            "tipUpdatedOn": "2023-11-15T12:30:30.876543",
            "isPositive": true
        },
        {
            "tipId": "f66f3bb0-aa5e-4c9a-9e50-48f9726d937d",
            "tipText": "December applicant. Received a timely response to my application. Thanks for the efficiency!",
            "tipUpdatedOn": "2023-12-05T09:45:45.987654",
            "isPositive": true
        }
    ]

}
export default function Template(props) {

    return (
        <div>
            <div id="template">
                <div className="container-page">
                    <div className="container">
                        <div className="page-content">
                            <h1 className='title'>Page title</h1>
                            <a href="https://agent.wisepeople.be/engie_belgium/img">
                                <img src="https://agent.wisepeople.be/engie_belgium/img" alt="wisepeople" />
                            </a>
                            <img src="https://agent.wisepeople.be/engie_belgium/img" alt="wisepeople" />
                        </div>

                        <div style={{ position: "relative" }}>
                            <iframe title="WiSE-Widget" data-src="https://business.wisepeople.be" scrolling="no" src={"https://agent.wisepeople.be/2/engie_belgium"} style={{ height: "100%", minHeight: "250px", overflow: "hidden", border: "none", position: "relative", display: "block" }} />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";

const useGetRecruiterInfo = (props) => {
    const userStore = useUserStore();
    return useQuery({
        queryKey: ["getRecruiterInfo"],
        queryFn: () => axios.post("account/recruiter/info", {
            UserId: userStore.userData.id,
            ProfileId: userStore.userData.recruiterProfileId,
        }),
        retry: 2,
        refetchOnWindowFocus: false,
        enabled: !!userStore.userData,
        onSuccess: (res) => handleRequestSuccess({ response: res, callback: () => props.setData(res.data.instance) }),
        onError: (res) => handleRequestError({ response: res })
    })
}

export default useGetRecruiterInfo;
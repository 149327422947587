import axios, { isAxiosError } from "axios";
import chalk from "chalk";
import { toast } from "react-toastify";

const project = "WiSE Business";

const getSimpleApiRoute = (route) => route.replace(process.env.API_URL, "");

export function myRequestHandler(props) {
    const { t, response, hasAlert, hasAlertError, isSubmit, callback, customCallback, submitMessage } = props;
    var route = response.request.responseURL || "Unknown"; // API request route
    var status = response.status || response.request.status; // HTTP status

    if (!response || response.status !== 200 || isAxiosError(response)) {
        try {
            handleDebugLogsAndAlerts({
                t,
                status,
                route,
                response,
            });

            if (customCallback) {
                customCallback(response);
            }

            handleDebugLogsAndAlerts({
                t,
                status,
                route,
                response,
                hasAlert: hasAlertError !== undefined ? hasAlertError : hasAlert,
                isSubmit,
                submitMessage,
                callback: callback ? callback.toString() : undefined,
                customCallback: customCallback ? customCallback.toString() : undefined,
                hideDebugLogs: true,
            });
        } catch (error) {
            if (error) {
                throw new Error(`Caught (#1) - Something went wrong - \n${error}`);
            } else {
                throw new Error(`Caught (#2) - Something went wrong ⬆️`);
            }
        }
    } else {
        try {
            handleDebugLogsAndAlerts({
                t,
                status,
                route,
                response,
                hasAlert: hasAlertError !== undefined ? hasAlertError : hasAlert,
                isSubmit,
                submitMessage,
                callback: callback ? callback.toString() : undefined,
                customCallback: customCallback ? customCallback.toString() : undefined,
            });

            if (customCallback) {
                customCallback(response);
            }

            return callback ? callback(response) : !response && !response.data.instance ? null : response.data.instance === "No Data" ? null : response.data.instance;
        } catch (error) {
            if (error) {
                throw new Error(`Caught (#3) - Something went wrong - \n${error}`);
            } else {
                throw new Error(`Caught (#4) - Something went wrong ⬆️`);
            }
        }
    }
}

export const sendAxiosGetRequest = (props) => {
    return axios.get(`${props.route}`, props.header || {}).then((res) => {
        return myRequestHandler({
            response: res,
            callback: props.callback,
            customCallback: props.customCallback,
            hasAlert: props.hasAlert,
            submitMessage: props.submitMessage,
            isSubmit: props.isSubmit,
        });
    }).catch((error) => {
        myRequestHandler({
            response: error,
            callback: props.callback,
            customCallback: props.customCallback,
            hasAlert: props.hasAlert,
            submitMessage: props.submitMessage,
            isSubmit: props.isSubmit,
        });
        throw Error("Something went wrong ⬆️");
    });
};

export const sendAxiosPostRequest = (props) => {
    return axios.post(`${props.route}`, props.body || {}, props.header || {}).then((res) => {
        return myRequestHandler({
            response: res,
            callback: props.callback,
            customCallback: props.customCallback,
            hasAlert: props.hasAlert,
            submitMessage: props.submitMessage,
            isSubmit: props.isSubmit,
        });
    }).catch((error) => {
        console.log(error)
        myRequestHandler({
            response: error,
            callback: props.callback,
            customCallback: props.customCallback,
            hasAlert: props.hasAlert,
            submitMessage: props.submitMessage,
            isSubmit: props.isSubmit,
        });
        throw Error("Something went wrong ⬆️");
    });
};

export function handleDebugLogsAndAlerts(props) {
    try {
        const errorConnection = props.t
            ? props.t("home_extra_general_error")
            : "Error connecting to the server.";
        const errorSomething = props.t
            ? props.t("home_extra_general_error2")
            : "Something went wrong.";
        const logChanges = props.t
            ? props.t("home_extra_save_success")
            : "Changes saved successfully.";

        var {
            response,
            status,
            hasAlert,
            isSubmit,
            hideDebugLogs,
            submitMessage,
            route,
            callback,
            customCallback,
        } = props;

        var apiStatus = response?.data?.status || null;
        var apiResult = response?.data || response?.response.data;
        var method = response?.config?.method || null;
        var body = response?.config?.data ? JSON.parse(response.config.data) : null;

        const responseObject = {
            method,
            route,
            body,
            status,
            apiStatus,
            apiResult,
            hasAlert,
            isSubmit,
            callback: callback ? callback.toString() : null,
            customCallback: customCallback ? customCallback.toString() : null,
            project,
            log: "",
        };

        let methodLog = "";

        switch (status) {
            case 200:
                if (isSubmit && hasAlert) {
                    if (submitMessage) {
                        toast.success(submitMessage);
                    } else {
                        toast.success(logChanges);
                    }
                }
                if (callback) {
                    methodLog = callback && customCallback ? 'Callback & customCallback found' : 'Callback found';
                    responseObject.log = methodLog;
                } else {
                    if (customCallback) {
                        methodLog = "No callback found (found customCallback)";
                        responseObject.log = methodLog;
                    } else {
                        methodLog = "No callback / customCallback";
                        responseObject.log = methodLog;
                    }
                }
                break;
            case 204:
            case 290:
                methodLog = "There was no data found in the request";
                responseObject.log = methodLog;
                break;
            case 400:
                if (hasAlert) {
                    toast.error(errorSomething);
                }
                methodLog =
                    "This request had a backend error, check the server tab and call or use the debug logs";
                responseObject.log = methodLog;
                break;
            case 404:
                if (hasAlert) {
                    toast.error(errorSomething);
                }
                methodLog = "This request route was not found";
                responseObject.log = methodLog;
                break;
            case 500:
                if (hasAlert) {
                    toast.error(errorSomething);
                }
                methodLog =
                    "This request had a SERVER error, please contact support";
                responseObject.log = methodLog;
                break;
            default:
                if (hasAlert) {
                    toast.error(errorConnection);
                }
                methodLog = "Something went wrong";
                responseObject.log = methodLog;
                break;
        }

        if (!hideDebugLogs) {
            const generalMessage = status === 200 ? `✅ ${status} ${method.toUpperCase()} (${apiStatus}):` : `🛑 ${status} ${method.toUpperCase()} (${apiStatus || "Unknown"}):`;
            const apiRoute = getSimpleApiRoute(route);
            console.log(
                generalMessage,
                apiRoute + '\n\n',
                `${responseObject.log} \n`,
                responseObject
            );
        }
    } catch (error) {
        console.error(`Something went wrong in handleDebugLogsAndAlerts: ${error}`);
    }
}

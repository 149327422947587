import { Segmented } from "antd";
import Loading from "components/animations/loading";
import PieChart from "components/layouts/charts/piechart";
import NoData from "components/layouts/no-data/nodata";
import Panel from "components/modules/panel/panel";
import { t } from "i18next";
import { useEffect, useState } from "react";

function handleChartObjectData(rawData, filter, setSelectedData) {
    if (!rawData) { return }
    function handleColor(number) {
        switch (number) {
            case 1:
                return "hsl(0, 84%, 47%)"
            case 2:
                return "hsl(50, 100%, 67%)"
            case 3:
                return "hsl(105, 100%, 43%)"
            default:
                break;
        }
    }

    var data = rawData.filter(item => item.scoreType.toLowerCase().includes(filter.toLowerCase()));
    var newObjects = [];
    data.sort((a, b) => a.experienceCategorySortIndex < b.experienceCategorySortIndex ? 1 : -1).forEach((item) => {
        if (!item.experienceCategoryAmount) { return }
        const categoryName = t(`${item.experienceCategoryTranslationKey}`)

        var itemObj = {
            id: categoryName,
            label: `${categoryName} (${item.percentage.toFixed(2)}%)`,
            value: item.experienceCategoryAmount,
            sort: item.experienceCategorySortIndex,
            color: handleColor(item.experienceCategorySortIndex)
        }
        newObjects.push(itemObj)
    });

    setSelectedData && setSelectedData(newObjects);
    return newObjects
}

export default function ExperienceScalePanel(props) {
    const [activeFilter, setActiveFilter] = useState(null);
    const [filters, setFilters] = useState([]);
    const [selectedData, setSelectedData] = useState(null);
    const [selectedTitle, setSelectedTitle] = useState(null);

    useEffect(() => {
        if (props.data) {

            var items = [];
            props.data.forEach((item, i) => {
                if (item.id === "Information") {
                    setActiveFilter(item.id)
                    if (item.data) {
                        // get selected data and set first item as default
                        handleChartObjectData(item.data, "Information", setSelectedData)
                    }
                }
                items.push({
                    value: item.id,
                    label: item.label,
                })
            })
            setFilters(items);
        }
    }, [props.data])

    useEffect(() => {
        props.data.forEach((item, i) => {
            if (item.id === activeFilter) {

                if (item.data) {
                    // get selected data and set first item as default
                    handleChartObjectData(item.data, item.id, setSelectedData);
                }
                setSelectedTitle(item.label);
            }
        })

    }, [activeFilter])

    return <>
        <Panel
            isLoading={props.isLoading}
            isError={props.isError}
            title={<>{t("business_dashboard_panel_experience_scale_title")}</>}
        >
            {
                !props.data || props.data.length === 0 || props.data[0].data === null ? <NoData wise="sad" className="my-5" /> :
                    selectedData === null ? <Loading center /> :
                        !selectedData ? <NoData wise="notfound" /> :
                            <>
                                {filters.length <= 1 ? null :
                                    !activeFilter || !filters ? null :
                                        <div className="w-full flex justify-content-center">
                                            <Segmented
                                                className="my-3"
                                                size="large"
                                                options={filters}
                                                value={activeFilter} onChange={(value) => setActiveFilter(value)}
                                            />
                                        </div>
                                }
                                <div className="flex align-items-center justify-content-center flex-column">
                                    {
                                        filters.length <= 1 ? null :
                                            <h3 className="my-2 mt-4 mb-3 color-darkgrey">{selectedTitle || null}</h3>
                                    }
                                    <PieChart
                                        data={selectedData}
                                    />
                                </div>
                            </>
            }
        </Panel>
    </>
}
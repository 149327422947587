import { useQuery } from "@tanstack/react-query";
import { handleChartObjectData } from "components/layouts/dashboard/recruitment/db-recruitment";
import { sendAxiosGetRequest } from "components/utils/methods/myRequestHandlers";
import { useUserStore } from "components/utils/stores/userStore";

export default function useGetDbRecruitmentData(props) {
    const userStore = useUserStore();

    return useQuery({
        queryKey: ["getDbRecruitmentData"],
        queryFn: () => sendAxiosGetRequest({
            route: `v3/business/select/businesses/${userStore.companyData.id}/dashboards/users/${userStore.userData.id}`,
            callback: (res) => {
                console.log(res);
                if (!res || res.data.status === 290) {
                    return null;
                } else {
                    var newData = res.data.instance;

                    var amountAttractiveness = handleChartObjectData(newData.pies.instance, "amountAttractiveness");
                    var amountBrandOnline = handleChartObjectData(newData.pies.instance, "amountBrandOnline");
                    var amountCandidateExperience = handleChartObjectData(newData.pies.instance, "amountCandidateExperience");
                    var amountCulture = handleChartObjectData(newData.pies.instance, "amountCulture");

                    newData = {
                        ...newData,
                        pieData: {
                            amountAttractiveness,
                            amountBrandOnline,
                            amountCandidateExperience,
                            amountCulture

                        }
                    }
                    return newData
                }
            }
        }),
        refetchOnWindowFocus: false,
        retry: false
    })
}
import { Segmented, Tag } from "antd";
import BarChart from "components/layouts/charts/barchart";
import NoData from "components/layouts/no-data/nodata";
import Panel from "components/modules/panel/panel";
import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";

// ? Evolution in time in barchart for general rating (positive, moderate, negative)
export default function ExperienceEvolutionBarchartPanel(props) {
    const [data, setData] = useState()
    const [keys, setKeys] = useState()


    function handleColor(key) {
        switch (key) {
            case "business_dashboard_evolution_experience_category_high":
                return "hsl(105, 100%, 43%)"
            case "business_dashboard_evolution_experience_category_medium":
                return "hsl(50, 100%, 67%)"
            case "business_dashboard_evolution_experience_category_low":
                return "hsl(0, 84%, 47%)"
            default:
                return "hsl(50, 100%, 67%)"
        }
    }

    // ? Handle raw data and create usable data structure accepted for barchart component
    useEffect(() => {
        if (!props.data) { return }
        const chartData = [];
        var keys = [];
        const possibleItems = [
            "business_dashboard_evolution_experience_category_low",
            "business_dashboard_evolution_experience_category_medium",
            "business_dashboard_evolution_experience_category_high",
        ]

        props.data.forEach(item => {

            const date = moment({ year: item.yearIndex, month: item.monthIndex - 1 }); // Adjust month to 0-based index
            const formattedDate = date.format('MMM YY'); // Format like "Jun 23"

            const existingEntry = chartData.find(entry => entry.date === formattedDate);
            const categoryTranslation = t("business_dashboard_evolution_experience_category_medium")
            if (existingEntry) {
                existingEntry[categoryTranslation] = item.count;
            } else {
                var colorItems = {};
                possibleItems.map(item => {
                    return colorItems = { ...colorItems, [`${t(item)}Color`]: handleColor(item) }
                });
                const entry = { ...colorItems, date: formattedDate, [categoryTranslation]: 5 };
                chartData.push(entry);
            }
        });

        possibleItems.forEach(element => {
            keys = [
                ...keys,
                t(element)
            ]
        });
        setData(chartData);
        setKeys(keys);

    }, [props.data])


    return <>

        <Panel
            {...props.panelProps}
            title={t("business_dashboard_agency_score_evolution_panel_title")}
        >
            <>
                {
                    !props.data ? <NoData wise="sad" className="my-3" /> :
                        !data || !keys ? null :
                            <>

                                {/* <div className="w-full flex justify-content-center">
                                    <Segmented
                                        className="my-3"
                                        size="large"
                                        options={[
                                            {
                                                value: true,
                                                label: t("business_main_weekly")
                                            },
                                            {
                                                value: false,
                                                label: t("business_main_monthly")
                                            }
                                        ]}
                                        value={props.isWeeklyChart} onChange={(value) => props.setIsWeeklyChart(value)}
                                    />
                                </div> */}
                                <BarChart
                                    data={data}
                                    keys={keys}
                                    indexBy="date"
                                    xAxis={t("business_main_date")}
                                    yAxis={t("business_main_amount")}

                                />
                            </>
                }
            </>

        </Panel>
    </>
}
import useTokenValidator from 'components/requests/data/useTokenValidator';
import useVerification from 'components/requests/data/useVerification';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LabelWise from '../assets/images/wise-label.png';
import Loading from '../components/animations/loading';
import AlertMessage from '../components/forms/alerts/alert-message';
import envir from '../env.json';
import NoData from 'components/layouts/no-data/nodata';

export default function AcceptPage(props) {
    // # UTILS
    const urlParam = new URLSearchParams(window.location.search);

    // # CLIENT STATES
    const [message, setMessage] = useState(null);

    // # SERVER STATES
    const { refetch: validatorRefetch, isSuccess: validatorIsSuccess, isLoading: validatorIsLoading, isError } = useTokenValidator({ token: urlParam.get("token"), setMessage });
    const mutateVerification = useVerification({ setMessage, token: urlParam.get("token") });

    useEffect(() => {
        if (urlParam.get("token") === undefined) { return setMessage([t("business_verification_invalid"), "danger"]) }
        if (urlParam.get("token") === null) { return setMessage([t("business_verification_invalid"), "danger"]) }
        validatorRefetch();
    }, [urlParam.get("token")]);

    useEffect(() => {
        if (!validatorIsSuccess) { return }
        mutateVerification.mutate();
    }, [validatorIsSuccess]);


    return (
        <div>
            {
                <div id="accept">
                    <div className="container-page">
                        <div className="container">
                            <div className="page-content">
                                <div className="  d-flex hero-form">
                                    <div className="bg-darkgreen bg-hero hero-form-left">
                                        <div className="container py-10">
                                            <div className="d-flex justify-content-center align-items-center flex-column mt-10">
                                                <h1 className="title color-white mb-5">{t("business_extra_slogan")}</h1>
                                                <img src={LabelWise} alt="wise-label" />
                                                <div>
                                                    <div className="d-flex icons mt-10">
                                                        <a href={envir.Variables.WiseSocialFacebook} target="_blank" rel="noreferrer">
                                                            <div className="icon icon-facebook-round"></div>
                                                        </a>
                                                        <a href={envir.Variables.WiseSocialLinkedIn} target="_blank" rel="noreferrer">
                                                            <div className="icon icon-linkedin-round"></div>
                                                        </a>
                                                        <a href={envir.Variables.WiseSocialYoutube} target="_blank" rel="noreferrer">
                                                            <div className="icon icon-youtube-round"></div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-hero hero-form-right">
                                        <div className="container pt-16 py-10">
                                            <div>
                                                <h1 className='title text-center'>{t("business_unknown1")}</h1>
                                            </div>
                                            {
                                                !message
                                                    ?
                                                    null
                                                    :
                                                    <AlertMessage message={message} setMessage={setMessage} disabled />
                                            }
                                            {
                                                validatorIsLoading ? <div className="d-flex justify-content-center"><Loading text={null} /></div> :
                                                    isError ? <NoData error /> /*#VDG */ :
                                                        <div className='mt-8 w-75 mx-auto'>
                                                            <div className='mt-4'>
                                                                <div className='mt-2 mb-2'>
                                                                    <Link to="/" className="btn-primary btn btn-xlg">
                                                                        {t("business_main_back")}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}
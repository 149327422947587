import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";

const useGetCompanyTeam = (props) => {
    const userStore = useUserStore();
    return useQuery({
        queryKey: ["getCompanyTeam"],
        queryFn: () => axios.get(`v3/companies/${userStore.companyData.id}/team`),
        retry: 2,
        enabled: !!userStore.companyData,
        refetchOnWindowFocus: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {
                props.setData(res.data.instance)
            }
        }),
        onError: (res) => handleRequestError({ response: res })
    })
}

export default useGetCompanyTeam;
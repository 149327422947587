import { useUserStore } from "components/utils/stores/userStore";
import i18next, { t } from "i18next";
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { handleAllPermissions, isValid } from "components/utils/methods/MyMethods";
import { Menu } from "antd";

function Navigation(props) {
    // # STORES
    const userStore = useUserStore();
    const navigate = useNavigate();

    // # CLIENT STATES
    const [PM_JobView, setPM_JobView] = useState(false);
    const [PM_JobEventsView, setPM_JobEventsView] = useState(false);
    const [PM_DashboardView, setPM_DashboardView] = useState(false);
    const [menuItems, setMenuItems] = useState([]);

    const roleIdUser = "56640F73-03B0-4E63-93EE-CDD3AC9D7068".toLowerCase();

    useEffect(() => {
        if (!isValid([userStore.userData], [undefined, null])) { return }
        handleAllPermissions(userStore.userData.permissionsData, [
            ["PM_VIEW_JOBS", setPM_JobView],
            ["PM_VIEW_DASHBOARD", setPM_DashboardView],
            ['PM_JobEventsView', setPM_JobEventsView]
        ])
    }, [userStore.userData]);

    useEffect(() => {
        const items = [
            {
                label: t("business_main_home"),
                key: 'wise',
                icon: <>
                    <span className="icon-house pr-2"></span></>
            },
            !PM_DashboardView ? null :
                userStore.companyData && userStore.companyData.isDemo && userStore.companyData.isAgency === false ?
                    {
                        label: t("business_main_dashboard"),
                        key: 'dashboard',
                        icon: <><span className="icon-stats pr-2"></span></>,
                        children: [
                            {
                                label: "Accelerate dashboard",
                                key: 'accelerate-dashboard',
                            },
                            {
                                label: "Improve dashboard",
                                key: "improve-dashboard",
                            },
                            {
                                label: "Deep dive dashboard",
                                key: "deep-dive-dashboard",
                            },
                            {
                                label: t("business_main_dashboard"),
                                key: 'dashboard',
                            },
                            // {
                            //     label: t("business_main_recruitment_dashboard"),
                            //     key: 'recruiter-dashboard',
                            // },
                            // {
                            //     label: t("business_main_candidate_insight_dashboard"),
                            //     key: 'candidate-insight-dashboard',
                            // },
                            // {
                            //     label: t("business_main_must_have_dashboard"),
                            //     key: 'must-have-dashboard',
                            // },
                            // {
                            //     label: t("business_dashboard_type_cekey"),
                            //     key: 'agency-dashboard',
                            // },
                        ],
                    } :
                    userStore.companyData && userStore.companyData.isDemo && userStore.companyData.isAgency === true ?
                        {
                            label: t("business_main_dashboard"),
                            key: 'dashboard',
                            icon: <><span className="icon-stats pr-2"></span></>,
                            children: [
                                {
                                    label: "Recruiter dashboard",
                                    key: 'recruiter-dashboard2',
                                },
                                {
                                    label: "Business & Sales dashboard",
                                    key: "business-sales-dashboard",
                                },
                                {
                                    label: t("business_main_dashboard"),
                                    key: 'dashboard',
                                },
                                // {
                                //     label: t("business_main_recruitment_dashboard"),
                                //     key: 'recruiter-dashboard',
                                // },
                                // {
                                //     label: t("business_main_candidate_insight_dashboard"),
                                //     key: 'candidate-insight-dashboard',
                                // },
                                // {
                                //     label: t("business_main_must_have_dashboard"),
                                //     key: 'must-have-dashboard',
                                // },
                                // {
                                //     label: t("business_dashboard_type_cekey"),
                                //     key: 'agency-dashboard',
                                // },
                            ],
                        } :
                        {
                            label: t("business_main_dashboard"),
                            key: 'dashboard',
                            icon: <><span className="icon-stats pr-2"></span></>,
                        },
            {
                label: t("business_main_company"),
                key: 'company',
                icon: <>
                    <span className="icon-company pr-2"></span></>
            },
            !PM_JobView ? null :
                {
                    label: t("business_main_jobs"),
                    key: 'jobs',
                    icon: <><span className="icon-briefcase pr-2"></span></>,
                },
            {
                label: t("business_main_account"),
                key: 'account',
                icon: <><span className="icon-person pr-2"></span></>,
            },
            userStore.userData && userStore.userData.roleData && userStore.userData.roleData.id.toLowerCase() === roleIdUser ? null :
                {
                    label: t("business_profile_title"),
                    key: 'recruiter-branding',
                    icon: <><span className="icon-recruiter-brand pr-2"></span></>,
                },
        ];

        setMenuItems(items)

    }, [PM_JobView, PM_DashboardView, userStore.companyData, i18next.language])




    return (
        <div>
            {
                userStore.clientData.loggedIn ?
                    <div className="nav">
                        <nav className="nav-main container d-flex flex-row align-items-center">
                            <Menu mode="horizontal" style={{ width: "100%", height: "100%" }} items={menuItems} onClick={(({ item, key }) => navigate(`/${key}`))} />
                        </nav>
                        {/* <nav className="nav-main container d-flex flex-row align-items-center">
                            <NavLink to="/wise" activeclassname="active">
                                <div className="menu-item">
                                    <span className="icon-house pr-2"></span>
                                    <span>{t("business_main_home")}</span>
                                </div>
                            </NavLink>
                            {
                                !userStore.companyData ? null :
                                    userStore.companyData.subscriptionData.int1 === 10 && userStore.companyData.subscriptionData.guid1 === "0e63167e-848d-42aa-9365-864318aa89d0" ? null :
                                        !PM_DashboardView ? null :
                                            <NavLink to="/dashboard" activeclassname="active">
                                                <div className="menu-item">
                                                    <span className="icon-stats pr-2"></span>
                                                    <span>
                                                        {t("business_main_dashboard")}
                                                    </span>
                                                </div>
                                            </NavLink>
                            }
                            {
                                !userStore.companyData ? null :
                                    userStore.companyData.subscriptionData.int1 === 10 && userStore.companyData.subscriptionData.guid1 === "0e63167e-848d-42aa-9365-864318aa89d0" ? null :
                                        <NavLink to="/company" activeclassname="active">
                                            <div className="menu-item">
                                                <span className="icon-company fs-medium pr-2"></span>
                                                <span>
                                                    {t("business_main_company")}
                                                </span>
                                            </div>
                                        </NavLink>
                            }
                            {
                                !PM_JobView ? null :
                                    !userStore.companyData ? null :
                                        userStore.companyData.subscriptionData.int1 === 10 && userStore.companyData.subscriptionData.guid1 === "0e63167e-848d-42aa-9365-864318aa89d0" ? null :
                                            <NavLink to="/jobs" activeclassname="active">
                                                <div className="menu-item">
                                                    <span className="icon-briefcase fs-medium pr-2"></span>
                                                    <span>
                                                        {t("business_main_jobs")}
                                                    </span>
                                                </div>
                                            </NavLink>
                            }
                            <NavLink to="/account" activeclassname="active">
                                <div className="menu-item">
                                    <span className="icon-person pr-2"></span>
                                    <span>
                                        {t("business_main_account")}
                                    </span>
                                </div>
                            </NavLink>
                            <NavLink to="/recruiter-branding" activeclassname="active">
                                <div className="menu-item">
                                    <span className="icon-recruiter-brand fs-medium pr-2"></span>
                                    <span>
                                        {t("business_profile_title")}
                                    </span>
                                </div>
                            </NavLink>
                        </nav> */}
                    </div> :
                    null
            }
        </div>
    );
}

export default Navigation;
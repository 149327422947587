import { useUserStore } from "components/utils/stores/userStore";
import { useEffect, useState } from "react";

export default function HeaderAnnouncement(props) {
    const userStore = useUserStore();

    const warningIcon = <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 16L9.5 0L19 16H0ZM9.5 13.4737C9.7447 13.4737 9.94995 13.3928 10.1158 13.2312C10.281 13.07 10.3636 12.8702 10.3636 12.6316C10.3636 12.393 10.281 12.1931 10.1158 12.032C9.94995 11.8703 9.7447 11.7895 9.5 11.7895C9.2553 11.7895 9.05033 11.8703 8.88509 12.032C8.71927 12.1931 8.63636 12.393 8.63636 12.6316C8.63636 12.8702 8.71927 13.07 8.88509 13.2312C9.05033 13.3928 9.2553 13.4737 9.5 13.4737ZM8.63636 10.9474H10.3636V6.73684H8.63636V10.9474Z" fill="#F09023" />
    </svg>;

    const errorIcon = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 13.5C9.255 13.5 9.4689 13.4136 9.6417 13.2408C9.8139 13.0686 9.9 12.855 9.9 12.6C9.9 12.345 9.8139 12.1311 9.6417 11.9583C9.4689 11.7861 9.255 11.7 9 11.7C8.745 11.7 8.5314 11.7861 8.3592 11.9583C8.1864 12.1311 8.1 12.345 8.1 12.6C8.1 12.855 8.1864 13.0686 8.3592 13.2408C8.5314 13.4136 8.745 13.5 9 13.5ZM8.1 9.9H9.9V4.5H8.1V9.9ZM9 18C7.755 18 6.585 17.7636 5.49 17.2908C4.395 16.8186 3.4425 16.1775 2.6325 15.3675C1.8225 14.5575 1.1814 13.605 0.7092 12.51C0.2364 11.415 0 10.245 0 9C0 7.755 0.2364 6.585 0.7092 5.49C1.1814 4.395 1.8225 3.4425 2.6325 2.6325C3.4425 1.8225 4.395 1.1811 5.49 0.7083C6.585 0.2361 7.755 0 9 0C10.245 0 11.415 0.2361 12.51 0.7083C13.605 1.1811 14.5575 1.8225 15.3675 2.6325C16.1775 3.4425 16.8186 4.395 17.2908 5.49C17.7636 6.585 18 7.755 18 9C18 10.245 17.7636 11.415 17.2908 12.51C16.8186 13.605 16.1775 14.5575 15.3675 15.3675C14.5575 16.1775 13.605 16.8186 12.51 17.2908C11.415 17.7636 10.245 18 9 18Z" fill="#AB1818" />
    </svg>;

    const messageIcon = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.4 0H1.6C0.72 0 0.00799999 0.72 0.00799999 1.6L0 16L3.2 12.8H14.4C15.28 12.8 16 12.08 16 11.2V1.6C16 0.72 15.28 0 14.4 0ZM12.8 9.6H3.2V8H12.8V9.6ZM12.8 7.2H3.2V5.6H12.8V7.2ZM12.8 4.8H3.2V3.2H12.8V4.8Z" fill="#1E1E1E" />
    </svg>;

    const crossIcon = <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.41399 0L5.65699 4.242L9.89899 0L11.313 1.414L7.07099 5.656L11.313 9.898L9.89899 11.312L5.65699 7.07L1.41399 11.312L-1.23978e-05 9.898L4.24199 5.656L-1.23978e-05 1.414L1.41399 0Z" fill="#505050" />
    </svg>;

    const [data, setData] = useState(null);
    const [icon, setIcon] = useState(messageIcon);
    const [message, setMessage] = useState(null);

    function handleType(type) {
        switch (type) {
            case "Warning":
                setIcon(warningIcon)
                break;
            case "Error":
                setIcon(errorIcon)
                break;
            default:
                break;
        }
    }

    function handleMessage(translations) {
        return setMessage(translations.filter(translate => translate.languageId.toLowerCase() === userStore.clientData.language.id.toLowerCase())[0].text)
    }

    useEffect(() => {
        if (!props.data) { return }
        if (!props.data.descriptionTranslations) { return }
        handleMessage(props.data.descriptionTranslations);
        handleType(props.data.severityTypeName);
        setData(props.data);
    }, [props.data, userStore.clientData])

    return <>
        {
            !data ? null :
                <div id="header-announcement">
                    <div className="container mx-auto text-center py-2">
                        <div className="font-special d-flex align-items-center justify-content-center">
                            <span className="pr-2">
                                {icon}
                            </span>
                            {/* <span className="font-special" dangerouslySetInnerHTML={{ __html: "test" }} /> */}
                            <span className="font-special" dangerouslySetInnerHTML={{ __html: message }} />
                            <span className="ml-2 hover path-fill pointer " onClick={() => setData(null)}>
                                {crossIcon}
                            </span>
                        </div>
                    </div>
                </div>
        }
    </>
}
import { Input } from 'antd';
import Picker from 'emoji-picker-react';
import * as React from 'react';
import { useEffect, useState } from 'react';

export default function InputEmoji(props) {

  const smileySvg = <svg width="25" height="25" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.5 29.0625C11.903 29.0625 8.45333 27.6336 5.90986 25.0901C3.3664 22.5467 1.9375 19.097 1.9375 15.5C1.9375 11.903 3.3664 8.45333 5.90986 5.90986C8.45333 3.3664 11.903 1.9375 15.5 1.9375C19.097 1.9375 22.5467 3.3664 25.0901 5.90986C27.6336 8.45333 29.0625 11.903 29.0625 15.5C29.0625 19.097 27.6336 22.5467 25.0901 25.0901C22.5467 27.6336 19.097 29.0625 15.5 29.0625ZM15.5 31C19.6109 31 23.5533 29.367 26.4602 26.4602C29.367 23.5533 31 19.6109 31 15.5C31 11.3891 29.367 7.44666 26.4602 4.53984C23.5533 1.63303 19.6109 0 15.5 0C11.3891 0 7.44666 1.63303 4.53984 4.53984C1.63303 7.44666 0 11.3891 0 15.5C0 19.6109 1.63303 23.5533 4.53984 26.4602C7.44666 29.367 11.3891 31 15.5 31Z" fill="#505050" />
    <path d="M23.8913 18.4062C24.0614 18.7008 24.1509 19.0349 24.1509 19.375C24.1509 19.7151 24.0614 20.0492 23.8913 20.3438C23.0412 21.8169 21.8181 23.0403 20.345 23.8906C18.8719 24.7409 17.2009 25.1882 15.5 25.1875C13.7994 25.1878 12.1288 24.7404 10.6561 23.8901C9.18338 23.0398 7.96055 21.8167 7.11062 20.3438C6.94065 20.0494 6.85113 19.7154 6.85104 19.3755C6.85096 19.0356 6.94031 18.7016 7.11012 18.4071C7.27994 18.1126 7.52424 17.868 7.8185 17.6978C8.11276 17.5276 8.44662 17.4378 8.78656 17.4375H22.2134C22.5535 17.4375 22.8876 17.527 23.1822 17.6971C23.4767 17.8671 23.7213 18.1117 23.8913 18.4062ZM13.5625 12.5938C13.5625 14.198 12.6945 12.5938 11.625 12.5938C10.5555 12.5938 9.6875 14.198 9.6875 12.5938C9.6875 10.9895 10.5555 9.6875 11.625 9.6875C12.6945 9.6875 13.5625 10.9895 13.5625 12.5938ZM21.3125 12.5938C21.3125 14.198 20.4445 12.5938 19.375 12.5938C18.3055 12.5938 17.4375 14.198 17.4375 12.5938C17.4375 10.9895 18.3055 9.6875 19.375 9.6875C20.4445 9.6875 21.3125 10.9895 21.3125 12.5938Z" fill="#505050" />
  </svg>;
  const smileySvg2 = <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><g id="wink-tongue"><path d="M512,256c0,141.4-114.62,256-256,256S0,397.38,0,256,114.62,0,256,0,512,114.62,512,256Z" fill="#ffd764" /><path d="M294.74,172a61.44,61.44,0,1,0,61.42-61.42A61.41,61.41,0,0,0,294.74,172Z" fill="#fff" /><path d="M325.46,172a30.72,30.72,0,1,0,30.7-30.7A30.7,30.7,0,0,0,325.46,172Z" fill="#00b4dc" /><path d="M211.28,215.1a10.62,10.62,0,0,1-10.64-10.62,36.36,36.36,0,0,0-72.72,0,10.62,10.62,0,1,1-21.24,0,57.61,57.61,0,1,1,115.22,0,10.61,10.61,0,0,1-10.62,10.62Z" fill="#4a4a4a" /><path d="M389.12,310.6a133.12,133.12,0,1,1-266.24,0Z" fill="#ae453e" /><path d="M189.44,331.28V462.6c0,27.16,28.7,49.4,63.74,49.4h5.66c35,0,63.72-22.24,63.72-49.4V331.28Z" fill="#fa645a" /><path d="M256,440.32a10.23,10.23,0,0,1-10.24-10.24V343a10.24,10.24,0,1,1,20.48,0v87A10.23,10.23,0,0,1,256,440.32Z" fill="#ae453e" /></g></svg>
  // const smileySvg2 = <svg width="35" height="35" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  //   <rect x="1" y="1" width="22" height="22" rx="7.656" fill="#f8de40" />
  //   <path class="b" d="M8.907 9.844a.182.182 0 0 1-.331.1 2.016 2.016 0 0 0-.569-.567 1.731 1.731 0 0 0-1.915 0 2.016 2.016 0 0 0-.571.569.182.182 0 0 1-.331-.1 1.632 1.632 0 0 1 .346-1.023 1.927 1.927 0 0 1 3.026 0 1.64 1.64 0 0 1 .345 1.021zM18.81 9.844a.182.182 0 0 1-.331.1 2.026 2.026 0 0 0-.568-.567 1.732 1.732 0 0 0-1.916 0 2.016 2.016 0 0 0-.571.569.182.182 0 0 1-.331-.1 1.632 1.632 0 0 1 .346-1.023 1.927 1.927 0 0 1 3.026 0 1.64 1.64 0 0 1 .345 1.021z" />
  //   <path d="M23 13.938a14.69 14.69 0 0 1-12.406 6.531c-5.542 0-6.563-1-9.142-2.529A7.66 7.66 0 0 0 8.656 23h6.688A7.656 7.656 0 0 0 23 15.344z" fill="#e7c930" />
  //   <path d="M7.127 12h9.746a1.937 1.937 0 0 1 1.937 1.937 1.938 1.938 0 0 1-1.937 1.938H7.127a1.937 1.937 0 0 1-1.937-1.937A1.937 1.937 0 0 1 7.127 12z" fill="#fff" /><ellipse class="e" cx="12" cy="13.938" rx="6.188" ry=".25" /><ellipse class="e" cx="7.257" cy="13.938" rx=".208" ry="1.438" />
  //   <ellipse class="e" cx="9.628" cy="13.938" rx=".208" ry="1.438" /><ellipse class="e" cx="12" cy="13.938" rx=".208" ry="1.438" /><ellipse class="e" cx="14.372" cy="13.938" rx=".208" ry="1.438" />
  //   <ellipse class="e" cx="16.743" cy="13.938" rx=".208" ry="1.438" /></svg>
  //   ;

  const [showEmoteList, setShowEmoteList] = useState(false);

  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef);

  const onEmojiClick = (event, emojiObject) => {
    try {
      const val = !props.value ? "" : props.value
      props.setRecruiterMessage(val + emojiObject.emoji)
      if (props.form) {
        props.form.setFieldsValue({ "message": val + emojiObject.emoji })
      }
    } catch (error) {
      console.error(error)
    }
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowEmoteList(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <div>
      <div
        className={"d-flex flex-row justify-content-between align-items-center input-emoji"}
      >
        <Input
          id={props.name}
          name={props.name}
          defaultValue={props.defaultValue === null ? "" : props.defaultValue === "" ? "" : props.defaultValue}
          value={props.value === undefined ? null : props.value}
          placeholder={props.placeholder === undefined ? "..." : props.placeholder}
          disabled={props.disabled !== undefined ? props.disabled : false}
          onChange={props.onChange}
          maxLength={150}
          showCount
        />
        <div style={{ position: "relative" }}>
          <div className='ml-2 pointer' id="emoji-button" onClick={() => setShowEmoteList(!showEmoteList)}>{smileySvg2}</div>
          {
            !showEmoteList ? null :
              <div className="emoji-list" ref={wrapperRef}>
                <Picker
                  onEmojiClick={onEmojiClick}
                  disableAutoFocus={true}
                  groupNames={{ smileys_people: 'PEOPLE' }}
                  native
                />
              </div>
          }
        </div>
      </div >
    </div >
  );
}
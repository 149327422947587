import React, { createContext, useContext, useState } from "react";

var initJobfairsState = false; // initial state
export const JobfairsContext = createContext(initJobfairsState);

export const useJobfairsContextState = (useReactState) => {
    const [JobfairsState, setJobfairsState] = useContext(JobfairsContext);
    if (useReactState === undefined || useReactState === null || useReactState) {
        return [JobfairsState, setJobfairsState]; // Expects to be in [-, -] format like useState
    }
    else if (!useReactState) {
        return JobfairsState;
    }
}

const JobfairsContextProvider = ({ children }) => {
    // provide general React hook useState
    const [jobfairsState, setJobfairs] = useState(initJobfairsState);
    return (<JobfairsContext.Provider value={[jobfairsState, setJobfairs]}>
        {children}
    </JobfairsContext.Provider>)
}

export default JobfairsContextProvider;
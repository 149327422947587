import { useQuery } from "@tanstack/react-query";
import { sendAxiosPostRequest } from "components/utils/methods/myRequestHandlers";
import { useUserStore } from "components/utils/stores/userStore";
import moment from "moment";

export const useGetDbScoreEvolution = (propsFn) => {
    const userStore = useUserStore();
    const props = propsFn || {};

    return useQuery({
        queryKey: [props.key || 'getDbScoreEvolution'],
        queryFn: () => sendAxiosPostRequest({
            route: `v5/select/business/scores/evolutions`,
            // route: `v3/business/select/businesses/${userStore.companyData.id}/dashboards/evolutions`,
            body: {
                CeKey: props.ceKey || null,
                Category: props.category || 'Information',
                WeekNotation: props.isWeekNotation || false,
                UseDivisions: props.useDivisions || false,
                BusinessId: userStore.companyData.id,
                FilterPhaseId: props.filterPhaseId || null,
                FilterUserId: props.filterUserId || null,
                FilterDate: props.filterDate ? (moment(props.filterDate, 'MMM \'YY').format('YYYY-MM-DD')).toString() : null,
            },
            customCallback: (res) => {
                if (props.setFilterDates && res && res.data.status === 200 && res.data.instance && res.data.instance.length > 0) {
                    props.setFilterDates(res.data.instance.sort((a, b) => a.sortIndex - b.sortIndex).map(item => {
                        const date = moment({ year: item.yearIndex, month: item.monthIndex }).format('MMM \'YY');
                        return {
                            label: date,
                            value: date
                        }
                    }));
                }
            }
        }),
        refetchOnWindowFocus: false,
        enabled: props.enabled || false,
    })
}
import { Button, Form, Tag } from "antd";
import AccordionContent from "components/layouts/accordions/accordionContent";
import {
    CheckCircleOutlined, ExclamationCircleOutlined
} from '@ant-design/icons';
import { t } from 'i18next';
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";
import moment from "moment";

export default function JobQuestion(props) {
    // # STORES
    const userStore = useUserStore();

    // # CLIENT STATES
    const [formEdit] = Form.useForm();
    const [replyText, setReplyText] = useState("");
    const [isEditing, setEditing] = useState(false);

    // # SERVER STATES
    const mutatePostJobReply = usePostJobReply({ id: props.data.id, companyId: userStore.companyData.id, replyText, getData: props.getData });
    const mutateJobQuestionDelete = useJobQuestionDelete({ jobId: props.data.jobId, tipId: props.data.tipId, companyId: userStore.companyData.id, getData: props.getData })
    const mutateJobQuestionDeleteReply = useJobQuestionDeleteReply({ jobId: props.data.jobId, tipId: props.data.tipId, companyId: userStore.companyData.id, getData: props.getData })

    // # UTILS
    var locale = new moment(props.data.createdOn);
    const date = locale.utc(locale).local();
    const DeleteQuestionBtn = () => <div
        className="link mx-2 mb-2 color-darkgrey"
        onClick={() => mutateJobQuestionDelete.mutate()}
    >
        {t("business_company_jobs_questions_overlay_question_delete")}
    </div>
    // const deleteQuestionBtn = () =><Button
    //         className="btn btn-secondary mr-2 mb-2"
    //         onClick={() => mutateJobQuestionDelete.mutate()}
    //         loading={mutateJobQuestionDelete.isLoading}
    //     >
    //         {t("business_company_jobs_questions_overlay_question_delete")}
    //     </Button>


    return <>
        <AccordionContent
            title={<>
                <div className='d-flex align-items-center'>
                    {!props.data.answer ? <ExclamationCircleOutlined className='color-red mr-2' /> : <CheckCircleOutlined className='color-lime mr-2' />} {props.data.feedback}
                </div>
                {
                    props.data.answer ? null :
                        <div className='mt-2 mb-1'> <Tag color="red">{t("business_company_jobs_questions_overlay_tag_unanswered")}</Tag> </div>
                }
            </>
            }
        >
            {
                // ANSWERED QUESTION
                props.data.answer ? <>
                    <div className="mx-3 mt-1 mb-2">
                        {
                            isEditing ?
                                <>
                                    <Form
                                        form={formEdit}
                                        name="form-job-question-reply-edit"
                                        onFinishFailed={(err) => console.error(err)}
                                        onFinish={() => mutatePostJobReply.mutate()}
                                        initialValues={{ editReply: props.data.answer }}
                                    >
                                        <Form.Item
                                            name={`editReply`}
                                            rules={[{ required: true, message: false }]}
                                        >
                                            <TextArea
                                                showCount
                                                placeholder={t("business_company_jobs_questions_overlay_question_reply_placeholder")}
                                                maxLength={250}
                                                onChange={(e) => setReplyText(e.target.value)}
                                                className='mb-3'
                                            />
                                        </Form.Item>

                                        <div className="d-flex flex-row align-items-center">
                                            <Button
                                                htmlType='submit'
                                                className='btn btn-primary mb-2 mr-2'
                                                loading={mutatePostJobReply.isLoading}
                                            >
                                                {t("business_main_save")}
                                            </Button>
                                            <div className="btn btn-secondary mb-2 mr-2" onClick={() => { setEditing(false); setReplyText(props.data.answer) }}>
                                                {t("business_main_cancel")}
                                            </div>
                                        </div>

                                    </Form>
                                </> :
                                <>
                                    <div className="d-flex align-items-center mb-2">
                                        {
                                            !props.data.logoUrl ?
                                                <div className="avatar default grey small mr-2"></div> :
                                                <div className="avatar grey small mr-2">
                                                    <img src={props.data.logoUrl} alt="recruiter-avatar" />
                                                </div>
                                        }
                                        <span className="font-special color-grey">
                                            {`${props.data.firstName /* replier */} - ${date.format("HH:mm")} ${date.format("DD/MM/YYYY")}`}
                                        </span>
                                    </div>
                                    <p className="pt-2">{props.data.answer}</p>
                                    <div className="d-flex flex-row align-items-center mt-3">
                                        {
                                            props.data.replyUserId !== userStore.userData.id ? null :
                                                <div onClick={() => setEditing(true)} className="btn btn-primary mb-2 mr-2">
                                                    {t("business_company_jobs_questions_overlay_question_edit")}
                                                </div>
                                        }
                                        {/* DELETE REPLY */}
                                        <Button
                                            className="btn btn-secondary mr-2 mb-2"
                                            onClick={() => mutateJobQuestionDeleteReply.mutate()}
                                            loading={mutateJobQuestionDeleteReply.isLoading}
                                        >
                                            {t("business_company_jobs_questions_overlay_question_delete_reply")}

                                        </Button>
                                        {/* DELETE QUESTION */}
                                        <DeleteQuestionBtn />
                                    </div>
                                </>
                        }

                    </div>
                </> :
                    // UNANSWERED QUESTION 
                    <Form
                        className='mx-4 my-3'
                        name={"form-job-props.data"}
                        onFinishFailed={(err) => console.log(err)}
                        onFinish={() => mutatePostJobReply.mutate()}
                    >
                        <Form.Item
                            name={`reply`}
                            rules={[{ required: true, message: false }]}
                        >
                            <TextArea
                                showCount
                                placeholder={t("business_company_jobs_questions_overlay_question_reply_placeholder")}
                                maxLength={250}
                                onChange={(e) => setReplyText(e.target.value)}
                                className='mb-3'
                            />
                        </Form.Item>
                        <div className="d-flex flex-row align-items-center">
                            <Button
                                htmlType='submit'
                                className='btn btn-primary mb-2 mr-2'
                                loading={mutatePostJobReply.isLoading}
                            >
                                {t("business_company_jobs_questions_overlay_question_send")}
                            </Button>
                            {/* DELETE QUESTION */}
                            <DeleteQuestionBtn />
                        </div>
                    </Form>
            }
        </AccordionContent>
    </>
}


const usePostJobReply = (props) => {
    const userStore = useUserStore();

    return useMutation({
        mutationKey: ["mutatePostJobReply"],
        mutationFn: () => axios.post(`v5/insert/feedback/answer`, {
            FeedbackId: props.id,
            Answer: props.replyText,
            UserId: userStore.userData.id
        }),
        retry: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, hasAlert: true, isSubmit: true, callback: () => {
                setTimeout(() => {
                    props.getData();
                }, 100);
            }
        }),
        onError: (res) => handleRequestError({ response: res, hasAlert: true, isSubmit: true }),
    })
}

const useJobQuestionDelete = (props) => {
    return useMutation({
        mutationKey: ["mutateJobQuestionDelete"],
        mutationFn: () => axios.get(`v1/delete/companies/${props.companyId}/jobs/${props.jobId}/tips/${props.tipId}`),
        retry: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, hasAlert: true, isSubmit: true, callback: () => {
                setTimeout(() => {
                    props.getData();
                }, 100);
            }
        }),
        onError: (res) => handleRequestError({ response: res, hasAlert: true, isSubmit: true }),
    })
}

const useJobQuestionDeleteReply = (props) => {
    return useMutation({
        mutationKey: ["mutateJobQuestionDeleteReply"],
        mutationFn: () => axios.get(`v1/delete/companies/${props.companyId}/jobs/${props.jobId}/tips/${props.tipId}/reply`),
        retry: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, hasAlert: true, isSubmit: true, callback: () => {
                setTimeout(() => {
                    props.getData();
                }, 100);
            }
        }),
        onError: (res) => handleRequestError({ response: res, hasAlert: true, isSubmit: true }),
    })
}
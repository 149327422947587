import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";

const useGetPromisesContent = (props) => {
    const userStore = useUserStore();
    return useQuery({
        queryKey: ["getPromisesContent"],
        queryFn: async () => await axios.post(`v3/business/select/businesses/${userStore.companyData.id}/promises`, {
            Category: props.category,
            Iso: userStore.clientData.language.isoCode
        }),
        retry: 2,
        enabled: !!userStore.companyData,
        refetchOnWindowFocus: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {
                var data = res.data.instance;
                props.setData(data);
            }
        }),
        onError: (res) => handleRequestError({ response: res })
    })
}

export default useGetPromisesContent;
import Loading from "components/animations/loading";
import { useUserStore } from "components/utils/stores/userStore";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function GuestRedirectLayout(props) {
    const userStore = useUserStore();
    const navigate = useNavigate();

    useEffect(() => {
        if (userStore.clientData.checkedRememberMe === false) { return }
        if (!userStore.companyData && userStore.clientData.loggedIn) {
            console.log("red1")
            navigate("/login");
        }
        if (userStore.companyData) {
            if (props.redirectSmallSubsciption && userStore.companyData.subscriptionData.int1 === 10 && userStore.companyData.subscriptionData.guid1 === "0e63167e-848d-42aa-9365-864318aa89d0") {
                console.log("red2")
                navigate("/login");
            }
        }
        if (userStore.clientData.checkedRememberMe && userStore.clientData.loggedIn === false) {
            console.log("red3")
            console.log(userStore.clientData)
            navigate("/login");
        }

    }, [userStore.companyData, userStore.clientData]);

    return <>
        {
            !userStore.clientData.checkedRememberMe || !userStore.clientData.loggedIn || !userStore.companyData || !userStore.userData ?
                <div className="d-flex justify-content-center">
                    <Loading center fullscreen />
                </div> :
                <>
                    {
                        !userStore.companyData ? null :
                            props.children
                    }
                </>
        }
    </>
}
import { Segmented, Tag } from "antd";
import BarChart from "components/layouts/charts/barchart";
import NoData from "components/layouts/no-data/nodata";
import Panel from "components/modules/panel/panel";
import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import PieChart from "../../charts/piechart";

// ? Evolution in time in barchart for general rating (positive, moderate, negative)
export default function CeEvolutionPanel(props) {
    const [data, setData] = useState()
    const [keys, setKeys] = useState()


    function handleColor(key) {
        switch (key) {
            case "business_dashboard_evolution_experience_category_high":
                return "hsl(105, 100%, 43%)"
            case "business_dashboard_evolution_experience_category_medium":
                return "hsl(50, 100%, 67%)"
            case "business_dashboard_evolution_experience_category_low":
                return "hsl(0, 84%, 47%)"
            default:
                return "hsl(50, 100%, 67%)"
        }
    }

    // ? Handle raw data and create usable data structure accepted for barchart component
    useEffect(() => {
        if (!props.data) { return }
        const chartData = [];
        var keys = [];
        const possibleItems = [
            "business_dashboard_evolution_experience_category_low",
            "business_dashboard_evolution_experience_category_medium",
            "business_dashboard_evolution_experience_category_high",
        ]

        props.data.forEach(item => {

            const date = moment({ year: item.yearIndex, month: item.monthIndex - 1 }); // Adjust month to 0-based index
            const formattedDate = date.format('MMM YY'); // Format like "Jun 23"

            const existingEntry = chartData.find(entry => entry.date === formattedDate);
            if (existingEntry) {
                // existingEntry[categoryTranslation] = item.count;
                console.log("double entry found", existingEntry)
            } else {
                var colorItems = {};
                possibleItems.map(item => {
                    return colorItems = { ...colorItems, [`${t(item)}Color`]: handleColor(item) }
                });
                const entry = {
                    ...colorItems, date: formattedDate,
                    [t(possibleItems[0])]: item.amountLow || 0,
                    [t(possibleItems[1])]: item.amountMedium || 0,
                    [t(possibleItems[2])]: item.amountHigh || 0
                };
                chartData.push(entry);
            }
        });

        possibleItems.forEach(element => {
            keys = [
                ...keys,
                t(element)
            ]
        });
        setData(chartData);
        setKeys(keys);

    }, [props.data])


    return <>

        <Panel
            {...props.panelProps}
            title={t("business_dashboard_agency_score_evolution_panel_title")}
        >
            <>
                {
                    !props.data ? <NoData wise="sad" className="my-3" /> :
                        !data || !keys ? null :
                            <>
                                <BarChart
                                    data={data}
                                    keys={keys}
                                    indexBy="date"
                                    xAxis={t("business_main_date")}
                                    yAxis={t("business_main_amount")}

                                />
                            </>
                }
            </>

        </Panel>
    </>
}

export function CeEvolutionTotalPanel(props) {
    const [data, setData] = useState()
    const [keys, setKeys] = useState()


    function handleColor(key) {
        switch (key) {
            case "business_dashboard_evolution_experience_category_high":
                return "hsl(105, 100%, 43%)"
            case "business_dashboard_evolution_experience_category_medium":
                return "hsl(50, 100%, 67%)"
            case "business_dashboard_evolution_experience_category_low":
                return "hsl(0, 84%, 47%)"
            default:
                return "hsl(50, 100%, 67%)"
        }
    }

    // ? Handle raw data and create usable data structure accepted for barchart component
    useEffect(() => {
        if (!props.data) { return }
        const chartData = [];
        const possibleItems = [
            "business_dashboard_evolution_experience_category_low",
            "business_dashboard_evolution_experience_category_medium",
            "business_dashboard_evolution_experience_category_high",
        ]



        var totalAmountLow = 0;
        var totalAmountMedium = 0;
        var totalAmountHigh = 0;

        props.data.forEach((item) => {
            totalAmountLow = totalAmountLow + (item.amountLow || 0);
            totalAmountMedium = totalAmountMedium + (item.amountMedium || 0);
            totalAmountHigh = totalAmountHigh + (item.amountHigh || 0);
        })
        const entry = [
            {
                id: t(possibleItems[2]),
                label: t(possibleItems[2]),
                value: totalAmountHigh || 0,
                color: handleColor(possibleItems[2]),
                sort: 3
            },
            {
                id: t(possibleItems[1]),
                label: t(possibleItems[1]),
                value: totalAmountMedium || 0,
                color: handleColor(possibleItems[1]),
                sort: 2
            },
            {
                id: t(possibleItems[0]),
                label: t(possibleItems[0]),
                value: totalAmountLow || 0,
                color: handleColor(possibleItems[0]),
                sort: 1
            },
        ]

        // const entry = {
        //     [t(possibleItems[0])]: totalAmountLow || 0,
        //     [t(possibleItems[1])]: totalAmountMedium || 0,
        //     [t(possibleItems[2])]: totalAmountHigh || 0
        // };
        setData(entry);

    }, [props.data])


    return <>

        <Panel
            {...props.panelProps}
            title={t("business_dashboard_agency_score_total_evolution_panel_title")}
        >
            <>
                {
                    !props.data ? <NoData wise="sad" className="my-3" /> :
                        !data ? null :
                            <>
                                <PieChart
                                    data={data}
                                    className="flex justify-content-center"
                                />
                            </>
                }
            </>

        </Panel>
    </>
}
import { useMutation } from "@tanstack/react-query";
import { AutoComplete, Button, Form, Input, Popconfirm, Spin } from "antd";
import ImgCrop from "antd-img-crop";
import Dragger from "antd/es/upload/Dragger";
import axios from "axios";
import InputPhone from "components/forms/input/input-ant-phone";
import PrefixIcon from "components/forms/input/prefix-icon";
import { FileUploadSingleConditionalsCheck } from "components/modules/upload-handlers/FileUploadSingle";
import useEditAccountAvatar from "components/requests/account/useEditAccountAvatar";
import useGetAccountInfo from "components/requests/account/useGetAccountInfo";
import useGetSuggestions from "components/requests/data/useGetSuggestions";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import produce from "immer";
import { useEffect, useState } from "react";
import Loading from "../../components/animations/loading";
import AlertMessage from "../../components/forms/alerts/alert-message";
import GuestRedirectLayout from "../../components/layouts/page-layouts/guest-redirect";
import { getBase64, handleRequestError, handleRequestSuccess, hasPermission } from "../../components/utils/methods/MyMethods";
import LayoutAccount from "./layout-account";
import { toast } from "react-toastify";
import { handleUploadPicture } from "components/utils/methods/AppMethods";


export default function AccountGeneralPage(props) {
    // # STORES
    const userStore = useUserStore();

    // # CLIENT STATES
    const [message, setMessage] = useState(null);
    const [form] = Form.useForm();
    const [formObject, setFormObject] = useState({
        avatar: null,
        firstName: "",
        lastName: "",
        jobPosition: "",
        email: "",
        phone: "",
        recruiterMessage: "",
    });
    const [suggestions, setSuggestions] = useState([]);
    const [existingSuggestion, setExistingSuggestion] = useState(null);

    // # UTILS
    const editFormItem = (key, val, setInit) => {
        if (setInit) {
            form.setFieldsValue({
                [key]: val
            });
        }
        setFormObject(produce(draft => {
            draft[key] = val;
        }))
    }
    const editPhone = (val) => editFormItem("phone", val);
    const setAva = (val) => {
        setFormObject(produce(draft => {
            draft.avatar = val;
        }))
    }

    // # SERVER STATES
    const { data: accountData, refetch: accountInfoRefetch, isRefetching: accountInfoIsRefetching, isLoading: accountInfoIsLoading } = useGetAccountInfo({ editFormItem })
    const mutateSuggestions = useGetSuggestions({ setData: setSuggestions })
    const mutateAccountInfo = useEditAccountInfo({ formObject })
    const mutateAccountAvatar = useEditAccountAvatar({ setPreview: setAva })

    const suggestionThreshold = 1;

    const onFinish = (file) => {
        if (file) {
            handleUploadPicture(file, 10, 0, 0, "business/user/avatar").then((result) => {
                mutateAccountAvatar.mutate({ logoUrl: result })
            }).catch(() => {
                toast.error(t("business_extra_general_error2"));
                return;
            });
        }
        else {
            toast.error(t("business_extra_general_error2"));
            return;
        }
    }

    // START AT TOP OF PAGE ON LOAD
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!userStore.clientData.checkedRememberMe) { return }
        if (!userStore.userData) { return } // SOMETIMES WE GET USERID NULL 
        if (!userStore.userData.id) { return } // SOMETIMES WE GET USERID NULL 
        accountInfoRefetch()
    }, [userStore.userData, t.language, userStore.clientData]);

    function onSuggestionInput(e) {
        if ((e).length <= suggestionThreshold) {
            setSuggestions([]);
            return;
        }
        editFormItem("jobPosition", e)
        mutateSuggestions.mutate({ input: e, type: "UserFunction" });
    }

    return (
        <GuestRedirectLayout>
            <LayoutAccount
                title={t("business_account_title")}
                subtitle={t("business_account_subtitle")}
            >

                <div className="card mt-3">
                    {
                        !message ? null :
                            <AlertMessage message={message} setMessage={setMessage} />
                    }
                    {
                        accountInfoIsLoading || accountInfoIsRefetching ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                            <Form
                                form={form}
                                layout="vertical"
                                className="my-4 px-4"
                                name="form-account-general"
                                onFinish={() => mutateAccountInfo.mutate()}
                                onFinishFailed={(err) => console.log(err)}
                            >
                                <>
                                    {
                                        <div className="mb-5">
                                            <ImgCrop
                                                cropShape="round"
                                                aspect={1}
                                                rotationSlider
                                                maxZoom={8}
                                                beforeCrop={async (file, filelist) => {
                                                    const result = await FileUploadSingleConditionalsCheck({
                                                        file: file,
                                                        imageTypes: ["image/jpeg", "image/png"],
                                                        maxSize: 1000,
                                                        CompanyId: userStore.companyData.id,
                                                    });
                                                    console.log(result)
                                                    return result
                                                }}
                                                modalOk={t("business_main_save")}
                                                modalCancel={t("business_main_cancel")}
                                            >
                                                <Dragger
                                                    name="file"
                                                    accept="image/png, image/jpeg"
                                                    className='upload-logo upload-avatar'
                                                    action={null}
                                                    onRemove={() => setAva(undefined)}
                                                    multiple={false}
                                                    showUploadList={false}
                                                    // onPreview={onPreview}
                                                    beforeUpload={(file) => { onFinish(file); return false; }}
                                                >
                                                    <div className="flex flex-row align-items-center justify-content-center">
                                                        <div className="ant-upload-drag-icon logo-preview mx-4">

                                                            {

                                                                !formObject.avatar ?
                                                                    <span className="default icon-account fs-xxlarge d-flex align-items-center justify-content-center"></span> : <img
                                                                        alt="company-logo"
                                                                        // onClick={() => mutateCompanyLogo.mutate({ logo: "" })}
                                                                        style={{ padding: "0px" }}
                                                                        className='my-2'
                                                                        src={formObject.avatar} />
                                                            }
                                                            {
                                                                !formObject.avatar ? null :
                                                                    <div
                                                                        onClick={(event) => event.stopPropagation()}
                                                                    >
                                                                        <Popconfirm
                                                                            title={t("business_avatar_remove_overlay_title")}
                                                                            description={t("business_avatar_remove_overlay_description")}
                                                                            onConfirm={() => {
                                                                                mutateAccountAvatar.mutate({ image: "" })
                                                                            }}
                                                                            okText={t("business_company_logo_remove_overlay_yes")}
                                                                            cancelText={t("business_company_logo_remove_overlay_no")}
                                                                            okButtonProps={{ className: "btn-primary btn d-inline-block", style: { minHeight: "15", minWidth: "auto", padding: "0px 15px" } }}
                                                                            cancelButtonProps={{ className: "btn-secondary btn d-inline-block", style: { minHeight: "15", minWidth: "auto", padding: "0px 15px" } }}
                                                                        >
                                                                            <div
                                                                                className='link-generic relative'
                                                                                style={{ zIndex: 100, userSelect: "contain" }}
                                                                                onClick={(event) => {
                                                                                    event.stopPropagation();
                                                                                }}
                                                                            >
                                                                                {t("business_main_delete")}
                                                                            </div>
                                                                        </Popconfirm>
                                                                    </div>
                                                            }

                                                        </div>
                                                        <div style={{ maxWidth: 500 }}>
                                                            <p className="ant-upload-text mb-1" style={{ textAlign: "left" }}>{!formObject.avatar ? t("business_account_logo_upload") : t("business_account_logo_upload")}</p>
                                                            <p className="ant-upload-hint mb-1" style={{ textAlign: "left" }}>{t("business_company_general_logo_allowed")}</p>
                                                        </div>
                                                    </div>
                                                </Dragger>
                                            </ImgCrop>
                                        </div>
                                    }
                                    <h3 className='mb-4 icon-width d-flex align-items-center'> <span className="icon-account fs-medium icon round lime"></span> {t("business_account_panel_account_title")}</h3>
                                    <div className="d-flex flex-wrap">
                                        <Form.Item
                                            name="firstName"
                                            className="mobile-friendly w-50 pr-2"
                                            rules={[{ required: true, message: false }]}
                                            label={t("business_main_firstname")}
                                        >
                                            <Input
                                                placeholder={t("business_main_firstname")}
                                                onChange={(e) => editFormItem("firstName", e.target.value)}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="lastName"
                                            className="mobile-friendly w-50 pr-2"
                                            rules={[{ required: true, message: false }]}
                                            label={t("business_main_lastname")}
                                        >
                                            <Input
                                                placeholder={t("business_main_lastname")}
                                                onChange={(e) => editFormItem("lastName", e.target.value)}
                                            />
                                        </Form.Item>
                                    </div>
                                    <Form.Item
                                        name="jobPosition"
                                        rules={[{ required: true, message: false }]}
                                        label={t("business_main_job_position")}
                                    >
                                        <AutoComplete
                                            placeholder={t("business_account_jobposition_placeholder")}
                                            notFoundContent={mutateSuggestions.isLoading ? <Spin /> : null}
                                            options={suggestions}
                                            onChange={(e) => onSuggestionInput(e)}
                                            onSelect={(value, option) => editFormItem("jobPosition", value)}
                                            required
                                        />
                                    </Form.Item>
                                </>
                                <>
                                    {/* <h3 className='mb-4 icon-width d-flex align-items-center'> <span className="icon-mail-full icon fs-small round lime"></span> {t("business_main_contact_info")}</h3> */}

                                    <Form.Item
                                        name="email"
                                        rules={[{ required: true, message: false, type: "email" }]}
                                        label={t("business_main_professional_email")}
                                    >
                                        <div data-tip={t("business_tooltip_account_email")}>
                                            <Input
                                                type="mail"
                                                disabled
                                                value={formObject.email}
                                                prefix={<PrefixIcon value="icon-mail fs-small" />}
                                            />
                                        </div>
                                    </Form.Item>

                                    <Form.Item
                                        name="number"
                                        rules={[{ required: true, message: false }, { min: 7, message: false }]}
                                        label={t("business_main_phone")}
                                    >
                                        <InputPhone
                                            required
                                            onChange={editPhone}
                                            form={form}
                                            name="number"
                                            defaultValue={!accountData ? "" : accountData.data.instance.phone}
                                        />
                                    </Form.Item>
                                </>

                                <div className="btns-right">
                                    <Button
                                        htmlType="submit"
                                        className="btn btn-primary mr-2"
                                        loading={mutateAccountInfo.isLoading}
                                    >
                                        {t("business_main_save")}
                                    </Button>
                                    <div onClick={() => accountInfoRefetch()} className="btn-secondary btn d-inline-block">{t("business_main_cancel")}</div>
                                </div>
                            </Form>
                    }
                </div>
            </LayoutAccount>
        </GuestRedirectLayout>
    );
}

const useEditAccountInfo = (props) => {
    const userStore = useUserStore();
    return useMutation({
        mutationKey: ["mutateAccountInfo"],
        mutationFn: () => axios.post("account/info/update", {
            UserId: userStore.userData.id,
            FirstName: props.formObject.firstName.trim(),
            LastName: props.formObject.lastName.trim(),
            Email: props.formObject.email,
            Phone: props.formObject.phone,
            FunctionText: props.formObject.jobPosition,
            About: props.formObject.recruiterMessage
        }),
        retry: false,
        onSuccess: (res) => handleRequestSuccess({ response: res, hasAlert: true, isSubmit: true }),
        onError: (res) => handleRequestError({ response: res, hasAlert: true, isSubmit: true })
    })
}
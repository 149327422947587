import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button, Form, Input, Select } from 'antd';
import axios from 'axios';
import PrefixIcon from 'components/forms/input/prefix-icon';
import useGetRoleOptions from 'components/requests/data/useGetRoleOptions';
import { handleRequestError, handleRequestSuccess } from 'components/utils/methods/MyMethods';
import { useUserStore } from 'components/utils/stores/userStore';
import { toast } from 'react-toastify';

export default function InviteTeamOverlay(props) {
    // # STORES
    const userStore = useUserStore();

    // # CLIENT STATES
    const [form] = Form.useForm();
    const [done, setDone] = useState(false);
    const [allRoles, setAllRoles] = useState([]);
    const [currentRole, setRole] = useState(null);
    const [currentEmail, setEmail] = useState(null);

    // # SERVER STATES
    const { } = useGetRoleOptions({ setData: setAllRoles });
    const mutateSendInvite = useSendInvite({ email: currentEmail, roleId: !currentRole ? null : currentRole, setDone });

    const sentSvg = <svg width="99" height="101" viewBox="0 0 99 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M97.928 4.28634C98.1553 3.71843 98.2109 3.0963 98.088 2.49707C97.9651 1.89783 97.669 1.34785 97.2365 0.915303C96.8039 0.482758 96.2539 0.18667 95.6547 0.0637468C95.0555 -0.0591763 94.4333 -0.00352875 93.8654 0.223791L2.94558 36.593C2.14488 36.9135 1.44813 37.4487 0.932005 38.1397C0.415881 38.8307 0.100396 39.6506 0.0202736 40.5094C-0.059849 41.3681 0.0984967 42.2323 0.477883 43.0068C0.857269 43.7814 1.44299 44.4362 2.17057 44.8993L33.3897 64.7621L42.9586 79.7998C43.4105 80.4821 44.1121 80.9599 44.9125 81.1305C45.713 81.3011 46.5484 81.1509 47.2393 80.7121C47.9302 80.2734 48.4214 79.5811 48.6073 78.7841C48.7932 77.987 48.659 77.1489 48.2336 76.4497L39.6335 62.937L86.4716 16.099L74.6277 45.7118C74.465 46.0945 74.3803 46.5059 74.3786 46.9217C74.3768 47.3376 74.4581 47.7496 74.6177 48.1336C74.7772 48.5176 75.0118 48.8659 75.3077 49.1582C75.6036 49.4504 75.9548 49.6806 76.3408 49.8353C76.7268 49.99 77.1398 50.0662 77.5556 50.0592C77.9714 50.0523 78.3816 49.9625 78.7622 49.795C79.1429 49.6275 79.4862 49.3857 79.7722 49.0838C80.0582 48.7819 80.2811 48.426 80.4278 48.0369L97.928 4.28634ZM82.0528 11.6802L35.2147 58.5182L8.09564 41.2618L82.0528 11.6802Z" fill="#18A89B" />
        <path d="M98.1519 78.1253C98.1519 83.9269 95.8472 89.491 91.7448 93.5934C87.6424 97.6958 82.0783 100.001 76.2766 100.001C70.475 100.001 64.9109 97.6958 60.8085 93.5934C56.7061 89.491 54.4014 83.9269 54.4014 78.1253C54.4014 72.3236 56.7061 66.7595 60.8085 62.6571C64.9109 58.5547 70.475 56.25 76.2766 56.25C82.0783 56.25 87.6424 58.5547 91.7448 62.6571C95.8472 66.7595 98.1519 72.3236 98.1519 78.1253ZM85.6955 67.6314C85.3434 67.4204 84.9532 67.2808 84.5471 67.2206C84.1411 67.1604 83.7272 67.1809 83.329 67.2807C82.9309 67.3805 82.5563 67.5578 82.2267 67.8024C81.897 68.0471 81.6188 68.3542 81.4079 68.7064L74.0954 80.894L70.6766 77.4753C70.0898 76.8885 69.2939 76.5588 68.464 76.5588C67.6342 76.5588 66.8383 76.8885 66.2515 77.4753C65.6647 78.0621 65.3351 78.8579 65.3351 79.6878C65.3351 80.5176 65.6647 81.3135 66.2515 81.9003L71.0891 86.7316C71.5926 87.2361 72.2045 87.6191 72.8783 87.8516C73.5522 88.084 74.2701 88.1597 74.9776 88.0729C75.6851 87.9862 76.3634 87.7392 76.9611 87.3508C77.5588 86.9624 78.06 86.4428 78.4267 85.8316L86.7705 71.9189C86.9815 71.5668 87.1211 71.1766 87.1813 70.7706C87.2414 70.3645 87.221 69.9506 87.1212 69.5525C87.0214 69.1543 86.8441 68.7797 86.5995 68.4501C86.3548 68.1205 86.0477 67.8423 85.6955 67.6314Z" fill="#18A89B" />
    </svg>;

    useEffect(() => {
        if (props.overlayState) {
            var elem = document.querySelector('body');
            elem.style.overflow = "hidden"
        } else {
            var elem = document.querySelector('body');
            elem.style.overflow = "auto"
        }
    }, [props.overlayState]);

    useEffect(() => {
        if (allRoles.length === 0) { return }
        var initRole = allRoles.filter(item => item.label.toLowerCase() === "user")[0].id;
        form.setFieldsValue({
            role: initRole
        });
        setRole(initRole);
    }, [allRoles])

    function closeOverlay() {
        props.setOverlay(false);
        setDone(false);
    }

    return (
        <div>
            {
                props.overlayState === false ? null :
                    <div className="overlay">
                        <div className="overlay-content small d-flex align-items-center pt-2">
                            <div className="container px-1">
                                <span className='close' onClick={() => closeOverlay()}></span>
                                {
                                    done ?
                                        <div>
                                            <div className="text-center">
                                                {sentSvg}
                                            </div>
                                            <div className="mt-5">
                                                <h2>{t("business_company_team_invited_title")}</h2>
                                                <p className='color-grey fs-regular'>{t("business_company_team_invited_description_1")} (<i><a href={"mailto:" + currentEmail} className="link">{currentEmail}</a></i>)</p>
                                            </div>
                                        </div>
                                        :
                                        <Form
                                            form={form}
                                            layout='vertical'
                                            name="form-company-team-invite"
                                            className="my-2 form"
                                            onFinish={() => mutateSendInvite.mutate()}
                                        >
                                            <h2>{t("business_company_team_invite_title")}</h2>
                                            <h4 className='color-grey fs-regular'>{t("business_company_team_invite_subtitle")}</h4>
                                            <div>
                                                <Form.Item
                                                    label={t("business_main_email")}
                                                    name="email"
                                                    rules={[{ required: true, message: false, type: "email" }]}
                                                >
                                                    <Input
                                                        prefix={<PrefixIcon value="icon-mail fs-small" />}
                                                        placeholder={t("business_extra_placeholder_email_business")}
                                                        htmlType="mail"
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label={t("business_company_team_invite_assign_title")}
                                                    name="role"
                                                    rules={[{ required: true, message: false }]}
                                                >
                                                    <Select
                                                        onSelect={(value, option) => setRole(option.id)}
                                                        options={allRoles}
                                                    />
                                                </Form.Item>
                                                <div className="mt-2 d-flex justify-items-center flex-wrap align-items-start">
                                                    <Button
                                                        loading={mutateSendInvite.isLoading}
                                                        className="btn btn-primary mr-2 mb-2"
                                                        htmlType='submit'
                                                    >
                                                        {t("business_main_send")}
                                                    </Button>
                                                    <div onClick={() => closeOverlay()} className="btn-secondary btn">
                                                        {t("business_main_back")}
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                }
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
}

const useSendInvite = (props) => {
    const userStore = useUserStore();
    return useMutation({
        mutationKey: ["mutateSendInvite"],
        mutationFn: () => axios.post("invite", {
            Email: props.email,
            RoleId: props.roleId,
            CompanyId: userStore.companyData.id,
            UserId: userStore.userData.id
        }),
        onSuccess: (res) => handleRequestSuccess({
            response: res, hasAlert: true, callback: () => {
                props.setDone(true);
            },
            customCallback: () => handleCustomStatus(res.data.status)
        }),
        onError: (res) => handleRequestError({
            response: res, hasAlert: true, isSubmit: true,
            customCallback: () => handleCustomStatus(res.response.data.status)
        })
    })
}

function handleCustomStatus(status) {
    switch (parseInt(status)) {
        case 480:
            throw toast.warn(t("business_company_team_invite_invalid_email"));
        default:
            break;
    }
}
import { claimedCheckIcon, unclaimedCheckIcon, verifiedCheckIcon } from "components/utils/icons/iconsDashboard";

function CompanyLogo(props) {
    var premiumIcon = <div className="claimed-status">
        {claimedCheckIcon}
    </div>;

    var unclaimedIcon = <div className="claimed-status">
        {unclaimedCheckIcon}
    </div>

    var verifiedIcon = <div className="claimed-status">
        {verifiedCheckIcon}
    </div>



    const avatarImg = <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 4C13.0609 4 14.0783 4.42143 14.8284 5.17157C15.5786 5.92172 16 6.93913 16 8C16 9.06087 15.5786 10.0783 14.8284 10.8284C14.0783 11.5786 13.0609 12 12 12C10.9391 12 9.92172 11.5786 9.17157 10.8284C8.42143 10.0783 8 9.06087 8 8C8 6.93913 8.42143 5.92172 9.17157 5.17157C9.92172 4.42143 10.9391 4 12 4V4ZM12 14C16.42 14 20 15.79 20 18V20H4V18C4 15.79 7.58 14 12 14Z" fill="black" />
    </svg>;

    return (
        <span style={props.style || {}} className={`${props.avatar ? "company-logo company-avatar" : "company-logo"} ${props.className}`}>
            <div className={props.small === undefined ? "logo" : "logo logo-small"}>
                {
                    props.logoUrl === "avatar" ?
                        <div className="avatar profile-avatar grey"> <div className="no-border pb-2">{avatarImg} </div> </div> :
                        props.logoUrl === undefined || props.logoUrl === null || props.logoUrl === "" ?
                            <img width={100} height={100} id="logo" className={props.secondary === undefined ? "default" : "secondary default"} src={'/assets/img/w-logo.png'} alt="default-logo" /> :
                            <img width={100} height={100} id="logo" className={props.secondary === undefined ? null : "secondary"} src={props.logoUrl} alt="company-logo" />
                }
                {
                    props.verified === undefined ? null :
                        props.premium ? premiumIcon :
                            props.verified === true ?
                                verifiedIcon : unclaimedIcon
                }
            </div>
        </span>
    );
}

export default CompanyLogo;
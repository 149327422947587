import { useMutation, useQuery } from "@tanstack/react-query";
import { Carousel } from "antd";
import axios from "axios";
import AccordionCommunication from "components/layouts/accordions/accordion-communication";
import FilesAccordion from "components/layouts/accordions/accordion-files";
import { sjabloonAccordionIcon } from "components/utils/icons/icons";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from 'i18next';
import $ from 'jquery';
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import GuestRedirectLayout from "../components/layouts/page-layouts/guest-redirect";
import { handleRequestError, handleRequestSuccess, hasPermission } from "../components/utils/methods/MyMethods";
import envir from '../env.json';

export default function WisePage(props) {
    // # STORES
    const userStore = useUserStore();

    // # UILS
    const navigate = useNavigate();
    const newsPostMaxChar = 250;
    var line = <svg width="11" height="2" viewBox="0 0 11 2" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="10" y1="1" x2="1" y2="1" stroke="#18A89B" strokeWidth="2" strokeLinecap="round" />
    </svg>;


    // PERMISSIONS
    const [PM_NewsView, setPM_NewsView] = useState(false);
    const [PM_FeedbackView, setPM_FeedbackView] = useState(false);

    // # SERVER STATES

    useEffect(() => {
        if (!userStore.userData) { return }
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_NEWSFEED", setPM_NewsView);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_FEEDBACK", setPM_FeedbackView);
    }, [userStore.userData]);

    // START AT TOP OF PAGE ON LOAD
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const profileSvg = <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="17.5" cy="26.5" r="17.5" fill="#1B6A63" />
        <g filter="url(#filter0_d_1403_9865)">
            <circle cx="24" cy="20" r="20" fill="#18A89B" />
        </g>
        <path d="M19.1538 21.1568L28.8462 21.1579C29.3895 21.1577 29.9129 21.3683 30.3114 21.7475C30.7098 22.1268 30.9539 22.6465 30.9946 23.2026L31 23.3684V25.0263C30.9989 28.8947 26.9982 30 24 30C21.0686 30 17.1777 28.9434 17.0054 25.2805L17 25.0263V23.3673C17 22.2024 17.8788 21.2474 18.9923 21.1623L19.1538 21.1579V21.1568ZM19.1538 22.8147L19.0462 22.8257C18.9428 22.8478 18.8478 22.9 18.7726 22.9761C18.6985 23.0528 18.6476 23.1499 18.6262 23.2557L18.6154 23.3673V25.0263C18.6154 26.1415 19.1 26.9296 20.1414 27.5043C21.0309 27.9962 22.2985 28.2935 23.6586 28.3366L24 28.3421L24.3414 28.3366C25.7015 28.2935 26.968 27.9962 27.8586 27.5043C28.8343 26.9649 29.3222 26.2388 29.3792 25.2308L29.3846 25.0252V23.3684C29.3844 23.2391 29.3402 23.114 29.2596 23.0147C29.1789 22.9153 29.067 22.8481 28.9431 22.8246L28.8462 22.8158L19.1538 22.8147ZM24 9C25.2853 9 26.5179 9.52401 27.4267 10.4568C28.3356 11.3895 28.8462 12.6546 28.8462 13.9737C28.8462 15.2928 28.3356 16.5579 27.4267 17.4906C26.5179 18.4234 25.2853 18.9474 24 18.9474C22.7147 18.9474 21.4821 18.4234 20.5733 17.4906C19.6644 16.5579 19.1538 15.2928 19.1538 13.9737C19.1538 12.6546 19.6644 11.3895 20.5733 10.4568C21.4821 9.52401 22.7147 9 24 9ZM24 10.6579C22.2188 10.6579 20.7692 12.1456 20.7692 13.9737C20.7692 15.8018 22.2188 17.2895 24 17.2895C25.7812 17.2895 27.2308 15.8018 27.2308 13.9737C27.2308 12.1456 25.7812 10.6579 24 10.6579Z" fill="white" />
        <defs>
            <filter id="filter0_d_1403_9865" x="0" y="0" width="48" height="48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1403_9865" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1403_9865" result="shape" />
            </filter>
        </defs>
    </svg>;

    const brandingSvg = <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="17.5" cy="26.5" r="17.5" fill="#1B6A63" />
        <g filter="url(#filter0_d_1403_9870)">
            <circle cx="24" cy="20" r="20" fill="#18A89B" />
        </g>
        <path d="M22.6923 8C19.6923 8 17.3077 10.4111 17.3077 13.4444C17.3024 14.3365 17.5153 15.216 17.9273 16.0047C18.3393 16.7935 18.9377 17.4671 19.6692 17.9657C16.9308 19.158 15 21.916 15 25.1111H16.5385C16.5385 21.6889 19.3077 18.8889 22.6923 18.8889C25.6923 18.8889 28.0769 16.4778 28.0769 13.4444C28.0769 10.4111 25.6923 8 22.6923 8ZM22.6923 9.55556C24.8462 9.55556 26.5385 11.2667 26.5385 13.4444C26.5385 15.6222 24.8462 17.3333 22.6923 17.3333C20.5385 17.3333 18.8462 15.6222 18.8462 13.4444C18.8462 11.2667 20.5385 9.55556 22.6923 9.55556ZM26.5385 18.8889C25.6923 18.8889 25 19.5889 25 20.4444V23.8667L29.6154 28.5333C29.9231 28.8444 30.3077 29 30.6923 29C31.0769 29 31.4615 28.8444 31.7692 28.5333L34.5385 25.7333C34.8462 25.4222 35 25.0333 35 24.6444C35 24.2556 34.8462 23.8667 34.5385 23.5556L29.9231 18.8889H26.5385ZM26.5385 20.4444H29.3077L33.4615 24.6444L30.6923 27.4444L26.5385 23.2444V20.4444ZM28.0769 21.2222C27.8729 21.2222 27.6773 21.3042 27.533 21.45C27.3887 21.5959 27.3077 21.7937 27.3077 22C27.3077 22.2063 27.3887 22.4041 27.533 22.55C27.6773 22.6958 27.8729 22.7778 28.0769 22.7778C28.2809 22.7778 28.4766 22.6958 28.6209 22.55C28.7651 22.4041 28.8462 22.2063 28.8462 22C28.8462 21.7937 28.7651 21.5959 28.6209 21.45C28.4766 21.3042 28.2809 21.2222 28.0769 21.2222Z" fill="white" />
        <defs>
            <filter id="filter0_d_1403_9870" x="0" y="0" width="48" height="48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1403_9870" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1403_9870" result="shape" />
            </filter>
        </defs>
    </svg>;

    const wiseSvg = <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="17.5" cy="26.5" r="17.5" fill="#1B6A63" />
        <g filter="url(#filter0_d_1403_9898)">
            <circle cx="24" cy="20" r="20" fill="#18A89B" />
        </g>
        <path d="M28.6892 21.6005L30.9022 13.0532C31.2008 11.8776 31.5647 11.0061 32.9847 11.0061C34.1754 11.0061 35 11.677 35 12.9864C34.9985 13.4395 34.932 13.8904 34.8022 14.3261L31.7288 24.3833C31.3648 25.5569 31.0052 27 28.6892 27C26.4424 27 26.0154 25.7251 25.6492 24.4523L23.9664 18.4163L22.2477 24.4523C21.8838 25.6947 21.1244 27 19.3405 27C17.3568 27 16.6667 25.9865 16.185 24.3854L13.1642 14.3261C13.0521 13.9555 12.9968 13.571 13.0001 13.1849C13.0001 11.6405 14.1866 11.0061 15.2783 11.0061C16.6332 11.0061 17.0601 12.1452 17.3589 13.2842L19.5403 21.5944L21.7555 13.1464C22.0185 12.1755 22.416 11 24.1347 11C25.8534 11 26.2384 12.3073 26.4487 13.1464L28.6892 21.6005Z" fill="white" />
        <defs>
            <filter id="filter0_d_1403_9898" x="0" y="0" width="48" height="48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1403_9898" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1403_9898" result="shape" />
            </filter>
        </defs>
    </svg>;

    function getPlan() {
        if (!userStore.companyData) { return }
        return userStore.companyData.subscriptionData.name;
    }

    return (
        <GuestRedirectLayout>
            <div id="wise">
                <div className="container-page pb-0">
                    <div className="container">
                        <div className="page-content" style={{ minHeight: "unset" }}>
                            <div className="d-flex justify-content-between">
                                <div className="w-100">
                                    <div className="d-flex justify-content-between">
                                        <h1 className='title'>👋 {t("business_main_greet")}{!userStore.userData ? null : userStore.userData.firstName === null ? null : ", " + userStore.userData.firstName}</h1>
                                    </div>
                                    {
                                        !PM_NewsView & !PM_FeedbackView ? null :
                                            <div className="subtitle">{userStore.companyData.subscriptionData.int1 === 10 && userStore.companyData.subscriptionData.guid1 === "0e63167e-848d-42aa-9365-864318aa89d0" ? t("business_hero_subtitle_recruiter") : t("business_hero_subtitle")}</div>
                                    }
                                </div>
                            </div>
                            {
                                // !PM_FeedbackView && !PM_NewsView ?
                                !userStore.companyData ? null :
                                    userStore.companyData.subscriptionData.int1 === 10 && userStore.companyData.subscriptionData.guid1 === "0e63167e-848d-42aa-9365-864318aa89d0" ?
                                        <section className="mt-4">
                                            {/* CTA RECRUITER */}
                                            <div className="accordion-custom mb-2 hover" onClick={() => navigate("/recruiter-branding/info")}>
                                                <div className="accordion-head" >
                                                    <div className="d-flex flex-row align-items-center flex-wrap">
                                                        <div className="fs-xregular fw-semi-bold">
                                                            <span className="pr-3">
                                                                {profileSvg}
                                                            </span>
                                                            {t("business_hero_quick_profile_title")}
                                                        </div>
                                                        <div className="mx-3">
                                                            <svg width="2" height="14" viewBox="0 0 2 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <line x1="1" y1="-4.37114e-08" x2="1" y2="14" stroke="#808080" stroke-opacity="0.3" stroke-width="2" />
                                                            </svg>
                                                        </div>
                                                        <div className="font-special">
                                                            {t("business_hero_quick_profile_subtitle")}
                                                        </div>

                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <span className={"icon-arrow2-right fs-xsmall icon"} />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* CTA RECRUITER */}
                                            <div className="accordion-custom mb-2 hover" onClick={() => navigate("/recruiter-branding/overview")}>
                                                <div className="accordion-head" >
                                                    <div className="d-flex flex-row align-items-center flex-wrap">
                                                        <div className="fs-xregular fw-semi-bold">
                                                            <span className="pr-3">
                                                                {brandingSvg}
                                                            </span>
                                                            {t("business_hero_quick_recommendations_title")}
                                                        </div>
                                                        <div className="mx-3">
                                                            <svg width="2" height="14" viewBox="0 0 2 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <line x1="1" y1="-4.37114e-08" x2="1" y2="14" stroke="#808080" stroke-opacity="0.3" stroke-width="2" />
                                                            </svg>
                                                        </div>
                                                        <div className="font-special">
                                                            {t("business_hero_quick_recommendations_subtitle")}
                                                        </div>

                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <span className={"icon-arrow2-right fs-xsmall icon"} />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* CTA RECRUITER */}
                                            <a href={envir.Variables.WiseHomeUrl + "/recruiter/" + userStore.companyData.shortCode} className="hover" target="_blank" rel="noreferrer">
                                                <div className="accordion-custom mb-2">
                                                    <div className="accordion-head" >
                                                        <div className="d-flex flex-row align-items-center flex-wrap">
                                                            <div className="fs-xregular fw-semi-bold">
                                                                <span className="pr-3">
                                                                    {wiseSvg}
                                                                </span>
                                                                {t("business_hero_quick_wise_title")}
                                                            </div>
                                                            <div className="mx-3">
                                                                <svg width="2" height="14" viewBox="0 0 2 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <line x1="1" y1="-4.37114e-08" x2="1" y2="14" stroke="#808080" stroke-opacity="0.3" stroke-width="2" />
                                                                </svg>
                                                            </div>
                                                            <div className="font-special">
                                                                {t("business_hero_quick_wise_subtitle")}
                                                            </div>

                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <span className={"icon-arrow2-right fs-xsmall icon"} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </section>
                                        :
                                        <section className="mt-4">
                                            {
                                                !hasPermission(userStore.userData.permissionsData, "PM_VIEW_SJABLOON") ? null :
                                                    <AccordionCommunication
                                                        icon={sjabloonAccordionIcon}
                                                        title={<>{t("business_accordion_sjabloon_title")}</>}
                                                    >
                                                        <div className="sjabloon-how-it-works content-wrapper mb-4 mx-4">
                                                            <p>{t("business_accordion_sjabloon_description")}</p>
                                                            <div className="d-flex sjabloon-main-content flex-row justify-content-center align-items-center mx-10 my-5">
                                                                <div className="relative mx-1" style={{ maxWidth: 450, width: "100%", objectFit: "contain" }}>
                                                                    <Carousel dots={false} autoplay={true} autoplaySpeed={7000}>
                                                                        <div>
                                                                            <img style={{ width: "90%" }} alt="example" src="assets/img/sjabloons/1.png" />
                                                                        </div>
                                                                        <div>
                                                                            <img style={{ width: "90%" }} alt="example" src="assets/img/sjabloons/2.png" />
                                                                        </div>
                                                                        <div>
                                                                            <img style={{ width: "90%" }} alt="example" src="assets/img/sjabloons/3.png" />
                                                                        </div>
                                                                    </Carousel>
                                                                </div>


                                                                <div className='howitworks-info mb-10' style={{ maxWidth: 700 }}>
                                                                    <div>
                                                                        <h3 className="font-special text-uppercase mb-2 fs-regular fw-regular">{line} {t("business_accordion_sjabloon_how_it_works")}</h3>
                                                                        <h2 className="title mb-4 fs-xlarge">{t("business_accordion_sjabloon_how_it_works_title")}</h2>
                                                                    </div>
                                                                    <div className="features">
                                                                        <p>{t("business_accordion_sjabloon_how_it_works_description1")}</p>
                                                                        <p>{t("business_accordion_sjabloon_how_it_works_description2")}</p>
                                                                    </div>
                                                                    <div className="flex align-items-center">
                                                                        <Link to="/create-visual" className="mr-2 mb-2">
                                                                            <div className="btn btn-primary d-inline-block">
                                                                                {t("business_accordion_sjabloon_how_it_works_cta")}
                                                                            </div>
                                                                        </Link>
                                                                        {
                                                                            !hasPermission(userStore.userData.permissionsData, "PM_BUY_SJABLOON") ? null :
                                                                                <a href={`mailto:${envir.Variables.WiseEmail}?subject=${t("business_sjabloon_mail_subject")}&body=${t("business_sjabloon_mail_body")}`} className="mr-2 mb-2">
                                                                                    <div className="btn btn-secondary d-inline-block">
                                                                                        {t("business_accordion_sjabloon_how_it_works_cta_tokens")}
                                                                                    </div>
                                                                                </a>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </AccordionCommunication>
                                            }
                                            {/* {
                                                !hasPermission(userStore.userData.permissionsData, "PM_VIEW_SCORE_REACTION") ? null :
                                                    <ScoreReactionAccordion />
                                            } */}
                                            <FilesAccordion />
                                        </section>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </GuestRedirectLayout>
    );
}

const useEditNewsMessage = (props) => {
    const userStore = useUserStore();
    var textId = null;
    return useMutation({
        mutationKey: ["mutateEditNewsMessage"],
        mutationFn: (propsFn) => {
            textId = propsFn.textId;
            return axios.post("news/edit", {
                Text: props.newsMessageText,
                TextId: textId,
                UserId: userStore.userData.id
            })
        },
        onSuccess: (res) => handleRequestSuccess({
            response: res, isSubmit: true, hasAlert: true, callback: () => {
                props.setNewsMessageId(textId);
                props.getNewsMessages();
                props.setOverlayEdit(false);
            }
        }),
        onError: (res) => handleRequestError({ response: res, isSubmit: true, hasAlert: true })
    })
}
const useCreateNewsMessage = (props) => {
    const userStore = useUserStore();
    return useMutation({
        mutationKey: ["mutateCreateNewsMessage"],
        mutationFn: (propsFn) => axios.post("news/create", {
            UserId: userStore.userData.id,
            Text: props.newMessageText
        }),
        onSuccess: (res) => handleRequestSuccess({
            response: res, isSubmit: true, hasAlert: true, callback: () => {
                $("#messages-all .messages-new textarea").val("");
                $('#messages-all .messages-flow').scrollTop(0, 0)
                props.getNewsMessages();
            }
        }),
        onError: (res) => handleRequestError({ response: res, isSubmit: true, hasAlert: true })
    })
}

const useGetNewsMessages = (props) => {
    const userStore = useUserStore();
    return useQuery({
        queryKey: ["getNewsMessages"],
        queryFn: () => axios.post("news/latest2/50", {
            UserId: userStore.userData.id,
            CompanyId: userStore.companyData.id
        }),
        retry: 2,
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {
                props.setData(res.data.instance);
            }
        }),
        onError: (res) => handleRequestError({ response: res }),
    })
}

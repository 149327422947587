import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";

const useGetSuggestions = (props) => {
    var type = null;
    return useMutation({
        mutationKey: ["mutateSuggestions"],
        mutationFn: (propsFn) => {
            if (propsFn.type) {
                type = propsFn.type
            }
            return axios.get(`autosearch/${propsFn.type}/${propsFn.input}`)
        },
        retry: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, customCallback: () => {
                switch (res.data.status) {
                    case 290:
                        props.setData([])
                        break;
                    default:
                        break;
                }
            },
            callback: () => {
                var newData = [];
                var data = res.data.instance;
                if (!props.type && !type) {
                    throw Error("No type found")
                }
                if (props.type && !type) {
                    type = props.type;
                }

                switch (type) {
                    case "UserFunction":
                        Array.from(data.references).forEach(ele => {
                            newData = [...newData, { "value": !ele.id ? ele.value : ele.id, "label": ele.value, "data": ele }];
                        })
                        break;
                    case "CompanyName":
                        Array.from(data.references).forEach((ele, i) => {
                            // ant design autocomplete issue! - property value needs to be unique (unique key does not work - https://github.com/ant-design/ant-design/issues/11909)
                            // so i gave a new property 'actualValue', in here is the value that will be used in the states (visual state: value of the autosuggest input so that it shows correct text and not the property value one)
                            newData = [...newData, {
                                id: ele.id,
                                value: `${ele.value} (${ele.id})`,
                                actualValue: ele.value,
                                label: ele.value,
                                extra: ele.extra1,
                                data: ele
                            }];
                        })
                        break;
                    case "CompanyVat":
                        Array.from(data.references).forEach(ele => {
                            newData = [...newData, { "key": ele.id, "value": ele.value }];
                        })
                        break;
                    default: break;
                }
                props.setData(newData);
            }
        }),
        onError: (res) => handleRequestError({ response: res }),
    })
}

export default useGetSuggestions;
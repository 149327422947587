import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import AccordionProcedure from "components/layouts/accordions/accordionProcedure";
import NoData from "components/layouts/no-data/nodata";
import GuestRedirectLayout from "components/layouts/page-layouts/guest-redirect";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../components/animations/loading";
import { handleRequestError, handleRequestSuccess, hasPermission } from "../../components/utils/methods/MyMethods";
import LayoutCompany from "./layout-company";
import { Fragment } from 'react';

export default function CompanyProcedurePage(props) {
    // # STORES
    const userStore = useUserStore();

    // # CLIENT STATES
    const [procedureData, setProcedureData] = useState(false);

    // # SERVER STATES
    const { refetch: procedureRefetch, isLoading: procedureLoading, isRefetching: procedureRefetching, isError: procedureError } = useQuery({
        queryKey: ["companyProcedures"],
        queryFn: () => axios.get(`v3/business/select/businesses/${userStore.companyData.id}/applicationflows/options/${userStore.clientData.language.id}`),
        refetchOnWindowFocus: false,
        enabled: false,
        onSuccess: (res) => handleRequestSuccess({ response: res, hasAlert: true, callback: () => setProcedureData(res.data.instance.sort((a, b) => a.flowSortIndex > b.flowSortIndex ? -1 : 1)) }),
        onError: (res) => handleRequestError({ response: res, hasAlert: true })
    })

    // # UTILS
    const navigate = useNavigate();

    // # CLIENT STATES
    const [PM_ProcedureView, setPM_ProcedureView] = useState(false);
    const [PM_ProcedureEdit, setProcedureEdit] = useState(false);
    const [PM_PlanView, setPM_PlanView] = useState(false);
    const [PM_PromiseView, setPM_PromiseView] = useState(false);
    const [PM_RecruitmentHistoryView, setPM_RecruitmentHistoryView] = useState(false);

    useEffect(() => {
        if (userStore.companyData.id && userStore.clientData.language.id) {
            procedureRefetch()
        }
    }, [userStore.companyData, userStore.clientData])

    useEffect(() => {
        if (!userStore.userData) { return }

        // PERMISSION_COMPANY_HANDLER
        hasPermission(userStore.userData.permissionsData, "PM_EDIT_PROCEDURES", setProcedureEdit);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_SUBSCRIPTIONS", setPM_PlanView);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_VALUES", setPM_PromiseView);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_PROCEDURES", setPM_ProcedureView, navigate);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_RECRUITHISTORY", setPM_RecruitmentHistoryView);
    }, [userStore.userData]);


    // START AT TOP OF PAGE ON LOAD
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <GuestRedirectLayout>
            <LayoutCompany
                title={t("business_company_process_title")}
                subtitle={t("business_company_process_subtitle")}
            >
                <div className="mt-3">
                    {
                        !PM_ProcedureView ? <NoData permission /> :
                            !PM_ProcedureEdit ? <NoData permission /> :
                                procedureError ? <NoData text={t("business_extra_general_error2")} /> :
                                    procedureLoading || procedureRefetching ? <Loading text={null} big center /> :
                                        !procedureData ? <NoData text={t("business_extra_general_error2")} /> :
                                            (procedureData).sort((a, b) => a.flowSortIndex > b.flowSortIndex ? -1 : 1).map((data, key) => {
                                                return <Fragment key={key}>
                                                    <AccordionProcedure
                                                        data={data}
                                                        procedureRefetch={procedureRefetch}
                                                        companyId={userStore.companyData.id} />
                                                </Fragment>
                                            })
                    }
                </div>
            </LayoutCompany>
        </GuestRedirectLayout>
    );
}
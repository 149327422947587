import { Empty, Select, Table, Tag } from "antd";
import { DemoPanelDedicatedApplicants } from "components/layouts/dashboard/basic/db-premium";
import DashboardLayout from "components/layouts/dashboard/dashboard-layout";
import CandidateBrandExperiencePanel, { DemoCandidateBrandExperiencePanel } from "components/layouts/dashboard/panels/candidate-brand-experience-panel";
import MessagesPanel, { DemoMessagesPanel } from "components/layouts/dashboard/panels/messages-panel";
import DashboardRecruitment from "components/layouts/dashboard/recruitment/db-recruitment";
import GuestRedirectLayout from "components/layouts/page-layouts/guest-redirect";
import Panel from "components/modules/panel/panel";
import { useGetDbGeneralData } from "components/requests/dashboard/getDbGeneralData";
import useGetDbMessages from "components/requests/dashboard/getDbMessages";
import { handleAllPermissions } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function BusinessSalesDashboardPage(props) {
    const userStore = useUserStore();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const [isPremiumPlan, setIsPremiumPlan] = useState(null);
    const [PM_DashboardView, setPM_DashboardView] = useState(false);
    const { data: dbGeneralData, isLoading: dbGeneralDataIsLoading, isRefetching: dbGeneralDataIsRefetching, isError: dbGeneralDataIsError, refetch } = useGetDbGeneralData({ enabled: true }); // company data (number of ratings, rating, companyname, planname)
    const { data: dbMessages, isLoading: dbMessagesIsLoading, isError: dbMessagesIsError, isRefetching: dbMessagesIsRefetching, refetch: dbMessagesRefetch } = useGetDbMessages({ filterPhaseId: null, filterUserId: null, enabled: true });

    useEffect(() => {
        if (!userStore.userData) { return }
        handleAllPermissions(userStore.userData.permissionsData, [
            ["PM_VIEW_DASHBOARD", setPM_DashboardView],
        ])
    }, [userStore.userData]);

    useEffect(() => {
        if (!userStore.flagData || !userStore.flagData.toggleDashboardAdvanced) { return setIsPremiumPlan(false) }
        setIsPremiumPlan(userStore.flagData.toggleDashboardAdvanced)
    }, [userStore.flagData])

    const colums = [
        {
            "title": "Category",
            "dataIndex": "category",
            "key": "category",
            "width": "30%"
        },
        {
            "title": "Measure",
            "dataIndex": "measure",
            "key": "measure",
            "width": "40%"
        },
        {
            "title": "Range",
            "dataIndex": "range",
            "key": "range",
            "width": "15%"
        },
        {
            "title": "Score",
            "dataIndex": "score",
            "key": "score",
            "width": "15%"
        }
    ];

    const data = [
        {
            "key": 1,
            "category": "Service",
            "measure": "NPS",
            "range": "Net voldoende",
            "score": 50,
            "categorySortIndex": 10
        },
        {
            "key": 2,
            "category": "",
            "measure": "Help",
            "range": "Zit goed",
            "score": 78,
            "categorySortIndex": 10
        },
        {
            "key": 3,
            "category": "",
            "measure": "Job match",
            "range": "Zit goed",
            "score": 62,
            "categorySortIndex": 10
        },
        {
            "key": 4,
            "category": "Candidate experience",
            "measure": "Feedback",
            "range": "Uitstekend",
            "score": 81,
            "categorySortIndex": 20
        },
        {
            "key": 5,
            "category": "",
            "measure": "Snelheid",
            "range": "Uitstekend",
            "score": 82,
            "categorySortIndex": 20
        },
        {
            "key": 6,
            "category": "",
            "measure": "Sfeer",
            "range": "Net voldoende",
            "score": 50,
            "categorySortIndex": 20
        },
        {
            "key": 7,
            "category": "",
            "measure": "Informatie",
            "range": "Net voldoende",
            "score": 50,
            "categorySortIndex": 20
        },
    ];

    return (
        <GuestRedirectLayout>

            <DashboardLayout data={dbGeneralData} type={"Business & Sales"}>
                <>

                    <div className="row">
                        <div className="flex flex-row align-items-start justify-content-end mb-4">
                            <div className="flex flex-row align-items-center flex-nowrap" style={{ gap: 5 }}>
                                <Select
                                    defaultValue={"all"}
                                    style={{ minWidth: 200 }}
                                    options={[
                                        {
                                            label: "All categories",
                                            value: "all",
                                        },
                                        {
                                            label: "Sales",
                                            value: "sales",
                                        },
                                        {
                                            label: "Operations",
                                            value: "operations",
                                        },
                                        {
                                            label: "IT",
                                            value: "it",
                                        },
                                        {
                                            label: "Finance",
                                            value: "finance",
                                        },

                                    ]}>
                                </Select>
                                <Select
                                    defaultValue={"all"}
                                    style={{ minWidth: 200 }}
                                    options={[
                                        {
                                            label: "All recruiters",
                                            value: "all",
                                        },
                                        {
                                            label: "Jef Doe",
                                            value: "sales",
                                        },
                                        {
                                            label: "Vince de Groot",
                                            value: "operations",
                                        },
                                        {
                                            label: "Hilde van der Hoorn",
                                            value: "it",
                                        },
                                        {
                                            label: "Sander de Boer",
                                            value: "finance",
                                        },

                                    ]}>
                                </Select>
                            </div>
                        </div>
                    </div>
                    <Panel bodyStyle={{
                        paddingBottom: "15px"
                    }}>
                        <Table
                            showHeader={false}
                            locale={{
                                emptyText: <>
                                    <Empty description={t("business_extra_no_data")} />
                                </>
                            }}
                            pagination={false}
                            columns={colums}
                            dataSource={data.filter(fItem => fItem.categorySortIndex === 10).map((item) => {


                                const handleTag = (myItem) => {
                                    const item = myItem.toLowerCase();
                                    let color = null;

                                    if (item.includes("voldoende")) {
                                        color = "orange"
                                    } else if (item.includes("voldoende")) {
                                        color = "yellow"
                                    } else if (item.includes("zit goed")) {
                                        color = "lime"
                                    } else if (item.includes("uitstekend")) {
                                        color = "green"
                                    }
                                    else {
                                        color = "red"
                                    }

                                    return <Tag color={color} className="font-regular"  >{t(myItem)}</Tag>
                                }

                                return {
                                    ...item,
                                    range: handleTag(item.range)
                                }
                            })} />
                    </Panel>
                    <Panel bodyStyle={{
                        paddingBottom: "15px"
                    }}>
                        <Table
                            showHeader={false}
                            locale={{
                                emptyText: <>
                                    <Empty description={t("business_extra_no_data")} />
                                </>
                            }}
                            pagination={false}
                            columns={colums}
                            dataSource={data.filter(fItem => fItem.categorySortIndex === 20).map((item) => {


                                const handleTag = (myItem) => {
                                    const item = myItem.toLowerCase();
                                    let color = null;
                                    if (item.includes("voldoende")) {
                                        color = "orange"
                                    } else if (item.includes("voldoende")) {
                                        color = "yellow"
                                    } else if (item.includes("zit goed")) {
                                        color = "lime"
                                    } else if (item.includes("uitstekend")) {
                                        color = "green"
                                    }
                                    else {
                                        color = "red"
                                    }
                                    return <Tag color={color} className="font-regular"  >{t(myItem)}</Tag>
                                }

                                return {
                                    ...item,
                                    range: handleTag(item.range)
                                }
                            })} />
                    </Panel>
                </>
            </DashboardLayout>

        </GuestRedirectLayout>
    );
}
import Loading from "components/animations/loading";
import LineChart from "components/layouts/charts/linechart";
import NoData from "components/layouts/no-data/nodata";
import { t } from "i18next";
import moment from "moment";

export default function AttractivenessDetailsPanelContent(props) {
    return <>
        {
            props.isLoading ? <Loading type="circle" center /> :
                props.isRefetching ? <Loading type="circle" center /> :
                    props.isError ? <NoData error /> :
                        !props.data || !props.data[0].data ? <NoData className="my-5" /> :
                            <LineChart
                                xLabel={t("business_dashboard_date")}
                                yLabel={t("business_dashboard_score")}
                                maxYscale={"100"}
                                minYscale={"0"}
                                data={() => {
                                    var data = props.data;
                                    var newObjects = [];

                                    data.forEach(elementType => {
                                        var newData = [];
                                        if (!elementType.data) { return }
                                        [...elementType.data].sort((a, b) => { return a.sortIndex - b.sortIndex }).forEach((element) => {
                                            // var date = getDateInFormat(element.adjustedDate, "MMM YY");
                                            const month = moment().month(element.monthIndex).format('MMM');
                                            const year = moment().year(element.yearIndex).format('YY');
                                            const date = `${month} ${year}`;
                                            newData.push({ x: date, y: element.score })
                                        });
                                        newObjects.push({ id: elementType.id, data: newData })
                                    });
                                    return newObjects;
                                }} />
        }
    </>
}
import { useUserStore } from 'components/utils/stores/userStore';
import { t } from 'i18next';
import produce from 'immer';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingRedirect from '../../components/animations/loading-redirect';
import * as MyMethods from '../../components/utils/methods/MyMethods';
import RegisterStep1 from './register-1';
import RegisterStep2 from './register-2';
import RegisterStep3 from './register-3';
import RegisterStep3dot1 from './register-3.1';
import RegisterStep3dot2 from './register-3.2';
import RegisterStep4 from './register-4';
import RegisterStep5 from './register-5';
import RegisterStep6 from './register-6';
import { usePlansStore } from 'components/utils/stores/plansStore';

export default function RegisterPage(props) {

    // # STORES
    const userStore = useUserStore();
    const plansStore = usePlansStore();

    // # CLIENT STATES
    const [formObject, setFormObject] = useState({
        companyId: null,
        userId: null,
        registerType: null,
        registerAcceptedTerms: false,
        registerEmail: "", // mail used to register account
        password: "",
        password2: "",
        firstName: "",
        lastName: "",
        address1: "",
        address2: "",
        zipCode: "",
        city: "",
        country: "",
        phone: "",
        role: "",
        companyEmail: "",
        companyName: "",
        companyVat: "",
        referrence: "",

    });
    const [currentStep, setStep] = useState(1);
    const [sideStepData, setSideStepData] = useState(null); // IF EMAIL NOT CORRECT DOMAIN
    const [existingData, setExistingData] = useState(null); // KEEP ADMIN AND SKIP DATA

    // # UTILS
    const navigate = useNavigate();
    const editFormItem = (key, val) => setFormObject(produce(draft => {
        draft[key] = val;
    }))

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentStep])

    return (
        <div>
            {
                MyMethods.setPageSeo(t("business_SEO_register_title"), t("business_SEO_register_description"), t("business_SEO_register_keywords"))
            }
            {
                userStore.clientData.loggedIn ?
                    <div className="page-content">
                        <div className="container">
                            <div className="text-center d-flex mt-5 justify-content-center">
                                <LoadingRedirect
                                    to="/"
                                    navigate={navigate}
                                    delay={500}
                                    text={null}
                                    big />
                            </div>
                        </div>
                    </div>
                    :
                    <div id="hero-form">
                        <div className="container-page">
                            <div className="container">
                                <div className="page-content">
                                    {
                                        currentStep === 1 ?
                                            <RegisterStep1
                                                editFormItem={editFormItem}
                                                formObject={formObject}
                                                setStep={setStep}
                                                currentStep={currentStep}
                                            />
                                            :
                                            currentStep === 2 ?
                                                <RegisterStep2
                                                    editFormItem={editFormItem}
                                                    formObject={formObject}
                                                    setStep={setStep}
                                                    currentStep={currentStep}
                                                /> :
                                                currentStep === 3 ?
                                                    <RegisterStep3
                                                        setStep={setStep}
                                                        currentStep={currentStep}
                                                        editFormItem={editFormItem}
                                                        formObject={formObject}
                                                        setSideStepData={setSideStepData}
                                                        setExistingData={setExistingData}
                                                    /> :
                                                    currentStep === 3.1 ?
                                                        <RegisterStep3dot1
                                                            setStep={setStep}
                                                            currentStep={currentStep}
                                                            editFormItem={editFormItem}
                                                            formObject={formObject}
                                                            existingData={existingData}
                                                            sideStepData={sideStepData}
                                                        /> :
                                                        currentStep === 3.2 ?
                                                            <RegisterStep3dot2
                                                                setStep={setStep}
                                                                currentStep={currentStep}
                                                                sideStepData={sideStepData}
                                                                formObject={formObject}
                                                                setSideStepData={setSideStepData}
                                                            /> :
                                                            currentStep === 4 ?
                                                                <RegisterStep4
                                                                    setStep={setStep}
                                                                    currentStep={currentStep}
                                                                    editFormItem={editFormItem}
                                                                    formObject={formObject}
                                                                /> :
                                                                currentStep === 5 ?
                                                                    <RegisterStep5
                                                                        setStep={setStep}
                                                                        currentStep={currentStep}
                                                                        editFormItem={editFormItem}
                                                                        formObject={formObject}
                                                                    /> :
                                                                    currentStep === 6 ?
                                                                        <RegisterStep6
                                                                            setStep={setStep}
                                                                            currentStep={currentStep}
                                                                            editFormItem={editFormItem}
                                                                            formObject={formObject}
                                                                            existingData={existingData}
                                                                        /> :
                                                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
}
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { handleTranslationLabel } from "components/utils/methods/AppMethods";
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";
import { useEffect } from "react/cjs/react.production.min";

const useGetSectorsOptions = (props) => {
    const userStore = useUserStore();

    return useQuery({
        queryKey: ["getSectorsOptions"],
        queryFn: () => axios.get("v2/generic/catalogues/SectorsSelectable"),
        retry: 2,
        refetchOnWindowFocus: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {
                let allIndustries = [];
                var selectedIndustry = [];
                var data = res.data.instance;
                (data).forEach(industry => {
                    if (industry.nameTranslations === null) { return }
                    if (industry.nameTranslations === undefined) { return }
                    Array.prototype.forEach.call(industry.nameTranslations, function (industryTranslation, i) {
                        if (userStore.clientData.language.id === industryTranslation.languageId) {
                            return selectedIndustry = {
                                id: industry.id,
                                label: industryTranslation.text,
                                value: industry.id,
                                translations: industry.nameTranslations,
                                data: industry
                            };
                        }
                    });
                    if (selectedIndustry.length !== 0) {
                        allIndustries = [...allIndustries, (selectedIndustry)];
                        selectedIndustry = [];
                    }
                });
                props.setData(allIndustries.sort((a, b) => a.label.localeCompare(b.label)));
            }
        }),
        onError: (res) => handleRequestError({ response: res }),
    })
}

export default useGetSectorsOptions;
import { useMutation } from '@tanstack/react-query';
import { Button, Form, Input } from 'antd';
import axios from 'axios';
import chalk from "chalk";
import { handleUserLoginData } from 'components/utils/methods/AppMethods';
import { handleRequestError, handleRequestSuccess } from 'components/utils/methods/MyMethods';
import { useUserStore } from 'components/utils/stores/userStore';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LabelWise from '../assets/images/wise-label.png';
import LoadingRedirect from '../components/animations/loading-redirect';
import AlertMessage from '../components/forms/alerts/alert-message';
import envir from '../env.json';

function LoginPage(props) {
    // # STORES
    const userStore = useUserStore();

    // # UTILS
    const navigate = useNavigate();

    // # CLIENT STATES
    const [message, setMessage] = useState(null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);

    // # SERVER STATES
    const mutateLogin = useAccountLogin({
        email, password, rememberMe, setMessage: setMessage, navigate
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            {
                userStore.clientData.loggedIn ?
                    <div className="page-content">
                        <div className="container">
                            <div className="text-center d-flex mt-5 justify-content-center">
                                <LoadingRedirect
                                    to="/wise"
                                    navigate={navigate}
                                    delay={500}
                                    text={null}
                                    big />
                            </div>
                        </div>
                    </div> :
                    <div id="hero-form">
                        <div className="container-page">
                            <div className="container">
                                <div className="page-content">
                                    <div className="  d-flex hero-form">
                                        <div className="bg-darkgreen bg-hero hero-form-left">
                                            <div className="container py-10">
                                                <div className="d-flex justify-content-center align-items-center flex-column mt-10">
                                                    <h1 className="title color-white mb-5">{t("business_extra_slogan")}</h1>
                                                    <img src={LabelWise} alt="wise-label" />
                                                    <div>
                                                        <div className="d-flex icons mt-10">
                                                            <a href={envir.Variables.WiseSocialFacebook} target="_blank" rel="noreferrer">
                                                                <div className="icon icon-facebook-round"></div>
                                                            </a>
                                                            <a href={envir.Variables.WiseSocialLinkedIn} target="_blank" rel="noreferrer">
                                                                <div className="icon icon-linkedin-round"></div>
                                                            </a>
                                                            <a href={envir.Variables.WiseSocialYoutube} target="_blank" rel="noreferrer">
                                                                <div className="icon icon-youtube-round"></div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-hero hero-form-right">
                                            <div className="container pt-16 py-10">
                                                <div>
                                                    <h1 className='title text-center'>{t("business_main_welcome")}</h1>
                                                    <div className="text-center">{t("business_login_subtitle")}</div>
                                                </div>
                                                {
                                                    !message
                                                        ?
                                                        null
                                                        :
                                                        <AlertMessage message={message} setMessage={setMessage} />
                                                }

                                                <Form
                                                    layout='vertical'
                                                    className='mt-8 w-75 mx-auto'
                                                    onFinish={() => mutateLogin.mutate()}
                                                    name="form-login"
                                                >

                                                    <Form.Item
                                                        name="email"
                                                        label={t("business_main_email")}
                                                        rules={[{ required: true, message: false, type: "email" }]}
                                                    >
                                                        <Input
                                                            prefix={<span className='icon fs-small icon-nospace mr-2 icon-mail ' />}
                                                            placeholder={t("business_extra_placeholder_email_business")}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            htmlType="mail" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="password"
                                                        label={t("business_main_password")}
                                                        rules={[{ required: true, message: false }]}
                                                    >
                                                        <Input.Password
                                                            prefix={<span className='icon icon-nospace mr-2 icon-lock ' />}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            placeholder={t("business_main_password")}
                                                            htmlType="password" />
                                                    </Form.Item>

                                                    <div className='mt-4'>
                                                        <Button
                                                            htmlType='submit'
                                                            className='btn btn-primary btn-xlg mb-2'
                                                            onClick={(e) => setMessage(null)} loading={mutateLogin.isLoading}>
                                                            {t("business_main_login")}
                                                        </Button>
                                                        {/* <button id="btn-submit" onClick={(e) => setMessage(null)} type="submit" className="btn btn-primary btn-xlg mb-2">{t("business_main_login")}</button> */}
                                                        {/* <hr />
                                                        <div className='mt-2 mb-5'>
                                                            <Link to="/register" className="btn-secondary btn btn-xlg">
                                                                {t("business_main_register")}
                                                            </Link>
                                                        </div> */}
                                                    </div>
                                                </Form>
                                                <div className="mt-4 d-flex justify-content-center flex-column align-items-center">
                                                    <Link to="/forgot" className="color-grey mb-2">{t("business_login_password_request")}</Link>
                                                    <Link to="/register" className="color-grey">{t("business_login_new_account")}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
}

export default LoginPage;


export const useAccountLogin = (props) => {
    const userStore = useUserStore();

    return useMutation({
        mutationKey: ["mutateRememberMe"],
        mutationFn: () => axios.post(`login2`, {
            UserEmail: props.email,
            UserPassword: props.password,
            AutoLogin: "1"
            // AutoLogin: props.rememberMe ? "1" : "0"
        }),
        retry: false,
        cacheTime: 0,
        onSuccess: (res) => handleRequestSuccess({
            response: res,
            callback: () => {
                const data = res.data.instance;
                handleUserLoginData({ data: data, userStore: userStore });
                if (props.navigate) {
                    return props.navigate("/wise");
                }
            },
            customCallback: () => {
                if (!props.setMessage) { return console.log(chalk.red("No message")) }
                switch (parseInt(res.data.status)) {
                    // EMAIL INCORRECT
                    // PASSWORD INCORRECT
                    case 290:
                    case 283:
                        return props.setMessage([t("business_login_invalid"), "danger"]);
                    // UNVERIFIED EMAIL
                    case 282:
                        return props.setMessage([t("business_login_unverified"), "danger"]);
                    // BANNED
                    case 280:
                        return props.setMessage([t("login_banned"), "danger"]);
                    // COMPANY OR USER INCORRECT
                    case 483:
                        throw props.setMessage([t("COMPANY OR USER INCORRECT"), "danger"]);
                    // USER IS NOT VERIFIED - BUSINESS HAS TO VERIFY
                    case 486:
                        return props.setMessage([t("business_login_unverified"), "danger"]);
                    // COMPANY OR USER INCORRECT
                    case 480:
                        return props.setMessage([t("business_login_expired_plan"), "danger"]);
                    default:
                        return props.setMessage([t("business_extra_general_error1"), "danger"]);
                }
            }
        }),
        onError: (res) => handleRequestError({
            response: res,
            customCallback: () => {
                if (!props.setMessage) { return console.log(chalk.red("No message")) }
                switch (parseInt(res.response.data.status)) {
                    // EMAIL INCORRECT
                    // PASSWORD INCORRECT
                    case 290:
                    case 487:
                    case 283:
                    case 483:
                        return props.setMessage([t("business_login_invalid"), "danger"]);
                    // UNVERIFIED EMAIL
                    case 486:
                        return props.setMessage([t("business_login_unverified"), "danger"]);
                    // BANNED
                    case 280:
                        return props.setMessage([t("login_banned"), "danger"]);
                    // USER IS NOT VERIFIED - BUSINESS HAS TO VERIFY
                    case 482:
                        props.setMessage([t("business_login_unverified"), "danger"]);
                        break;
                    // COMPANY OR USER INCORRECT
                    case 480:
                        return props.setMessage([t("business_login_expired_plan"), "danger"]);
                    default:
                        return props.setMessage([t("business_extra_general_error1"), "danger"]);
                }
            }
        })
    })
}
import Panel from "components/modules/panel/panel"
import { t } from "i18next"
import { Empty, Table } from "antd";
import { useState } from "react";


export default function TablePanel(props) {
    const [isLimited, setIsLimited] = useState(props.itemsLimited ? true : false)

    const data = props.data && props.data.items ? props.data : {
        items: [
            {
                "sortIndex": 1,
                "jobName": "Audit Consultant",
                "totalApplicants": 6,
                "lastRateDate": "2023-11-17",
            },
            {
                "sortIndex": 2,
                "jobName": "Support Assistant",
                "totalApplicants": 2,
                "lastRateDate": "2023-10-11",
            },
            {
                "sortIndex": 3,
                "jobName": "Accountant",
                "totalApplicants": 12,
                "lastRateDate": "2022-03-24",
            }
        ]
    }

    const columns = props.columns && props.columns.length > 0 ? props.columns : [
        {
            title: t("business_dashboard_panel_hot_talent_table_head_jobname"),
            dataIndex: 'jobName',
            key: 'jobName',
        },
        {
            title: t("business_dashboard_panel_hot_talent_table_head_amount"),
            dataIndex: 'totalApplicants',
            key: 'totalApplicants',
        },
        {
            title: t("business_dashboard_panel_hot_talent_table_head_update_date"),
            dataIndex: 'lastRateDate',
            key: 'lastRateDate',
        },
    ]

    return <>
        {
            !data || !data.items || !columns || data.items.length === 0 || columns.length === 0 ? null :
                <Panel
                    bodyStyle={{ padding: 0 }}
                    title={props.title || ''}
                >

                    <Table
                        locale={{
                            emptyText: <>
                                <Empty description={t("business_extra_no_data")} />
                            </>
                        }}
                        style={{ borderTop: "1px solid #eee" }} className="mb-3"
                        pagination={false} columns={columns} dataSource={isLimited ? props.data.items.slice(0, props.itemsLimited) : data.items} />
                    <div className="flex justify-content-end mx-4 gap-3">
                        {
                            props.showMessages && <div
                                className="link"
                            >
                                Candidate messages
                            </div>
                        }
                        <div
                            className="link"
                            onClick={() => setIsLimited(!isLimited)}
                        >
                            {!props.itemsLimited ? null : isLimited ? t("business_main_show_all") : t("business_main_show_less")}
                        </div>
                    </div>

                </Panel>
        }
    </>
}
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Form } from "antd";
import axios from "axios";
import GuestRedirectLayout from "components/layouts/page-layouts/guest-redirect";
import { handleRequestError, handleRequestSuccess, isValid } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import $ from "jquery";
import { useEffect, useState } from "react";
import Loading from "../../components/animations/loading";
import AlertMessage from "../../components/forms/alerts/alert-message";
import LayoutAccount from "./layout-account";

export default function AccountAlertsPage(props) {
    // # STORES
    const userStore = useUserStore();

    // # CLIENT STATES
    const [message, setMessage] = useState(null);
    const [accountNotifications, setAccountNotifications] = useState(false);
    const [notificationFrequencyTypes, setNotificationFrequencyTypes] = useState(false);
    const [notificationTypes, setNotificationTypes] = useState(false);

    // # SERVER STATES
    const { refetch: accountNotificationsRefetch } = useGetAccountNotifications({ notificationTypes: notificationTypes, setCurrentNotifications: setAccountNotifications })
    const { } = useGetNotificationFrequencyTypes({ setData: setNotificationFrequencyTypes })
    const { } = useGetNotificationTypes({ setData: setNotificationTypes })
    const mutateAccountNotifications = useEditAccountNotifications({ currentNotifications: accountNotifications });

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!notificationTypes) { return }
        if (!notificationFrequencyTypes) { return }
        accountNotificationsRefetch();
    }, [notificationTypes, notificationFrequencyTypes]);

    useEffect(() => {
        if (!accountNotifications) { return }
        setTimeout(() => {
            accountNotifications.map(item => {
                $("#notification-type-" + item.notificationTypeId + "-notification-freq-" + item.notificationSubscriptionTypeId).attr('checked', 'checked');
            })
        }, 10);

    }, [accountNotifications, notificationFrequencyTypes, notificationTypes]);

    function getNotifications() {
        if (!notificationTypes) { return }
        setAccountNotifications(false);
        setTimeout(() => {
            accountNotificationsRefetch();
        }, 200);
    }



    return (
        <GuestRedirectLayout>
            <LayoutAccount
                title={t("business_account_notifications_title")}
                subtitle={t("business_account_notifications_subtitle")}
            >
                <div className="card mt-3">
                    <div className='account-alerts'>
                        {
                            !message ? null :
                                <AlertMessage Message={message} setMessage={setMessage} />
                        }
                        {
                            notificationTypes === false ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                                notificationFrequencyTypes === false ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                                    accountNotifications === false ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                                        notificationTypes === null || notificationTypes === undefined ? null :
                                            notificationTypes.length === 0 ? null :
                                                <Form
                                                    name={"form-account-alerts"}
                                                    layout="vertical"
                                                    className="my-4 px-4"
                                                    onFinish={() => mutateAccountNotifications.mutate()}
                                                >

                                                    <h3 className='mb-4 icon-width d-flex align-items-center'> <span className="icon-alert icon round lime"></span> {t("business_main_email")}</h3>
                                                    <div className="mt-4 mb-4">
                                                        {
                                                            notificationTypes.map((notificationType, i) => {
                                                                return <div key={i}>
                                                                    <p>{notificationType.text}</p>
                                                                    <div id={notificationType.id} className="checkbox-options mb-4">
                                                                        {
                                                                            notificationFrequencyTypes === null || notificationFrequencyTypes === undefined ? null :
                                                                                notificationFrequencyTypes.length === 0 ? null :
                                                                                    notificationFrequencyTypes.map((notificationFrequencyType, y) => {
                                                                                        // if (y === 0) {return} else {}
                                                                                        return <label className="input-checkbox">
                                                                                            <input className="form-check-input"
                                                                                                name={"notification-type-" + notificationType.id}
                                                                                                id={"notification-type-" + notificationType.id + "-notification-freq-" + notificationFrequencyType.id}
                                                                                                onChange={(e) => {
                                                                                                    var newNotifications = accountNotifications;
                                                                                                    newNotifications[i].notificationSubscriptionTypeId = notificationFrequencyType.id
                                                                                                    console.log(newNotifications[i])
                                                                                                    setAccountNotifications(newNotifications);
                                                                                                }}
                                                                                                type="radio"
                                                                                                value={notificationFrequencyType.id}
                                                                                            />
                                                                                            <span>
                                                                                                {notificationFrequencyType.text}
                                                                                            </span>
                                                                                        </label>
                                                                                    })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            })

                                                        }
                                                    </div>
                                                    <div className="btns-right">
                                                        <Button
                                                            htmlType="submit"
                                                            className="btn btn-primary mr-2"
                                                            loading={mutateAccountNotifications.isLoading}
                                                        >
                                                            {t("business_main_save")}
                                                        </Button>
                                                        <div onClick={() => getNotifications()} className="btn-secondary btn d-inline-block">{t("business_main_cancel")}</div>
                                                    </div>
                                                </Form>
                        }
                    </div>
                </div>
            </LayoutAccount>
        </GuestRedirectLayout>
    );
}

const useEditAccountNotifications = (props) => {
    const userStore = useUserStore();
    return useMutation({
        mutationKey: ["mutateAccountNotifications"],
        mutationFn: () => axios.post(`v2/update/users/${userStore.userData.id}/notifications`, {
            Notifications:
                // ID & frequency                          
                props.currentNotifications === undefined ? null :
                    ((props.currentNotifications).map((item, i) => {
                        return ({
                            Id: item.notificationTypeId,
                            Frequency: item.notificationSubscriptionTypeId
                        });
                    }))
        }),
        onSuccess: (res) => handleRequestSuccess({ response: res, hasAlert: true, isSubmit: true }),
        onError: (res) => handleRequestError({ response: res, hasAlert: true, isSubmit: true }),
    })
}

const useGetAccountNotifications = (props) => {
    const userStore = useUserStore();
    return useQuery({
        queryKey: ["getAccountNotifications"],
        queryFn: () => axios.post("notifications/info", {
            UserId: userStore.userData.id
        }),
        refetchOnWindowFocus: false,
        enabled: false,
        retry: 2,
        onSuccess: (res) => handleRequestSuccess({
            response: res,
            customCallback: () => {
                if (parseInt(res.data.status) === 290) {
                    let newArray = [];
                    // SET NOTIFICATION STATES
                    if (props.notificationTypes !== undefined) {
                        (props.notificationTypes).map((item, i) => {
                            newArray.push({
                                notificationTypeId: (item.id),
                                notificationSubscriptionTypeId: 0
                            });
                        });
                    }
                    props.setCurrentNotifications(newArray);
                }
            },
            callback: () => {

                let newArray = [];
                var data = res.data.instance;
                console.log(props.notificationTypes);
                if (!data) {
                    // SET NOTIFICATION STATES
                    if (props.notificationTypes !== undefined) {
                        (props.notificationTypes).map((item, i) => {
                            newArray.push({
                                notificationTypeId: (item.id),
                                notificationSubscriptionTypeId: 0
                            });
                        });
                    }
                }
                else if (data.length !== props.notificationTypes.length) {
                    console.log(props.notificationTypes);
                    // CREATE ALL ITEMS U MUST HAVE       
                    (props.notificationTypes).map((item, i) => {
                        // DISABLE NEWSFEED NOTIS
                        if (item.notificationTypeId === 2) { return }
                        newArray.push({
                            notificationTypeId: (i + 1),
                            notificationSubscriptionTypeId: null
                        });
                    });

                    // IF ITEM ID EXCISTS IN DATA THEN FILL IN CORRECT ID
                    newArray.map((item, i) => {
                        data.map((anItem, l) => {
                            if (item.notificationTypeId === anItem.notificationTypeId) {
                                item.notificationSubscriptionTypeId = anItem.notificationSubscriptionTypeId;
                            } else {
                                item.notificationSubscriptionTypeId = 0;
                            }
                        })
                    });
                }
                else {
                    if (props.notificationTypes !== undefined) {
                        (props.notificationTypes).map(type => {
                            data.map(availableType => {
                                // DISABLE NEWSFEED NOTIS
                                if (type.notificationTypeId === 2) { return }
                                // IF THERE IS A VALUE SAVED
                                if (availableType.notificationTypeId === type.id) {
                                    newArray.push({
                                        notificationTypeId: (availableType.notificationTypeId),
                                        notificationSubscriptionTypeId: availableType.notificationSubscriptionTypeId
                                    });
                                }
                            });
                        });
                    }
                }
                newArray.sort(function (a, b) {
                    return a.notificationTypeId - b.notificationTypeId;
                });
                props.setCurrentNotifications(newArray);
                console.log(newArray)
                console.log(data)
            }
        }),
        onError: (res) => handleRequestError({
            response: res,
            customCallback: () => {
                if (parseInt(res.data.status) === 290) {
                    let newArray = [];
                    // SET NOTIFICATION STATES
                    if (props.notificationTypes !== undefined) {
                        (props.notificationTypes).map((item, i) => {
                            newArray.push({
                                notificationTypeId: (item.id),
                                notificationSubscriptionTypeId: 0
                            });
                        });
                    }
                    props.setCurrentNotifications(newArray);
                }
            },
        }),
    })
}

const useGetNotificationFrequencyTypes = (props) => {
    const userStore = useUserStore();
    return useQuery({
        queryKey: ["getNotificationFrequencyTypes"],
        queryFn: () => axios.get("v2/generic/catalogues/NotificationFrequencyTypes"),
        refetchOnWindowFocus: false,
        retry: 2,
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {
                var data = res.data.instance;
                let allItems = [];
                var selectedItem = [];
                (data).forEach(item => {
                    if (item.nameTranslations === null) { return }
                    if (item.nameTranslations === undefined) { return }
                    Array.prototype.forEach.call(item.nameTranslations, function (itemTranslation, i) {
                        if (userStore.clientData.language.id === itemTranslation.languageId) {
                            // return selectedItem = [item.id, itemTranslation.text];
                            return selectedItem = {
                                id: item.id,
                                text: itemTranslation.text,
                                sortIndex: item.id,
                            };
                        }
                    });
                    if (selectedItem.length !== 0) {
                        allItems = [...allItems, (selectedItem)];
                        selectedItem = [];
                    }
                });

                allItems = allItems.sort(function (a, b) {
                    if (b.id === 0) {
                        return -1;
                    }
                    if (a.sortIndex > b.sortIndex) {
                        return a - b;
                    }
                })

                props.setData(allItems);
            }
        }),
        onError: (res) => handleRequestError({ response: res }),
    })
}
const useGetNotificationTypes = (props) => {
    const userStore = useUserStore();
    return useQuery({
        queryKey: ["getNotificationTypes"],
        queryFn: () => axios.get("v2/generic/catalogues/NotificationTypesBusiness"),
        refetchOnWindowFocus: false,
        retry: 2,
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {
                var data = res.data.instance;
                let allItems = [];
                var selectedItem = [];
                (data).forEach(item => {
                    if (item.descriptionTranslations === null) { return }
                    if (item.descriptionTranslations === undefined) { return }
                    Array.prototype.forEach.call(item.descriptionTranslations, function (itemTranslation, i) {
                        if (userStore.clientData.language.id === itemTranslation.languageId) {
                            // return selectedItem = [item.id, itemTranslation.text];
                            return selectedItem = {
                                id: item.id,
                                text: itemTranslation.text,
                                sortIndex: item.id,
                            };
                        }
                    });
                    if (selectedItem.length !== 0) {
                        allItems = [...allItems, (selectedItem)];
                        selectedItem = [];
                    }
                });
                props.setData(allItems);
            }
        }),
        onError: (res) => handleRequestError({ response: res }),
    })
}
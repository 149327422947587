
import { useQuery } from '@tanstack/react-query';
import { Button, Select } from 'antd';
import Input from 'antd/es/input/Input';
import PrefixIcon from 'components/forms/input/prefix-icon';
import JobProbeQuestion from 'components/forms/job/job-probe-question';
import { sendAxiosGetRequest, sendAxiosPostRequest } from 'components/utils/methods/myRequestHandlers';
import { useUserStore } from 'components/utils/stores/userStore';
import { t } from 'i18next';
import { Fragment, useEffect, useState } from 'react';
import Loading from '../../animations/loading';
import NoData from '../no-data/nodata';
import useGetJobProbeOptions from 'components/requests/data/useGetJobProbeOptions';

export default function JobProbeQuestionsOverlay(props) {
    // # STORES
    const userStore = useUserStore();

    // # SERVER STATES
    const { data, isLoading, isRefetching, isError, refetch } = useQuery({
        queryKey: ["jobProbeQuestions"],
        queryFn: () => sendAxiosGetRequest({
            route: `v5/business/select/jobs/${props.jobItem.jobId}/probes`,
            hasAlert: true,
            callback: (res) => {
                setDataItems(res.data.instance);
                return res.data.instance
            }
        }),
        enabled: false
    })

    const { data: jobProbeOptions, isLoading: isLoadingJobProbeOptions } = useGetJobProbeOptions()

    // # CLIENT STATES
    const [searchFilter, setSearchFilter] = useState("");
    const [categoryFilter, setCategoryFilter] = useState();
    const [dataItems, setDataItems] = useState();

    useEffect(() => {
        if (categoryFilter && categoryFilter === "all") setDataItems(data);
        else if (categoryFilter) {
            setDataItems(data?.filter((item) => item.jobProbeId === categoryFilter));
        }
    }, [categoryFilter])

    useEffect(() => {
        console.log({ dataItems, searchFilter })
        if (!searchFilter) setDataItems(data);
        else if (searchFilter && dataItems) setDataItems(data.filter((item) => item.question.toLowerCase().includes(searchFilter.toLowerCase())));
        else {
            if (categoryFilter && categoryFilter === "all") setDataItems(data);
            else if (categoryFilter) {
                setDataItems(data?.filter((item) => item.jobProbeId === categoryFilter));
            }
        }
    }, [searchFilter])

    useEffect(() => {
        if (props.overlayState) {
            setDataItems(null);
            refetch();
            var elem = document.querySelector('body');
            elem.style.overflow = "hidden"
        } else {
            props.setCurrentJobs(false);
            props.getJobs();
            var elem = document.querySelector('body');
            elem.style.overflow = "auto"
        }
    }, [props.overlayState]);


    return (
        <>
            {
                props.overlayState === false ? null :
                    <div className="overlay">
                        <div className="overlay-content d-flex align-items-center pt-2">
                            <div className="container px-1">
                                <span className='close' onClick={() => props.setOverlay(false)}></span>
                                <div>
                                    <h1>
                                        {t("business_company_job_probe_questions_overlay_title")}
                                    </h1>
                                    <p>
                                        {t("business_company_job_probe_questions_overlay_description")}
                                    </p>
                                    {
                                        data && data.length === 0 ? null :
                                            <div className='flex align-items-center flex-row' style={{ gap: "10px" }}>
                                                <Input
                                                    allowClear
                                                    value={searchFilter}
                                                    onChange={(e) => setSearchFilter(e.target.value)}
                                                    placeholder={t("business_main_search")}
                                                    prefix={<PrefixIcon value="icon-search" />}
                                                    style={{ maxWidth: "800px" }}
                                                />
                                                <Select
                                                    loading={isLoadingJobProbeOptions}
                                                    defaultValue={"all"}
                                                    options={[
                                                        {
                                                            label: t("business_main_all"),
                                                            value: "all"
                                                        },
                                                        ...jobProbeOptions,
                                                    ]}
                                                    onChange={(val) => {
                                                        // setDataItems("loading")
                                                        setSearchFilter("");
                                                        setCategoryFilter(val);
                                                    }}
                                                    style={{
                                                        width: "25%",
                                                        minWidth: "300px"
                                                    }}
                                                />
                                            </div>
                                    }
                                    <div className='mt-3'>
                                        <div className="mt-4">
                                            {
                                                isError ? <NoData wise="sad" className="mb-4 pt-4" error /> :
                                                    data && data.length === 0 ? <NoData wise="smile" className="mb-4 pt-4" /> :
                                                        isLoading || isRefetching || dataItems === "loading" ? <Loading center /> : <>
                                                            {
                                                                !dataItems || (dataItems && dataItems.filter(item => item.question).length === 0) ? <NoData wise="search" className="mb-4 pt-4" /> : <>
                                                                    {
                                                                        dataItems && dataItems.filter(item => item.question).map((item, index) => (
                                                                            <Fragment key={index}>
                                                                                <JobProbeQuestion
                                                                                    data={item}
                                                                                    refetch={refetch}
                                                                                    jobProbeOptionData={jobProbeOptions.find(option => option.value === item.jobProbeId)}
                                                                                />
                                                                            </Fragment>
                                                                        ))
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
            }
        </>
    );
}

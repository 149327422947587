import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { t } from "i18next";
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";

const useTokenValidator = (props) => {
    return useQuery({
        queryKey: ["tokenValidator"],
        queryFn: () => {
            if (props.setMessage) {
                props.setMessage(null);
            }
            return axios.post("token/user/validate", {
                token: props.token
            });
        },
        refetchOnWindowFocus: false,
        enabled: false,
        retry: 2,
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {
                var data = res.data.instance
                if (props.setUserId) {
                    props.setUserId(data.id)
                }
                if (props.setData) {
                    props.setData(data)
                }
                if (props.mutateVerification) {
                    props.mutateVerification.mutate();
                }
            }
        }),
        onError: (res) => handleRequestError({
            response: res, customCallback: () => {
                if (parseInt(res.response.data.status) === 480) {
                    props.setMessage([t("business_extra_token_invalid"), "danger"]);
                    throw Error("Invalid token")
                }
            }
        })
    })
}

export default useTokenValidator;
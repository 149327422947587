
export default function WidgetCompany(props) {
    return (
        <div style={{ position: "relative" }}>
            <iframe title="WiSE-Widget" data-src="https://business.wisepeople.be" scrolling="no" src={props.urlCompany} style={{ width: "100%", overflow: "hidden", border: "none", position: "relative", display: "block" }} />
        </div>

        // <div style="position: relative;">
        //     <iframe data-src="https://business.wisepeople.be" title="WiSE-Widget" scrolling="no" src="http://localhost:5173/skeyes" style="width: 100%; overflow:hidden;  border:none; position: relative; display: block;"></iframe>
        // </div>
    )
}
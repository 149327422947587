import { Timeline, Divider, Select } from "antd"
import NoData from "components/layouts/no-data/nodata"
import Message from "components/modules/message/message"
import Panel from "components/modules/panel/panel"
import { dateFormat } from "components/utils/methods/AppMethods"
import { getDate, getDateInFormat } from "components/utils/methods/MyMethods"
import { t } from "i18next"
import { Fragment, useEffect, useState } from "react"
import { CornerDownRight, MinusCircle, PlayCircle, PlusCircle, XCircle } from "react-feather"

export default function MessagesStepsPanel(props) {
    const dateFormat = "MMMM 'YY";

    const [dates, setDates] = useState();
    const [activeFilters, setActiveFilters] = useState([]);

    useEffect(() => {
        if (!props.data || props.data.length <= 0) {
            return
        }
        var dates = [];
        props.data.forEach(element => {
            if (getDateInFormat(element.tipUpdatedOn, dateFormat) && dates.filter(date => date.label === getDateInFormat(element.tipUpdatedOn, dateFormat)).length <= 0) {
                const randomIndex = Math.floor(Math.random() * stepsDemoData.length);

                dates.push({
                    raw: element,
                    label: getDateInFormat(element.tipUpdatedOn, dateFormat),
                    filter: stepsDemoData[randomIndex].value
                });
            }
        });
        setDates(dates)
    }, [props.data])

    // console.log(stepsDemoData.map(item => {
    //     return {
    //         label: item.nameTranslations.find(trans => trans.languageId === "a021ac18-5f7e-4cd5-bf05-6085565fd516").text,
    //         id: item.id
    //     }
    // }))


    return <>
        <Panel
            {...props.panelProps}
            scroll
            scrollHeight={400}
            title={t("business_dashboard_panel_messages_title")}
        >
            <>
                <p className="mb-0 font-special">Your filtered steps:</p>
                <Select
                    mode="multiple"
                    allowClear
                    className={`${activeFilters ? "mb-4 mt-2" : ''}`}
                    placeholder="Please select your filtered steps"
                    style={{ width: '100%' }}
                    value={activeFilters}
                    onChange={(value) => {
                        setActiveFilters(value)
                    }}
                    options={stepsDemoData}
                />
                <div className="d-inline-block" style={{ width: "100%" }}>
                    {
                        activeFilters.length <= 0 ? <NoData wise="add" text="Please select your filters" className="my-4" center /> :
                            !dates || dates.length <= 0 || (dates.filter((dateItem) => dateItem.filter && activeFilters.includes(dateItem.filter))).length <= 0 ? <NoData wise="sad" className="my-4" center /> :
                                dates.filter((dateItem) => dateItem.filter && activeFilters.includes(dateItem.filter)).sort((a, b) => a.raw.tipUpdatedOn > b.raw.tipUpdatedOn ? -1 : 1).map(date => {
                                    var positiveItems = [];
                                    var negativeItems = [];
                                    props.data.filter(element => getDateInFormat(element.tipUpdatedOn, dateFormat) === date.label).map(element => {
                                        if (element.isPositive) {
                                            positiveItems.push(element)
                                        } else {
                                            negativeItems.push(element)
                                        }
                                    })
                                    return <>
                                        <Divider orientationMargin="20" orientation="middle"><h2 className="fw-regular color-darkgrey mb-0" style={{ fontSize: 22 }}>  {date.label}</h2></Divider>

                                        <div className="flex flex-row flex-wrap justify-content-center mb-5" style={{ width: "100%" }}>
                                            <div className="w-50 px-2">
                                                <div className=" my-4 d-flex flex-row flex-wrap align-items-center justify-content-center">
                                                    <PlusCircle color="green" className="mx-3" size={50} />
                                                    <h3 style={{ color: "#008000" }} className="fw-medium color-darkgrey mb-0">{t("business_main_positive")} {`(${positiveItems.filter(item => item.tipText.trim() !== 0).length})`}</h3>
                                                </div>

                                                {
                                                    positiveItems && positiveItems.length > 0 ? positiveItems.sort((a, b) => a.tipUpdatedOn > b.tipUpdatedOn ? 1 : -1).map((message, i) => message.tipText && message.tipText.trim().length > 0 &&
                                                        <Message key={i} message={message.tipText} from={`${t("business_main_anonymous_user")} - ${getDateInFormat(message.tipUpdatedOn, `hh:mmu DD/MM/YYYY`)}`} />) :
                                                        <NoData className="py-4" wise="smile" text={t("business_dashboard_panel_messages_none")} />
                                                }


                                            </div>
                                            <div className="w-50 b px-2">
                                                <div className=" my-4 d-flex flex-row flex-wrap align-items-center justify-content-center">
                                                    <MinusCircle color="red" className="mx-3" size={50} />
                                                    <h3 style={{ color: "#FF0000" }} className="fw-medium color-darkgrey mb-0">{t("business_main_negative")} {`(${negativeItems.filter(item => item.tipText.trim() !== 0).length})`}</h3>
                                                </div>
                                                {
                                                    negativeItems && negativeItems.length > 0 ? negativeItems.sort((a, b) => a.tipUpdatedOn > b.tipUpdatedOn ? 1 : -1).map((message, i) => message.tipText && message.tipText.trim().length > 0 &&
                                                        <Message key={i} message={message.tipText} from={`${t("business_main_anonymous_user")} - ${getDateInFormat(message.tipUpdatedOn, `hh:mmu DD/MM/YYYY`)}`} />) :
                                                        <NoData className="py-4" wise="smile" text={t("business_dashboard_panel_messages_none")} />
                                                }

                                            </div>
                                        </div>
                                    </>
                                })
                    }
                </div>
            </>
        </Panel>
    </>
}

export const stepsDemoData = [
    {
        "label": "visit / plant visit",
        "value": "62818843-f778-4f1b-a55c-0c402a75aad8"
    },
    {
        "label": "telephone interview",
        "value": "079f64b7-f1a2-4f69-b1c5-134c0a81cc15"
    },
    {
        "label": "video interview",
        "value": "2802657f-4e76-4d50-8df5-25a3dc8b9d94"
    },
    {
        "label": "assessment / business case",
        "value": "226ef31f-d49a-4e7e-96e2-31a1792b5452"
    },
    {
        "label": "face-to-face interview",
        "value": "ae7c8ce5-a93e-4cee-b8e1-b2d9ef630ecb"
    },
    {
        "label": "online application",
        "value": "e791f886-fc9a-4c43-b42d-b60f2bc90a4f"
    },
    {
        "label": "testing / questionnaires",
        "value": "00c49560-411c-407c-847f-b6261ace71c3"
    },
    {
        "label": "recap of application",
        "value": "098e4292-ca4c-480e-8a57-c7a6ba37120a"
    },
    {
        "label": "contract phase",
        "value": "34ce8600-eb58-4c1f-8d95-d340233db50a"
    }
]
import { t } from "i18next"
import { Link } from "react-router-dom"
import { useState } from "react";
import { useUserStore } from "components/utils/stores/userStore";

export default function LayoutRecruiter(props) {
    // # STORES
    const userStore = useUserStore();

    // # CLIENT STATES
    const title = props.title;
    const subtitle = props.subtitle;


    return <>
        <div id="recruiter">
            <div className="sidebar-container profile-menu" >
                <div className="sidebar-menu">
                    <div className="sidebar-menu-wrapper">
                        <div className="menu-header">
                            <div className="icon icon-recruiter-brand">
                                {t("business_profile_title")}
                            </div>
                        </div>
                        <div className="menu-content pb-10">
                            <div className="menu-content-wrapper">
                                <Link to="/recruiter-branding/info">
                                    <div className={`menu-item ${window.location.pathname.includes("/recruiter-branding/info") ? "active" : ""}`}>
                                        {t("business_profile_general_title")}
                                    </div>
                                </Link>
                                {
                                    // issue 1308 - Recruiter brand – Aanbevelingen verdwijnt indien Toggle uit staatDit mag altijd zichtbaar zijn
                                    // props.isVisible === false ? null :
                                    <Link to="/recruiter-branding/overview">
                                        <div className={`menu-item ${window.location.pathname.includes("/recruiter-branding/overview") ? "active" : ""}`}>
                                            {t("business_main_recommendations")}
                                        </div>
                                    </Link>
                                }
                                <Link to="/create-visual?type=recruiter">
                                    <div className={`menu-item`}>
                                        {t("business_main_recruiter_create_visual")}
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sidebar-content container">
                    <div className="page-content my-10 mb-15">
                        <div className="content-header mb-4">
                            <div className="d-flex justify-content-between flex-wrap">
                                <h1 className="title pr-4 desktop-lg-only">{title}</h1>
                                <div onClick={() => userStore.toggleClientDataMobileMenuOverlay()} className="lg-only menu-title icon icon-arrow2-left">
                                    <h1>{title}</h1>
                                </div>
                            </div>
                            <p className="subtitle">{subtitle}</p>
                        </div>
                        <>
                            {props.children}
                        </>
                    </div>
                </div>
            </div>
        </div>
    </>
}
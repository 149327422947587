
import { Button, Checkbox, Form, Input, Radio } from 'antd';
import useRegisterRecruiterStepsHandler from 'components/requests/account/useRegisterRecruiterStepsHandler';
import useRegisterStepsHandler from 'components/requests/account/useRegisterStepsHandler';
import { useUserStore } from 'components/utils/stores/userStore';
import i18next, { t } from 'i18next';
import $ from 'jquery';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LabelWise from '../../assets/images/wise-label.png';
import AlertMessage from '../../components/forms/alerts/alert-message';
import envir from '../../env.json';

export default function RegisterStep1(props) {
    // # STORES
    const userStore = useUserStore();

    // # CLIENT STATES
    const [message, setMessage] = useState(null);


    // # UTILS
    const urlParam = new URLSearchParams(window.location.search);
    const editUserId = (val) => props.editFormItem("userId", val);

    // # SERVER STATES
    const mutateRegisterStepsHandler = useRegisterStepsHandler({
        password: props.formObject.password,
        email: props.formObject.registerEmail,
        acceptTerms: props.formObject.registerAcceptedTerms,
        setMessage: setMessage,
        step: props.currentStep,
        setStep: props.setStep,
        editUserId: editUserId
    })
    const mutateRegisterRecruiterStepsHandler = useRegisterRecruiterStepsHandler({
        email: props.formObject.registerEmail,
        setMessage: setMessage,
        step: props.currentStep,
        setStep: props.setStep,
    })


    useEffect(() => {
        setMessage(false);
    }, [i18next.language])

    useEffect(() => {
        var plan = urlParam.get("plan");
        if (plan === null) { return }

        if (plan.toLowerCase() === "agency" || plan.toLowerCase() === "small" ||
            plan.toLowerCase() === "medium" || plan.toLowerCase() === "large") {
            $("input[value='company']").prop("checked", true);
            props.editFormItem("registerType", "company");
        }
    }, [window.location.search])

    var pasThresh = <div>{t("business_extra_password_conditions_unmet")}
        <ul>
            <li>{t("business_extra_password_condition3")}</li>
            <li>{t("business_extra_password_condition1")}</li>
            <li>{t("business_extra_password_condition2")}</li>
            <li>{t("business_extra_password_condition4")}</li>
        </ul>
    </div>

    function PasswordValidate(password) {
        var re = {
            'capital': /[A-Z]/,
            'digit': /[0-9]/,
            'special': /\W/,
        };
        return re.capital.test(password) &&
            re.digit.test(password) &&
            re.special.test(password) &&
            password.length >= 8;
    }

    const submitAccount = (event) => {
        setMessage(null);
        if (props.formObject.password !== props.formObject.password2) {
            setMessage([t("business_password_reset_mismatch"), 'danger']);
            return;
        }
        if (!PasswordValidate(props.formObject.password)) {
            setMessage([pasThresh, 'warning']);
            return;
        }
        if (props.formObject.registerAcceptedTerms === false) {
            setMessage([t("business_register_warning_terms_accept"), 'warning']);
            return;
        }
        mutateRegisterStepsHandler.mutate();
    }

    const submitAccountRecruiter = (event) => {
        setMessage(null);
        if (props.formObject.password !== props.formObject.password2) {
            setMessage([t("business_password_reset_mismatch"), 'danger']);
            return;
        }
        if (!PasswordValidate(props.formObject.password)) {
            setMessage([pasThresh, 'warning']);
            return;
        }
        if (props.formObject.registerAcceptedTerms === false) {
            setMessage([t("business_register_warning_terms_accept"), 'warning']);
            return;
        }

        mutateRegisterRecruiterStepsHandler.mutate();
    }

    return (
        <div className="  d-flex hero-form">
            <div className="bg-darkgreen bg-hero hero-form-left">
                <div className="container py-10">
                    <div className="d-flex justify-content-center align-items-center flex-column mt-10">
                        <h1 className="title color-white mb-5">{t("business_extra_slogan")}</h1>
                        <img src={LabelWise} alt="wise-label" />
                        <div>
                            <div className="d-flex icons mt-10">
                                <a href={envir.Variables.WiseSocialFacebook} target="_blank" rel="noreferrer">
                                    <div className="icon icon-facebook-round"></div>
                                </a>
                                <a href={envir.Variables.WiseSocialLinkedIn} target="_blank" rel="noreferrer">
                                    <div className="icon icon-linkedin-round"></div>
                                </a>
                                <a href={envir.Variables.WiseSocialYoutube} target="_blank" rel="noreferrer">
                                    <div className="icon icon-youtube-round"></div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-hero hero-form-right">
                <div className="container pt-16 py-10">
                    <div>
                        <h1 className='title text-center'>{t("business_main_register")}</h1>
                        <div className="text-center">{t("business_registration_subtitle")}</div>
                    </div>
                    {
                        !message ? null :
                            <AlertMessage message={message} setMessage={setMessage} />
                    }

                    <Form
                        layout='vertical'
                        className='mt-8 w-75 mx-auto'
                        // onFinish={(e) => console.log("letsgo?")}
                        onFinish={(e) => props.formObject.registerType === "company" ? submitAccount(e) : submitAccountRecruiter(e)}
                        name="form-register-1"
                    >
                        <Form.Item
                            name="email"
                            label={t("business_main_email")}
                            rules={[{ required: true, message: false, type: "email" }]}
                        >
                            <Input
                                prefix={<span className='icon fs-small icon-nospace mr-2 icon-mail ' />}
                                placeholder={t("business_extra_placeholder_email_business")}
                                onChange={(e) => props.editFormItem("registerEmail", e.target.value)}
                                htmlType="mail" />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            label={t("business_main_password")}
                            rules={[{ required: true, message: false }]}
                        >
                            <Input.Password
                                prefix={<span className='icon icon-nospace mr-2 icon-lock ' />}
                                onChange={(e) => props.editFormItem("password", e.target.value)}
                                placeholder={t("business_main_password")}
                                htmlType="password" />
                        </Form.Item>
                        <Form.Item
                            name="password2"
                            label={t("business_password_confirm_new_password")}
                            rules={[{ required: true, message: false }]}
                        >
                            <Input.Password
                                prefix={<span className='icon icon-nospace mr-2 icon-lock ' />}
                                onChange={(e) => props.editFormItem("password2", e.target.value)}
                                placeholder={t("business_main_password")}
                                htmlType="password" />
                        </Form.Item>
                        <Form.Item
                            name="referral"
                            label={t("discard_register_step_plan_reference") + " (" + t("business_main_optional") + ")"}
                            rules={[{ required: false, message: false }]}
                        >
                            <Input
                                onChange={(e) => props.editFormItem("referrence", e.target.value)}
                                placeholder={t("discard_register_step_plan_reference")}
                            />
                        </Form.Item>

                        <Form.Item
                            name="type"
                            label={t("business_registration_accounttype")}
                            rules={[{ required: true, message: t("business_main_required_message") }]}
                        >
                            <Radio.Group onChange={(e) => props.editFormItem("registerType", e.target.value)}>
                                <Radio value="company"> <span className='color-grey fs-small'>{t("business_registration_accounttype_company")}</span></Radio>
                                <Radio value="recruiter"> <span className='color-grey fs-small'>{t("business_registration_accounttype_recruiter")}</span></Radio>
                            </Radio.Group>
                        </Form.Item>
                        <hr />
                        <Form.Item
                            valuePropName="checked"
                            rules={[
                                {
                                    validator: (_, value) =>
                                        value ? Promise.resolve() : Promise.reject(new Error(t("business_register_warning_terms_accept"))),
                                },
                            ]}
                            name="terms"
                        >
                            <Checkbox
                                onChange={(e) => props.editFormItem("registerAcceptedTerms", !props.formObject.registerAcceptedTerms)}
                            >
                                <div className='mt-1'>
                                    <span className="color-grey fs-small">{t("business_extra_agree_1") + " "}</span>
                                    <Link className='link color-grey fs-small ' target={"_blank"} to="/terms-conditions">{t("business_terms_title").toLowerCase()}</Link>
                                </div>
                            </Checkbox>
                        </Form.Item>

                        <div className="mt-4">
                            <Button
                                htmlType='submit'
                                className='btn btn-primary btn-xlg mb-2'
                                onClick={(e) => setMessage(null)} loading={mutateRegisterStepsHandler.isLoading || mutateRegisterRecruiterStepsHandler.isLoading ? true : false}>
                                {t("business_main_next")}
                            </Button>
                        </div>
                    </Form>
                    <div className="mt-8 d-flex justify-content-center flex-column align-items-center">
                        <Link to="/login" className="color-grey">{t("business_registration_login")}</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
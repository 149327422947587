import Loading from "components/animations/loading";
import { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import envir from "env.json"
import { useUserStore } from "components/utils/stores/userStore";
import { useNavigate } from 'react-router-dom';

export default function HomeRedirectPage() {
    const userStore = useUserStore();
    const navigate = useNavigate();

    useEffect(() => {
        if (userStore.clientData.loggedIn) {
            navigate("/wise")
        } else {
            window.location.href = envir.Variables.WiseB2BInfoUrl;
        }

    }, [userStore.clientData])

    return (
        <section>
            <div className="d-flex justify-content-center"><Loading text={null} big fullscreen /></div>
        </section>
    );
}
import { DemoPanelDedicatedApplicants } from "components/layouts/dashboard/basic/db-premium";
import DashboardLayout from "components/layouts/dashboard/dashboard-layout";
import CandidateBrandExperiencePanel, { DemoCandidateBrandExperiencePanel } from "components/layouts/dashboard/panels/candidate-brand-experience-panel";
import MessagesPanel, { DemoMessagesPanel } from "components/layouts/dashboard/panels/messages-panel";
import DashboardRecruitment from "components/layouts/dashboard/recruitment/db-recruitment";
import GuestRedirectLayout from "components/layouts/page-layouts/guest-redirect";
import { useGetDbGeneralData } from "components/requests/dashboard/getDbGeneralData";
import useGetDbMessages from "components/requests/dashboard/getDbMessages";
import { handleAllPermissions } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function RecruitmentDashboardPage2(props) {
    const userStore = useUserStore();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const [isPremiumPlan, setIsPremiumPlan] = useState(null);
    const [PM_DashboardView, setPM_DashboardView] = useState(false);
    const { data: dbGeneralData, isLoading: dbGeneralDataIsLoading, isRefetching: dbGeneralDataIsRefetching, isError: dbGeneralDataIsError, refetch } = useGetDbGeneralData({ enabled: true }); // company data (number of ratings, rating, companyname, planname)
    const { data: dbMessages, isLoading: dbMessagesIsLoading, isError: dbMessagesIsError, isRefetching: dbMessagesIsRefetching, refetch: dbMessagesRefetch } = useGetDbMessages({ filterPhaseId: null, filterUserId: null, enabled: true });

    useEffect(() => {
        if (!userStore.userData) { return }
        handleAllPermissions(userStore.userData.permissionsData, [
            ["PM_VIEW_DASHBOARD", setPM_DashboardView],
        ])
    }, [userStore.userData]);

    useEffect(() => {
        if (!userStore.flagData || !userStore.flagData.toggleDashboardAdvanced) { return setIsPremiumPlan(false) }
        setIsPremiumPlan(userStore.flagData.toggleDashboardAdvanced)
    }, [userStore.flagData])

    return (
        <GuestRedirectLayout>

            <DashboardLayout data={dbGeneralData} type={"Recruiter"}>
                <>
                    <DemoPanelDedicatedApplicants />
                    <section>
                        <>
                            <div className="row">
                                <div className="col gx-2">
                                    <DemoCandidateBrandExperiencePanel
                                        filterPhaseId={null}
                                        filterUserId={null}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col gx-2">
                                    <DemoMessagesPanel />
                                </div>
                            </div>
                        </>
                    </section>
                </>
            </DashboardLayout>

        </GuestRedirectLayout>
    );
}
import { t } from "i18next";
import { useEffect } from "react";

export default function PolicyPage(props) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <div>
                <div className="container-page">
                    <div className="container">
                        <div className="page-content">
                            <h1 className='title'>{t("business_privacy_title")}</h1>
                            <p>{t("business_extra_policy_date")}</p>

                            <h3>{t("business_privacy1")}</h3>
                            <p>{t("business_privacy11")}</p>
                            <p>{t("business_privacy111")}</p>
                            <p>{t("business_privacy112")}</p>
                            <p>{t("business_privacy113")}</p>
                            <p>{t("business_privacy114")}</p>
                            <p>{t("business_privacy12")}</p>
                            <p>{t("business_privacy13")}</p>
                            <p>{t("business_privacy14")}</p>

                            <h3>{t("business_privacy2")}</h3>
                            <p>{t("business_privacy21")}</p>
                            <p>{t("business_privacy22")}</p>
                            <p>{t("business_privacy23")}</p>
                            <p>{t("business_privacy24")}</p>
                            <p>{t("business_privacy241")}</p>
                            <p>{t("business_privacy242")}</p>
                            <p>{t("business_privacy2421")}</p>
                            <p>{t("business_privacy2422")}</p>
                            <p>{t("business_privacy2423")}</p>
                            <p>{t("business_privacy243")}</p>

                            <h3>{t("business_privacy3")}</h3>
                            <p>{t("business_privacy31")}</p>
                            <p>{t("business_privacy32")}</p>
                            <p>{t("business_privacy33")}</p>
                            <p>{t("business_privacy34")}</p>
                            <p>{t("business_privacy35")}</p>
                            <p>{t("business_privacy351")}</p>

                            <h3>{t("business_privacy4")}</h3>
                            <p>{t("business_privacy41")}</p>
                            <p>{t("business_privacy42")}</p>

                            <h3>{t("business_privacy5")}</h3>
                            <p>{t("business_privacy51")}</p>

                            <h3>{t("business_privacy6")}</h3>
                            <p>{t("business_privacy61")}</p>

                            <h3>{t("business_privacy7")}</h3>
                            <p>{t("business_privacy71")}</p>
                            <p>{t("business_privacy72")}</p>
                            <p>{t("business_privacy73")}</p>

                            <h3>{t("business_privacy8")}</h3>
                            <p>{t("business_privacy81")}</p>

                            <h3>{t("business_privacy9")}</h3>
                            <p>{t("business_privacy91")}</p>

                            <h3>{t("business_privacyA")}</h3>
                            <p>{t("business_privacyA1")}</p>
                            <p>{t("business_privacyA2")}</p>
                            <p>{t("business_privacyA21")}</p>
                            <p>{t("business_privacyA22")}</p>
                            <p>{t("business_privacyA23")}</p>
                            <p>{t("business_privacyA24")}</p>
                            <p>{t("business_privacyA25")}</p>
                            <p>{t("business_privacyA26")}</p>
                            <p>{t("business_privacyA27")}</p>
                            <p>{t("business_privacyA28")}</p>
                            <p>{t("business_privacyA3")}</p>
                            <p>{t("business_privacyA4")}</p>

                            <h3>{t("business_privacyB")}</h3>
                            <p>{t("business_privacyB1_1")}<u><a href={"mailto:" + t("business_privacyB1_2")}>{(t("business_privacyB1_2"))}</a></u>{t("business_privacyB1_3")}</p>
                            <p>{t("business_privacyB2_1")}<u><a style={{ color: 'blue' }} href={t("business_privacyB2_2")}>{(t("business_privacyB2_2"))}</a></u></p>

                            <h3>{t("business_privacyC")}</h3>
                            <p>{t("business_privacyC1")}</p>
                            <p>{t("business_privacyC2")}</p>
                            <p>{t("business_privacyC3")}</p>
                            <p>{t("business_privacyC4_1")}<u><a href={"mailto:" + t("business_privacyC4_2")}>{(t("business_privacyC4_2"))}</a></u></p>
                            <p>{t("business_privacyC5_1")}<u><a href={"tel:" + t("business_privacyC5_2")}>{(t("business_privacyC5_2"))}</a></u></p>
                            <p>{t("business_privacyC6")}</p>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
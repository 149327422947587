import { t } from "i18next";
import { useNavigate } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';

function LoadingRedirect(props) {
  const navigate = useNavigate();

  setTimeout(function () {
    navigate(props.to);
  }, props.delay);

  return (
    <div className={props.big ? 'my-10 pb-5' : null}>
      <div className={props.big ? 'loading big' : 'loading'}>
        <ScaleLoader color="#18A89B" size={props.big ? '40px' : '20px'} /> <span>{!props.text ? null : props.text}</span>
        {/* <CircularProgress size={props.big ? '40px' : '20px'} /> <span>{props.text === undefined ? t("loading") : props.text}</span> */}
      </div>

    </div>
  );
}

export default LoadingRedirect;
//Event, Allowed file types*, Size in bytes, Width in pixels, Height in pixels, Width == Height, React setState
//*https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers
import { t } from 'i18next';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';

export default function FileUploadNames(props) {
  try {
    props.setData(null);
    props.setMessage(null);
    const file = props.uploadEvent.target.files[0];

    if (file === undefined) { return }
    //Check file types
    let typeCheck = false;
    props.fileTypes.forEach(element => {
      if (file.type === element) {
        typeCheck = true;
      }
    });

    if (!typeCheck) {
      return toast.error(t("business_extra_allowed_filetype_invalid"));
    }

    //Check the maximum allowed file size in bytes
    if (file.size > (props.maxSize * 1000)) {
      //File is bigger than allowed size
      return toast(t("business_extra_allowed_filesize2") + " " + props.maxSize + " Kb.");
    }

    // if (file.type.includes("xls")) {
    //   console.log("lmkjqdsfjklmsqdfjklm");
    //   var reader = new FileReader();
    //   reader.onload = function (e) {
    //     var data = props.uploadEvent.target.result;
    //     let readedData = XLSX.read(data, { type: 'binary' });
    //     const wsname = readedData.SheetNames[0];
    //     const ws = readedData.Sheets[wsname];

    //     /* Convert array to json*/
    //     const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
    //     // setFileUploaded(dataParse);
    //     console.log(dataParse);
    //   };
    //   // reader.readAsBinaryString(f)

    // } 

    const reader = new FileReader(); // READ FILE METHOD
    let error2 = false;
    var items2 = [];
    reader.onload = async (e) => {
      const text = (e.target.result);
      var items = text.split(/\n/);
      // REMOVE \r IN ITEMS 
      items.map((item, i) => {
        if (error2 !== false) { return }
        var newItem = item.replace(/(\r\n|\n|\r)/gm, "");
        if (newItem.length > props.maxNameSize) {
          error2 = t("business_jobs_upload_name_length_1") + ": " + props.maxNameSize + ". " + t("business_jobs_upload_multiple_excel_failed");
          return;
        }
        if (i < 2 && items.length === i + 1) {
          error2 = t("business_jobs_upload_multiple_excel_failed");
          return;
        }

        items2.push(newItem);
      })
      if (error2 === false) {
        props.setData(items2);
      }
      if (error2 !== false) {
        props.setMessage([(error2), "danger"]);
      }
    };

    reader.readAsText(file);
  }

  catch (err) {
    console.log(err)
  }
}
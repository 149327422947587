import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";

// the register is split up in multiple steps, these steps are seperately handled accordingly
const useRegisterStepsHandler = (props) => {
    const userStore = useUserStore()
    var apiBody = null;
    var isSideStep = false;
    const apiStep = props.step === 3.1 ? 6 : props.step === 3.2 ? 3 : props.step;

    switch (props.step) {
        case 1:
            apiBody = {
                UserEmail: props.email.trim(),
                UserPassword: props.password,
                LanguageId: userStore.clientData.language.id,
                Iso: userStore.clientData.language.isoCode
            }
            break;
        case 2:
            apiBody = {
                UserId: props.userId,
                UserFirstName: props.formObjectStep2.firstName.trim(),
                UserLastName: props.formObjectStep2.lastName.trim(),
                UserPhone: props.formObjectStep2.phone.trim(),
                UserFunction: !props.formObjectStep2.jobPosition ? null : props.formObjectStep2.jobPosition.label,
                LanguageId: userStore.clientData.language.id,
                Iso: userStore.clientData.language.isoCode
            }
            break;
        case 3:
            apiBody = {
                UserId: props.userId,
                CompanyVatNumber: props.companyvat,
                CompanyName: props.companyname.trim(),
                CompanyReferralCode: props.companyReferralcode.trim(),
                LanguageId: userStore.clientData.language.id,
                Iso: userStore.clientData.language.isoCode
            }
            break;
        case 3.1:
            apiBody = {
                UserId: props.userId,
                UserEmail: props.email.trim(),
            }
            break;
        case 3.2:
            apiBody = {
                UserId: props.userId,
                CompanyVatNumber: props.companyvat,
                CompanyName: props.companyname.trim(),
                // UserRole: props.accountRole,
                CompanyReferralCode: props.companyReferralcode.trim(),
                LanguageId: userStore.clientData.language.id,
                Iso: userStore.clientData.language.isoCode
            }
            break;
        case 4:
            apiBody = {
                UserId: props.userId,
                CompanyId: props.companyId,
                CompanySectorId: props.companySectorId,
                CompanySizeId: props.companySizeId,
                CompanyCountryId: props.companyCountryId,
                CompanyZipCode: props.companyPostalcode.trim(),
                CompanyCity: props.companyCity.trim(),
                CompanyEmail: props.companyEmail.trim(),
                CompanyPhone: props.companyPhone.trim(),
                CompanyAdress: props.companyAddress1.trim(),
                CompanyAdressOptional: props.companyAddress2,
                LanguageId: userStore.clientData.language.id,
                Iso: userStore.clientData.language.isoCode
            }
            break;
        case 5:
            apiBody = {
                UserId: props.userId,
                CompanyId: props.companyId,
                CompanyPlan: props.companyPlan,
                CompanyPo: props.companyPo.trim(),
                CompanyAdress: props.invoiceAddress1.trim(),
                CompanyAdressOptional: props.invoiceAddress2,
                CompanyEmail: props.invoiceEmail.trim(),
                CompanyCity: props.invoiceCity.trim(),
                CompanyCountryId: props.invoiceCountry,
                CompanyZipCode: props.invoicePostalcode,
                CompanyName: props.invoiceCompany.trim(),
                LanguageId: userStore.clientData.language.id,
                Iso: userStore.clientData.language.isoCode
            }
            break;
        default:
            break;

    }

    return useMutation({
        mutationKey: ["mutateRegisterStepsHandler"],
        mutationFn: () => axios.post(`register/${apiStep}`, apiBody),
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {
                var data = res.data.instance;
                switch (props.step) {
                    case 1:
                        props.editUserId(data.userId);
                        props.setStep(2);
                        break;
                    case 2:
                        return props.setStep(3);
                    case 3:
                        if (isSideStep === true && props.setSideStepData !== undefined) {
                            if (props.setExistingData !== undefined) {
                                props.setExistingData(data);
                            }
                            return props.setSideStepData(data);
                        }
                        if (props.setExistingData !== undefined) {
                            props.setExistingData(data);
                        }
                        props.editFormItem("companyId", data.companyId);
                        if (data.completed) { return props.setStep(6); }
                        else { props.setStep(4); }
                        break;
                    case 3.1:
                        return props.setStep(3.2);
                    case 3.2:
                        return props.setStep(6);
                    case 4:
                        return props.setStep(5);
                    case 5:
                        return props.setStep(6);
                    default: break;
                }
            },
        }),
        onError: (res) => handleRequestError({
            response: res,
            customCallback: () => {
                var data = res.response.data.instance;
                // HANDLE MESSAGES
                switch (parseInt(res.response.data.status)) {
                    case 200:
                        props.setMessage(null);
                        break;
                    case 488:
                    case 489:
                        props.setMessage([t("business_registration_exiting"), "danger"]);
                        break;
                    case 491:
                        props.setMessage([t("business_extra_form_incomplete"), "warning"]);
                        break;
                    case 400:
                        props.setMessage([t("business_extra_general_error2"), "danger"]);
                        break;
                    case 482:
                        props.setMessage([t("business_login_unverified"), "danger"]);
                        break;
                    // This comany has restricted email domains, please restart your registration with an allowed email domain. Contact 'data.adminEmail' for more info
                    case 483:
                        props.setStep(3.1);
                        if (props.setSideStepData !== undefined) {
                            if (props.setExistingData !== undefined) {
                                props.setExistingData(data);
                            }
                            return props.setSideStepData(data);
                        }
                        break;
                    default:
                        props.setMessage([t("business_extra_general_error1"), "danger"]);
                        break;
                }
            }
        })

    })
}

export default useRegisterStepsHandler;
import { Button, Form, Input, Popconfirm, Tag } from "antd";
import AccordionContent from "components/layouts/accordions/accordionContent";
import {
    CheckCircleOutlined, ExclamationCircleOutlined
} from '@ant-design/icons';
import { t } from 'i18next';
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { getDateInFormat, handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";
import moment from "moment";
import { sendAxiosPostRequest } from "components/utils/methods/myRequestHandlers";
import { dateFormat } from "components/utils/methods/AppMethods";

export default function JobProbeQuestion(props) {
    // # STORES
    const userStore = useUserStore();

    // # CLIENT STATES
    const [form] = Form.useForm();

    // # SERVER STATES
    const mutateInsertAnswerQuestion = useMutation({
        mutationKey: ["jobProbeInsertAnswerQuestion"],
        mutationFn: (propsFn) => sendAxiosPostRequest({
            route: `v4/insert/business/job/qa`,
            body: {
                UserId: userStore.userData.id,
                ItemId: props.data.id,
                JobId: props.data.jobId,
                JobProbeId: props.data.jobProbeId === "00000000-0000-0000-0000-000000000000" ? null : props.data.jobProbeId,
                Question: propsFn.question,
                Answer: propsFn.answer
            },
            customCallback: (res) => {
                if (res && parseInt(res.data.status) === 200) {
                    props.refetch();
                }
            }
        })
    })
    const mutateHideAnswerQuestion = useMutation({
        mutationKey: ["jobProbeHideAnswerQuestion"],
        mutationFn: (propsFn) => sendAxiosPostRequest({
            route: `v4/update/talentuser/job/probe/hidden`,
            body: {
                Id: props.data.id,
            },
            customCallback: (res) => {
                if (res && parseInt(res.data.status) === 200) {
                    props.refetch();
                }
            }
        })
    })

    const DeleteQuestionBtn = () => <Popconfirm
        title={t("business_company_jobs_questions_overlay_question_delete_reply_confirm")}
        description={t("business_company_jobs_questions_overlay_question_delete_reply_confirm_description")}
        okText={t("business_main_ok")}
        cancelText={t("business_main_cancel")}
        okButtonProps={{ className: "btn hover btn-primary !inline-block" }}
        cancelButtonProps={{ className: "btn hover btn-secondary !inline-block" }}
        onConfirm={() => mutateHideAnswerQuestion.mutate({ id: props.data.id })}
    >
        <Button
            className="btn btn-secondary mr-2 mb-2"
            loading={mutateHideAnswerQuestion.isLoading}
        >
            {t("business_company_jobs_questions_overlay_question_delete")}
        </Button>
    </Popconfirm>


    return <>
        <AccordionContent
            title={<>
                <div className='d-flex align-items-center'>
                    {!props.data.answer ? <ExclamationCircleOutlined className='color-red mr-2' /> : <CheckCircleOutlined className='color-lime mr-2' />} {props.data.question}
                    {
                        props.data.questionFirstName && <>
                            <span className="font-special color-grey mx-2">{` - ${t("business_company_job_probe_questions_overlay_asked_by")} ${props.data.questionFirstName}${props.data.questionLastName && ` ${props.data.questionLastName}`}`}</span>
                        </>
                    }
                    {
                        props.data.createdOn && <span className="font-special color-grey mx-2">{`${props.data.createdOn && `(${getDateInFormat(props.data.createdOn, dateFormat)})`}`}</span>
                    }
                </div>
                <div className="flex align-items-center mt-3">
                    {
                        props.data.answer ? null : <Tag color="red">{t("business_company_job_probe_questions_overlay_unanswered")}</Tag>
                    }
                    {
                        props.jobProbeOptionData && <Tag>{props.jobProbeOptionData.label}</Tag>
                    }
                </div>
            </>
            }
        >
            {
                // ANSWERED QUESTION
                props.data.answer ? <>
                    <div className="mx-3 mt-1 mb-2">
                        <>
                            <div className="d-flex align-items-center mb-2">
                                {
                                    !props.data.answerLogoUrl ?
                                        <div className="avatar default grey small mr-2"></div> :
                                        <div className="avatar grey small mr-2">
                                            <img src={props.data.answerLogoUrl} alt="recruiter-avatar" />
                                        </div>
                                }
                                <span className="font-special color-grey">
                                    {`${props.data.answerFirstName}${props.data.answerLastName && ` ${props.data.answerLastName}`}`}
                                </span>
                            </div>
                            <p className="pt-2">{props.data.answer}</p>
                            <div className="d-flex flex-row align-items-center mt-3">

                                <DeleteQuestionBtn />
                                {/* {
                                            props.data.replyUserId !== userStore.userData.id ? null :
                                                <div onClick={() => setEditing(true)} className="btn btn-primary mb-2 mr-2">
                                                    {t("business_company_jobs_questions_overlay_question_edit")}
                                                </div>
                                        } */}
                                {/* DELETE REPLY */}
                                {/* <Popconfirm
                                    title={t("business_company_jobs_questions_overlay_question_delete_reply_confirm")}
                                    description={t("business_company_jobs_questions_overlay_question_delete_reply_confirm_description")}
                                    okText={t("business_main_ok")}
                                    cancelText={t("business_main_cancel")}
                                    okButtonProps={{ className: "btn hover btn-primary !inline-block" }}
                                    cancelButtonProps={{ className: "btn hover btn-secondary !inline-block" }}
                                    onConfirm={() => mutateHideAnswerQuestion.mutate({ id: props.data.id })}
                                >
                                    <Button
                                        className="btn btn-secondary mr-2 mb-2"
                                        loading={mutateHideAnswerQuestion.isLoading}
                                    >
                                        {t("business_company_jobs_questions_overlay_question_delete_reply")}
                                    </Button>
                                </Popconfirm> */}
                            </div>
                        </>

                    </div>
                </> :
                    // UNANSWERED QUESTION 
                    <Form
                        className='mx-4 my-3'
                        name={"form-job-props.data"}
                        layout="vertical"
                        onFinishFailed={(err) => console.log(err)}
                        onFinish={(values) => mutateInsertAnswerQuestion.mutate({
                            question: values.question,
                            answer: values.reply
                        })}
                    >
                        <Form.Item
                            name={`question`}
                            rules={[{ required: true, message: false }]}
                            label={t("business_company_jobs_questions_overlay_question")}
                            initialValue={props.data.question}
                        >
                            <Input
                                // onChange={(e) => setReplyText(e.target.value)}
                                className='mb-3'
                            />
                        </Form.Item>
                        <Form.Item
                            name={`reply`}
                            rules={[{ required: true, message: false }]}
                            label={t("business_company_jobs_questions_overlay_answer")}
                        >
                            <TextArea
                                showCount
                                placeholder={(props.jobProbeOptionData && props.jobProbeOptionData.data.translationKeyPlaceholder && t(props.jobProbeOptionData.data.translationKeyPlaceholder)) || t("business_company_jobs_questions_overlay_question_reply_placeholder")}
                                maxLength={250}
                                // onChange={(e) => setReplyText(e.target.value)}
                                className='mb-3'
                            />
                        </Form.Item>
                        <div className="d-flex flex-row align-items-center">
                            <Button
                                htmlType='submit'
                                className='btn btn-primary mb-2 mr-2'
                                loading={mutateInsertAnswerQuestion.isLoading}
                            >
                                {t("business_company_jobs_questions_overlay_question_send")}
                            </Button>
                            {/* DELETE QUESTION */}
                            <DeleteQuestionBtn />
                        </div>
                    </Form>
            }
        </AccordionContent>
    </>
}


const usePostJobReply = (props) => {
    const userStore = useUserStore();

    return useMutation({
        mutationKey: ["mutatePostJobReply"],
        mutationFn: () => axios.post(`v1/update/companies/${props.companyId}/jobs/${props.jobId}/tips/${props.tipId}/reply`, {
            Message: props.replyText,
            UserId: userStore.userData.id
        }),
        retry: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, hasAlert: true, isSubmit: true, callback: () => {
                setTimeout(() => {
                    props.getData();
                }, 100);
            }
        }),
        onError: (res) => handleRequestError({ response: res, hasAlert: true, isSubmit: true }),
    })
}

const useJobQuestionDelete = (props) => {
    return useMutation({
        mutationKey: ["mutateJobQuestionDelete"],
        mutationFn: () => axios.get(`v1/delete/companies/${props.companyId}/jobs/${props.jobId}/tips/${props.tipId}`),
        retry: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, hasAlert: true, isSubmit: true, callback: () => {
                setTimeout(() => {
                    props.getData();
                }, 100);
            }
        }),
        onError: (res) => handleRequestError({ response: res, hasAlert: true, isSubmit: true }),
    })
}

const useJobQuestionDeleteReply = (props) => {
    return useMutation({
        mutationKey: ["mutateJobQuestionDeleteReply"],
        mutationFn: () => axios.get(`v1/delete/companies/${props.companyId}/jobs/${props.jobId}/tips/${props.tipId}/reply`),
        retry: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, hasAlert: true, isSubmit: true, callback: () => {
                setTimeout(() => {
                    props.getData();
                }, 100);
            }
        }),
        onError: (res) => handleRequestError({ response: res, hasAlert: true, isSubmit: true }),
    })
}
import { useUserStore } from "components/utils/stores/userStore";
import { Link } from "react-router-dom";
import { t } from "i18next";

export default function LayoutAccount(props) {
    const userStore = useUserStore();
    const title = props.title;
    const subtitle = props.subtitle;

    return <>
        <div id="company">
            <div className="sidebar-container profile-menu" >
                <div className="sidebar-menu">
                    <div className="sidebar-menu-wrapper">
                        <div className="menu-header">
                            <div className="icon icon-person">
                                {t("business_main_account")}
                            </div>
                        </div>
                        <div className="menu-content pb-10">
                            <div className="menu-content-wrapper">
                                <Link to="/account/general">
                                    <div className={`menu-item ${window.location.pathname.includes("/account/general") ? "active" : ''}`}>
                                        {t("business_account_sidebar_account_title")}
                                    </div>
                                </Link>
                                <Link to="/account/security">
                                    <div className={`menu-item ${window.location.pathname.includes("/account/security") ? "active" : ''}`}>
                                        {t("business_account_sidebar_security_title")}
                                    </div>
                                </Link>
                                {
                                    userStore.companyData.isRecruiter ? null :
                                        <Link to="/account/alerts">
                                            <div className={`menu-item ${window.location.pathname.includes("/account/alerts") ? "active" : ''}`}>
                                                {t("business_account_sidebar_notifications_title")}
                                            </div>
                                        </Link>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sidebar-content container">
                    <div className="page-content my-10 mb-15">
                        <div className="content-header mb-4">
                            <div className="d-flex justify-content-between flex-wrap">
                                <h1 className="title pr-4 desktop-lg-only">{title}</h1>
                                <div onClick={() => userStore.toggleClientDataMobileMenuOverlay()} className="lg-only menu-title icon icon-arrow2-left">
                                    <h1>{title}</h1>
                                </div>
                            </div>
                            <p className="subtitle">{subtitle}</p>
                        </div>
                        <>
                            {props.children}
                        </>
                    </div>
                </div>
            </div>
        </div>
    </>
}
import { Tooltip } from "antd";
import { emptyCircleAgencyDbIcon, emptyCircleCompanyDbIcon, emptyCircleNoValueCompanyDbIcon, fullCircleAgencyDbIcon, fullCircleCompanyDbIcon, halfCircleAgencyDbIcon, halfCircleCompanyDbIcon } from "components/utils/icons/iconsDashboard";
import { t } from "i18next";
import { useEffect, useState } from "react";

function Rating(props) {
    // # STORES

    // # CLIENT STATES
    const [fullCircle, setfullCircle] = useState(fullCircleCompanyDbIcon);
    const [halfCircle, sethalfCircle] = useState(halfCircleCompanyDbIcon);
    const [emptyCircle, setemptyCircle] = useState(emptyCircleCompanyDbIcon);
    const [amountOfRating, setAmount] = useState([1, 2, 3, 4, 5]);
    const [ratingClasses, setRatingClasses] = useState("rating");

    useEffect(() => {
        if (props.rating === undefined) { return null }
        var hasHalf = props.rating % 2;
        var roundDown = Math.floor(props.rating / 2);
        var newArray = [];

        // ADD FULL
        for (var i = 1; i <= roundDown; i++) {
            newArray.push(1);
        }

        // ADD EMPTY
        if (hasHalf) {
            newArray.push(0.5);
            for (let i = 1; i <= (5 - (roundDown + 1)); i++) {
                newArray.push(0);
            }
        } else {
            for (let i = 1; i <= (5 - (roundDown)); i++) {
                newArray.push(0);
            }
        }
        setAmount(newArray);
    }, [props.rating]);

    // handle classes & circle svgs
    useEffect(() => {
        var newClasses = "rating";
        if (props.color === "grey" || props.disabled || props.rating === "-1" || props.rating === -1) {
            setemptyCircle(emptyCircleNoValueCompanyDbIcon);
        }
        else if (props.color === "blue") {
            setfullCircle(fullCircleAgencyDbIcon);
            sethalfCircle(halfCircleAgencyDbIcon);
            setemptyCircle(emptyCircleAgencyDbIcon);
        } else {
            setfullCircle(fullCircleCompanyDbIcon);
            sethalfCircle(halfCircleCompanyDbIcon);
            setemptyCircle(emptyCircleCompanyDbIcon);
        }
        if (props.small) {
            newClasses += " small";
        }

        setRatingClasses(newClasses);
    }, [props.amount, props.color])

    return (
        <div className={"rating-item-wrapper d-inline-block d-flex flex-wrap"}>
            <div className={`${ratingClasses} ${props.color || ''}`} data-color={props.color}>
                {
                    !props.rating || parseInt(props.amount) <= 0 || props.rating === "-1" ?
                        amountOfRating.map((item, i) => {
                            if (i >= 5) { return }
                            return <span data-circle-type="none" style={{ marginRight: "1px" }} key={i}>{emptyCircle}</span>

                        }) :
                        amountOfRating.map((item, i) => {
                            if (i >= 5) { return }
                            switch (item) {
                                case 0:
                                    return <span data-circle-type="empty" className="empty" key={i} style={{ maxWidth: "24px", marginRight: "1px" }}>{emptyCircle}</span>;
                                case 0.5:
                                    return <span data-circle-type="half" className="half" key={i} style={{ maxWidth: "24px", marginRight: "1px" }}>{halfCircle}</span>;
                                case 1:
                                    return <span data-circle-type="full" className="full" key={i} style={{ maxWidth: "24px", marginRight: "1px" }}>{fullCircle}</span>;
                                default:
                                    return;
                            }
                        })
                }
            </div>
            {
                props.noamount !== undefined ? null :
                    props.amount !== undefined ?
                        parseInt(props.amount) < 5 || props.amount === null || props.amount === "" ?
                            <div className="pl-1 fs-xsmall font-special d-flex align-items-end color-darkgrey" style={{ paddingBottom: "2px" }}>
                                ({"< 5"}
                                <Tooltip
                                    title={<p className="mb-1">{t("home_tooltip_anonymous_icon")}</p>}
                                    placement="bottom"
                                >
                                    <span className="pl-1 icon-anonymous icon icon-nospace grey fs-small" />
                                </Tooltip>
                                )
                            </div> :
                            <div className="pl-1 fs-xsmall font-special d-flex align-items-end color-darkgrey" style={{ paddingBottom: "2px" }}>
                                ({props.amount})
                            </div> : <div className="pl-1 fs-xsmall font-special d-flex align-items-end color-darkgrey" style={{ paddingBottom: "2px" }}>
                            ({"< 5"}
                            <Tooltip
                                title={<p className="mb-1">{t("business_tooltip_anonymous_icon")}</p>}
                                placement="bottom"
                            >
                                <span className="pl-1 icon-anonymous icon icon-nospace grey fs-small" />
                            </Tooltip>
                            )
                        </div>
            }
        </div >
    );
}

export default Rating;
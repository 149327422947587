import { useQuery } from "@tanstack/react-query";
import { sendAxiosGetRequest } from "components/utils/methods/myRequestHandlers";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";

const useGetJobProbeOptions = (props) => {
    const userStore = useUserStore();
    return useQuery({
        queryKey: ["getCompanySizeOptions"],
        queryFn: () => sendAxiosGetRequest({
            route: `v2/generic/catalogues/Probes`,
            callback: (res) => {
                let allItems = [];
                var data = res.data.instance;
                (data).sort((a, b) => a.sortIndex > b.sortIndex ? 1 : -1).forEach(itemLoop => {
                    allItems.push({
                        id: itemLoop.id,
                        sortIndex: itemLoop.sortIndex,
                        value: itemLoop.id,
                        label: t(itemLoop.translationKey),
                        name: itemLoop.name,
                        data: itemLoop
                    })
                });

                // props.setData(allItems);
                return allItems
            }
        }),
        retry: 2,
        refetchOnWindowFocus: false,
    })
}

export default useGetJobProbeOptions;
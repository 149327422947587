import { useQuery } from "@tanstack/react-query";
import { sendAxiosPostRequest } from "components/utils/methods/myRequestHandlers";
import { useUserStore } from "components/utils/stores/userStore";

export const useGetDbGeneralData = (propsFn) => {
    const userStore = useUserStore();
    const props = propsFn || {};

    return useQuery({
        queryKey: ['getDbGeneralData'],
        queryFn: () => sendAxiosPostRequest({
            // route: `v3/business/select/businesses/${userStore.companyData.id}/dashboards/general`,
            route: `v5/select/business/dashboard`,
            body: {
                CeKey: props.ceKey || null,
                BusinessId: userStore.companyData.id,
                FilterUserId: props.filterUserId || null,
                FilterPhaseId: props.filterPhaseId || null,
                IsRelation: props.isRelation || false,
            },
            customCallback: (res) => {
                console.log(res);
                if (!res || res.data.status === 290) {
                    return null;
                } else {
                    return res.data.instance
                }
            }
        }),
        refetchOnWindowFocus: false,
        enabled: props.enabled || false,
    })
}
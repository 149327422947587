import { useQuery } from "@tanstack/react-query";
import { sendAxiosPostRequest } from "components/utils/methods/myRequestHandlers";
import { useUserStore } from "components/utils/stores/userStore";

export const useGetDbExperienceScores = (propsFn) => {
    const userStore = useUserStore();
    const props = propsFn || {};

    return useQuery({
        queryKey: ['getDbExperienceScoreData'],
        queryFn: () => sendAxiosPostRequest({
            // route: `v5/select/business/scores`,
            route: `v3/business/select/businesses/${userStore.companyData.id}/dashboards/scores`,
            body: {
                CeKey: props.ceKey || null,
                BusinessId: props.businessId
            }
        }),
        refetchOnWindowFocus: false,
        enabled: props.enabled || false,
    })
}
import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import FileUploadNames from '../../utils/methods/FileUploadNames';
import AlertMessage from '../alerts/alert-message';

export default function UploadFileNames(props) {
    const [showData, setShowData] = useState(null);
    const [message, setMessage] = useState(null);

    useEffect(() => {
        setShowData(props.currentData);
    }, [props.currentData])

    return (
        <div className='upload-logo'>
            <div className='logo-upload px-4'>
                {
                    props.currentData === null ?
                        <div>
                            <label>
                                <input
                                    type="file"
                                    id="inputfile"
                                    name="file"
                                    className='hidden'
                                    onChange={(e) => {
                                        FileUploadNames({
                                            uploadEvent: e,
                                            fileTypes: props.fileTypes,
                                            maxSize: props.max,
                                            square: true,
                                            setData: props.setData,
                                            setMessage: setMessage,
                                            maxNameSize: props.maxNameSize
                                        });
                                        // props.setIsBlocking(true);
                                    }}
                                    accept={
                                        props.fileTypesText === undefined ? null :
                                            props.fileTypesText.map((item, i) => {
                                                if ((props.fileTypesText.length) === (i + 1)) {
                                                    return item;
                                                } else {
                                                    return item + ", ";
                                                }
                                            })
                                    }
                                />
                                <div className='btn btn-primary'>
                                    {t("business_main_upload_file")}
                                </div>
                            </label>
                            {
                                !message ? null :
                                    <AlertMessage message={message} setMessage={setMessage} />
                            }
                        </div>
                        :
                        <div>
                            <div className='mb-2 fs-small fw-bold'>{t("business_main_job")} {t("business_main_jobtitle").toLowerCase()}:</div>
                            <ul className='pl-0'>
                                {
                                    showData === null ? null :
                                        Array.from(showData).map((item, i) => {
                                            if (i > 3) {
                                                return;
                                            }
                                            if (i === 3) {
                                                return <li className='lime icon icon-check list-unstyled fs-xsmall'>{"..."}</li>;
                                            }
                                            else {
                                                return <li className='lime icon icon-check list-unstyled fs-xsmall'>{item}</li>;
                                            }
                                        })
                                }
                            </ul>
                            <div className="link fs-small d-inline-block mb-2" onClick={() => props.setData(null)}>Remove</div>
                        </div>
                }
                {/* <span className='fs-xsmall'>{t("business_company_general_logo_allowed")}{prosp}</span> */}
                {
                    props.currentData === null ?
                        <span className='fs-xsmall'>
                            {t("business_extra_allowed_filesize1")}: {props.max / 1000}Mb |{" "}
                            {t("business_extra_allowed_filetypes")}:{" "}
                            {
                                props.fileTypesText === undefined ? null :
                                    props.fileTypesText.map((item, i) => {
                                        if ((props.fileTypes.length) - 1 === (i)) {
                                            return item;
                                        } else {
                                            return item + ", ";
                                        }
                                    })
                            }
                        </span>
                        : null
                }
            </div>
        </div>
    );
}
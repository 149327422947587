import moment from "moment";

// PROPS
// - message
// - from
// - date

// used to show a classic message "blob"
export default function Message(props) {
    // # UTILS
    var dateFormat = !props.date ? null : new moment(props.date);
    var local = !props.date ? null : dateFormat.utc(dateFormat).local();
    return <>
        <div className="message">
            <div className="mb-1 from">{props.from}{`${local ? " - " + local.format("HH:mmu DD/MM/YYYY") : ""}`}</div>
            <div className="w-100 disabled textarea" disabled>{props.message}</div>
        </div>
    </>
}
import { Button, Checkbox, Form, Input, Select } from 'antd';
import useEditJob from 'components/requests/company/useEditJob';
import { useUserStore } from 'components/utils/stores/userStore';
import { t } from 'i18next';
import produce from 'immer';
import { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';

export default function EditJobOverlay(props) {
    // # STORES
    const userStore = useUserStore();

    // # CLIENT STATES
    const [form] = Form.useForm();
    const [formObject, setFormObject] = useState({
        name: "",
        status: 0, // 1 = closed | 0 = open
        assignedTo: [],
        highlighted: !props.jobItem ? false : props.jobItem.jobIsHighlight
    })
    const [assignedOptions, setAssignedOptions] = useState(props.team);

    // # UTILS
    const editFormItem = (key, val, setInit) => {
        if (setInit) {
            form.setFieldsValue({
                [key]: val
            });
        }
        setFormObject(produce(draft => {
            draft[key] = val;
        }))
    }

    // # SERVER STATES
    const mutateJob = useEditJob({ setOverlay: props.setOverlay, getJobs: props.getJobs })
    useEffect(() => {
        setAssignedOptions(props.team)
    }, [props.team])

    // set defaults
    useEffect(() => {
        if (!props.jobItem) { return }
        const job = props.jobItem;
        editFormItem("name", job.jobName, true);
        editFormItem("status", job.jobIsClosed ? 1 : 0, true);
        editFormItem("highlighted", job.jobIsHighlight, true);

        // get selected team members
        var selectedIds = [];
        if (job.assignedToUserIds) {
            if (job.assignedToUserIds.length !== 0) {
                job.assignedToUserIds.forEach(assignedUser => {
                    props.team.forEach(element => {
                        if (element.value.toLowerCase() === assignedUser.toLowerCase()) {
                            selectedIds.push(element);
                        }
                    });
                });
            }
        }
        editFormItem("assignedTo", selectedIds, true);
        // filter selected out of options

    }, [props.jobItem])

    useEffect(() => {
        if (props.overlayState) {
            var elem = document.querySelector('body');
            elem.style.overflow = "hidden"
        } else {
            var elem = document.querySelector('body');
            elem.style.overflow = "auto"
        }
    }, [props.overlayState]);

    return (
        <div>
            {
                props.overlayState === false ? null :
                    // props.overlayState.length <= 0 ? null :
                    <div className="overlay">
                        <div className="overlay-content small d-flex align-items-center pt-2">
                            <div className="container px-1">
                                <span className='close' onClick={() => props.setOverlay(false)}></span>
                                <div className="mb-4">
                                    <h1>{t("business_company_jobs_edit_title")}:</h1>
                                    {/* <p>{t("business_company_jobs_delete_description")}<br /></p> */}
                                </div>
                                <Form
                                    form={form}
                                    className='form'
                                    name="form-jobs-edit"
                                    layout="vertical"
                                    onFinish={() => mutateJob.mutate({
                                        formObject,
                                        jobId: !props.jobItem ? null : props.jobItem.jobId,
                                    })}
                                >
                                    <ReactTooltip place='bottom' className='tooltip' />
                                    <Form.Item
                                        label={t("business_main_name")}
                                        name="name"
                                        rules={[{ required: true, message: false }]}
                                    >
                                        <Input
                                            placeholder={t("...")}
                                            onChange={(e) => editFormItem("name", e.target.value)}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="assignedTo"
                                        label={<>{t("business_main_assigned_to")}<span className="icon-info fs-small default ml-1" data-tip={t("business_tooltip_jobs_upload_assign")} /></>}
                                    >

                                        <Select
                                            mode="multiple"
                                            size={"large"}
                                            placeholder="-"
                                            onSelect={(value, option) => editFormItem("assignedTo", [...formObject.assignedTo, option])}
                                            onDeselect={(value, option) => editFormItem("assignedTo", formObject.assignedTo.filter(item => item.value !== value))}
                                            style={{ width: '100%' }}
                                            options={assignedOptions}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={t("business_main_jobstatus")}
                                        name="status"
                                        rules={[{ required: true, message: false }]}
                                    >
                                        <Select
                                            onSelect={(value, option) => {
                                                if (value === 1) { editFormItem("highlighted", false) }
                                                else {
                                                    // form doesnt update value for some reason when formObject state changes so gotta change highlighted here
                                                    form.setFieldValue("highlighted", formObject.highlighted)
                                                }
                                                editFormItem("status", value);
                                            }}
                                            options={[
                                                { value: 0, label: t("business_main_open") },
                                                { value: 1, label: t("business_main_closed") },
                                            ]}
                                        />
                                    </Form.Item>
                                    {/* {
                                        Boolean(parseInt(formObject.status)) ? null :
                                            <Form.Item
                                                name="highlighted" valuePropName="checked"
                                            >
                                                <Checkbox
                                                    checked={formObject.highlighted}
                                                    onChange={(e) => {
                                                        console.log(e.target.checked);
                                                        editFormItem("highlighted", e.target.checked)
                                                    }}
                                                >
                                                    {t("business_job_edit_highlight_label")}
                                                </Checkbox>
                                            </Form.Item>
                                    } */}

                                    <div className="mt-4 d-flex flex-row flex-wrap">

                                        <Button
                                            loading={mutateJob.isLoading}
                                            className='mb-2 mr-2 btn btn-primary'
                                            htmlType='submit'
                                        >
                                            {t("business_main_save")}
                                        </Button>
                                        <div className="button">
                                            <div onClick={() => props.setOverlay(false)} className="btn-secondary btn">
                                                {t("business_main_back")}
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
}
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";

const useGetUserFunctionOptions = (props) => {
    const userStore = useUserStore();
    return useQuery({
        queryKey: ["getUserFunctionOptions"],
        queryFn: () => axios.get("v2/generic/catalogues/FunctionsUser"),
        retry: 2,
        refetchOnWindowFocus: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {
                var data = res.data.instance;
                var newItems = [];
                var selectedItem = [];
                (data).forEach(item => {
                    if (!item.nameTranslations) { return }
                    Array.prototype.forEach.call(item.nameTranslations, function (itemTranslation, i) {
                        if (userStore.clientData.language.id === itemTranslation.languageId) {
                            return selectedItem = {
                                value: item.id,
                                label: itemTranslation.text,
                                translations: item.nameTranslations,
                                data: item
                            };
                        }
                    });
                    if (selectedItem.length !== 0) {
                        newItems = [...newItems, (selectedItem)];
                        selectedItem = [];
                    }
                });

                props.setData(newItems);
            }
        }),
        onError: (res) => handleRequestError({ response: res }),
    })
}

export default useGetUserFunctionOptions;
import { InfoCircleFilled, QuestionCircleFilled } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { Button, Divider, Form, Input, Tabs } from "antd";
import axios from "axios";
import chalk from 'chalk';
import InputHtml from "components/forms/input/input-html";
import GuestRedirectLayout from "components/layouts/page-layouts/guest-redirect";
import useGetCompanyPromises from "components/requests/company/useGetCompanyPromises";
import useGetPromisesContent from "components/requests/data/useGetPromisesContent";
import useGetPromisesLanguages from "components/requests/data/useGetPromisesLanguages";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import $ from 'jquery';
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import Loading from "../../components/animations/loading";
import AlertMessage from "../../components/forms/alerts/alert-message";
import NoData from "../../components/layouts/no-data/nodata";
import { handleRequestError, handleRequestSuccess, hasPermission } from "../../components/utils/methods/MyMethods";
import envir from '../../env.json';
import LayoutCompany from "./layout-company";
import { useLanguagesStore } from "components/utils/stores/languagesStore";

// the promise functionality (tabs, states etc) need a rework because they are not well made and hard to understand and maintain..
export default function CompanyPromisePage(props) {
    // # STORE
    const userStore = useUserStore();
    const languagesStore = useLanguagesStore();

    // # UTILS
    const navigate = useNavigate();
    const maxChar = 1250; // ALSO CHANGE IN INPUT HTML COMPONENT
    const objectCore = "898E6212-9F23-46E2-9243-1E3E3B108E97";
    const objectTips = "32E6922B-B896-4C37-AFD0-DCA0A6A0740B";
    const objectSocialValues = "5F233DA7-C7E3-41A5-BE02-AD5B0EE23907";

    // # CLIENT STATES
    const [message, setMessage] = useState(null);
    const [tabs, setTabs] = useState([]);
    const [form] = Form.useForm();
    // COMPANY PROMISES
    const [promiseContent, setPromiseContent] = useState(null); // CONTENT - TOOLTIPS
    const [currentPromiseContent, setCurrentPromiseContent] = useState(null)

    const [currentPromiseCore, setPromiseCore] = useState([]);
    const [currentPromiseSocialValues, setPromiseSocialValues] = useState([]);
    const [currentPromiseTips, setPromiseTips] = useState([]);
    const [currentPromises, setPromises] = useState([]); // DATA - COMPANY
    const [currentLangs, setLangs] = useState([]);
    const [cCounter, setCounter] = useState(0);
    const [coreVideo, setCoreVideo] = useState("");
    const [tipsVideo, setTipsVideo] = useState("");
    // PERMISSIONS
    const [PM_PromiseView, setPM_PromiseView] = useState(false);
    const [PM_PromiseEdit, setPM_PromiseEdit] = useState(false);
    const [PM_PlanView, setPM_PlanView] = useState(false);
    const [PM_ProcedureView, setPM_ProcedureView] = useState(false);
    const [PM_RecruitmentHistoryView, setPM_RecruitmentHistoryView] = useState(false);

    // # SERVER STATES
    const mutateCompanyPromises = useEditCompanyPromises({
        setCounter, cCounter, coreVideo, tipsVideo, currentPromiseCore, currentPromiseTips, maxChar, currentPromiseSocialValues
    })
    const { refetch: companyPromisesRefetch, isRefetching: companyPromisesIsFetching, isLoading: companyPromisesIsLoading } = useGetCompanyPromises({
        setCoreVideo: setCoreVideo,
        setTipsVideo: setTipsVideo,
        setPromiseCore: setPromiseCore,
        setPromiseSocialValues: setPromiseSocialValues,
        setPromiseTips: setPromiseTips,
        setPromises,
        category: envir.Variables.PromiseTag_Company
    }) // gets the promise values of the company
    const { refetch: promisesContentRefetch, isError: promiseContentIsError } = useGetPromisesContent({ setData: setPromiseContent, category: envir.Variables.PromiseTag_Company }); // gets the promise content
    const { } = useGetPromisesLanguages({ setData: setLangs }); // gets all available promise languages

    function editPromises(e) {
        let maxCharBool = false;
        console.log(currentPromiseCore, currentPromiseTips)

        // Function to check the length of text and show a warning if it exceeds the limit
        const checkTextLength = (text) => {
            const length = $(text)
                .contents()
                .filter(function () {
                    // Exclude script and style tags from the filter
                    return this.localName !== 'script' && this.localName !== 'style';
                })
                .text()
                .replace(/\s+/g, '').length;

            if (length > maxChar) {
                maxCharBool = true;
                throw toast.warn(t('business_extra_character_limit_1000'));
            }
        };

        // Check the text length for currentPromiseCore translations
        if (currentPromiseCore.translations !== undefined) {
            currentPromiseCore.translations.forEach((item) => {
                checkTextLength(item.text);
            });
        }

        // Check the text length for currentPromiseSocialValues translations
        // if (currentPromiseSocialValues.translations !== undefined) {
        //     currentPromiseSocialValues.translations.forEach((item) => {
        //         checkTextLength(item.text);
        //     });
        // }

        // Check the text length for currentPromiseTips translations
        if (currentPromiseTips.translations !== undefined) {
            currentPromiseTips.translations.forEach((item) => {
                checkTextLength(item.text);
            });
        }

        // If maxCharBool is still false, execute the mutation
        if (!maxCharBool) {
            mutateCompanyPromises.mutate();
        }
    }

    function getPromiseData() {
        console.log(chalk.bgBrightBlue("Function getPromiseData"))
        setPromises([]);
        setLangs([]);

        setTimeout(() => {
            if (!userStore.clientData.checkedRememberMe) { return }
            if (!userStore.companyData) { return }
            companyPromisesRefetch();
            promisesContentRefetch();
        }, 200);
    }

    // handle promise language tabs & content and put it in a state used by tabs component
    useEffect(() => {
        if (currentLangs.length === 0) { return }
        if (!promiseContent) { return }
        if (companyPromisesIsFetching || companyPromisesIsLoading) { return }
        console.log("promise useeffect")
        console.log({ currentLangs, currentPromises, promiseContent, currentPromiseCore, currentPromiseTips })

        // handle promise videos defaults
        form.setFieldsValue({
            "video-core": currentPromises.length === 0 ? "" : currentPromises.filter(promise => promise.valueTypeId.toLowerCase() === objectCore.toLowerCase())[0].videoUrl,
            "video-tips": currentPromises.length === 0 ? "" : currentPromises.filter(promise => promise.valueTypeId.toLowerCase() === objectTips.toLowerCase())[0].videoUrl
        })

        newTabsHandler();
    }, [currentLangs, currentPromises, promiseContent])


    useEffect(() => {
        if (currentLangs.length === 0) { return }
        if (!promiseContent) { return }
        if (companyPromisesIsFetching || companyPromisesIsLoading) { return }
        newTabsHandler()
    }, [userStore.clientData])

    useEffect(() => {
        if (!userStore.companyData) { return }
        if (!userStore.companyData) {
            navigate("/");
        }

        if (userStore.companyData.subscriptionData.int1 === 10 && userStore.companyData.subscriptionData.guid1 === "0e63167e-848d-42aa-9365-864318aa89d0") {
            navigate("/");
        }
    }, [userStore.companyData]);

    useEffect(() => {
        if (!userStore.userData) { return }
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_VALUES", setPM_PromiseView);
        hasPermission(userStore.userData.permissionsData, "PM_EDIT_VALUES", setPM_PromiseEdit);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_SUBSCRIPTIONS", setPM_PlanView);

        // PERMISSION_COMPANY_HANDLER
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_VALUES", setPM_PromiseView, navigate);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_PROCEDURES", setPM_ProcedureView);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_RECRUITHISTORY", setPM_RecruitmentHistoryView);
    }, [userStore.userData]);

    // START AT TOP OF PAGE ON LOAD
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function newTabsHandler() {
        var newItems = [];
        const currentLangId = userStore.clientData.language.id;

        currentLangs.map((lang, i) => {
            return newItems.push({
                key: i + 1,
                forceRender: true,
                label: <>{`${lang.name}`}</>,
                children: <div className="mt-3 mx-3">
                    {
                        promiseContent === null ? <Loading text={null} /> :
                            promiseContent === undefined ? "null" :
                                (promiseContent).map((subitem, ie) => {
                                    const selectedCategoryId = subitem.id.toLowerCase();
                                    // filter items through current translation
                                    const selectedTitle = (subitem.nameTranslations).filter(item => item.languageId.toLowerCase() === currentLangId)[0].text;
                                    const selectedSuggestions = (subitem.suggestionTranslations).filter(item => item.languageId.toLowerCase() === currentLangId)[0].text;
                                    const selectedExamples = (subitem.exampleTranslations).filter(item => item.languageId.toLowerCase() === currentLangId)[0].text;

                                    return <div key={ie} className="mb-4">
                                        <div className="item-info d-flex align-items-end mb-3">
                                            <div>
                                                {selectedTitle}
                                            </div>
                                            <div className="ml-2">
                                                <ReactTooltip className="tooltip" place="bottom" html />
                                                <span className="mr-1" data-tip={selectedSuggestions}>
                                                    <QuestionCircleFilled className="color-darkgrey" />
                                                </span>
                                                <span className="mr-1" data-tip={selectedExamples}>
                                                    <InfoCircleFilled className="color-darkgrey" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="item-edit">
                                            {
                                                // RENDER CORRECT INPUT DEPENING ON LOOP ITEM  
                                                (selectedCategoryId).toUpperCase() === (objectCore).toUpperCase() ?
                                                    <InputHtml
                                                        // CORE VALUES
                                                        setItem={setPromiseCore}
                                                        currentValues={currentPromiseCore}
                                                        defaultValue={currentPromiseCore}
                                                        currentLang={lang.id}
                                                        currentObject={objectCore}
                                                        currentLangISO={lang.isoCode}
                                                        disabled={!PM_PromiseEdit}
                                                    /> :
                                                    (selectedCategoryId).toUpperCase() === (objectTips).toUpperCase() ?
                                                        <InputHtml
                                                            // TIPS
                                                            setItem={setPromiseTips}
                                                            currentValues={currentPromiseTips}
                                                            defaultValue={currentPromiseTips}
                                                            currentLang={lang.id}
                                                            currentObject={objectTips}
                                                            currentLangISO={lang.isoCode}
                                                            disabled={!PM_PromiseEdit}
                                                        /> :
                                                        (selectedCategoryId).toUpperCase() === (objectSocialValues).toUpperCase() ?
                                                            <InputHtml
                                                                // SOCIAL VALUES
                                                                setItem={setPromiseSocialValues}
                                                                currentValues={currentPromiseSocialValues}
                                                                defaultValue={currentPromiseSocialValues}
                                                                currentLang={lang.id}
                                                                currentObject={objectSocialValues}
                                                                currentLangISO={lang.isoCode}
                                                                disabled={!PM_PromiseEdit}
                                                            /> :
                                                            null
                                            }
                                        </div>
                                    </div>
                                })
                    }
                </div>
            })
        })
        setTabs(newItems);
    }

    return (
        <GuestRedirectLayout>
            <LayoutCompany
                title={t("business_company_promise_title")}
                subtitle={t("business_company_promise_subtitle")}
            >
                <div className="card mt-3">
                    {
                        !PM_PromiseView ? <div className="d-flex justify-content-center my-5"><NoData wise="cross" text={t("business_extra_permission_required")} /></div> :
                            currentLangs.length <= 0 ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                                promiseContentIsError ? <NoData error /> :
                                    !promiseContent ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                                        companyPromisesIsFetching || companyPromisesIsLoading ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :

                                            <Form
                                                name="form-company-promises"
                                                layout="vertical"
                                                form={form}
                                                onFinish={() => editPromises()}
                                            >
                                                {
                                                    !message ? null :
                                                        <AlertMessage message={message} setMessage={setMessage} />
                                                }
                                                {
                                                    currentLangs.length <= 0 ? null :
                                                        <div className="my-4 mx-4">
                                                            <p>{t("business_company_promise_videos_description")}</p>
                                                            <Form.Item
                                                                name="video-core"
                                                                label={t("business_company_promise_core_video_label")}
                                                            >
                                                                <Input
                                                                    allowClear
                                                                    name="video-core"
                                                                    onChange={(e) => setCoreVideo(e.target.value)}
                                                                    placeholder={t("business_company_promise_core_video_placeholder")}
                                                                    disabled={!PM_PromiseEdit}
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="video-tips"
                                                                label={t("business_company_promise_tips_video_label")}
                                                            >
                                                                <Input
                                                                    allowClear
                                                                    name="video-tips"
                                                                    onChange={(e) => setTipsVideo(e.target.value)}
                                                                    placeholder={t("business_company_promise_tips_video_placeholder")}
                                                                    disabled={!PM_PromiseEdit}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                }
                                                <Divider className="my-8" />
                                                <div className="mx-4">
                                                    <p>{t("business_company_promise_description")}</p>
                                                    {
                                                        tabs.length === 0 ? null :
                                                            <Tabs
                                                                onChange={() => newTabsHandler()}
                                                                items={tabs}
                                                                defaultActiveKey="1"
                                                                destroyInactiveTabPane={true}
                                                            />
                                                    }
                                                    {
                                                        !PM_PromiseEdit ? null :
                                                            <div className="container">
                                                                <div className="btns-right container mt-4" >
                                                                    <div className="mr-2 mb-2">
                                                                        <Button
                                                                            loading={mutateCompanyPromises.isLoading}
                                                                            htmlType="submit"
                                                                            className="btn-primary btn"
                                                                        >
                                                                            {t("business_main_save")}
                                                                        </Button>
                                                                    </div>
                                                                    <div className="mr-2 mb-2">
                                                                        <div onClick={() => getPromiseData()} className="btn-secondary btn d-inline-block">{t("business_main_cancel")}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            </Form>
                    }
                </div>
            </LayoutCompany>
        </GuestRedirectLayout>
    );
}


const useEditCompanyPromises = (props) => {
    const userStore = useUserStore();

    return useMutation({
        mutationKey: ["mutateCompanyPromises"],
        mutationFn: () => axios.post(`v3/update/companies/${userStore.companyData.id}/promises`, {
            CompanyId: userStore.companyData.id,
            UserId: userStore.userData.id,
            // CompanyValues: [
            Types: [
                // CERTAIN INPUT FIELD (ex. social values)
                {
                    TypeId: "898E6212-9F23-46E2-9243-1E3E3B108E97",
                    Translations: props.currentPromiseCore.translations === undefined ? null :
                        ((props.currentPromiseCore.translations).map((item, i) => {
                            console.log(props.currentPromiseCore.translations);
                            return ({
                                LanguageId: item.languageId,
                                // ObjectId: item.objectId,
                                Text: item.value === undefined ? item.text : item.value
                            });
                        })),
                    VideoUrl: props.coreVideo
                },
                {
                    TypeId: "5F233DA7-C7E3-41A5-BE02-AD5B0EE23907",
                    Translations: props.currentPromiseSocialValues.translations === undefined ? null :
                        ((props.currentPromiseSocialValues.translations).map((item, i) => {
                            return ({
                                LanguageId: item.languageId,
                                // ObjectId: item.objectId,
                                Text: item.value === undefined ? item.text : item.value
                            });
                        }))
                },

                {
                    TypeId: "32E6922B-B896-4C37-AFD0-DCA0A6A0740B",
                    Translations: props.currentPromiseTips.translations === undefined ? null :
                        ((props.currentPromiseTips.translations).map((item, i) => {
                            return ({
                                LanguageId: item.languageId,
                                // ObjectId: item.objectId,
                                Text: item.value === undefined ? item.text : item.value
                            });
                        })),
                    VideoUrl: props.tipsVideo
                }
            ]
        }),
        onSuccess: (res) => handleRequestSuccess({
            response: res, hasAlert: true, isSubmit: true, callback: () => {
                props.setCounter(props.cCounter + 1)
            }
        }),
        onError: (res) => handleRequestError({ response: res, hasAlert: true, isSubmit: true, }),
    })
}
import { useMutation } from "@tanstack/react-query";
import { Button, Divider, Form, Input, Popconfirm, Select } from "antd";
import axios from "axios";
import InputPhone from "components/forms/input/input-ant-phone";
import PrefixIcon from "components/forms/input/prefix-icon";
import NoData from "components/layouts/no-data/nodata";
import useGetAccountInfo from "components/requests/account/useGetAccountInfo";
import useGetCompanyData from "components/requests/company/useGetCompanyData";
import useGetCountryOptions from "components/requests/data/useGetCountryOptions";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import { useEffect, useState } from "react";
import Loading from "../../components/animations/loading";
import GuestRedirectLayout from "../../components/layouts/page-layouts/guest-redirect";
import { getBase64, handleEditFormObjectItem, handleRequestError, handleRequestSuccess, hasPermission, isValid } from "../../components/utils/methods/MyMethods";
import LayoutAccount from "./layout-account";
import useEditAccountAvatar from "components/requests/account/useEditAccountAvatar";
import Dragger from "antd/es/upload/Dragger";
import { FileUploadSingleConditionalsCheck } from "components/modules/upload-handlers/FileUploadSingle";
import ImgCrop from "antd-img-crop";
import { handleUploadPicture } from "components/utils/methods/AppMethods";
import { toast } from "react-toastify";

export default function AccountGeneralRecruiterPage(props) {
    // # STORES
    const userStore = useUserStore();

    // # CLIENT STATES
    const [form] = Form.useForm();
    const [formObject, setFormObject] = useState({
        avatar: null,
        companyVat: "",
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        site: "",
        country: "",
        zipCode: "",
        city: "",
        address1: "",
        address2: ""
    });
    const [allCountries, setAllCountries] = useState(false); // LIST VALUES
    const [companyData, setCompanyData] = useState(false);

    // # UTILS
    const editFormItem = (key, val, isInit) => handleEditFormObjectItem({
        key: key,
        value: val,
        setDefault: isInit,
        form: form,
        setFormObject
    })
    const editAva = (val) => editFormItem("avatar", val);
    const editPhone = (val) => editFormItem("phone", val);

    // # SERVER STATES
    const { data: accountData, refetch: accountInfoRefetch, isRefetching: accountInfoIsRefetching, isLoading: accountInfoIsLoading, isError } = useGetAccountInfo({
        editFormItem
    });
    const { refetch: companyDataRefetch } = useGetCompanyData({ setData: setCompanyData });
    const mutateRecruiterAccountInfo = useEditRecruiterAccountInfo({ formObject });
    const { } = useGetCountryOptions({ setData: setAllCountries });
    const mutateAccountAvatar = useEditAccountAvatar({ setPreview: editAva })

    const onFinish = (file) => {
        if (file) {
            handleUploadPicture(file, 10, 0, 0, "business/user/avatar").then((result) => {
                mutateAccountAvatar.mutate({ LogoUrl: result })
            }).catch(() => {
                toast.error(t("business_extra_general_error2"));
                return;
            });
        }
        else {
            toast.error(t("business_extra_general_error2"));
            return;
        }
    }

    // START AT TOP OF PAGE ON LOAD
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!userStore.clientData.checkedRememberMe) { return }
        if (!userStore.userData) { return } // SOMETIMES WE GET USERID NULL 
        companyDataRefetch();
        accountInfoRefetch()
    }, [userStore.userData, t.language, userStore.clientData, userStore.companyData])

    useEffect(() => {
        if (!isValid([companyData, allCountries], [null, false, undefined])) { return }
        var selected = allCountries.filter(country => country.id.toLowerCase() === companyData.locationData.countryId.toLowerCase())[0]
        editFormItem("email", companyData.email, true);
        editFormItem("companyVat", companyData.registrationNumber, true);
        editFormItem("country", selected.id, true);
        editFormItem("zipCode", companyData.locationData.zipCode, true);
        editFormItem("address1", companyData.locationData.address1, true);
        editFormItem("address2", companyData.locationData.address2, true);
        editFormItem("city", companyData.locationData.city, true);
        editFormItem("site", companyData.website, true);
    }, [companyData, allCountries]);

    return (
        <GuestRedirectLayout>
            <LayoutAccount
                title={t("business_account_title")}
                subtitle={t("business_account_subtitle")}
            >

                <div className="mt-3 card">
                    {
                        accountInfoIsLoading || accountInfoIsRefetching ? null :
                            isError ? null :
                                <div className="mb-5">
                                    <ImgCrop
                                        cropShape="round"
                                        aspect={1}
                                        rotationSlider
                                        maxZoom={8}
                                        beforeCrop={async (file, filelist) => {
                                            const result = await FileUploadSingleConditionalsCheck({
                                                file: file,
                                                imageTypes: ["image/jpeg", "image/png"],
                                                maxSize: 1000,
                                                CompanyId: userStore.companyData.id,
                                            });
                                            console.log(result)
                                            return result
                                        }}
                                        modalOk={t("business_main_save")}
                                        modalCancel={t("business_main_cancel")}
                                    >
                                        <Dragger
                                            name="file"
                                            accept="image/png, image/jpeg"
                                            className='upload-logo upload-avatar'
                                            action={null}
                                            onRemove={() => editAva(undefined)}
                                            multiple={false}
                                            showUploadList={false}
                                            // onPreview={onPreview}
                                            beforeUpload={(file) => { onFinish(file); return false; }}
                                        >
                                            <div className="flex flex-row align-items-center justify-content-center">
                                                <div className="ant-upload-drag-icon logo-preview mx-4">

                                                    {

                                                        !formObject.avatar ?
                                                            <span className="default icon-account fs-xxlarge d-flex align-items-center justify-content-center"></span> : <img
                                                                alt="company-logo"
                                                                // onClick={() => mutateCompanyLogo.mutate({ logo: "" })}
                                                                style={{ padding: "0px" }}
                                                                className='my-2'
                                                                src={formObject.avatar} />
                                                    }
                                                    {
                                                        !formObject.avatar ? null :
                                                            <div
                                                                onClick={(event) => event.stopPropagation()}
                                                            >
                                                                <Popconfirm
                                                                    title={t("business_avatar_remove_overlay_title")}
                                                                    description={t("business_avatar_remove_overlay_description")}
                                                                    onConfirm={() => {
                                                                        mutateAccountAvatar.mutate({ image: "" })
                                                                    }}
                                                                    okText={t("business_company_logo_remove_overlay_yes")}
                                                                    cancelText={t("business_company_logo_remove_overlay_no")}
                                                                    okButtonProps={{ className: "btn-primary btn d-inline-block", style: { minHeight: "15", minWidth: "auto", padding: "0px 15px" } }}
                                                                    cancelButtonProps={{ className: "btn-secondary btn d-inline-block", style: { minHeight: "15", minWidth: "auto", padding: "0px 15px" } }}
                                                                >
                                                                    <div
                                                                        className='link-generic relative'
                                                                        style={{ zIndex: 100, userSelect: "contain" }}
                                                                        onClick={(event) => {
                                                                            event.stopPropagation();
                                                                        }}
                                                                    >
                                                                        {t("business_main_delete")}
                                                                    </div>
                                                                </Popconfirm>
                                                            </div>
                                                    }

                                                </div>
                                                <div style={{ maxWidth: 500 }}>
                                                    <p className="ant-upload-text mb-1" style={{ textAlign: "left" }}>{!formObject.avatar ? t("business_account_logo_upload") : t("business_account_logo_upload")}</p>
                                                    <p className="ant-upload-hint mb-1" style={{ textAlign: "left" }}>{t("business_company_general_logo_allowed")}</p>
                                                </div>
                                            </div>
                                        </Dragger>
                                    </ImgCrop>
                                </div>
                    }
                    {
                        accountInfoIsLoading || accountInfoIsRefetching ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                            isError ? <NoData error /> /*#VDG */ :
                                companyData === null ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                                    companyData.length <= 0 ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                                        <Form
                                            form={form}
                                            layout="vertical"
                                            onFinish={() => mutateRecruiterAccountInfo.mutate()}
                                            className="my-4 px-4"
                                        >
                                            <Form.Item
                                                name="companyVat"
                                                label={t("business_main_vatnumber")}
                                                rules={[{ required: true, message: false }]}
                                            >
                                                <div data-tip={t("business_tooltip_vatnumber")}>
                                                    <Input
                                                        value={formObject.companyVat}
                                                        disabled
                                                    />
                                                </div>
                                            </Form.Item>
                                            <Form.Item
                                                name="email"
                                                label={t("business_main_email")}
                                                rules={[{ required: true, message: false, type: "email" }]}
                                            >
                                                <div data-tip={t("business_tooltip_account_email")}>
                                                    <Input
                                                        type="mail"
                                                        value={formObject.email}
                                                        prefix={<PrefixIcon value="icon-mail fs-small" />}
                                                        disabled
                                                    />
                                                </div>
                                            </Form.Item>
                                            <div className="d-flex flex-wrap">
                                                <Form.Item
                                                    className="w-50 mobile-friendly pr-2"
                                                    name="firstName"
                                                    label={t("business_main_firstname")}
                                                    rules={[{ required: true, message: false }]}
                                                >
                                                    <Input
                                                        placeholder={t("business_main_firstname")}
                                                        onChange={(e) => editFormItem("firstName", e.target.value)}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    className="w-50 mobile-friendly"
                                                    name="lastName"
                                                    label={t("business_main_lastname")}
                                                    rules={[{ required: true, message: false }]}
                                                >
                                                    <Input
                                                        placeholder={t("business_main_lastname")}
                                                        onChange={(e) => editFormItem("lastName", e.target.value)}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="d-flex flex-wrap">
                                                <Form.Item
                                                    className="w-50 mobile-friendly pr-2"
                                                    name="phone"
                                                    label={t("business_main_phone")}
                                                    rules={[{ required: true, message: false }]}
                                                >
                                                    <InputPhone
                                                        onChange={editPhone}
                                                        required
                                                        name="phone"
                                                        defaultValue={!accountData ? "" : accountData.data.instance.phone}
                                                        form={form}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    className="w-50 mobile-friendly"
                                                    name="site"
                                                    label={t("business_main_website")}
                                                    rules={[{ required: false, message: false }]}
                                                >
                                                    <Input
                                                        placeholder={t("business_extra_placeholder_website")}
                                                        onChange={(e) => editFormItem("site", e.target.value)}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <Divider />
                                            <>
                                                <Form.Item
                                                    name="country"
                                                    label={t("business_main_country")}
                                                    rules={[{ required: true, message: false }]}
                                                >
                                                    <Select
                                                        placeholder={t("-")}
                                                        options={allCountries}
                                                        onSelect={(value, option) => editFormItem("country", value)}
                                                    />
                                                </Form.Item>
                                                <div className="d-flex flex-wrap">
                                                    <Form.Item
                                                        name="zipCode"
                                                        className="w-50 mobile-friendly pr-2"
                                                        label={t("business_main_zipcode")}
                                                        rules={[{ required: false, message: false }]}
                                                    >
                                                        <Input
                                                            placeholder={t("business_main_zipcode")}
                                                            onChange={(e) => editFormItem("zipCode", e.target.value)}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="city"
                                                        className="w-50 mobile-friendly"
                                                        label={t("business_main_city")}
                                                        rules={[{ required: true, message: false }]}
                                                    >
                                                        <Input
                                                            placeholder={t("business_main_city")}
                                                            onChange={(e) => editFormItem("city", e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <Form.Item
                                                    name="address1"
                                                    label={t("discard_register_step_company_adres1")}
                                                    rules={[{ required: true, message: false }]}
                                                >
                                                    <Input
                                                        placeholder={t("discard_register_step_company_adres1")}
                                                        onChange={(e) => editFormItem("address1", e.target.value)}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name="address2"
                                                    label={t("discard_register_step_company_adres2")}
                                                    rules={[{ required: false, message: false }]}
                                                >
                                                    <Input
                                                        placeholder={t("discard_register_step_company_adres2")}
                                                        onChange={(e) => editFormItem("address2", e.target.value)}
                                                    />
                                                </Form.Item>
                                            </>
                                            <div className="btns-right">
                                                <Button
                                                    htmlType="submit"
                                                    className="btn btn-primary mr-2"
                                                    loading={mutateRecruiterAccountInfo.isLoading}
                                                >
                                                    {t("business_main_save")}
                                                </Button>
                                                <div onClick={() => accountInfoRefetch()} className="btn-secondary btn d-inline-block">{t("business_main_cancel")}</div>
                                            </div>

                                        </Form>
                    }
                </div>
            </LayoutAccount>
        </GuestRedirectLayout>
    );
}

const useEditRecruiterAccountInfo = (props) => {
    const userStore = useUserStore();

    return useMutation({
        mutationKey: ["mutateRecruiterAccountInfo"],
        mutationFn: () => axios.post("account/info/recruiter/update", {
            UserId: userStore.userData.id,
            CompanyId: userStore.companyData.id,
            FirstName: props.formObject.firstName,
            LastName: props.formObject.lastName,
            Phone: props.formObject.phone,
            Adress1: props.formObject.address1,
            Adress2: props.formObject.address2,
            City: props.formObject.city,
            CompanyWebsite: props.formObject.site,
            CountryId: props.formObject.country,
            ZipCode: props.formObject.zipCode,
        }),
        retry: 2,
        onSuccess: (res) => handleRequestSuccess({ response: res, isSubmit: true }),
        onError: (res) => handleRequestError({ response: res })
    })
}
import { useUserStore } from "components/utils/stores/userStore";
import envir from 'env.json';
import { t } from "i18next";
import { Fragment, useEffect, useState } from "react";
import { ChevronDown, ChevronUp, PlusCircle } from 'react-feather';
import CompanyLogo from "../company-logo/company-logo";
import { handleRenderBlock } from "./blocks-data";
import cloneDeep from 'lodash/cloneDeep';

export default function SjabloonComponent(props) {

    return <>
        <div
            style={{
                display: "inline-block",
                position: "relative",
            }}
            ref={props.sjabloonRef || undefined}
        >
            <div
                id={props.isExample ? "wise-sjabloon" : ''}
                className={`sjabloon ${props.isEdit ? "editing" : ""} ${props.isExample ? "example" : ''} ${props.className || ''}`}
                style={{
                    width: props.headerBlock.states.width || 550,
                }}
            >
                <SjabloonHeader
                    id="header"
                    logoUrl={props.logoUrl}
                    block={props.headerBlock}
                    activeBlock={props.activeBlock}
                    blocks={props.blocks}
                    setBlocks={props.setBlocks}
                    setActiveSettings={props.setActiveSettings}
                    setActiveBlock={props.setActiveBlock}
                    sjabloonType={props.sjabloonType}
                />
                <div className="sjabloon-content">
                    <div className="wrapper">
                        {
                            props.blocks && [...props.blocks].sort((a, b) => a.sort > b.sort ? 1 : -1).map((block, i) => {
                                if (block.blockType === "header") { return }
                                return <Fragment key={i}>
                                    <SjabloonBlokWrapper
                                        edit
                                        block={block}
                                        activeBlock={props.activeBlock}
                                        blocks={props.blocks}
                                        setBlocks={props.setBlocks}
                                        setActiveSettings={props.setActiveSettings}
                                        setActiveBlock={props.setActiveBlock}
                                    >
                                        {handleRenderBlock(block)}
                                    </SjabloonBlokWrapper>
                                </Fragment>
                            })
                        }
                        {
                            !props.isEdit ? null :
                                <div onClick={() => {
                                    props.setActiveSettings("add");
                                    props.setActiveBlock(undefined)
                                }} className="blok blok-add mx-3 flex align-items-center flex-row  justify-content-center">
                                    <span className="mr-2">
                                        <PlusCircle size={25} />
                                    </span>
                                    <span>
                                        {t("business_sjabloon_block_add")}
                                    </span>
                                </div>

                        }
                    </div>

                </div>
                <div className="sjabloon-footer pb-3">
                    <div className="flex justify-content-center mb-1 mt-3">
                        {/* <img alt="wise logo" src="/assets/img/wise.png" style={{ height: 20 }} /> */}
                        <img alt="wise logo" src="/assets/img/home-logo.png" style={{ height: 42 }} />
                    </div>
                    {
                        !props.shortcode ? null :
                            <div className="flex justify-content-center flex-row mx-2" >
                                {/* <span className="font-special color-darkgrey mr-1">{t("business_sjabloon_footer_via")}</span> */}
                                <a
                                    href={`${envir.Variables.WiseHomeUrl + "/company/"}${props.shortcode}`}
                                    target="_blank" rel="noreferrer"
                                    className="font-special text-center color-darkgrey mx-2" style={{ textDecoration: "underline", wordBreak: "break-all" }}>
                                    {/* https://wisepeople.be/company/sethub */}
                                    {(envir.Variables.WiseHomeUrl) + "/company/"}{props.shortcode}
                                </a>
                            </div>
                    }
                </div>
            </div>
        </div>
    </>
}


function SjabloonBlokWrapper(props) {
    const editIcon = <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.4863 4.73699L9.23066 1.51507L10.3031 0.441096C10.5968 0.147032 10.9576 0 11.3855 0C11.8135 0 12.174 0.147032 12.4671 0.441096L13.5396 1.51507C13.8332 1.80913 13.9864 2.16405 13.9992 2.57984C14.012 2.99562 13.8715 3.35028 13.5779 3.64384L12.4863 4.73699ZM11.3755 5.86849L3.25563 14H0V10.7397L8.11992 2.60822L11.3755 5.86849Z" fill="#18A89B" />
    </svg>;

    const deleteIcon = <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.714286 12.4444C0.714286 13.3 1.35714 14 2.14286 14H7.85714C8.64286 14 9.28571 13.3 9.28571 12.4444V3.11111H0.714286V12.4444ZM10 0.777778H7.5L6.78571 0H3.21429L2.5 0.777778H0V2.33333H10V0.777778Z" fill="#18A89B" />
    </svg>;

    const [isEditing, setIsEditing] = useState(props.activeBlock && props.activeBlock.id === props.block.id ? true : false);

    const resortBlocks = (dir) => {
        const currentIndex = props.blocks.findIndex((item) => item.id === props.block.id);
        // clone object to prevent circular json errors and unchangable object errors
        const newBlocks = cloneDeep(props.blocks);
        props.setActiveSettings(undefined);
        props.setActiveBlock(undefined);

        if (currentIndex >= 0) {
            const newIndex = dir === "up" ? currentIndex - 1 : currentIndex + 1;

            if (newIndex >= 0 && newIndex < props.blocks.length) {

                // Swap the sort values of the current and new blocks
                const currentSort = newBlocks[currentIndex].sort;
                newBlocks[currentIndex].sort = newBlocks[newIndex].sort;
                newBlocks[newIndex].sort = currentSort;

                // Sort the blocks array based on the updated sort values
                return newBlocks.sort((a, b) => a.sort > b.sort ? 1 : -1);
            }
        }
        return newBlocks.sort((a, b) => a.sort > b.sort ? 1 : -1);
    };


    function deleteBlockById(blockId) {
        const blocks = props.blocks.map((block) => ({ ...block })); // Create a new array with copies of blocks

        // Find the index of the block to delete
        const blockIndex = blocks.findIndex((block) => block.id === blockId);

        // If the block is not found, return the original blocks array
        if (blockIndex === -1) {
            return props.blocks;
        }

        // Remove the block with the given ID
        blocks.splice(blockIndex, 1);

        // Update sort numbers for remaining blocks
        var newBlocks = [];
        console.log(blocks)
        blocks.forEach((block, index) => {
            newBlocks.push({
                ...block,
                sort: index + 1
            })
        });

        props.setBlocks(newBlocks);
        return newBlocks;
    }

    useEffect(() => {
        setIsEditing(props.activeBlock && props.activeBlock.id === props.block.id ? true : false);
    }, [props.activeBlock])

    return <>
        <div
            // data-block={JSON.stringify(props.block)}
            className={`blok ${props.edit ? 'mx-3' : ''} ${isEditing ? 'active' : ''} ${props.block.blockType === "space" ? 'p-0 m-0 flex h-100 justify-content-center align-items-center' : ''}`}
        >
            {
                props.edit ? <>
                    <div className="flex align-items-center actions">
                        <div className="mx-1">
                            {
                                // ? cant edit these blocks so dont show edit button for it
                                props.block.editable === false ? null :
                                    <div className={`hover path-fill action-edit action ${isEditing ? 'editing' : ''}`}
                                        onClick={() => {
                                            props.setActiveSettings("loading");
                                            props.setActiveBlock(props.block)
                                            setTimeout(() => {
                                                console.log(`Edit \n\n`, props.block)
                                                props.setActiveSettings(props.block.blockType);
                                            }, 100);
                                        }}>{editIcon}</div>
                            }

                        </div>
                        {
                            props.block.deletable === false ? null :
                                <div className="mx-1">
                                    <div className="hover path-fill action action-delete" onClick={() => {
                                        deleteBlockById(props.block.id);
                                        props.setActiveSettings(undefined);
                                    }}>{deleteIcon}</div>
                                </div>
                        }
                    </div>
                </> : null
            }
            {
                !props.edit ? null :
                    !props.blocks || (props.blocks).filter(item => item.blockType !== "header").length <= 1 ? null :
                        <div style={{ position: "absolute", top: 1, right: -32 }} className="flex flex-column align-items-center">
                            <ChevronUp onClick={() => {
                                props.setActiveSettings(undefined);
                                props.setActiveBlock(undefined);
                                props.setBlocks(resortBlocks("up"))
                            }}
                                size={22}
                                className={`${props.block.sort === 1 ? 'color-lightgrey' : 'color-grey hover'} action`}
                            />
                            <ChevronDown onClick={() => {
                                props.setActiveSettings(undefined);
                                props.setActiveBlock(undefined);
                                props.setBlocks(resortBlocks("down"))
                            }}
                                size={22}
                                className={`${props.block.sort === (props.blocks).filter(item => item.blockType !== "header").length ? 'color-lightgrey' : 'color-grey hover'} action`}
                            />
                        </div>

            }
            {props.children}
        </div>
    </>
}

function SjabloonHeader(props) {
    const userStore = useUserStore();
    const [isEditing, setIsEditing] = useState(props.activeBlock && props.activeBlock.id === props.block.id ? true : false);

    useEffect(() => {
        setIsEditing(props.activeBlock && props.activeBlock.id === props.block.id ? true : false);
    }, [props.activeBlock])

    const editingStyleProps = {
        border: "3px dashed green"
    }

    return <>
        <div
            className={`sjabloon-head blok-head ${isEditing ? 'active' : ''}`}
            style={{
                height: parseInt(props.block.states.height),
                backgroundImage: props.block.states.backgroundImage ? `url(${props.block.states.backgroundImage})` : "",
                backgroundColor: props.block.states.backgroundImage ? '' : props.block.states.backgroundColor,
                // margin: props.block.states.backgroundImage ? -2 : null
            }}>
            <div className="actions" style={{
                top: 5,
                right: 5
            }}>
                <div className={`action hover path-fill ${isEditing ? 'editing' : ''}`} onClick={() => {
                    props.setActiveSettings("loading");
                    props.setActiveBlock(props.block)
                    setTimeout(() => {
                        props.setActiveSettings("header");
                    }, 100);
                }}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.4863 4.73699L9.23066 1.51507L10.3031 0.441096C10.5968 0.147032 10.9576 0 11.3855 0C11.8135 0 12.174 0.147032 12.4671 0.441096L13.5396 1.51507C13.8332 1.80913 13.9864 2.16405 13.9992 2.57984C14.012 2.99562 13.8715 3.35028 13.5779 3.64384L12.4863 4.73699ZM11.3755 5.86849L3.25563 14H0V10.7397L8.11992 2.60822L11.3755 5.86849Z" fill="#18A89B" />
                    </svg>
                </div>
            </div>
            <div className="sjabloon-avatar">
                <div title={t("business_company_general_logo_title")}>
                    {
                        props.sjabloonType === 'recruiter' ?
                            <CompanyLogo logoUrl={props.logoUrl || null} avatar />
                            :
                            <CompanyLogo logoUrl={props.logoUrl || null} />
                    }
                </div>
            </div>
        </div>
    </>
}
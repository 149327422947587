import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";

const useGetCountryOptions = (props) => {
    return useQuery({
        queryKey: ["getCountryOptions"],
        queryFn: () => axios.get("v2/generic/catalogues/Countries"),
        retry: 2,
        refetchOnWindowFocus: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {
                let allItems = [];
                var data = res.data.instance;
                (data.sort((a, b) => a.name > b.name ? 1 : -1)).forEach(itemLoop => {
                    if (itemLoop.length !== 0) {
                        allItems = [...allItems, { value: itemLoop.id, id: itemLoop.id, label: itemLoop.name, data: itemLoop }];
                    }
                });

                props.setData(allItems);
            }
        }),
        onError: (res) => handleRequestError({ response: res }),
    })
}

export default useGetCountryOptions;
import { ResponsiveLine } from '@nivo/line';
import produce from 'immer';
import { useEffect, useState } from "react";

export default function LineChart(props) {
    const [data, setData] = useState(null);
    const [filteredState, setFilteredState] = useState([]); // keeps track of the lines that are toggled on/off + has original data to 

    useEffect(() => {
        var myData = null;
        if (typeof props.data === "function") {
            var result = props.data();
            setData(result);
            myData = result;
        } else {
            setData(props.data);
            myData = props.data;
        }
        // set the filtered state (timeout for myData)
        setTimeout(() => {
            var filtered = [];
            myData.forEach(element => {
                filtered.push({
                    id: element.id,
                    data: element.data,
                    hidden: false
                })
            });
            setFilteredState(filtered);
        }, 200);
    }, [props.data])


    function toggleLine(id) {
        const selectedItem = filteredState.find(item => item.id === id);
        if (!selectedItem) { return console.error("No selected item found") }
        var isCurrentlyHidden = selectedItem.hidden;
        // update filter state
        setFilteredState(prevState =>
            produce(prevState, draftState => {
                const itemToUpdate = draftState.find(item => item.id === id);
                if (itemToUpdate) {
                    itemToUpdate.hidden = !isCurrentlyHidden;
                }
            })
        );
        // update the data state
        if (isCurrentlyHidden) {
            // unhide - add regular data that was originally given
            setData(prevState =>
                produce(prevState, draftState => {
                    const itemToUpdate = draftState.find(item => item.id === id);
                    if (itemToUpdate) {
                        itemToUpdate.data = selectedItem.data;
                    }
                })
            );
        } else {
            // hide - all Y values has to be null
            setData(prevState =>
                prevState.map(item => {
                    if (item.id === id) {
                        return {
                            ...item,
                            data: item.data.map(dataItem => ({
                                ...dataItem,
                                y: null
                            }))
                        };
                    }
                    return item;
                })
            );
        }
    }

    return (
        !data ? null :
            <div className="relative" style={{ height: props.height || 470, width: "100%", maxWidth: props.maxWidth || 970, margin: "auto", marginBottom: "20px" }}>
                <ResponsiveLine
                    data={data}
                    margin={{ top: 85, right: 10, bottom: 45, left: 30 }}
                    padding={{ right: 30 }}
                    // curve='monotoneX'
                    xScale={{
                        format: '%b %y',
                        precision: 'month',
                        type: 'time',
                        useUTC: false
                    }}
                    xFormat="time:%b %y"
                    yScale={{
                        type: 'linear',
                        min: props.minYscale || "auto",
                        max: props.maxYscale || 'auto',
                        stacked: false,
                        reverse: false
                    }}
                    yFormat=" >-.2f"
                    crosshairType="cross"
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 10,
                        tickRotation: -30,
                        format: '%b %y',
                        legend: props.xLabel || 'X',
                        legendOffset: 50,
                        legendPosition: 'middle'
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: props.yLabel || 'Y',
                        legendOffset: -50,
                        legendPosition: 'middle'
                    }}
                    pointSize={9}
                    pointColor={{ from: 'color', modifiers: [] }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: 'serieColor', modifiers: [] }}
                    pointLabelYOffset={-12}
                    enableSlices="x"
                    useMesh={true}
                    colors={["#18A89B", "#F19023", "#247BB6"]}
                    legends={[
                        {
                            onClick: (res) => toggleLine(res.id),
                            anchor: 'top-right',
                            direction: 'column',
                            justify: false,
                            translateX: 0,
                            translateY: -80,
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemWidth: 180,
                            itemHeight: 18,
                            itemOpacity: 0.7,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                />
            </div>
    );
}

import { Form, Input, Select } from "antd";
import { Fragment, useEffect, useState } from "react";
import { t } from "i18next";

// PROPS
// - form
// - onChange -> set(val)
// - ?defaultValue
// - ?placeholder

export default function InputPhone(props) {
    // # UTILS
    const countryList = [
        { id: 1, sort: 1, isoCode: "BE", name: "Belgium", value: "+32" },
        { id: 2, sort: 2, isoCode: "FR", name: "France", value: "+33" },
        { id: 3, sort: 3, isoCode: "NL", name: "Netherlands", value: "+31" },
        { id: 3, sort: 3, isoCode: "EN", name: "UK", value: "+44" },
    ]

    // # CLIENT STATES
    const [number, setNumber] = useState("");
    const [country, setCountry] = useState(countryList[0].isoCode);

    const doChange = (key, val) => {
        if (props.onChange) {
            if (key === "number") {
                props.onChange(countryList.filter(item => item.isoCode === country)[0].value + val);
            } else {
                props.onChange(val + number);
            }
        }
    }


    useEffect(() => {
        if (!props.defaultValue) { return setCountry("BE") }
        var number = props.defaultValue.replace("+", "");
        var getCountry = countryList.filter(item => parseInt(item.value) === parseInt(number.slice(0, 2)));
        var cVal = getCountry.length === 0 ? "BE" : getCountry[0].isoCode;
        var nVal = number.slice(2);
        setCountry(cVal)
        setNumber(nVal)
        if (props.form) {
            props.form.setFieldsValue({
                "phone-suffix": cVal
            })
            if (props.name) {
                props.form.setFieldsValue({
                    [props.name]: nVal
                })
            } else {
                props.form.setFieldsValue({
                    "phone": nVal
                })
            }
        }

    }, [props.defaultValue])

    return <>
        <Input.Group compact className="d-flex flex-row antd-custom-phone">
            <Input
                addonBefore={
                    <Form.Item name="phone-suffix" noStyle className="bg-white" rules={[{ required: props.required || false, message: false }]}>
                        <Select
                            placeholder="-"
                            style={{ minWidth: "90px" }}
                            onSelect={(value, option) => { setCountry(value); doChange("country", parseInt(option.id)); }}
                            disabled={props.disabled}
                            value={country}
                            required
                        >
                            {
                                (countryList.sort((a, b) => a.sort > b.sort)).map((item, i) => <Fragment key={i}>
                                    <Select.Option value={item.isoCode} id={item.value}>{`${item.isoCode} ${item.value}`}</Select.Option>
                                </Fragment>)
                            }

                        </Select>
                    </Form.Item>
                }
                onWheel={() => document.activeElement.blur()}
                placeholder={!props.placeholder ? t("business_main_phone") : props.placeholder}
                disabled={props.disabled}
                style={{ borderRadius: "5px" }}
                value={number}
                min={0}
                onChange={(e) => { setNumber(e.target.value); doChange("number", e.target.value) }}
                type="number"
            />
        </Input.Group>
    </>
}
import Loading from "components/animations/loading";
import DashboardAgency from "components/layouts/dashboard/basic/db-agency";
import DashboardPremium from "components/layouts/dashboard/basic/db-premium";
import NoData from "components/layouts/no-data/nodata";
import GuestRedirectLayout from "components/layouts/page-layouts/guest-redirect";
import { handleAllPermissions } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function NewDashboardPage(props) {
    const userStore = useUserStore();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const [isPremiumPlan, setIsPremiumPlan] = useState(null);
    const [PM_DashboardView, setPM_DashboardView] = useState(false);

    useEffect(() => {
        if (!userStore.userData) { return }
        handleAllPermissions(userStore.userData.permissionsData, [
            ["PM_VIEW_DASHBOARD", setPM_DashboardView],
        ])
    }, [userStore.userData]);

    useEffect(() => {
        if (!userStore.flagData || !userStore.flagData.toggleDashboardAdvanced) { return setIsPremiumPlan(false) }
        setIsPremiumPlan(userStore.flagData.toggleDashboardAdvanced)
    }, [userStore.flagData])

    return (
        <GuestRedirectLayout>
            <div className="container-page">
                {
                    // LOADING
                    !PM_DashboardView ? <div className="page-content"><NoData permission /></div> :
                        urlParams.get("type") && urlParams.get("type") === "ce" ?
                            urlParams.get("ref") ? <>
                                <DashboardAgency ceKey={urlParams.get("ref")} />
                            </> : <NoData className="my-5" text={t("business_dashboard_cekey_missing_key")} /> :
                            userStore.flagData !== undefined && userStore.flagData.isAgency ?
                                <DashboardAgency /> :
                                isPremiumPlan === null ? <Loading center /> :
                                    <DashboardPremium />
                    // isPremiumPlan ? <DashboardPremium /> :
                    //     <DashboardBasic />
                }
            </div>
        </GuestRedirectLayout>
    );
}
import { t } from "i18next";
import { ScaleLoader, BarLoader, MoonLoader } from 'react-spinners';

export default function Loading(props) {

  return <>
    <div className={props.center ? "d-flex justify-content-center w-100" : null}>
      <div
        data-origin={!props.origin ? "loading" : `loading-${props.origin}`}
        className={`${props.fullscreen ? 'my-10 pb-5 loading-container fullscreen' : props.big ? 'mt-3 mb-5 loading-container' : "loading-container"} ${props.className || ''}`}>
        {
          props.type === "circle" ?
            <div className="loading" style={props.style || {}}>
              <MoonLoader color="#18A89B" size={props.size ? props.size : props.big ? '40px' : '30px'} />
            </div> :
            props.fullscreen ?
              <div className="loading mt-1" style={props.style || {}}>
                {/* <TraceSpinner  size={"70"} frontColor={"#F19023"} /> */}
                {/* <PushSpinner size={70} color={"#18A89B"} /> */}
                <BarLoader
                  color="#18A89B"
                  height={10}
                  width={150} />
                {/* <MagicSpinner size={"90"} color={"#F19023"} /> */}
              </div>
              :
              props.color ?
                <div className={props.big ? 'loading big ' + props.color : 'loading ' + props.color} style={props.style || {}}>
                  <ScaleLoader color="#18A89B" size={props.size ? props.size : props.big ? '40px' : '20px'} /> <span>{!props.text ? null : props.text}</span>
                </div> :
                <div className={props.big ? 'loading big' : 'loading'} style={props.style || {}}>
                  <ScaleLoader color="#18A89B" size={props.size ? props.size : props.big ? '40px' : '20px'} /> <span>{!props.text ? null : props.text}</span>
                </div>
        }
      </div>
    </div>
  </>
}
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";

const useGetPromisesLanguages = (props) => {
    const userStore = useUserStore();
    return useQuery({
        queryKey: ["getPromisesLanguages"],
        queryFn: async () => await axios.get("v2/generic/catalogues/Languages"),
        retry: 2,
        enabled: !!userStore.companyData,
        refetchOnWindowFocus: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {
                var data = res.data.instance;
                props.setData(data);
            }
        }),
        onError: (res) => handleRequestError({ response: res })
    })
}

export default useGetPromisesLanguages;
import { ResponsiveBar } from '@nivo/bar';
import { useEffect, useState } from "react";

export default function BarChart(props) {
    const [data, setData] = useState(null);

    useEffect(() => {
        if (typeof props.data === "function") {
            var result = props.data();
            setData(result);
        } else {
            setData(props.data);
        }
    }, [props.data]);

    return (
        !data ? null :
            <div className="relative" style={{ height: props.height || 470, width: "100%", maxWidth: props.maxWidth || 1370, margin: "auto", marginBottom: "20px" }}>
                <ResponsiveBar
                    data={data}
                    keys={props.keys}
                    groupMode={props.groupMode || "stacked"}
                    layout={props.layout || "vertical"}
                    indexBy={props.indexBy || "date"}
                    margin={{ top: 50, right: 250, bottom: 110, left: 60 }}
                    padding={0.3}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    axisTop={null}
                    colors={({ id, data }) => String(data[`${id}Color`])}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 10,
                        tickRotation: -30,
                        legend: props.xAxis || '?',
                        legendPosition: 'middle',
                        legendOffset: 42
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: props.yAxis || '?',
                        legendPosition: 'middle',
                        legendOffset: -50
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                1.6
                            ]
                        ]
                    }}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                    role="application"
                />
            </div>
    );
}

// used at home page - score reaction accordion
export const scoreReactionIcon = <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="17.5" cy="26.5" r="17.5" fill="#1B6A63" />
    <g filter="url(#filter0_d_2418_11129)">
        <circle cx="24" cy="20" r="20" fill="#18A89B" />
    </g>
    <path d="M32.2526 11.2195L32.7817 11.7553C33.5117 12.4945 33.5184 13.687 32.7951 14.4186L23.6901 23.6336L20.5534 24.537C20.3619 24.5906 20.157 24.566 19.9835 24.4687C19.8101 24.3713 19.6824 24.2092 19.6284 24.0178C19.5882 23.8802 19.5877 23.734 19.6267 23.5961L20.5392 20.3961L29.6201 11.2053C29.7928 11.0314 29.9983 10.8937 30.2248 10.8001C30.4513 10.7065 30.6941 10.6589 30.9391 10.6602C31.1842 10.6614 31.4265 10.7114 31.652 10.8073C31.8775 10.9032 32.0817 11.0439 32.2526 11.2195ZM22.6534 12.1803C23.0667 12.1803 23.4017 12.5195 23.4017 12.9378C23.4024 13.0367 23.3836 13.1347 23.3463 13.2263C23.309 13.3179 23.2541 13.4013 23.1846 13.4717C23.1151 13.542 23.0324 13.598 22.9413 13.6363C22.8501 13.6747 22.7523 13.6948 22.6534 13.6953H19.6601C18.8334 13.6953 18.1634 14.3736 18.1634 15.2095V24.2978C18.1634 25.1345 18.8334 25.8128 19.6601 25.8128H28.6401C29.4667 25.8128 30.1376 25.1345 30.1376 24.2978V21.2686C30.1376 20.8503 30.4726 20.5111 30.8859 20.5111C31.2992 20.5111 31.6342 20.8503 31.6342 21.2695V24.2978C31.6342 25.9711 30.2934 27.3278 28.6401 27.3278H19.6601C18.0067 27.3278 16.6667 25.9711 16.6667 24.2978V15.2095C16.6667 13.537 18.0067 12.1803 19.6601 12.1803H22.6534Z" fill="white" />
    <defs>
        <filter id="filter0_d_2418_11129" x="0" y="0" width="48" height="48" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2418_11129" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2418_11129" result="shape" />
        </filter>
    </defs>
</svg>

export const sjabloonAccordionIcon = <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="17.8977" cy="27.1009" r="17.8977" fill="#1B6A63" />
    <g filter="url(#filter0_d_3094_14470)">
        <circle cx="24.5454" cy="20.4545" r="20.4545" fill="#18A89B" />
    </g>
    <path d="M26.591 15.3409H18.4092C17.8667 15.3409 17.3465 15.5564 16.9629 15.94C16.5793 16.3236 16.3638 16.8439 16.3638 17.3864V21.4773C16.3638 22.0198 16.5793 22.54 16.9629 22.9236C17.3465 23.3072 17.8667 23.5227 18.4092 23.5227H19.432V27.6136C19.432 27.8849 19.5397 28.145 19.7315 28.3368C19.9233 28.5286 20.1834 28.6364 20.4547 28.6364H22.5001C22.7714 28.6364 23.0315 28.5286 23.2233 28.3368C23.4151 28.145 23.5229 27.8849 23.5229 27.6136V23.5227H26.591L31.7047 27.6136V11.25L26.591 15.3409ZM36.307 19.4318C36.307 21.1807 35.3251 22.7659 33.7501 23.5227V15.3409C35.3149 16.108 36.307 17.6932 36.307 19.4318Z" fill="white" />
    <defs>
        <filter id="filter0_d_3094_14470" x="0.0908203" y="0" width="48.9092" height="48.9062" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3094_14470" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3094_14470" result="shape" />
        </filter>
    </defs>
</svg>
export const filesAccordionIcon = <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6896_15872)">
        <path d="M17.8977 44.9985C27.7823 44.9985 35.7954 36.9855 35.7954 27.1008C35.7954 17.2162 27.7823 9.20312 17.8977 9.20312C8.01307 9.20312 0 17.2162 0 27.1008C0 36.9855 8.01307 44.9985 17.8977 44.9985Z" fill="#1B6A63" />
        <g filter="url(#filter0_d_6896_15872)">
            <path d="M24.5453 40.909C35.842 40.909 44.9998 31.7512 44.9998 20.4545C44.9998 9.15779 35.842 0 24.5453 0C13.2486 0 4.09082 9.15779 4.09082 20.4545C4.09082 31.7512 13.2486 40.909 24.5453 40.909Z" fill="#18A89B" />
            <path d="M33.774 13.2747L29.9103 9.23625C29.8385 9.16127 29.7533 9.10181 29.6595 9.06127C29.5657 9.02074 29.4651 8.99992 29.3636 9H21.6364C21.2265 9 20.8334 9.17019 20.5436 9.47314C20.2537 9.77608 20.0909 10.187 20.0909 10.6154V12.2308H18.5455C18.1356 12.2308 17.7425 12.401 17.4527 12.7039C17.1628 13.0068 17 13.4177 17 13.8462V28.3846C17 28.813 17.1628 29.2239 17.4527 29.5269C17.7425 29.8298 18.1356 30 18.5455 30H29.3636C29.7735 30 30.1666 29.8298 30.4564 29.5269C30.7463 29.2239 30.9091 28.813 30.9091 28.3846V26.7692H32.4545C32.8644 26.7692 33.2575 26.599 33.5473 26.2961C33.8372 25.9932 34 25.5823 34 25.1538V13.8462C34.0001 13.7401 33.9802 13.635 33.9414 13.5369C33.9026 13.4389 33.8457 13.3498 33.774 13.2747ZM26.2727 25.9615H21.6364C21.4314 25.9615 21.2349 25.8764 21.09 25.725C20.945 25.5735 20.8636 25.3681 20.8636 25.1538C20.8636 24.9396 20.945 24.7342 21.09 24.5827C21.2349 24.4312 21.4314 24.3462 21.6364 24.3462H26.2727C26.4777 24.3462 26.6742 24.4312 26.8191 24.5827C26.964 24.7342 27.0455 24.9396 27.0455 25.1538C27.0455 25.3681 26.964 25.5735 26.8191 25.725C26.6742 25.8764 26.4777 25.9615 26.2727 25.9615ZM26.2727 22.7308H21.6364C21.4314 22.7308 21.2349 22.6457 21.09 22.4942C20.945 22.3427 20.8636 22.1373 20.8636 21.9231C20.8636 21.7089 20.945 21.5034 21.09 21.352C21.2349 21.2005 21.4314 21.1154 21.6364 21.1154H26.2727C26.4777 21.1154 26.6742 21.2005 26.8191 21.352C26.964 21.5034 27.0455 21.7089 27.0455 21.9231C27.0455 22.1373 26.964 22.3427 26.8191 22.4942C26.6742 22.6457 26.4777 22.7308 26.2727 22.7308ZM32.4545 25.1538H30.9091V17.0769C30.9092 16.9708 30.8893 16.8657 30.8505 16.7677C30.8117 16.6696 30.7548 16.5805 30.6831 16.5055L26.8194 12.467C26.7476 12.392 26.6624 12.3326 26.5686 12.292C26.4748 12.2515 26.3742 12.2307 26.2727 12.2308H21.6364V10.6154H29.0439L32.4545 14.1803V25.1538Z" fill="white" />
        </g>
    </g>
    <defs>
        <filter id="filter0_d_6896_15872" x="0.0908203" y="0" width="48.9092" height="48.9102" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6896_15872" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6896_15872" result="shape" />
        </filter>
        <clipPath id="clip0_6896_15872">
            <rect width="49" height="49" fill="white" />
        </clipPath>
    </defs>
</svg>



export const ratingAccordionIcon = <svg width="50" height="45" viewBox="0 0 71 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="26.5" cy="40.5" r="26.5" fill="#1B6A63" />
    <g filter="url(#filter0_d_207_3135)">
        <circle cx="37" cy="30" r="30" fill="#18A89B" />
    </g>
    <g filter="url(#filter1_d_207_3135)">
        <path d="M47.4 17H26.6C25.17 17 24.013 18.17 24.013 19.6L24 43L29.2 37.8H47.4C48.83 37.8 50 36.63 50 35.2V19.6C50 18.17 48.83 17 47.4 17ZM29.2 32.6V29.389L38.144 20.445C38.404 20.185 38.807 20.185 39.067 20.445L41.368 22.746C41.628 23.006 41.628 23.409 41.368 23.669L32.411 32.6H29.2ZM44.8 32.6H35.05L37.65 30H44.8V32.6Z" fill="white" />
    </g>
    <defs>
        <filter id="filter0_d_207_3135" x="3" y="0" width="68" height="68" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_207_3135" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_207_3135" result="shape" />
        </filter>
        <filter id="filter1_d_207_3135" x="20" y="17" width="34" height="34" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_207_3135" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_207_3135" result="shape" />
        </filter>
    </defs>
</svg>;

export const verifiedIcon = <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11.5" cy="12.125" r="8.625" fill="white" />
    <path d="M23 11.4062L20.4491 8.54152L20.8045 4.75268L17.0305 3.91071L15.0545 0.625L11.5 2.12411L7.94545 0.625L5.96955 3.90045L2.19545 4.73214L2.55091 8.53125L0 11.4062L2.55091 14.271L2.19545 18.0701L5.96955 18.9121L7.94545 22.1875L11.5 20.6781L15.0545 22.1772L17.0305 18.9018L20.8045 18.0598L20.4491 14.271L23 11.4062ZM9.50318 16.2527L5.53045 12.3406L7.07773 10.821L9.50318 13.2134L15.6191 7.18616L17.1664 8.7058L9.50318 16.2527Z" fill="#F09023" />
</svg>

export const tokenIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 24C5.3724 24 0 18.6276 0 12C0 5.3724 5.3724 0 12 0C18.6276 0 24 5.3724 24 12C24 18.6276 18.6276 24 12 24ZM12 6.9084L6.9084 12L12 17.0916L17.0904 12L12 6.9084Z" fill="#F09023" />
    <path d="M12.3536 6.55489L12 6.20125L11.6464 6.55485L6.55485 11.6464L6.20129 12L6.55485 12.3536L11.6464 17.4452L12 17.7987L12.3536 17.4451L17.444 12.3535L17.7974 12L17.444 11.6465L12.3536 6.55489ZM12 23.5C5.64854 23.5 0.5 18.3515 0.5 12C0.5 5.64854 5.64854 0.5 12 0.5C18.3515 0.5 23.5 5.64854 23.5 12C23.5 18.3515 18.3515 23.5 12 23.5Z" stroke="black" stroke-opacity="0.46" />
</svg>;
import DashboardRecruitment from "components/layouts/dashboard/recruitment/db-recruitment";
import GuestRedirectLayout from "components/layouts/page-layouts/guest-redirect";
import { handleAllPermissions } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function RecruitmentDashboardPage(props) {
    const userStore = useUserStore();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const [isPremiumPlan, setIsPremiumPlan] = useState(null);
    const [PM_DashboardView, setPM_DashboardView] = useState(false);

    useEffect(() => {
        if (!userStore.userData) { return }
        handleAllPermissions(userStore.userData.permissionsData, [
            ["PM_VIEW_DASHBOARD", setPM_DashboardView],
        ])
    }, [userStore.userData]);

    useEffect(() => {
        if (!userStore.flagData || !userStore.flagData.toggleDashboardAdvanced) { return setIsPremiumPlan(false) }
        setIsPremiumPlan(userStore.flagData.toggleDashboardAdvanced)
    }, [userStore.flagData])

    return (
        <GuestRedirectLayout>
            <DashboardRecruitment />
        </GuestRedirectLayout>
    );
}
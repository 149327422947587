import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";

const useGetCompanySizeOptions = (props) => {
    const userStore = useUserStore();
    return useQuery({
        queryKey: ["getCompanySizeOptions"],
        queryFn: () => axios.get("v2/generic/catalogues/SizesCompanySize"),
        retry: 2,
        refetchOnWindowFocus: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {
                let allItems = [];
                var selectedItem = [];
                var data = res.data.instance;
                (data).sort((a, b) => a.sortIndex > b.sortIndex ? 1 : -1).forEach(itemLoop => {
                    if (itemLoop.nameTranslations === null) { return }
                    if (itemLoop.nameTranslations === undefined) { return }
                    Array.prototype.forEach.call(itemLoop.nameTranslations, function (itemTranslation, i) {
                        if (userStore.clientData.language.id === itemTranslation.languageId) {
                            return selectedItem = {
                                id: itemLoop.sortIndex,
                                value: itemLoop.sortIndex,
                                label: itemTranslation.text,
                                translations: itemLoop.nameTranslations,
                                data: itemLoop
                            };
                        }
                    });

                    if (selectedItem.length !== 0) {
                        allItems = [...allItems, (selectedItem)];
                        selectedItem = [];
                    }
                });

                props.setData(allItems);
            }
        }),
        onError: (res) => handleRequestError({ response: res }),
    })
}

export default useGetCompanySizeOptions;
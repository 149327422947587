import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import produce from 'immer';
import create from "zustand";
import { handleRequestError, handleRequestSuccess } from '../methods/MyMethods';

// store for all available languages
export const useLanguagesStore = create((set) => ({
    data: null,
    setData(value) {
        set((state) => produce(state, draft => {
            draft.data = value;
        }))
    }
}))

export const useGetLanguages = () => {
    const langsStore = useLanguagesStore();

    return useQuery({
        queryKey: ["getLanguages"],
        queryFn: () => axios.get(`v2/generic/catalogues/Languages`),
        refetch: false,
        refetchOnWindowFocus: false,
        retry: 2,
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {
                setTimeout(() => {
                    langsStore.setData(res.data.instance)
                }, 2000);
            }
        }),
        onError: (res) => handleRequestError({ response: res }),
    })

}
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();


    useEffect(() => {
        window.scrollTo(0, 0);

        //#VDG
        if (window.location.href.includes("#")) {
            var anchorLink = window.location.href.slice(window.location.href.lastIndexOf("#")).replace("#", "");
            const timeoutId = setInterval(function () {
                var element = document.getElementById(anchorLink);
                if (element !== undefined && element !== null) {
                    setTimeout(function () {
                        if (element) {
                            element.scrollIntoView({ behavior: 'smooth' });
                        }
                    }, 1000);
                    clearInterval(timeoutId);
                }
            }, 250);
        }
    }, [pathname]);

    return null;
}
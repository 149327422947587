import * as React from 'react';
import $ from 'jquery';
import { t } from 'i18next';
import { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useUserStore } from 'components/utils/stores/userStore';

export default function CookiesPopup(props) {
    const userStore = useUserStore();
    const cookies = new Cookies();

    function acceptCookies() {
        localStorage.setItem("cookies", 1);
        userStore.changeClientDataItem("acceptedCookies", true);
        console.log("accept");
    }
    function declineCookies() {
        localStorage.setItem("cookies", 0);
        userStore.changeClientDataItem("acceptedCookies", true);
        window['ga-disable-G-7NP9JRE9XD'] = true;
        window['ga-disable-UA-27304107-9'] = true;

        cookies.remove('_ga', { path: '/' });
        cookies.remove('_ga_7NP9JRE9XD', { path: '/' });
        cookies.remove('_ga_N4KYL1JKBY', { path: '/' });
        cookies.remove('_gat_UA-27304107-9', { path: '/' });

        var tagManagerScript = document.getElementById("track-tagmanager");
        var gaScript = document.getElementById("track-ga");

        tagManagerScript.remove();
        gaScript.remove();
        // UA-27304107-9
    }

    return (
        <div className='cookies-container'>
            {
                <div className={"my-4 cookies-wrapper"}>
                    {/* <span className='cookies-close icon-cross' onClick={() => acceptCookies()}></span> */}
                    <div>
                        <h2><span className="icon icon-cookies lime full fs-xlarge icon-nospace pr-2" />{t("business_extra_cookies_title")}</h2>
                        {t("business_extra_cookies_description")}
                        <div className="mt-3 d-flex justify-content-start align-items-center">
                            <div onClick={() => acceptCookies()} className="d-inline-block mb-2 mr-4 btn btn-primary">{t("business_main_accept")}</div>
                            <div onClick={() => declineCookies()} className="d-inline-block mb-2 mr-2 link">{t("business_main_decline2")}</div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";
import { useLanguagesStore } from "components/utils/stores/languagesStore";
import { useUserStore } from "components/utils/stores/userStore";
import envir from 'env.json'

// gets all the promise values of the company
const useGetCompanyPromises = (props) => {
    const userStore = useUserStore();
    const languagesStore = useLanguagesStore();
    return useQuery({
        queryKey: ["getCompanyPromises"],
        queryFn: async () => await axios.post("company/promises2", {
            CompanyId: userStore.companyData.id,
            Iso: userStore.clientData.language.isoCode,
            Category: props.category,
        }),
        retry: 2,
        enabled: !!(userStore.companyData && languagesStore.data),
        refetchOnWindowFocus: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, hasAlert: true,
            customCallback: () => {
                const createEmptyObjects = (item) => {
                    var newObject = {
                        valueTypeId: item.toLowerCase(),
                        videoUrl: ""
                    }
                    const translations = [];
                    languagesStore.data.forEach(element => {
                        translations.push({
                            languageId: element.id.toLowerCase(),
                            text: ''
                        });
                    });
                    newObject.translations = translations;
                    return newObject;
                };
                switch (res.data.status) {
                    case 290:
                        // create empty expected objects - very custom function
                        if (props.category === envir.Variables.PromiseTag_Company) {
                            const promiseCore = createEmptyObjects("898E6212-9F23-46E2-9243-1E3E3B108E97")
                            const promiseSocial = createEmptyObjects("5F233DA7-C7E3-41A5-BE02-AD5B0EE23907")
                            const promiseTips = createEmptyObjects("32E6922B-B896-4C37-AFD0-DCA0A6A0740B")
                            props.setPromiseCore(promiseCore)
                            props.setPromiseSocialValues(promiseSocial)
                            props.setPromiseTips(promiseTips)
                            props.setPromises([promiseCore, promiseSocial, promiseTips])
                        } else {

                            const promiseCore = createEmptyObjects("E7E19A35-3C26-4863-9D44-366502A0E65C")
                            const promiseTips = createEmptyObjects("F94D3683-46C8-4CAA-8B66-BE0B6F36F0C8")
                            props.setPromiseCore(promiseCore)
                            props.setPromiseTips(promiseTips)
                            props.setPromises([promiseCore, promiseTips])
                        }

                        break;

                    default: break;
                }
            },
            callback: () => {
                var data = res.data.instance;

                // PROMISES
                if (data) {
                    if (data.length > 0) {
                        if (props.category === envir.Variables.PromiseTag_Company) {
                            //ORDER OF RETRIEVAL IS ALWAYS THE SAME! no id's may be used here
                            (data).map((item, i) => {
                                if (!item.valueTypeId) { return }
                                switch ((item.valueTypeId).toUpperCase()) {
                                    case "898E6212-9F23-46E2-9243-1E3E3B108E97":
                                        var translations = [];
                                        languagesStore.data.forEach(element => {
                                            translations.push({
                                                languageId: element.id.toLowerCase(),
                                                text: !item.translations ? "" : item.translations.filter(tItem => tItem.languageId.toLowerCase() === element.id.toLowerCase()).length > 0 ?
                                                    item.translations.filter(tItem => tItem.languageId.toLowerCase() === element.id.toLowerCase())[0].text : ""
                                            })
                                        });
                                        var newObject = { ...item };
                                        newObject.translations = translations;
                                        props.setPromiseCore(newObject);
                                        props.setCoreVideo(item.videoUrl);
                                        break;
                                    case "5F233DA7-C7E3-41A5-BE02-AD5B0EE23907":
                                        var translations = [];
                                        languagesStore.data.forEach(element => {
                                            translations.push({
                                                languageId: element.id.toLowerCase(),
                                                text: !item.translations ? "" : item.translations.filter(tItem => tItem.languageId.toLowerCase() === element.id.toLowerCase()).length > 0 ?
                                                    item.translations.filter(tItem => tItem.languageId.toLowerCase() === element.id.toLowerCase())[0].text : ""
                                            })
                                        });
                                        var newObject = { ...item };
                                        newObject.translations = translations;
                                        props.setPromiseSocialValues(newObject);
                                        break;
                                    case "32E6922B-B896-4C37-AFD0-DCA0A6A0740B":
                                        var translations = [];
                                        languagesStore.data.forEach(element => {
                                            translations.push({
                                                languageId: element.id.toLowerCase(),
                                                text: !item.translations ? "" : item.translations.filter(tItem => tItem.languageId.toLowerCase() === element.id.toLowerCase()).length > 0 ?
                                                    item.translations.filter(tItem => tItem.languageId.toLowerCase() === element.id.toLowerCase())[0].text : ""
                                            })
                                        });
                                        var newObject = { ...item };
                                        newObject.translations = translations;
                                        props.setPromiseTips(newObject);
                                        props.setTipsVideo(item.videoUrl)
                                        break;
                                    default:
                                        break;
                                }
                            })
                        } else {
                            (data).map((item, i) => {
                                switch ((item.valueTypeId).toUpperCase()) {
                                    case "32E6922B-B896-4C37-AFD0-DCA0A6A0740B":
                                        var translations = [];
                                        languagesStore.data.forEach(element => {
                                            translations.push({
                                                languageId: element.id.toLowerCase(),
                                                text: !item.translations ? "" : item.translations.filter(tItem => tItem.languageId.toLowerCase() === element.id.toLowerCase()).length > 0 ?
                                                    item.translations.filter(tItem => tItem.languageId.toLowerCase() === element.id.toLowerCase())[0].text : ""
                                            })
                                        });
                                        var newObject = { ...item };
                                        newObject.translations = translations;
                                        props.setPromiseTips(newObject);
                                        props.setTipsVideo(item.videoUrl);
                                        break;
                                    case "E7E19A35-3C26-4863-9D44-366502A0E65C":
                                        var translations = [];
                                        languagesStore.data.forEach(element => {
                                            translations.push({
                                                languageId: element.id.toLowerCase(),
                                                text: !item.translations ? "" : item.translations.filter(tItem => tItem.languageId.toLowerCase() === element.id.toLowerCase()).length > 0 ?
                                                    item.translations.filter(tItem => tItem.languageId.toLowerCase() === element.id.toLowerCase())[0].text : ""
                                            })
                                        });
                                        var newObject = { ...item };
                                        newObject.translations = translations;
                                        props.setCoreVideo(item.videoUrl);
                                        props.setPromiseCore(newObject);
                                        break;
                                    case "F94D3683-46C8-4CAA-8B66-BE0B6F36F0C8":
                                        var translations = [];
                                        languagesStore.data.forEach(element => {
                                            translations.push({
                                                languageId: element.id.toLowerCase(),
                                                text: !item.translations ? "" : item.translations.filter(tItem => tItem.languageId.toLowerCase() === element.id.toLowerCase()).length > 0 ?
                                                    item.translations.filter(tItem => tItem.languageId.toLowerCase() === element.id.toLowerCase())[0].text : ""
                                            })
                                        });
                                        var newObject = { ...item };
                                        newObject.translations = translations;
                                        props.setPromiseTips(newObject);
                                        props.setTipsVideo(item.videoUrl);
                                        break;
                                    default:
                                        break;
                                }
                            })
                        }
                    }
                }

                props.setPromises(data);
            }
        }),
        onError: (res) => handleRequestError({ response: res, hasAlert: true })
    })
}

export default useGetCompanyPromises;
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";

// the register is split up in multiple steps, these steps are seperately handled accordingly
const useRegisterRecruiterStepsHandler = (props) => {
    const userStore = useUserStore()
    var apiBody = null;
    const apiStep = props.step === 3.1 ? 6 : props.step === 3.2 ? 3 : props.step;

    switch (props.step) {
        case 1:
            apiBody = {
                UserEmail: props.email
            }
            break;
        case 2:
            apiBody = {
                CompanyVatNumber: props.vatNumber
            }
            break;
        case 3:
            apiBody = {
                UserEmail: props.email,
                UserPassword: props.password,
                UserFirstName: props.firstName,
                UserLastName: props.lastName,
                UserPhone: props.phone,
                CompanyVatNumber: props.vatNumber,
                CompanyReferralCode: props.referencecode,

                CompanyAdress: props.adress1,
                CompanyAdressOptional: props.adress2,
                CompanyZipCode: props.zipCode,
                CompanyCountryId: props.country,
                CompanyCity: props.city,
                OriginalCompanyVatNumber: props.vatNumber,
                LanguageId: userStore.clientData.language.id,
                Iso: userStore.clientData.language.isoCode
            }
            break;
        default:
            break;

    }

    return useMutation({
        mutationKey: ["mutateRegisterRecruiterStepsHandler"],
        mutationFn: () => axios.post(`registration/${apiStep}`, apiBody),
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {
                switch (props.step) {
                    case 1:
                        props.setStep(2);
                        break;
                    case 2:
                        props.setDoNextStep(true);
                        break;
                    case 3:
                        // FINAL SCREEN
                        props.setStep(6);
                        break;
                    default: break;
                }
            },
        }),
        onError: (res) => handleRequestError({
            response: res,
            customCallback: () => {
                var data = res.response.data.instance;
                // HANDLE MESSAGES
                switch (parseInt(res.response.data.status)) {
                    case 200:
                        props.setMessage(null);
                        break;
                    case 280:
                        if (props.step !== 2) {
                            props.setMessage([t("business_registration_exiting"), "danger"]);
                        } else {
                            props.setMessage([t("business_registration_existing_vatnumber"), "danger"]);
                        }
                        return;
                    case 480:
                    case 488:
                        props.setMessage([t("business_registration_exiting"), "danger"]);
                        break;
                    case 485:
                        props.setMessage([t("business_registration_existing_vatnumber"), "danger"]);
                        break;
                    case 491:
                        props.setMessage([t("business_extra_form_incomplete"), "warning"]);
                        break;
                    case 400:
                        props.setMessage([t("business_extra_general_error2"), "danger"]);
                        break;
                    case 482:
                        props.setMessage([t("business_login_unverified"), "danger"]);
                        break;
                    default:
                        props.setMessage([t("business_extra_general_error1"), "danger"]);
                        break;
                }
            }
        })

    })
}

export default useRegisterRecruiterStepsHandler;
import { useMutation } from '@tanstack/react-query';
import { Button, Form, Input } from 'antd';
import axios from 'axios';
import PrefixIcon from 'components/forms/input/prefix-icon';
import { handleRequestError, handleRequestSuccess } from 'components/utils/methods/MyMethods';
import { t } from 'i18next';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LabelWise from '../assets/images/wise-label.png';
import AlertMessage from '../components/forms/alerts/alert-message';
import envir from '../env.json';

export default function ForgotPage(props) {
    // # UTILS
    const navigate = useNavigate();

    // # CLIENT STATES
    const [message, setMessage] = useState(null);
    const [email, setEmail] = useState("");

    // # SERVER STATES
    const mutateRequestRecoverAccount = useRequestRecoverAccount({ email, setMessage });

    return (
        <div id="hero-form">
            <div className="container-page">
                <div className="container">
                    <div className="page-content">
                        <div className="  d-flex hero-form">
                            <div className="bg-darkgreen bg-hero hero-form-left">
                                <div className="container py-10">
                                    <div className="d-flex justify-content-center align-items-center flex-column mt-10">
                                        <h1 className="title color-white mb-5">{t("business_extra_slogan")}</h1>
                                        <img src={LabelWise} alt="wise-label" />
                                        <div>
                                            <div className="d-flex icons mt-10">
                                                <a href={envir.Variables.WiseSocialFacebook} target="_blank" rel="noreferrer">
                                                    <div className="icon icon-facebook-round"></div>
                                                </a>
                                                <a href={envir.Variables.WiseSocialLinkedIn} target="_blank" rel="noreferrer">
                                                    <div className="icon icon-linkedin-round"></div>
                                                </a>
                                                <a href={envir.Variables.WiseSocialYoutube} target="_blank" rel="noreferrer">
                                                    <div className="icon icon-youtube-round"></div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-hero hero-form-right">
                                <div className="container pt-16 py-10">
                                    <div>
                                        <h1 className='title text-center'>{t("business_password_request_title")}</h1>
                                        <div className="text-center">{t("business_password_request_subtitle")}</div>
                                    </div>
                                    {
                                        !message
                                            ?
                                            null
                                            :
                                            <AlertMessage message={message} setMessage={setMessage} disabled />
                                    }
                                    {
                                        mutateRequestRecoverAccount.isSuccess ? null :
                                            <Form
                                                layout='vertical'
                                                className='mt-8 w-75 mx-auto'
                                                name="form-forgot"
                                                onFinish={() => mutateRequestRecoverAccount.mutate()}
                                            >
                                                <Form.Item
                                                    name="email"
                                                    label={t("business_main_email")}
                                                    rules={[{ required: true, message: false, type: "email" }]}
                                                >
                                                    <Input
                                                        placeholder={t("business_extra_placeholder_email_business")}
                                                        type="mail"
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        prefix={<PrefixIcon value="icon-mail fs-small" />}
                                                    />
                                                </Form.Item>
                                                <div className='mt-4'>
                                                    <Button
                                                        htmlType='submit'
                                                        className='btn btn-primary btn-xlg mb-2'
                                                        loading={mutateRequestRecoverAccount.isLoading}
                                                        onClick={(e) => setMessage(null)}

                                                    >
                                                        {t("business_main_send")}
                                                    </Button>
                                                    <div className='mt-2 mb-5'>
                                                        <Link to="/login" className="btn-secondary btn btn-xlg">
                                                            {t("business_main_back")}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </Form>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const useRequestRecoverAccount = (props) => {
    return useMutation({
        mutationKey: ["mutateRequestRecoverAccount"],
        mutationFn: () => axios.post("account/password/reset/request", {
            UserEmail: props.email,
        }),
        retry: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {
                props.setMessage([(t("business_password_request_sent_1") + " " + props.email), "success"])
            }
        }),
        onError: (res) => handleRequestError({
            response: res, customCallback: () => {
                switch (parseInt(res.response.data.status)) {
                    // 460 EMAIL INCORRECT
                    case 480:
                        props.setMessage([t("business_password_request_invalid"), "danger"]);
                        break;
                    // 462 UNVERIFIED EMAIL
                    case 462:
                        props.setMessage([t("business_password_request_unverified"), "danger"]);
                        break;
                    default: break;
                }
            }
        }),
    })
}
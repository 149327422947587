import { t } from "i18next";
import { useEffect } from "react";
import { HashLink as Link } from 'react-router-hash-link';

export default function TermsPage(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <div>
                <div className="container-page">
                    <div className="container">
                        <div className="page-content">
                            <h1 className='title'>{t("business_terms_title")}</h1>

                            <p>{t("business_extra_policy_date")}</p>
                            <p>{t("business_terms_description")}</p>

                            <h3>{t("business_terms1")}</h3>
                            <p>{t("business_terms11")}</p>
                            <p>{t("business_terms12")}</p>
                            <p>{t("business_terms13")}</p>
                            <p>{t("business_terms14_1")}<u><Link style={{ color: 'blue' }} to="/privacy-policy">{t("business_terms14_2")}</Link></u></p>

                            <h3>{t("business_terms2")}</h3>
                            <p>{t("business_terms21")}</p>
                            <p>{t("business_terms22")}</p>
                            <ul>
                                <li>{t("business_terms221")}</li>
                                <li>{t("business_terms222")}</li>
                                <li>{t("business_terms223")}</li>
                                <li>{t("business_terms224")}</li>
                                <li>{t("business_terms225")}</li>
                                <li>{t("business_terms226")}</li>
                                <li>{t("business_terms227")}</li>
                            </ul>
                            <p>{t("business_terms23")}</p>
                            <p>{t("business_terms24")}</p>
                            <p>{t("business_terms25")}</p>
                            <p>{t("business_terms26")}</p>

                            <h3>{t("business_terms3")}</h3>
                            <p>{t("business_terms31")}</p>
                            <p>{t("business_terms32")}</p>
                            <p>{t("business_terms33")}</p>

                            <h3>{t("business_terms4")}</h3>
                            <p>{t("business_terms41")}</p>
                            <p>{t("business_terms42")}</p>
                            <p>{t("business_terms43")}</p>
                            <p>{t("business_terms44")}</p>
                            <p>{t("business_terms45")}</p>

                            <h3>{t("business_terms5")}</h3>
                            <p>{t("business_terms51")}</p>
                            <p>{t("business_terms52")}</p>
                            <p>{t("business_terms53")}</p>

                            <h3>{t("business_terms6")}</h3>
                            <p>{t("business_terms61")}</p>
                            <p>{t("business_terms62")}</p>
                            <p>{t("business_terms63")}</p>
                            <p>{t("business_terms64")}</p>

                            <h3>{t("business_terms7")}</h3>
                            <p>{t("business_terms71")}</p>
                            <p>{t("business_terms72")}</p>
                            <p>{t("business_terms73")}</p>

                            <h3>{t("business_terms8")}</h3>
                            <p>{t("business_terms81")}</p>
                            <p>{t("business_terms82")}</p>
                            <p>{t("business_terms83")}</p>

                            <h3>{t("business_terms9")}</h3>
                            <p>{t("business_terms91")}</p>
                            <p>{t("business_terms92")}</p>
                            <p>{t("business_terms93")}</p>
                            <p>{t("business_terms94")}</p>
                            <p>{t("business_terms95")}</p>
                            <p>{t("business_terms96")}</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
import React from 'react';
import ReactDOM from 'react-dom';
import WiSEBusinessApp from './App.jsx';
import AppWrapper from './AppWrapper.jsx';

ReactDOM.render(
    <div>
        <AppWrapper>
            <WiSEBusinessApp />
        </AppWrapper>
    </div>,
    document.getElementById('root-wisebusiness')
);
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";

const useEditTeamRole = (props) => {
    const userStore = useUserStore();
    return useMutation({
        mutationKey: ["mutateTeamRole"],
        mutationFn: (propsFn) => axios.post("company/team/member/edit/role", {
            UserId: userStore.userData.id,
            RoleId: propsFn.roleId,
            TeamUserId: propsFn.teamUserId
        }),
        retry: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, isSubmit: true, hasAlert: true, callback: () => {
                if (props.getData) {
                    props.getData();
                }
            }
        }),
        onError: (res) => handleRequestError({ response: res, isSubmit: true, hasAlert: true, })
    })
}

export default useEditTeamRole;
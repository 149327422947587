import { useMutation } from '@tanstack/react-query';
import axios from "axios";
import ProcedureItem from "components/modules/draglist/procedureItem";
import { handleRequestError, handleRequestSuccess } from 'components/utils/methods/MyMethods';
import { useLanguagesStore } from 'components/utils/stores/languagesStore';
import { useUserStore } from 'components/utils/stores/userStore';
import { t } from "i18next";
import update from 'immutability-helper';
import $ from 'jquery';
import Slider from "rc-slider";
import 'rc-slider/assets/index.css';
import { useCallback, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { toast } from 'react-toastify';
import styles from './accordionProcedure.module.scss';
import { Input, Tabs } from 'antd';
import produce from 'immer';

export default function AccordionProcedure(props) {
    // # STORES
    const langsStore = useLanguagesStore();
    const userStore = useUserStore();

    // # CLIENT STATES
    const [isOpen, setIsOpen] = useState(props.defaultOpen ? true : false);
    const [initDataSet, setInitDataSet] = useState(false); // keeps track if initial data was set
    const [isVisible, setIsVisible] = useState(false);
    const [isCustomVisible, setIsCustomVisible] = useState(false);
    const [valueTime, setValueTime] = useState((props.data.flowDurationOptions.sort((a, b) => (a.sortIndex > b.sortIndex ? 1 : -1)).findIndex(item => item.id === props.data.flowDurationId)));
    const [valueRounds, setValueRounds] = useState(0);
    // stores names of process in different languages like: [{ langid: { value: string}},...]
    const [textValuesObject, setTextValuesObject] = useState(null);
    // available eventitems
    const [selectedSteps, setSelectedSteps] = useState([])
    // selected eventitems
    const [availableSteps, setAvailableSteps] = useState([]);
    const [tabs, setTabs] = useState([]);

    // # SERVER STATES
    const mutateVisibility = useToggleProcedureVisibility({ data: props.data, isVisible, setIsVisible });
    const mutateProcedure = useEditProcedure({ selectedSteps, isCustomVisible, textValuesObject, valueTime, valueRounds, data: props.data, getData: props.procedureRefetch })

    // # UTILS
    const thresholdSteps = 10;

    function handleStepRemove(index) {
        var selected = (selectedSteps.splice(index, 1));
        return setSelectedSteps(selectedSteps.filter(selectedItem => selectedItem !== selected));
    }
    function handleStepAdd(id) {
        // set all available steps in state
        var newSteps = selectedSteps;
        (props.data.eventOptions).forEach(step => {
            if (step.id === id) {
                newSteps = [...newSteps, {
                    id: step.id,
                    nameTranslations: step.nameTranslations,
                    // select flowItem name, it will be the first in translations because the backend already returns correct translation
                    name: step.nameTranslations[0].text,
                    img: step.ImageSVG
                }]
            }
        });
        $(`#procedure-accordion-${props.key} #select-steps-add`).val(0); // set steps select on unselectable label
        setSelectedSteps(newSteps);
        return setSelectedSteps(newSteps);
    }

    // initial states when data & lang props updated
    useEffect(() => {
        if (props.data) {
            setInitDataSet(true)
            setIsVisible(props.data.flowIsVisible);

            // set rounds
            if (props.data.flowStepAmountId) {
                setValueRounds(props.data.flowStepAmountOptions.findIndex(item => item.id === props.data.flowStepAmountId) + 1);
            }

            // set all available steps in state
            var availableSteps = [];
            (props.data.eventOptions).forEach(step => {
                availableSteps.push({
                    id: step.id,
                    nameTranslations: step.nameTranslations,
                    // select flowItem name, it will be the first in translations because the backend already returns correct translation
                    name: step.nameTranslations[0].text,
                    img: step.ImageSVG,
                    sort: step.simpleId
                })
            });
            setAvailableSteps(availableSteps);

            setTimeout(() => {
                // set selected steps in state
                var selectedSteps = [];
                if (props.data.events) {
                    (props.data.events).sort((a, b) => a.flowEventSortIndex - b.flowEventSortIndex).forEach(step => {
                        // select flowItem name, it will be the first in translations because the backend already returns correct translation
                        const filteredResults = availableSteps.filter(aStep => aStep.id.toLowerCase() === step.eventId.toLowerCase());
                        console.log({ filteredResults, step, availableSteps, data: props.data });
                        let selectedName = filteredResults.length > 0 && filteredResults[0].nameTranslations === null ? step.flowEventName :
                            availableSteps.filter(aStep => aStep.id === step.eventId)[0].nameTranslations[0].text;

                        selectedSteps.push({
                            id: step.eventId,
                            nameTranslations: filteredResults[0].nameTranslations,
                            name: selectedName,
                            img: step.flowEventIcon
                        })
                    });
                }
                setSelectedSteps(selectedSteps);
                // procedure-accordion-${props.key}
                $(`#procedure-accordion-${props.key} #select-steps-add`).val(0); // set steps select on unselectable label
            }, 10);
        }

        // check if item has custom name and fill in frontend object
        if (langsStore.data && props.data) {
            var newTextValues = {};
            langsStore.data.forEach(element => {
                // if data has customname, fill in object
                if (props.data.customNameTranslations) {
                    if (props.data.customNameTranslations[0].text !== "") {
                        setIsCustomVisible(true);
                        newTextValues = {
                            ...newTextValues, ...{
                                [element.id]: {
                                    value: props.data.customNameTranslations.filter(translation => translation.languageId === element.id)[0].text,
                                    langId: element.id
                                }
                            }
                        }
                    } else {
                        newTextValues = { ...newTextValues, ...{ [element.id]: { value: "", langId: element.id } } }
                    }
                } else {
                    newTextValues = { ...newTextValues, ...{ [element.id]: { value: "", langId: element.id } } }
                }
            });
            setTextValuesObject(newTextValues);


            var newTabs = [];
            langsStore.data.forEach((lang, i) => {
                newTabs.push({
                    key: i + 1,
                    label: <div style={{ minWidth: "30px", textAlign: "center" }}>{`${lang.isoCode}`}</div>,
                    children: <>
                        <div className="px-3 py-4">
                            <label className="input-label required">
                                <div className="label-text">{t("business_company_procedures_accordion_label_name")}:</div>
                                <Input
                                    required
                                    defaultValue={newTextValues[lang.id].value}
                                    onChange={(e) =>
                                        setTextValuesObject(produce(draft => {
                                            // select correct item and replace
                                            draft[lang.id].value = e.target.value
                                        }))
                                    }
                                />
                            </label>
                        </div>
                    </>
                })
            })

            return setTabs(newTabs);
        }
    }, [props.data, userStore.clientData, langsStore])


    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setSelectedSteps((prevselectedSteps) =>
            update(prevselectedSteps, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevselectedSteps[dragIndex]],
                ],
            }),
        )
    }, []);


    return <>
        <div className="my-2">
            <div className={`accordion ${isOpen ? 'bg-white' : ''}`}>
                <div className={`head p-3 d-flex justify-content-between align-items-center pointer rounded ${isOpen ? 'bg-snow' : 'bg-white'}`} onClick={() => setIsOpen(!isOpen)}>
                    <div className="d-flex flex-row align-items-center">
                        <span>{props.data.flowName}</span>
                        {
                            !props.data.customNameTranslations ? null :
                                props.data.customNameTranslations[0].text === "" ? null :
                                    <span className="font-special color-grey ml-2 mr-1">({props.data.customNameTranslations.filter(custom => custom.languageId === userStore.clientData.language.id)[0].text})</span>
                        }
                        <label>
                            <div className="switch small mt-1 ml-2">
                                <input type="checkbox" checked={isVisible} onClick={(e) => mutateVisibility.mutate()} />
                                <span className="slider round"></span>
                            </div>
                        </label>
                        <div className="ml-2 color-grey fs-small inline-block">({isVisible ? t("business_company_procedures_accordion_visible") : t("business_company_procedures_accordion_invisible")})</div>
                    </div>
                    {
                        isOpen ?
                            <div className="icon-arrow2-up icon-nospace icon d-inline-block"></div> :
                            <div className="icon-arrow2-down icon-nospace icon d-inline-block"></div>
                    }
                </div>
                {
                    !isOpen ? null :
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            mutateProcedure.mutate();
                        }} className="body m-4 mb-5">
                            <>
                                {
                                    !isCustomVisible ? <div className="link mb-4 d-inline-block" onClick={() => setIsCustomVisible(!isCustomVisible)}>{t("business_company_procedure_steps_custom_add")}</div> :
                                        <>
                                            <div className="link mb-4 d-inline-block" onClick={() => {
                                                // create new custom name object with no values
                                                var newObj = {}
                                                Object.entries(textValuesObject).forEach(item => {
                                                    newObj[item[0]] = { value: "", langId: item[0] }
                                                })
                                                setTextValuesObject(newObj)
                                                setIsCustomVisible(!isCustomVisible)
                                            }}>{t("business_company_procedure_steps_custom_remove")}</div>
                                            {
                                                !tabs ? null :
                                                    tabs.length === 0 ? null :
                                                        <Tabs
                                                            defaultActiveKey="1"
                                                            items={tabs}
                                                        />
                                            }
                                        </>
                                }
                            </>

                            <label className="w-100 input-label">
                                <div className="label-text">{t("business_company_procedures_accordion_label_time_to_hire")}:</div>
                                <div className="d-flex align-items-center ml-2" style={{ maxWidth: "500px" }}>
                                    <Slider
                                        onChange={(e) => setValueTime(e)}
                                        className="mr-4"
                                        steps={!props.data.flowDurationOptions ? 0 : props.data.flowDurationOptions.length - 1}
                                        max={!props.data.flowDurationOptions ? 0 : props.data.flowDurationOptions.length - 1}
                                        value={valueTime}
                                        tabIndex={20} />
                                    <span className="font-special color-grey d-block w-100">
                                        {
                                            !props.data.flowDurationOptions ? "-" :
                                                (props.data.flowDurationOptions.sort((a, b) => (a.sortIndex > b.sortIndex ? 1 : -1))[valueTime].nameTranslations).filter(trans => trans.languageId === userStore.clientData.language.id)[0].text
                                        }
                                    </span>
                                </div>
                            </label>
                            <div className="mb-4">
                                <div className="fs-small mb-3">{t("business_company_procedures_accordion_label_number_of_interviews")}:</div>
                                {
                                    !selectedSteps ? null :
                                        <DndProvider backend={HTML5Backend}>
                                            {selectedSteps.map((card, i) => {
                                                return (
                                                    <ProcedureItem
                                                        key={i}
                                                        index={i}
                                                        id={card.id}
                                                        text={(card.nameTranslations).filter(name => name.languageId === userStore.clientData.language.id)[0].text}
                                                        moveCard={moveCard}
                                                        handleRemove={handleStepRemove}
                                                    />
                                                )
                                            })}
                                        </DndProvider>
                                }
                                <select defaultValue={0} disabled={selectedSteps.length >= thresholdSteps} id="select-steps-add" onChange={(e) => !e.target.value ? null : handleStepAdd(e.target.value)} className={`${styles["select-steps-add"]} pointer mt-2`}>
                                    <option disabled hidden value={0}>{`${t("business_company_procedure_steps_add")} (${selectedSteps.length}/${thresholdSteps})`}</option>
                                    {
                                        (availableSteps).sort((a, b) => (a.sort < b.sort) ? -1 : 1).map((step, i) => {
                                            return <option value={step.id}>{step.nameTranslations.filter(name => name.languageId === userStore.clientData.language.id)[0].text}</option>
                                        })
                                    }
                                </select>
                            </div>


                            <button className={`btn-primary btn ${mutateProcedure.isLoading ? 'btn-loading' : ''}`}>{t("business_main_save")}</button>
                        </form>
                }
            </div>
        </div>
    </>
}


const useToggleProcedureVisibility = (props) => {
    const userStore = useUserStore();
    return useMutation({
        mutationKey: "mutateProcedureVisibility",
        mutationFn: () => axios.get(`v3/business/update/businesses/${userStore.companyData.id}/applicationflows/${props.data.flowId}/toggle`),
        onSuccess: (res) => handleRequestSuccess({ response: res, hasAlert: true, isSubmit: true, callback: () => props.setIsVisible(!props.isVisible) }),
        onError: (res) => handleRequestError({ response: res, hasAlert: true, isSubmit: true }),
    })
}

const useEditProcedure = (props) => {
    const userStore = useUserStore();
    return useMutation({
        mutationKey: "mutateProcedure",
        mutationFn: () => {
            var flowEvents = [];
            var flowCustomNames = [];
            var allCustomFilledIn = true;
            props.selectedSteps.forEach((step, i) => {
                flowEvents.push({ EventId: step.id, SortIndex: i + 1 })
            });

            // check if custom names have values
            Object.entries(props.textValuesObject).forEach((customname, i) => {
                if (customname[1].value.length === 0 && props.isCustomVisible) { allCustomFilledIn = false; }
                else if (props.isCustomVisible === false) {
                    return flowCustomNames.push({ LanguageId: customname[1].langId, Text: "", ObjectId: props.data.flowCustomNameId })
                }
                flowCustomNames.push({ LanguageId: customname[1].langId, Text: customname[1].value, ObjectId: props.data.flowCustomNameId })
            });


            if (allCustomFilledIn === false) {
                console.error("Not all custom names are filled in")
                throw toast.error(t("business_company_procedures_error_customname"))
            } else {
                return axios.post(`v3/business/update/businesses/${userStore.companyData.id}/applicationflows/${props.data.flowId}`, {
                    FlowCategoryId: props.data.categoryId,
                    FlowDurationId: props.data.flowDurationOptions[props.valueTime].id,
                    // FlowStepAmountId: props.data.flowStepAmountOptions[props.valueRounds].id,
                    CustomNames: flowCustomNames,
                    Events: flowEvents,
                });
            }

        },
        onSuccess: (res) => handleRequestSuccess({
            response: res, isSubmit: true, hasAlert: true, callback: () => {
                if (props.getData) {
                    return props.getData();
                }
            }
        }),
        onError: (res) => handleRequestError({ response: res, hasAert: true, isSubmit: true })
    })
}
import { verifiedIcon } from "components/utils/icons/icons";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import { ArrowLeftCircle } from "react-feather";
import { Link } from "react-router-dom";

export default function DashboardLayout(props) {
    const userStore = useUserStore();

    return <>
        <div className="dashboard">
            <div className="dashboard-header">
                <div className="container py-3 flex justify-content-between align-items-center">
                    {
                        props.hasBack &&
                        <Link to="/dashboard" className="d-inline-block mr-3 flex align-items-center hover">
                            <ArrowLeftCircle className="mr-1" />
                            <span className="font-special"> {t("business_main_back")}</span>
                        </Link>
                    }
                    <div>

                        <span className="fw-bold mr-2">
                            {`${t("business_main_dashboard")}${props.type ? ` (${props.type})` : ''}`}:
                        </span>
                        <span className="mr-2">{props.title ? props.title : props.data ? props.data.companyName : userStore.companyData.name}</span>
                    </div>
                    <div className="flex"><span className="mr-2">{verifiedIcon}</span><span className="fw-bold">{props.data !== undefined && userStore.companyData.subscriptionData.string1.toUpperCase()}</span></div>
                </div>
            </div>
            {
                props.noContainer ? props.children :
                    <div className="container-page">
                        <div className="container page-content mt-0 pt-4">
                            {props.children}
                        </div>
                    </div>
            }
        </div>
    </>
}
import { filesAccordionIcon } from "components/utils/icons/icons";
import AccordionCommunication from "./accordion-communication";
import { t } from "i18next";
import { useQuery } from "@tanstack/react-query"
import { sendAxiosGetRequest } from "components/utils/methods/myRequestHandlers";
import { useUserStore } from "components/utils/stores/userStore";
import { isAxiosError } from "axios";
import NoData from "../no-data/nodata";
import { Fragment } from "react";

export default function FilesAccordion() {
    const userStore = useUserStore();

    const { data } = useQuery({
        queryKey: ["getFilesCompany"],
        queryFn: () => sendAxiosGetRequest({
            route: `v3/select/businesses/${userStore.companyData.id}/files`,
            callback: (res) => {
                if (res && res.data.instance && parseInt(res.data.status) === 290) {
                    return null;
                } else {
                    return res.data.instance
                }
            }
        }),
        refetchOnWindowFocus: false,
        retry: false,
        enabled: !!userStore.companyData.id
    })

    return <>
        {
            data &&
            <AccordionCommunication
                icon={filesAccordionIcon}
                title={<>{t("business_accordion_files_title")}</>}
            >
                <div className="content-wrapper mb-4 mx-4">
                    <ul>
                        {data.sort((a, b) => a.sortIndex > b.sortIndex ? 1 : -1).map((file, index) => (
                            <Fragment key={index}>
                                <li className="my-1">
                                    <a
                                        href={file.fileUrl}
                                        target="_blank"
                                        className="link-generic"
                                        rel="noreferrer"
                                    >
                                        {file.fileName || file.fileUrl}
                                    </a>
                                </li>
                            </Fragment>
                        ))}
                    </ul>
                </div>
            </AccordionCommunication>
        }
    </>
}
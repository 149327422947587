import produce from 'immer';
import create from 'zustand';

// store to keep all available plans, data has all the raw data and in options it is used for in select input
export const usePlansStore = create((set) => ({
    data: null,
    options: null,
    setData(data) {
        set((state) => produce(state, draft => {
            draft.data = data;
        }))
    },
    setOptions(data) {
        set((state) => produce(state, draft => {
            draft.options = data;
        }))
    }
}))
import { Card, Tooltip } from "antd";
import Loading from "components/animations/loading";
import NoData from "components/layouts/no-data/nodata";
import { Fragment } from "react";

// PROPS
// - title                          -> title of panel
// - children                       -> content of panel
// - style?                         -> style of panel
// - className?                     -> className of panel
// - scroll?: boolean               -> show content in scrollable area 
// - scrollHeight?: number          -> scrollable area max height 
// - panelIndicator?: {tooltip: string, icon: element} -> element (mostly tooltip icon) that shows at the top right of panel
// - options?: [{element: element, onClick: event}, ...] -> options at the bottom of the panel
// - isLoading?                     -> general server state of data to showcase default loading animation 
// - isError?                       -> general server state of data to showcase default loading animation 
// - panelProps                     -> object of remaining panel props that are given to the panel
// - setIsModalOpen?                -> !!if panel uses modal, you will need to give the setState
// - setModalContent?               -> !!if panel uses modal, you will need to give the setState
export default function Panel(props) {
    return <>
        <Card
            title={!props.title ? null : <span className='fs-regular fw-semibold color-darkgrey'>
                {
                    props.panelIndicator && props.panelIndicator.tooltip ?
                        <span className="flex flex-row justify-content-between"><span>{props.title}</span><Tooltip overlayInnerStyle={{ width: "max-content" }} placement={props.panelIndicator.placement || "bottom"} title={<div>{props.panelIndicator.tooltip}</div>}>{props.panelIndicator.icon}</Tooltip></span> :
                        props.title
                }
            </span>}
            className={`panel relative mb-2 ${props.className}`}
            style={props.style}
            type="inner"
            size="small"
        >
            <span id={props.anchorid} style={{ position: "absolute", top: -50 }}></span>
            <div
                className={`panel-content relative ${props.scroll ? "panel-scroll" : ''}`}
                style={{ maxHeight: props.scroll && !props.scrollHeight ? 300 : props.scroll && props.scrollHeight ? props.scrollHeight : "auto", ...props.bodyStyle }}
            >
                {
                    props.isLoading !== undefined && props.isLoading === true ? <Loading type="circle" center /> : null
                }
                {
                    props.isRefetching !== undefined && props.isRefetching === true ? <Loading type="circle" center /> : null
                }
                {
                    props.isLoading === true && props.isError === true ? null : props.isError !== undefined && props.isError === true ? <NoData error className="mt-2 mb-3" /> : null
                }
                {
                    props.isLoading || props.isError || props.isRefetching ? null :
                        props.children
                }
            </div>
            {
                !props.options || props.options.length === 0 ? null :
                    <div className="options">
                        {
                            props.options.map((option, i) => option.element && <Fragment key={i}>
                                <div className="option hover" onClick={() => {
                                    if (option.onClick) {
                                        option.onClick();
                                    }
                                    if (option.modalContent && props.setModalContent && props.setIsModalOpen) {
                                        props.setModalContent(option.modalContent);
                                        props.setIsModalOpen(true)
                                    }
                                }}>{option.element}</div>
                            </Fragment>)
                        }
                    </div>
            }
        </Card>
    </>
}

export const defaultModalContent = {
    title: "-",
    children: <><NoData /></>
}
import { t } from "i18next";
import { HashLink as Link } from 'react-router-hash-link';
import envir from "../../../env.json"
import $ from 'jquery';

function Footer(props) {

  function handleScroll(id) {
    $('html, body').animate({
      scrollTop: $('#' + id).offset().top
    }, 500);
  }

  return (
    <div className="footer">
      <footer className="d-flex align-items-center">
        <div className="container">
          <div className="container footer-wrapper d-flex flex-row flex-wrap justify-content-start">
            <div>
              <h2 className="title">{t("business_main_about")}</h2>
              <ul className="footer-links">
                <li>
                  <a target="_blank" href={envir.Variables.WiseB2BInfoUrl} rel="noreferrer">{t("business_footer_about_why")}</a>
                </li>
                {/* <li>
                  <Link to="/#plans">{t("business_footer_about_plans")}</Link>
                </li> */}
                {/* <li>
                  <Link to="/#faq">{t("business_footer_about_faq")}</Link>
                </li> */}
              </ul>
            </div>
            <div>
              <h2 className="title">{t("business_main_contact_us")}</h2>
              <div className="footer-info">
                <div className="footer-contact">
                  <div className="footer-icon footer-tele">
                    <div className="footer-icon-content">
                      <span className="fw-bold">{t("business_main_phone")}:</span> <a href={"tel:" + envir.Variables.WisePhone} className="link-secondary">{envir.Variables.WisePhone}</a></div>
                  </div>
                  <div className="footer-icon footer-mail">
                    <div className="footer-icon-content">
                      <span className="fw-bold">{t("business_main_email")}:</span> <a href={"mailto:" + envir.Variables.WiseEmail} className="link-secondary">{envir.Variables.WiseEmail}</a></div>
                  </div>
                  <div className="footer-icon footer-loc">
                    <div className="footer-icon-content">
                      <span className="fw-bold">{t("business_main_address")}:</span> Zemstbaan 22/24, 2800 Mechelen</div>
                  </div>
                </div>
                <div className="footer-follow">
                  <div>{t("business_extra_follow_us")}:</div>
                  <div className="d-flex">
                    <a href={envir.Variables.WiseSocialFacebook} target={"_blank"} rel="noreferrer">
                      <div className="follow-icon link-facebook-round"></div>
                    </a>
                    <a href={envir.Variables.WiseSocialLinkedIn} target={"_blank"} rel="noreferrer">
                      <div className="follow-icon link-linkedin-round"></div>
                    </a>
                    <a href={envir.Variables.WiseSocialYoutube} target={"_blank"} rel="noreferrer">
                      <div className="follow-icon link-youtube-round"></div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="off-footer d-flex flex-row">
            <div className="color-snow">{t("business_extra_copyright")}</div>
            <div>
              <Link to="/terms-conditions" className="text-decoration-none color-snow" >{t("business_terms_title")}</Link>
            </div>
            <div>
              <Link to="/privacy-policy" className="text-decoration-none color-snow" >{t("business_privacy_title")}</Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
import React, { useState, useEffect } from 'react';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import $ from 'jquery';

const InputHtml = (props) => {
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    const handleEditorChange = (newEditorState) => {
        setEditorState(newEditorState);
        const contentState = stateToHTML(newEditorState.getCurrentContent());

        const newArray = {
            typeId: props.currentObject,
            translations: []
        };

        if (props.currentValues && props.currentValues.translations) {
            props.currentValues.translations.forEach((item) => {
                if (item.languageId !== props.currentLang) {
                    newArray.translations.push(item);
                } else {
                    newArray.translations.push({
                        languageId: item.languageId,
                        text: contentState
                    });
                }
            });
        }

        props.setItem(newArray);
    };

    useEffect(() => {
        console.log(props.currentValues)
        // Check if there are current values and translations available
        if (props.currentValues) {
            // Find the translation corresponding to the current language
            const currentTranslation = props.currentValues.translations.find(
                (item) => item.languageId === props.currentLang
            );

            if (currentTranslation) {
                // Convert the HTML content to Draft.js ContentState
                const content = currentTranslation.text || currentTranslation.value;
                if (content) {
                    const contentState = ContentState.createFromBlockArray(
                        convertFromHTML(content)
                    );

                    // Set the editor state with the converted content
                    setEditorState(EditorState.createWithContent(contentState));
                } else {

                    setEditorState(EditorState.createEmpty());
                }
            }
        }
    }, [props.currentValues, props.currentLang]);

    const getCharacterLength = () => {
        const contentState = stateToHTML(editorState.getCurrentContent());
        var length = $(contentState)
            .contents()
            .filter(function () {
                return this.localName !== "script" && this.localName !== "style";
            })
            .text()
            .length;


        return (maxChar - length);
    };

    const maxChar = 1250;

    return (
        <div className="input-html">
            <Editor
                wrapperClassName="wrapper-simple"
                editorClassName="editor-simple"
                toolbarClassName="toolbar-simple"
                editorState={editorState}
                onEditorStateChange={handleEditorChange}
                readOnly={props.disabled}
            />
            {!props.disabled && (
                <span className="fs-xsmall pl-2 pt-5">
                    {getCharacterLength()}
                </span>
            )}
        </div>
    );
};

export default InputHtml;

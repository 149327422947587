import { t } from "i18next"
import Loading from "../../animations/loading"
import defaultImg from "../../../assets/images/account2.png";
import wiseImg from "../../../assets/images/Logo2.png";
import React, { useEffect, useState } from "react";
import { isValid } from "components/utils/methods/MyMethods";

const ShareScreenshotComponent = React.forwardRef(

    function ShareScreenshot(props, ref) {
        const [allRecommendations, setAllRecommendations] = useState(null);


        useEffect(() => {
            if (!isValid([props.recommendations], [null, false, undefined])) { return }

            var newArray = [];
            if (props.recommendations !== null) {
                (props.recommendations).map(recommendation => {

                    if (newArray.length === 0) {
                        newArray = [...newArray, [recommendation.recommendationTypeId, recommendation.recommendationTypeName, 1]]
                    } else {

                        var hasItem = false;
                        newArray.filter((item, i) => {
                            if (!item[0]) { return }
                            if (item[0].toLowerCase() === recommendation.recommendationTypeId.toLowerCase()) {
                                item[2] = item[2] + 1;
                                hasItem = true
                            }
                            if (newArray.length === i + 1) {
                                if (hasItem === false) {
                                    newArray = [...newArray, [recommendation.recommendationTypeId, recommendation.recommendationTypeName, 1]]
                                    hasItem = false;
                                } else {
                                    hasItem = false;
                                }
                            }
                        })
                    }
                })

            }

            setAllRecommendations(newArray);

        }, [props.recommendations])
        return (
            <div>
                {
                    props.data === false ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                        props.data === null ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                            props.data.user === null || props.data.user === undefined ? <div className="d-flex justify-content-center"><Loading text={null} big fullscreen /></div> :
                                <div className="recruiter-share-box" ref={ref}>
                                    <div className="wrapper">
                                        <div className="head">
                                            <div className="avatar"><img src={props.data.user.instance.logoUrl === null ? defaultImg : props.data.user.instance.logoUrl} alt="profile-avatar" /></div>
                                            <div>
                                                {
                                                    !props.data.user.instance.firstName && !props.data.user.instance.lastName ? null :
                                                        <span className="fw-bold">{props.data.user.instance.firstName + " " + props.data.user.instance.lastName}</span>
                                                }
                                                {
                                                    props.data.user.instance.bio === null ? null :
                                                        <span className="font-special">{props.data.user.instance.bio}</span>
                                                }
                                                <span>

                                                    {
                                                        props.data === null || props.data.tags === undefined ? null :
                                                            props.data.tags === null || props.data.tags === undefined ? null :
                                                                props.data.tags.instance === null || props.data.tags.instance === undefined ? null :
                                                                    (props.data.tags.instance).map(item => {
                                                                        return <span className="mr-1 font-special fs-small">#{item.tag}</span>
                                                                    })
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="body">
                                            <div className="recommendations d-flex flex-wrap flex-row">

                                                {
                                                    allRecommendations === null || allRecommendations === undefined || allRecommendations.length === 0 ? null :
                                                        (allRecommendations).map(item => {
                                                            return <div className="recommendation d-inline-block" id={item[0]}>{item[1]} <span>({item[2]})</span></div>
                                                        })
                                                }
                                            </div>
                                        </div>
                                        <div className="bottom">
                                            <img src={wiseImg} alt="wise-logo" />
                                            <span className="fs-small" dangerouslySetInnerHTML={{ __html: t("business_profile_recommendations_panel_screenshot_waterwark") }} />
                                        </div>
                                    </div>
                                </div>
                }
            </div>
        )
    }
)


export default ShareScreenshotComponent;
import { useMutation } from "@tanstack/react-query"
import axios from "axios"
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore"

const useEditJob = (props) => {
    const userStore = useUserStore();
    return useMutation({
        mutationKey: ["mutateJob"],
        mutationFn: (propsFn) => {

            var assignedIds = [];
            if (propsFn.formObject) {
                if (propsFn.formObject.assignedTo === null) {
                    assignedIds = null

                } else {
                    if (propsFn.addYourself !== undefined) {
                        if (propsFn.formObject.assignedTo.length > 0 && Array.isArray(propsFn.formObject.assignedTo)) {
                            Array.from(propsFn.formObject.assignedTo).map(item => {
                                assignedIds.push(item);
                            });
                        }
                    } else {
                        // IF IS NOT ADD YOURSELF, YOU GET [ID, NAME] IN ARRAY BUT ONLY NEED ARRAY OF ID's
                        if (propsFn.formObject.assignedTo !== undefined) {
                            if (propsFn.formObject.assignedTo.length > 0 && Array.isArray(propsFn.formObject.assignedTo)) {
                                console.log(propsFn.formObject.assignedTo);
                                if (Array.from(propsFn.formObject.assignedTo).length === 1) {
                                    assignedIds = [propsFn.formObject.assignedTo[0].value];
                                } else {
                                    Array.from(propsFn.formObject.assignedTo).map(item => {
                                        assignedIds.push(item.value);
                                    });
                                }
                            }
                        }
                    }
                }
            } else {
                assignedIds = propsFn.assignedIds;
            }
            console.log(props, propsFn)

            return axios.post("job/edit", {
                JobName: !propsFn.formObject ? propsFn.jobName : propsFn.formObject.name,
                AssignedToUserIds: assignedIds,
                IsClosed: !propsFn.formObject ? propsFn.isClosed : propsFn.formObject.status,
                JobId: propsFn.jobId,
                UserId: userStore.userData.id,
                IsHighlight: !propsFn.formObject ? propsFn.isHighlight : propsFn.formObject.highlighted
            })
        },
        retry: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, hasAlert: true, isSubmit: true, callback: () => {
                if (props.getJobs !== undefined) {
                    setTimeout(() => {
                        props.getJobs();
                    }, [200])
                }
                if (props.setOverlay !== undefined) {
                    props.setOverlay(false);
                }
            }
        }),
        onError: (res) => handleRequestError({ response: res, hasAlert: true, isSubmit: true, }),
    })
}



export default useEditJob
import { useMutation } from '@tanstack/react-query';
import { Button, Checkbox, Form, Input } from 'antd';
import axios from 'axios';
import PrefixIcon from 'components/forms/input/prefix-icon';
import useTokenValidator from 'components/requests/data/useTokenValidator';
import { handleRequestError, handleRequestSuccess } from 'components/utils/methods/MyMethods';
import { useUserStore } from 'components/utils/stores/userStore';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import LabelWise from '../assets/images/wise-label.png';
import Loading from '../components/animations/loading';
import AlertMessage from '../components/forms/alerts/alert-message';
import envir from '../env.json';
import { useAccountLogin } from './login';
import NoData from 'components/layouts/no-data/nodata';

export default function InvitePage(props) {
    // # STORES
    const userStore = useUserStore();

    // # CLIENT STATES
    const [message, setMessage] = useState(null);
    const [tokenData, setTokenData] = useState(null);
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [acceptTerms, setAcceptTerms] = useState(false);

    // # UTILS
    const navigate = useNavigate();
    const urlParam = new URLSearchParams(window.location.search);

    // # SERVER STATES
    const mutateInviteRegister = useInviteRegister({ email: !tokenData ? null : tokenData.email, password: password, userId: !tokenData ? null : tokenData.id, navigate });
    const { refetch: validatorRefetch, isSuccess: validatorIsSuccess, isLoading: validatorIsLoading, isError } = useTokenValidator({ token: urlParam.get("token"), setMessage, setData: setTokenData });


    var pasThresh = <div>       {t("business_extra_password_conditions_unmet")}
        <ul>
            <li>{t("business_extra_password_condition1")}</li>
            <li>{t("business_extra_password_condition2")}</li>
            <li>{t("business_extra_password_condition4")}</li>
            <li>{t("business_extra_password_condition3")}</li>
        </ul>
    </div>


    useEffect(() => {
        if (userStore.clientData.loggedIn) { navigate("/logout") }
        if (urlParam.get("token") === undefined) { return setMessage([t("business_verification_invalid"), "danger"]) }
        if (urlParam.get("token") === null) { return setMessage([t("business_verification_invalid"), "danger"]) }
        validatorRefetch();
    }, [urlParam.get("token")]);


    const submitRegister = (e) => {
        if (password !== password2) {
            setMessage([t("business_password_reset_mismatch"), 'warning']);
            return;
        }
        if (!PasswordValidate(password)) {
            setMessage([pasThresh, 'warning']);
            return;
        }

        if (tokenData === null) { return setMessage([t("business_extra_general_error2"), "danger"]) }
        if (tokenData.email === undefined) { return setMessage([t("business_extra_general_error2"), "danger"]) }
        mutateInviteRegister.mutate();
    }

    function PasswordValidate(password) {
        var re = {
            'capital': /[A-Z]/,
            'digit': /[0-9]/,
            'special': /\W/,
        };
        return re.capital.test(password) &&
            re.digit.test(password) &&
            re.special.test(password) &&
            password.length >= 8;
    }

    return (
        <div>
            {
                <div id="hero-form">
                    <div className="container-page">
                        <div className="container">
                            <div className="page-content">
                                <div className="  d-flex hero-form">
                                    <div className="bg-darkgreen bg-hero hero-form-left">
                                        <div className="container py-10">
                                            <div className="d-flex justify-content-center align-items-center flex-column mt-10">
                                                <h1 className="title color-white mb-5">{t("business_extra_slogan")}</h1>
                                                <img src={LabelWise} alt="wise-label" />
                                                <div>
                                                    <div className="d-flex icons mt-10">
                                                        <a href={envir.Variables.WiseSocialFacebook} target="_blank" rel="noreferrer">
                                                            <div className="icon icon-facebook-round"></div>
                                                        </a>
                                                        <a href={envir.Variables.WiseSocialLinkedIn} target="_blank" rel="noreferrer">
                                                            <div className="icon icon-linkedin-round"></div>
                                                        </a>
                                                        <a href={envir.Variables.WiseSocialYoutube} target="_blank" rel="noreferrer">
                                                            <div className="icon icon-youtube-round"></div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-hero hero-form-right">
                                        <div className="container pt-16 py-10">
                                            <div>
                                                <h1 className='title text-center'>{t("business_invitiation_title")}</h1>
                                                <div className="text-center">{t("business_invitiation_subtitle")}</div>
                                            </div>
                                            {
                                                !message
                                                    ?
                                                    null
                                                    :
                                                    <AlertMessage message={message} setMessage={setMessage} disabled />
                                            }
                                            {
                                                validatorIsLoading ? <div className="d-flex justify-content-center mt-3"><Loading text={null} /></div> :
                                                    isError ? <NoData error /> /*#VDG */ :
                                                        !mutateInviteRegister.isSuccess && validatorIsSuccess ?
                                                            <>
                                                                <Form
                                                                    name="form-invite"
                                                                    layout='vertical'
                                                                    className='mt-8 w-75 mx-auto'
                                                                    onFinish={() => submitRegister()}
                                                                >
                                                                    <Form.Item
                                                                        label={t("business_main_password")}
                                                                        rules={[{ required: true, message: false }]}
                                                                        name="password1"
                                                                    >
                                                                        <Input.Password
                                                                            prefix={<PrefixIcon value="icon-lock fs-small" />}
                                                                            onChange={(e) => setPassword(e.target.value)}
                                                                            placeholder={t("business_main_password")}
                                                                        />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        label={t("business_password_confirm_new_password")}
                                                                        rules={[{ required: true, message: false }]}
                                                                        name="password2"
                                                                    >
                                                                        <Input.Password
                                                                            prefix={<PrefixIcon value="icon-lock fs-small" />}
                                                                            placeholder={t("business_main_password")}
                                                                            onChange={(e) => setPassword2(e.target.value)}
                                                                        />
                                                                    </Form.Item>

                                                                    <Form.Item
                                                                        valuePropName="checked"
                                                                        className='mt-3'
                                                                        rules={[
                                                                            {
                                                                                validator: (_, value) =>
                                                                                    value ? Promise.resolve() : Promise.reject(new Error(t("business_register_warning_terms_accept"))),
                                                                            },
                                                                        ]}
                                                                        name="terms"
                                                                    >
                                                                        <Checkbox onChange={(e) => setAcceptTerms(!acceptTerms)}>
                                                                            <>
                                                                                <span className="color-grey fs-small">{t("business_extra_agree_1") + " "}</span>
                                                                                <Link className='link color-grey fs-small ' target={"_blank"} to="/terms-conditions">{t("business_terms_title").toLowerCase()}</Link>
                                                                            </>
                                                                        </Checkbox>
                                                                    </Form.Item>

                                                                    <div className='mt-4'>
                                                                        <Button
                                                                            htmlType='submit'
                                                                            className='btn btn-primary btn-xlg mb-2'
                                                                            onClick={(e) => setMessage(null)}
                                                                            loading={mutateInviteRegister.isLoading}
                                                                        >
                                                                            {t("business_main_register")}
                                                                        </Button>
                                                                    </div>
                                                                </Form>
                                                            </>
                                                            :
                                                            <div className='mt-8 w-75 mx-auto'>
                                                                <div className='mt-4'>
                                                                    <div className='mt-2 mb-2'>
                                                                        <Link to="/login" className="btn-primary btn btn-xlg">
                                                                            {t("business_main_login")}
                                                                        </Link>
                                                                    </div>
                                                                    <div className='mt-2 mb-5'>
                                                                        <Link to="/" className="btn-secondary btn btn-xlg">
                                                                            {t("business_main_back")}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

const useInviteRegister = (props) => {
    const userStore = useUserStore();
    const mutateLogin = useAccountLogin({
        email: props.email, password: props.password, rememberMe: false, navigate: props.navigate, navigateTo: "/account/general"
    });
    const urlParam = new URLSearchParams(window.location.search);

    return useMutation({
        mutationKey: ["mutateInviteRegister"],
        mutationFn: () => axios.post(`invited`, {
            UserId: props.userId,
            UserPassword: props.password,
            LanguageId: userStore.clientData.language.id,
            Token: urlParam.get("token")
        }),
        retry: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, hasAlert: true, callback: () => {
                toast.success(t("business_registration_succes"))
                return mutateLogin.mutate();
            }
        }),
        onError: (res) => handleRequestError({ response: res, hasAlert: true })
    })
}
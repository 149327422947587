import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Modal } from "antd";
import Loading from "components/animations/loading";
import NoData from "components/layouts/no-data/nodata";
import GuestRedirectLayout from "components/layouts/page-layouts/guest-redirect";
import { defaultBlockValues } from "components/modules/sjabloon/blocks-data";
import { AddSettingsContent, ChecklistSettingsContent, ContactSettingsContent, CreateSettingsContent, CtaSettingsContent, ExportSettingsContent, FeedbackSettingsContent, HeadSettingsContent, HeaderSettingsContent, HelpSettingsContent, HtmlSettingsContent, RatingSettingsContent, RecommendationsSettingsContent, SjabloonSettingsWrapper, SpaceSettingsContent, TextSettingsContent } from "components/modules/sjabloon/sjabloon-settings-content";
import SjabloonComponent from "components/modules/sjabloon/sjabloon";
import { tokenIcon } from "components/utils/icons/icons";
import { sendAxiosGetRequest, sendAxiosPostRequest } from "components/utils/methods/myRequestHandlers";
import { useUserStore } from "components/utils/stores/userStore";
import envir from 'env.json';
import html2canvas from "html2canvas";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import * as htmlToImage from "html-to-image";
import { toast } from "react-toastify";
import { hasPermission } from "components/utils/methods/MyMethods";
import { BlobServiceClient } from '@azure/storage-blob';
import { v4 as uuidv4 } from 'uuid';

// ! TUTORIAL to add blocks to sjabloon in @blocks-data.jsx
function CreateVisualPage() {
    // # CLIENT STATES
    const [activeSettings, setActiveSettings] = useState(); // keeps track of which settings layout to show
    const [activeBlock, setActiveBlock] = useState(); // keeps track of what block you selected to edit
    const [headerBlock, setHeaderBlock] = useState(defaultBlockValues.filter(item => item.blockType === "header")[0]); // keeps track of all your blocks data
    const [blocks, setBlocks] = useState([]); // keeps track of all your blocks data
    const [showExampleModal, setShowExampleModal] = useState(false); // show example modal
    const [isEdit, setIsEdit] = useState(true); // indicates if sjabloon is in edit state
    const [isComplete, setIsComplete] = useState(false); // indicates if sjabloon is in edit state
    const [sjabloonType, setSjabloonType] = useState(null); // indicates who the sjabloon is for, currently company or recruiter
    const [sjabloonRoute, setSjabloonRoute] = useState(null);
    const userStore = useUserStore();
    const ref = useRef();
    const urlParam = new URLSearchParams(window.location.search);
    const [base64Image, setBase64Image] = useState(false)
    const [generatedUrl, setGeneratedUrl] = useState(false)

    // # SERVER STATES
    const { data, refetch, isLoading, isRefetching, isError } = useQuery({
        queryKey: ["getSjabloonsData"],
        queryFn: () => sendAxiosGetRequest({
            route: sjabloonRoute
        }),
        retry: 1,
        cacheTime: 0,
        enabled: false,
        refetchOnWindowFocus: false
    });
    const mutateInsertSjabloon = useMutation({
        mutationKey: ["getBusinessSjabloons"],
        mutationFn: async () => {
            const url = await convert64toUrl(base64Image);

            return sendAxiosPostRequest({
                route: `v3/business/insert/businesses/${userStore.companyData.id}/sjabloons`,
                body: {
                    Picture: url
                },
                callback: (res) => { setBase64Image("blocked"); setGeneratedUrl(res.data.instance.picture || null) }
            })
        }
    })

    const convert64toUrl = async (base64) => {
        let finalUrl = null;

        try {
            //Upload to Azure Blob directly using SAS
            const blobServiceClient = new BlobServiceClient("https://wisepeoplestorage.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-01-01T01:36:24Z&st=2024-04-15T16:36:24Z&spr=https&sig=nr%2Ff%2FqxcRjWa1iOBKgBqr2bB62IhjLCTR3kjz3OmW%2FM%3D");
            const byteArray = Uint8Array.from(Array.from(atob(base64.split(',')[1]), char => char.charCodeAt(0)));

            let result = await blobServiceClient
                .getContainerClient("assets")
                .getBlockBlobClient(`business/sjabloon/${uuidv4()}.png`)
                .uploadData(byteArray.buffer, { blobHTTPHeaders: { blobContentType: "image/png" } });

            finalUrl = result?._response?.request?.url?.split('?')[0];

            if (!finalUrl) {
                console.log("Hellooooo");
                throw new Error("Failed upload Azure");
            }
        } catch (error) {
            console.error(error);
        }

        return finalUrl;
    }

    const createFileName = (extension = "", ...names) => {
        if (!extension) {
            return "";
        }
        return `${names.join("")}.${extension}`;
    };

    const takeScreenShot = async (node) => {
        const dataURI = await htmlToImage.toPng(node);
        return dataURI;
    };

    const download = (image, { name = "WiSE-sjabloon", extension = "png" } = {}) => {
        const a = document.createElement("a");
        a.href = image;
        a.download = createFileName(extension, name);
        if (!base64Image) {
            setBase64Image(image)
        }
        a.click();
    };


    const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

    function handleDownload() {
        try {
            if (!ref) { throw console.log("no reference found") }
            setIsEdit(false);
            setActiveSettings(undefined);
            ref.current.style.padding = "30px 80px"
            setIsComplete("load");
            setTimeout(() => {
                downloadScreenshot();
                ref.current.style.margin = "0px"
            }, 100);
            setIsComplete(true);
        } catch (error) {
            console.error(error);
            setIsComplete(false);
            setIsEdit(true);
            toast.error(t("business_extra_general_error2"))
        }
    }

    useEffect(() => {
        if (base64Image !== "blocked" && base64Image) {
            mutateInsertSjabloon.mutate();
        }
    }, [base64Image])

    useEffect(() => {
        if (urlParam.get("type") === "recruiter") {
            setSjabloonType("recruiter")
        } else {
            setSjabloonType("company")
        }
    }, [urlParam.get("type")])

    useEffect(() => {
        if (!sjabloonType) { return }
        if (userStore.companyData && userStore.companyData.id) {
            if (sjabloonType === "recruiter") {
                setSjabloonRoute(`v3/business/select/businesses/${userStore.companyData.id}/users/${userStore.userData.id}/sjabloons`);
            } else {
                setSjabloonRoute(`v3/business/select/businesses/${userStore.companyData.id}/sjabloons`);
            }
            setTimeout(() => {
                refetch()
            }, 300);
        }
    }, [userStore.companyData, sjabloonType])

    const defaultProps = {
        headerBlock: headerBlock,
        activeBlock: activeBlock,
        sjabloonType: sjabloonType,
        setHeaderBlock: setHeaderBlock,
        setActiveBlock: setActiveBlock,
        blocks: blocks,
        setBlocks: setBlocks,
        activeSettings: activeSettings,
        setActiveSettings: setActiveSettings,
        handleDownload: handleDownload,
        generatedUrl: generatedUrl
    }

    function renderSettingsContent() {
        switch (activeSettings) {
            case "loading":
                return <Loading type="circle" center />;
            case "header":
                return (
                    <HeaderSettingsContent
                        {...defaultProps}
                        setIsComplete={setIsComplete}
                        isComplete={isComplete}
                        data={data && data}
                    />
                );
            case "help":
                return (
                    <HelpSettingsContent
                        close={() => setActiveSettings(undefined)}
                    />
                );
            case "add":
                return (
                    <AddSettingsContent
                        {...defaultProps}
                        data={data && data}
                    />
                );
            case "export":
                return (
                    <ExportSettingsContent
                        {...defaultProps}
                    />
                );
            case "html":
                return (
                    <HtmlSettingsContent
                        {...defaultProps}
                    />
                );
            case "head":
                return (
                    <HeadSettingsContent
                        {...defaultProps}
                    />
                );
            case "text":
                return (
                    <TextSettingsContent
                        {...defaultProps}
                    />
                );
            case "cta":
                return (
                    <CtaSettingsContent
                        {...defaultProps}
                    />
                );
            case "space":
                return (
                    <SpaceSettingsContent
                        {...defaultProps}
                    />
                );
            case "checklist":
                return (
                    <ChecklistSettingsContent
                        {...defaultProps}
                    />
                );
            case "feedback":
                return (
                    <FeedbackSettingsContent
                        {...defaultProps}
                    />
                );
            case "rating":
                return (
                    <RatingSettingsContent
                        {...defaultProps}
                        data={data && data.scores && data.scores.instance}
                    />
                );
            case "recommendations":
                return (
                    <RecommendationsSettingsContent
                        {...defaultProps}
                        data={data && data.recommendations && data.recommendations.instance}
                    />
                );
            case "contact":
                return (
                    <ContactSettingsContent
                        {...defaultProps}
                        data={data && data.userData && data.userData.instance}
                    />
                );
            default:
                return <NoData error className="py-10" />;
        }

    }


    console.log(`Blocks \n\n`, { blocks, activeBlock, activeSettings })

    return <>
        <GuestRedirectLayout>
            <div className="container">
                <div style={{ minHeight: "80vh" }} className="page-content">
                    <div className="flex justify-content-between align-items-end">
                        <h1 className="title">{t("business_sjabloon_title")}</h1>
                        {
                            !data ? null :
                                isComplete ? null :
                                    <div className="color-darkgrey font-special">
                                        <span className="mr-2">{tokenIcon}</span>
                                        <span className="mr-1">
                                            {data.tokensLeft && data.tokensLeft.instance.tokensLeft}
                                        </span>
                                        {t("business_tokens_left")}
                                    </div>
                        }
                    </div>
                    {
                        isError ? <NoData className="my-10" error /> :
                            sjabloonType === null ? <Loading center /> :
                                !sjabloonType || isLoading || isRefetching ? <Loading center /> :
                                    data.tokensLeft.instance.tokensLeft <= 0 ?
                                        <div className="flex justify-content-center">
                                            <NoData className="my-4" wise="smile" text={t("business_sjabloon_settings_no_tokens")} />
                                            {
                                                !hasPermission(userStore.userData.permissionsData, "PM_BUY_SJABLOON") ? null :
                                                    <Button href={`mailto:${envir.Variables.WiseEmail}?subject=${t("business_sjabloon_mail_subject")}&body=${t("business_sjabloon_mail_body")}`} className="btn btn-primary">{t("business_sjabloon_settings_create_cta_tokens")}</Button>
                                            }
                                        </div> :
                                        !hasPermission(userStore.userData.permissionsData, "PM_EDIT_SJABLOON") ?
                                            <div className="flex justify-content-center">
                                                <NoData className="my-4" permission />
                                            </div> :
                                            <>
                                                <Modal
                                                    closable
                                                    onCancel={() => setShowExampleModal(false)}
                                                    title={t("business_sjabloon_settings_example_overlay_title")}
                                                    open={showExampleModal}
                                                    okButtonProps={{ style: { display: "none" } }}
                                                    cancelButtonProps={{ style: { display: "none" } }}
                                                    width={800}
                                                >
                                                    <ExampleModal
                                                        {...defaultProps}
                                                        sjabloonComponent={
                                                            <SjabloonComponent
                                                                {...defaultProps}
                                                                logo={
                                                                    data && data.scores && sjabloonType !== "recruiter" ?
                                                                        data.scores.instance.companyLogoUrl :
                                                                        data.userData.instance.logoUrl
                                                                }
                                                                shortcode={data && data.scores && data.scores.instance.shortCode}
                                                                isEdit={false}
                                                                isExample={true}
                                                            />
                                                        } />
                                                </Modal>
                                                <div className="my-3 flex align-items-start flex-row flex-wrap" style={{ marginLeft: "-10px", marginRight: "-10px" }}>
                                                    <div style={isComplete ? { position: "absolute", top: "-1000000vh" } : {}} className="mx-2 md-w-full flex justify-content-center editor-sjabloon-wrapper" >
                                                        <SjabloonComponent
                                                            {...defaultProps}
                                                            logo={
                                                                data && data.scores && sjabloonType !== "recruiter" ?
                                                                    data.scores.instance.companyLogoUrl :
                                                                    data.userData.instance.logoUrl
                                                            }
                                                            shortcode={data && data.scores && sjabloonType !== "recruiter" ? data.scores.instance.shortCode : ''}
                                                            isEdit={isEdit}
                                                            sjabloonRef={ref}
                                                        />
                                                    </div>
                                                    <div className="mx-2 w-100" style={{ flex: "1" }}>
                                                        <SjabloonSettingsWrapper
                                                            {...defaultProps}
                                                            help={isComplete ? null : activeSettings ? null : true}
                                                            closable={!activeSettings ? null : true}
                                                        >
                                                            {
                                                                activeSettings ?
                                                                    renderSettingsContent() :
                                                                    <CreateSettingsContent
                                                                        {...defaultProps}
                                                                        setShowExampleModal={setShowExampleModal}
                                                                        setIsEdit={setIsEdit}
                                                                        sjabloonRef={ref}
                                                                        isComplete={isComplete}
                                                                        setIsComplete={setIsComplete}
                                                                        sjabloonComponent={
                                                                            <SjabloonComponent
                                                                                {...defaultProps}
                                                                                logo={data && data.scores && data.scores.instance.companyLogoUrl}
                                                                                shortcode={data && data.scores && data.scores.instance.shortCode}
                                                                                isEdit={false}
                                                                                isExample={true}
                                                                            />}
                                                                    />
                                                            }
                                                        </SjabloonSettingsWrapper>
                                                    </div>
                                                </div>
                                            </>
                    }
                </div>
            </div>
        </GuestRedirectLayout>
    </>;
}

export default CreateVisualPage;

function ExampleModal(props) {
    const [capturedImage, setCapturedImage] = useState(null);

    useEffect(() => {
        const captureAsImage = async (element) => {
            const canvas = await html2canvas(element);
            return canvas.toDataURL('image/png');
        };
        removeCapturedImage()
        setTimeout(() => {
            const elementToCapture = document.getElementById("wise-sjabloon");
            if (elementToCapture) {
                captureAsImage(elementToCapture).then((dataURL) => {
                    setCapturedImage(dataURL);
                });
            }
        }, 150);
    }, [props.blocks, props.headerBlock]);

    const removeCapturedImage = () => {
        setCapturedImage(null);
    };

    return (
        <>
            <p>{t("business_sjabloon_settings_example_overlay_description")}</p>
            <div className="py-3 mx-auto mb-10">{capturedImage ? null : <div style={{ position: "absolute", top: "-100000vh" }}>{props.sjabloonComponent}</div>}
                <div id="sjabloon-image-example" className="flex justify-content-center">
                    {
                        !capturedImage ? <Loading center /> :
                            <img src={capturedImage} alt="Sjabloon" style={{ maxWidth: 500 }} />
                    }
                </div>
            </div>
        </>
    );
}


import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, Select } from 'antd';
import PrefixIcon from 'components/forms/input/prefix-icon';
import useRegisterStepsHandler from 'components/requests/account/useRegisterStepsHandler';
import useGetCountryOptions from 'components/requests/data/useGetCountryOptions';
import { usePlansStore } from 'components/utils/stores/plansStore';
import { useUserStore } from 'components/utils/stores/userStore';
import produce from 'immer';
import Loading from '../../components/animations/loading';
import AlertMessage from '../../components/forms/alerts/alert-message';

export default function RegisterStep5(props) {
    // # STORES
    const userStore = useUserStore();
    const plansStore = usePlansStore();

    // # UTILS
    const urlParam = new URLSearchParams(window.location.search);

    // # CLIENT STATES
    const [message, setMessage] = useState(null);
    const [form] = Form.useForm();
    const [formObjectStep5, setFormObjectStep5] = useState({
        plan: null,
        invoiceCountry: null,
        referrence: "",
        invoiceAddress1: "",
        invoiceAddress2: "",
        invoiceEmail: "",
        invoiceCity: "",
        invoiceCompany: "",
        invoicePostalcode: "",

    });
    const [allCountries, setCountries] = useState(null);
    const [isSameAdress, setSameAdress] = useState(false);

    const editFormItemStep5 = (key, val, setInit) => {
        if (setInit) {
            form.setFieldsValue({
                [key]: val
            });
        }
        setFormObjectStep5(produce(draft => {
            draft[key] = val;
        }))
    }

    // # SERVER STATES
    const { } = useGetCountryOptions({ setData: setCountries });
    const mutateRegisterStepsHandler = useRegisterStepsHandler({
        invoiceAddress1: formObjectStep5.invoiceAddress1,
        invoiceAddress2: formObjectStep5.invoiceAddress2,
        invoiceEmail: formObjectStep5.invoiceEmail,
        invoiceCity: formObjectStep5.invoiceCity,
        invoiceCompany: formObjectStep5.invoiceCompany,
        invoiceCountry: formObjectStep5.invoiceCountry,
        invoicePostalcode: formObjectStep5.invoiceZipCode,
        companyPlan: formObjectStep5.plan,
        companyId: props.formObject.companyId,
        userId: props.formObject.userId,
        companyPo: formObjectStep5.referrence,
        setMessage: setMessage,
        step: props.currentStep,
        setStep: props.setStep,
    })

    const doneSvg = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="7" fill="white" />
        <path fillRule="evenodd" clip-rule="evenodd" d="M8 0C3.58182 0 0 3.58182 0 8C0 12.4182 3.58182 16 8 16C12.4182 16 16 12.4182 16 8C16 3.58182 12.4182 0 8 0ZM11.4676 6.64727C11.5315 6.57429 11.5801 6.48927 11.6106 6.39721C11.6411 6.30516 11.6529 6.20794 11.6453 6.11126C11.6377 6.01459 11.6108 5.92041 11.5662 5.83428C11.5217 5.74814 11.4604 5.67179 11.3859 5.6097C11.3114 5.54762 11.2252 5.50106 11.1324 5.47277C11.0397 5.44448 10.9422 5.43503 10.8457 5.44497C10.7493 5.4549 10.6558 5.48403 10.5707 5.53064C10.4857 5.57725 10.4108 5.6404 10.3505 5.71636L7.22327 9.46836L5.60509 7.84946C5.46793 7.71698 5.28422 7.64367 5.09353 7.64533C4.90284 7.64699 4.72043 7.72347 4.58559 7.85831C4.45074 7.99316 4.37426 8.17557 4.3726 8.36625C4.37094 8.55694 4.44425 8.74065 4.57673 8.87782L6.75855 11.0596C6.83001 11.1311 6.91556 11.1868 7.00976 11.2233C7.10395 11.2598 7.20473 11.2763 7.30565 11.2717C7.40657 11.2672 7.50544 11.2416 7.59594 11.1967C7.68643 11.1518 7.76658 11.0885 7.83127 11.0109L11.4676 6.64727Z" fill="#18A89B" />
    </svg>;

    const currentSvg = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="8" fill="#F19023" />
    </svg>;

    const nextSvg = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="8" fill="#1B6A63" />
    </svg>;

    function toggleSameAdress() {
        if (!isSameAdress === true) {
            editFormItemStep5("invoiceAddress1", props.formObject.address1, true);
            editFormItemStep5("invoiceAddress2", props.formObject.address2, true);
            editFormItemStep5("invoiceCity", props.formObject.city, true);
            editFormItemStep5("invoiceCountry", props.formObject.country, true);
            editFormItemStep5("invoiceZipCode", props.formObject.zipCode, true);
            editFormItemStep5("invoiceEmail", props.formObject.companyEmail, true);
            editFormItemStep5("invoiceCompany", props.formObject.companyName, true);
        }
        setSameAdress(!isSameAdress)
    }

    function isFreePlan() {
        if (
            formObjectStep5.plan === "3cb3c932-9d09-4a58-aca0-266919db8641" || // FREE
            formObjectStep5.plan === "03f65615-7fb8-485f-9ff0-af8cdcb54607" || // FREE AGENCY
            !formObjectStep5.plan) {
            return true;
        } else {
            return false
        }
    }

    useEffect(() => {
        if (plansStore.options === undefined) { return }
        if (plansStore.options === null) { return }
        if (plansStore.options === false) { return }
        // AUTO SELECT PLAN IF PARAMETER
        if (urlParam.get("plan") !== null) {
            var selectedPlan = urlParam.get("plan");
            // if(urlParam.get("plan")==="")
            var defaultPlan = plansStore.options.filter(plan => plan.label.toLowerCase() === selectedPlan.toLowerCase());
            if (defaultPlan.length === 0 || defaultPlan === null || defaultPlan === undefined) {
                editFormItemStep5("plan", plansStore.options[4].value, true); //BASIC
            } else {
                editFormItemStep5("plan", defaultPlan[0].value, true); //BASIC
            }

        } else {
            if (plansStore.options[0] === undefined) { return }
            editFormItemStep5("plan", plansStore.options[1].value, true); //BASIC
        }
    }, [plansStore.options])

    const submitStep = (event) => {
        mutateRegisterStepsHandler.mutate();
    }

    return (
        <div className="  d-flex hero-form hero-form-steps">
            <div className="bg-darkgreen bg-hero hero-form-left">
                <div className="container py-10">
                    <div className="d-flex justify-content-center align-items-start flex-column mt-3 ml-2">
                        <h1 className='color-white'>{t("discard_register_hero_title_steps")}</h1>
                        <div className="mt-3 register-steps">
                            <div>
                                <span>
                                    {doneSvg}
                                </span>
                                <span>
                                    {t("discard_register_step_account")}
                                </span>
                            </div>
                            <div>
                                <span>
                                    {doneSvg}
                                </span>
                                <span>
                                    {t("business_main_account_info")}
                                </span>
                            </div>
                            <div>
                                <span>
                                    {doneSvg}
                                </span>
                                <span>
                                    {t("discard_register_step_company")}
                                </span>
                            </div>
                            <div>
                                <span>
                                    {currentSvg}
                                </span>
                                <span>
                                    {t("discard_register_step_plan")}
                                </span>
                            </div>
                            <div>
                                <span>
                                    {nextSvg}
                                </span>
                                <span>
                                    {t("discard_register_step_complete")}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-hero hero-form-right">
                <div className="container py-10 ">
                    <div className="px-5 py-3">
                        <div className="d-flex justify-content-between">
                            <div>
                                <h1 className='title'>{t("discard_register_step_company")}</h1>
                                <div className='subtitle'>{t("business_registration_subtitle")}</div>
                            </div>
                            <span className="color-grey">
                                4/5
                            </span>
                        </div>
                        {
                            !message
                                ?
                                null
                                :
                                <AlertMessage message={message} setMessage={setMessage} />
                        }
                        {
                            plansStore.options === false ? <Loading text={null} big center /> :
                                <>
                                    <Form
                                        className='mt-8 mx-auto'
                                        name="form-register-5"
                                        layout='vertical'
                                        onFinish={() => submitStep()}
                                        form={form}
                                    >
                                        <Form.Item
                                            name="plan"
                                            label={t("discard_register_step_plan_pick")}
                                            rules={[{ required: true, message: false }]}
                                        >
                                            <Select
                                                options={plansStore.options}
                                                onSelect={(value, option) => editFormItemStep5("plan", value)}
                                            />
                                        </Form.Item>
                                        {
                                            isFreePlan() ? null :
                                                <>
                                                    <Form.Item
                                                        name="referrence"
                                                        label={<>{t("discard_register_step_po_reference") + " (" + t("business_main_optional") + ")"}</>}
                                                    >
                                                        <Input
                                                            onChange={(e) => editFormItemStep5("referrence", e.target.value)}
                                                        />
                                                    </Form.Item>
                                                    <>
                                                        <h3 className='mt-5 mb-4 icon-width d-flex align-items-center'> <span className="icon-invoice icon round lime"></span>{t("discard_register_invoice_title")}</h3>
                                                        <Checkbox checked={isSameAdress} onChange={(e) => toggleSameAdress()} className='mt-3 mb-4'>
                                                            <>
                                                                <span className="color-grey fs-small">{t("discard_register_step_plan_same_invoice")}</span>
                                                            </>
                                                        </Checkbox>
                                                        <>
                                                            <Form.Item
                                                                name="invoiceCompany"
                                                                label={t("business_main_company")}
                                                                rules={[{ required: true, message: false }]}
                                                            >
                                                                <Input
                                                                    placeholder={t("business_main_company")}
                                                                    onChange={(e) => editFormItemStep5("invoiceCompany", e.target.value)}
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="invoiceEmail"
                                                                label={t("business_main_email")}
                                                                rules={[{ required: true, message: false, type: "email" }]}
                                                            >
                                                                <Input
                                                                    prefix={<PrefixIcon value="icon-mail fs-small" />}
                                                                    placeholder={t("business_extra_placeholder_email_business")}
                                                                    onChange={(e) => editFormItemStep5("invoiceEmail", e.target.value)}
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="invoiceCountry"
                                                                label={t("business_main_country")}
                                                                rules={[{ required: true, message: false }]}
                                                            >
                                                                <Select
                                                                    showSearch
                                                                    placeholder={t("-")}
                                                                    onSelect={(value, option) => editFormItemStep5("invoiceCountry", value)}
                                                                    options={allCountries}
                                                                />
                                                            </Form.Item>
                                                            <div className="d-flex flex-wrap justify-content-between">
                                                                <Form.Item
                                                                    name="invoiceCity"
                                                                    label={t("business_main_city")}
                                                                    className='w-50 mobile-friendly pr-2'
                                                                    rules={[{ required: true, message: false }]}
                                                                >
                                                                    <Input
                                                                        placeholder={t("business_main_city")}
                                                                        onChange={(e) => editFormItemStep5("invoiceCity", e.target.value)}
                                                                    />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="invoiceZipCode"
                                                                    label={t("business_main_zipcode")}
                                                                    className='w-50 mobile-friendly'
                                                                    rules={[{ required: true, message: false }]}
                                                                >
                                                                    <Input
                                                                        placeholder={t("business_main_zipcode")}
                                                                        onChange={(e) => editFormItemStep5("invoiceZipCode", e.target.value)}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                            <Form.Item
                                                                name="invoiceAddress1"
                                                                label={t("discard_register_step_company_adres1")}
                                                                rules={[{ required: true, message: false }]}
                                                            >
                                                                <Input
                                                                    placeholder={t("discard_register_step_company_adres1")}
                                                                    onChange={(e) => editFormItemStep5("invoiceAddress1", e.target.value)}
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="invoiceAddress2"
                                                                label={t("discard_register_step_company_adres2")}
                                                            >
                                                                <Input
                                                                    placeholder={t("discard_register_step_company_adres2")}
                                                                    onChange={(e) => editFormItemStep5("invoiceAddress2", e.target.value)}
                                                                />
                                                            </Form.Item>
                                                        </>
                                                    </>
                                                </>
                                        }

                                        <div className='mt-3 d-block'>
                                            <Button
                                                htmlType='submit'
                                                className='btn-primary btn mb-2'
                                                loading={mutateRegisterStepsHandler.isLoading}
                                            >
                                                {t("business_main_next")} <span className="icon-arrow-right default pl-2" />
                                            </Button>
                                        </div>

                                    </Form>
                                </>
                        }

                    </div>
                </div>
            </div>
        </div>
    );
}
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";

const useEditLanguage = (props) => {
    const userStore = useUserStore();

    return useMutation({
        mutationKey: ["mutateLanguage"],
        mutationFn: (propsFn) => axios.post("account/language/update", {
            UserId: !userStore.userData ? null : userStore.userData.id,
            Iso: propsFn.iso
        }),
        retry: 2,
        onSuccess: (res) => handleRequestSuccess({ response: res }),
        onError: (res) => handleRequestError({ response: res })
    })
}

export default useEditLanguage;
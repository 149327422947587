import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";
import { t } from "i18next";

const useVerification = (props) => {
    return useMutation({
        mutationKey: ["mutateVerification"],
        mutationFn: () => axios.post("account/verify", {
            Token: props.token
        }),
        retry: 2,
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {
                props.setMessage([t("business_verification_success"), "success"]);

            }
        }),
        onError: (res) => handleRequestError({
            response: res, customCallback: () => {
                switch (res.data.status) {
                    case 281:
                        props.setMessage([t("business_verification_expired"), "danger"]);
                        return;
                    case 480:
                        props.setMessage([t("business_verification_invalid"), "danger"]);
                        return;
                    default:
                        props.setMessage([t("business_extra_general_error2"), "danger"]);
                        break;
                }
            }
        })
    })
}

export default useVerification;
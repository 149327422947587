import { Empty, Table } from "antd";
import AccordionContent from "components/layouts/accordions/accordionContent";
import DashboardLayout from "components/layouts/dashboard/dashboard-layout";
import CandidateBrandExperiencePanel from "components/layouts/dashboard/panels/candidate-brand-experience-panel";
import MessagesPanel from "components/layouts/dashboard/panels/messages-panel";
import ScoreEvolutionPanel from "components/layouts/dashboard/panels/score-evolution-panel";
import { useGetDbGeneralData } from "components/requests/dashboard/getDbGeneralData";
import useGetDbMessages from "components/requests/dashboard/getDbMessages";
import { useGetDbScoreEvolution } from "components/requests/dashboard/getDbScoreEvolution";
import { useUserStore } from "components/utils/stores/userStore";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

export default function ImproveDashboardPage(props) {
    // # STORES
    const userStore = useUserStore();
    const { t } = useTranslation()
    const [filterUserId, setFilterUserId] = useState(null);
    const [filterPhaseId, setFilterPhaseId] = useState(null);

    // # SERVER STATES
    // base data
    const { data: dbGeneralData, isLoading: dbGeneralDataIsLoading, isRefetching: dbGeneralDataIsRefetching, isError: dbGeneralDataIsError, refetch } = useGetDbGeneralData({ enabled: true }); // company data (number of ratings, rating, companyname, planname)
    const { data: dbMessages, isLoading: dbMessagesIsLoading, isError: dbMessagesIsError, isRefetching: dbMessagesIsRefetching, refetch: dbMessagesRefetch } = useGetDbMessages({ filterPhaseId, filterUserId, enabled: true });

    return <>
        <DashboardLayout data={dbGeneralData} type={"Accelerate"}>
            <>

                {/* ### APPLICANT EXPERIENCE ###  */}
                <section>
                    <h1 className="title mb-4">Perception of applicants</h1>
                    <>
                        <div className="row">
                            <div className="col gx-2">
                                <CandidateBrandExperiencePanel
                                    filterPhaseId={filterPhaseId}
                                    filterUserId={filterUserId}
                                    catFilter={(item) => item === 20 || item === 30}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col gx-2">
                                <MessagesPanel
                                    data={dbMessages}
                                    panelProps={{
                                        isLoading: dbMessagesIsLoading || dbMessagesIsRefetching, isError: dbMessagesIsError,
                                        isRefetching: dbMessagesIsRefetching,
                                    }}
                                />
                            </div>
                        </div>
                    </>

                </section>
            </>
        </DashboardLayout>
    </>
}
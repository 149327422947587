import { useState } from "react";

export default function AccordionCommunication(props) {
    // # CLIENT STATES
    const [expanded, setExpanded] = useState(false);

    return <>
        <div className="accordion-custom mb-2">
            <div className="accordion-head" onClick={() => setExpanded(!expanded)}>
                <div className="fs-xregular">
                    <span className="pr-3">
                        {props.icon}
                    </span>
                    {props.title}
                </div>
                <span className={`fs-large icon ${expanded ? 'icon-arrow2-up' : 'icon-arrow2-down'}`} />
            </div>
            {
                !expanded ? null :
                    <div className="mt-2 content-wrapper">
                        {props.children}
                    </div>
            }
        </div>
    </>
}
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";

const useGetAccountInfo = (props) => {
    const userStore = useUserStore();
    return useQuery({
        queryKey: ["getAccountInfo"],
        queryFn: () => axios.post(`account/info`, {
            UserId: userStore.userData.id
        }),
        refetchOnWindowFocus: false,
        retry: 2,
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {
                var data = res.data.instance;
                props.editFormItem("firstName", data.firstName, true);
                props.editFormItem("lastName", data.lastName, true);
                props.editFormItem("email", data.email, true);
                props.editFormItem("phone", data.phone, true);
                props.editFormItem("avatar", !data.logoUrl ? null : data.logoUrl, true);
                props.editFormItem("recruiterMessage", data.about, true);
                props.editFormItem("jobPosition", data.functionName, true);

            }
        }),
        onError: (res) => handleRequestError({ response: res }),
    })
}

export default useGetAccountInfo
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LabelWise from '../assets/images/wise-label.png';
import envir from '../env.json';

// COMPONENTS
import AlertMessage from '../components/forms/alerts/alert-message';

// FUNCTIONAL COMPONENTS
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Form, Input } from 'antd';
import axios from 'axios';
import { handleRequestError, handleRequestSuccess } from 'components/utils/methods/MyMethods';
import { useUserStore } from 'components/utils/stores/userStore';
import { t } from 'i18next';
import Loading from '../components/animations/loading';
import NoData from 'components/layouts/no-data/nodata';

export default function RecoverAccountPage(props) {
    // # STORES
    const userStore = useUserStore();

    // # UTILS
    const navigate = useNavigate();
    const urlParam = new URLSearchParams(window.location.search);

    // # CLIENT STATES
    const [userId, setUserId] = useState(null);
    const [message, setMessage] = useState(null);
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    // # SERVER STATES
    const { refetch: validatorRefetch, isSuccess: validatorIsSuccess, isLoading: validatorIsLoading, isError } = useValidateResetPasswordToken({ setMessage, setUserId });
    const mutateRequestResetPassword = useRequestResetPassword({ userId, password, setMessage });

    useEffect(() => {
        if (urlParam.get("token") === undefined) { return setMessage([t("business_password_reset_invalid"), "danger"]) }
        if (urlParam.get("token") === null) { return setMessage([t("business_password_reset_invalid"), "danger"]) }
        validatorRefetch();
    }, [urlParam.get("token")])

    var pasThresh = <div>{t("business_extra_password_conditions_unmet")}
        <ul>
            <li>{t("business_extra_password_condition3")}</li>
            <li>{t("business_extra_password_condition1")}</li>
            <li>{t("business_extra_password_condition2")}</li>
            <li>{t("business_extra_password_condition4")}</li>
        </ul>
    </div>

    function PasswordValidate(password) {
        var re = {
            'capital': /[A-Z]/,
            'digit': /[0-9]/,
            'special': /\W/,
        };
        return re.capital.test(password) &&
            re.digit.test(password) &&
            re.special.test(password) &&
            password.length >= 8;
    }

    const submitReset = () => {
        setMessage(null);
        if (password !== password2) {
            setMessage([t("business_password_reset_mismatch"), 'danger']);
            return;
        }
        if (!PasswordValidate(password)) {
            setMessage([pasThresh, 'warning']);
            return;
        }
        mutateRequestResetPassword.mutate();
    }


    return (
        <div>
            {
                <div id="forgetreset">
                    <div className="container-page">
                        <div className="container">
                            <div className="page-content">
                                <div className="  d-flex hero-form">
                                    <div className="bg-darkgreen bg-hero hero-form-left">
                                        <div className="container py-10">
                                            <div className="d-flex justify-content-center align-items-center flex-column mt-10">
                                                <h1 className="title color-white mb-5">{t("business_extra_slogan")}</h1>
                                                <img src={LabelWise} alt="wise-label" />
                                                <div>
                                                    <div className="d-flex icons mt-10">
                                                        <a href={envir.Variables.WiseSocialFacebook} target="_blank" rel="noreferrer">
                                                            <div className="icon icon-facebook-round"></div>
                                                        </a>
                                                        <a href={envir.Variables.WiseSocialLinkedIn} target="_blank" rel="noreferrer">
                                                            <div className="icon icon-linkedin-round"></div>
                                                        </a>
                                                        <a href={envir.Variables.WiseSocialYoutube} target="_blank" rel="noreferrer">
                                                            <div className="icon icon-youtube-round"></div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-hero hero-form-right">
                                        <div className="container pt-16 py-10">
                                            <div>
                                                <h1 className='title text-center'>{t("business_password_reset_title")}</h1>
                                                <div className="text-center">{t("business_password_reset_subtitle")}</div>
                                            </div>
                                            {
                                                !message
                                                    ?
                                                    null
                                                    :
                                                    <AlertMessage message={message} setMessage={setMessage} disabled />
                                            }
                                            {
                                                validatorIsLoading ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                                                    isError ? <NoData error /> /*#VDG */ :
                                                        validatorIsSuccess === false || mutateRequestResetPassword.isSuccess ?
                                                            <div className='mt-8 w-75 mx-auto'>
                                                                <div className='mt-4'>
                                                                    <div className='mt-2 mb-2'>
                                                                        <Link to="/login" className="btn-primary btn btn-xlg">
                                                                            {t("business_main_back")}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div> :
                                                            <Form
                                                                name="form-recover"
                                                                layout='vertical'
                                                                onFinish={() => submitReset()}
                                                                className='mt-8 w-75 mx-auto'
                                                            >
                                                                <Form.Item
                                                                    name="password1"
                                                                    label={t("business_main_password")}
                                                                    rules={[{ required: true, message: false }]}
                                                                >
                                                                    <Input.Password
                                                                        prefix={<span className='icon icon-nospace mr-2 icon-lock ' />}
                                                                        onChange={(e) => setPassword(e.target.value)}
                                                                        placeholder={t("business_main_password")}
                                                                        htmlType="password" />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="password2"
                                                                    label={t("business_password_confirm_new_password")}
                                                                    rules={[{ required: true, message: false }]}
                                                                >
                                                                    <Input.Password
                                                                        prefix={<span className='icon icon-nospace mr-2 icon-lock ' />}
                                                                        onChange={(e) => setPassword2(e.target.value)}
                                                                        placeholder={t("business_main_password")}
                                                                        htmlType="password" />
                                                                </Form.Item>
                                                                <div className='mt-4'>
                                                                    <Button
                                                                        htmlType='submit'
                                                                        className='btn btn-primary btn-xlg'
                                                                        onClick={(e) => setMessage(null)}
                                                                        loading={mutateRequestResetPassword.isLoading}
                                                                    >
                                                                        {t("business_main_save")}
                                                                    </Button>
                                                                    <div className='mt-2 mb-5'>
                                                                        <Link to="/login" className="btn-secondary btn btn-xlg">
                                                                            {t("business_main_back")}
                                                                        </Link>
                                                                    </div>
                                                                </div>

                                                            </Form>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

const useRequestResetPassword = (props) => {
    const urlParam = new URLSearchParams(window.location.search);
    return useMutation({
        mutationKey: ["mutateRequestResetPassword"],
        mutationFn: () => axios.post("account/password/reset/confirm", {
            UserId: props.userId,
            UserPassword: props.password,
            Token: urlParam.get("token")
        }),
        onSuccess: (res) => handleRequestSuccess({
            response: res, hasAlert: true, callback: () => {
                props.setMessage([t("business_extra_save_succes"), "success"]);
            }
        }),
        onError: (res) => handleRequestError({ response: res, hasAlert: true, }),
    })
}

const useValidateResetPasswordToken = (props) => {
    const urlParam = new URLSearchParams(window.location.search);
    return useQuery({
        queryKey: ["validateResetPasswordToken"],
        queryFn: () => axios.post("token/user/validate", {
            Token: urlParam.get("token")
        }),
        refetchOnWindowFocus: false,
        retry: 2,
        enabled: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {
                props.setUserId(res.data.instance.id)
            }
        }),
        onError: (res) => handleRequestError({
            response: res, customCallback: () => {
                if (parseInt(res.response.data.status) === 480) {
                    props.setMessage([t("business_password_reset_invalid"), "danger"]);
                }
            }
        })
    })
}

import { useQuery } from '@tanstack/react-query';
import Input from 'antd/es/input/Input';
import axios from 'axios';
import PrefixIcon from 'components/forms/input/prefix-icon';
import JobQuestion from 'components/forms/job/job-question';
import useAddTipReply from 'components/requests/company/useAddTipReply';
import { handleRequestError, handleRequestSuccess, isValid } from 'components/utils/methods/MyMethods';
import { useUserStore } from 'components/utils/stores/userStore';
import { t } from 'i18next';
import { Fragment, useEffect, useState } from 'react';
import Loading from '../../animations/loading';
import NoData from '../no-data/nodata';
import { sendAxiosPostRequest } from 'components/utils/methods/myRequestHandlers';

export default function JobQuestionsOverlay(props) {
    // # STORES
    const userStore = useUserStore();

    // # CLIENT STATES
    const [jobMessages, setJobmessages] = useState(null);
    const [searchFilter, setSearchFilter] = useState("");

    // # SERVER STATES
    const { refetch: jobMessagesRefetch, isLoading: jobMessagesIsLoading, isRefetching: jobMessagesIsRefetching, isError } = useGetJobMessages({ setData: setJobmessages, jobId: !props.jobItem ? null : props.jobItem.jobId });

    useEffect(() => {
        if (props.overlayState) {
            setJobmessages(null);
            jobMessagesRefetch();
            var elem = document.querySelector('body');
            elem.style.overflow = "hidden"
        } else {
            props.setCurrentJobs(false);
            props.getJobs();
            var elem = document.querySelector('body');
            elem.style.overflow = "auto"
        }
    }, [props.overlayState]);


    return (
        <>
            {
                props.overlayState === false ? null :
                    <div className="overlay">
                        <div className="overlay-content d-flex align-items-center pt-2">
                            <div className="container px-1">
                                <span className='close' onClick={() => props.setOverlay(false)}></span>
                                <div>
                                    <h1>
                                        {t("business_company_jobs_questions_overlay_title")}
                                    </h1>
                                    <Input
                                        allowClear
                                        onChange={(e) => setSearchFilter(e.target.value)}
                                        placeholder={t("business_main_search")}
                                        prefix={<PrefixIcon value="icon-search" />}
                                    />
                                    <div className='mt-3'>
                                        <div className="mt-4">
                                            {
                                                jobMessagesIsLoading || jobMessagesIsRefetching ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                                                    isError ? <NoData error /> : /*#VDG*/
                                                        !jobMessages || jobMessages.length <= 0 ? <div className="my-5"><NoData wise="sad" text={t("business_company_jobs_questions_nodata")} /> </div> :
                                                            (jobMessages).filter(filter => filter.feedback.toLowerCase().includes(searchFilter.toLowerCase())).length === 0 ?
                                                                <div className="my-5"><NoData wise="search" text={t("business_company_jobs_questions_search_nodata")} /> </div> :
                                                                (jobMessages).filter(filter => filter.feedback.toLowerCase().includes(searchFilter.toLowerCase())).map((item, i) => <Fragment key={i}>
                                                                    <JobQuestion data={item} getData={jobMessagesRefetch} />
                                                                </Fragment>
                                                                )
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
            }
        </>
    );
}


export const useGetJobMessages = (props) => {
    const userStore = useUserStore();
    return useQuery({
        queryKey: ["getJobMessages"],
        queryFn: () => sendAxiosPostRequest({
            route: "v5/select/company/job/feedback",
            body: {
                CompanyId: userStore.companyData.id,
                UserId: userStore.userData.id,
                JobId: props.jobId || null
            },
            callback: (res) => {
                console.log(res)
                if (res && res.data && res.data.status === 200 && res.data.instance && res.data.instance.length > 0) {
                    var newData = [];
                    res.data.instance.forEach(element => {
                        // element.talentUserId = element.tipTalentUserId;
                        newData.push(element)
                    });
                    props.setData(newData.sort((a, b) => {
                        if (!a.createdOn) {
                            return -1;
                        }
                        if (!b.createdOn) {
                            return 1;
                        }
                        return 0;
                        // return a.replyCreatedOn > b.replyCreatedOn ? -1 : 1;
                    }));
                }
                else if (res.data.status === 290) {
                    props.setData(null)
                }
                return res.data.instance
            }
        }),
        refetchOnWindowFocus: false,
        retry: 2,
        enabled: props.enabled || isValid([userStore.userData, userStore.companyData, props.jobItem], [undefined, null]),
        onError: (res) => handleRequestError({ response: res }),
    })
}
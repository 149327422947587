import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Form, Input, Select } from "antd";
import axios from "axios";
import PrefixIcon from "components/forms/input/prefix-icon";
import GuestRedirectLayout from "components/layouts/page-layouts/guest-redirect";
import useGetNationalitySizeOptions from "components/requests/data/useGetNationalitySizeOptions";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import produce from "immer";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../components/animations/loading";
import AlertMessage from "../../components/forms/alerts/alert-message";
import NoData from "../../components/layouts/no-data/nodata";
import { handleRequestError, handleRequestSuccess, hasPermission } from "../../components/utils/methods/MyMethods";
import { toast } from "react-toastify";
import LayoutCompany from "./layout-company";

export default function CompanyRecruitmentHistoryPage(props) {
    // # STORES
    const userStore = useUserStore();


    // # CLIENT STATES
    const [form] = Form.useForm();
    const [message, setMessage] = useState(null);
    const [allData, setAllData] = useState(null);
    const [allNationalitySizes, setAllNationalitySizes] = useState(null);
    const [formObject, setFormObject] = useState({
        genderX: null,
        genderM: null,
        genderF: null,
        seniors: null,
        graduates: null,
        young: null,
        nationalitySize: null,
    });
    // PERMISSIONS
    const [PM_RecruitmentHistoryView, setPM_RecruitmentHistoryView] = useState(false);
    const [PM_RecruitmentHistoryEdit, setPM_RecruitmentHistoryEdit] = useState(false);
    const [PM_PlanView, setPM_PlanView] = useState(false);
    const [PM_PromiseView, setPM_PromiseView] = useState(false);
    const [PM_ProcedureView, setPM_ProcedureView] = useState(false);

    // # UTILS
    const navigate = useNavigate();
    const InputLocalProps = {
        onWheel: () => document.activeElement.blur(),
        disabled: !PM_RecruitmentHistoryEdit,
        type: "number",
        placeholder: "-",
        max: 100,
        min: 0,
        addonAfter: "%",
    }
    const editFormItem = (key, value, setInit) => {
        if (setInit) {
            form.setFieldsValue({
                [key]: value
            });
        }
        setFormObject(produce(draft => {
            draft[key] = value;
        }))
    }

    // # SERVER STATES
    const mutateCompanyHistory = useEditCompanyHistory({ formObject })
    const { } = useGetNationalitySizeOptions({ setData: setAllNationalitySizes })
    const { refetch: companyHistoryRefetch, isLoading: companyHistoryIsLoading, isRefetching: companyHistoryIsRefetching, isError } = useGetCompanyHistory({ setData: setAllData, allNationalitySizes, editFormItem, form: form });

    function handleSubmit() {
        if (formObject.genderF !== null || formObject.genderM !== null) {
            if (formObject.genderF !== "" && formObject.genderM !== "" && formObject.genderX !== "") {
                if (parseInt(formObject.genderF) + parseInt(formObject.genderM) > 100) {
                    throw toast.warn(t("business_tooltip_company_history_gender_uneven"));
                }
                if (parseInt(formObject.genderF) + parseInt(formObject.genderM) + parseInt(formObject.genderX) !== 100) {
                    throw toast.warn(t("business_tooltip_company_history_gender_uneven"));
                }
            }
            else {
                editFormItem("genderX", 0, true);
            }

        }
        mutateCompanyHistory.mutate()

    }

    useEffect(() => {
        if (!userStore.userData) { return }
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_RECRUITHISTORY", setPM_RecruitmentHistoryView);
        hasPermission(userStore.userData.permissionsData, "PM_EDIT_RECRUITHISTORY", setPM_RecruitmentHistoryEdit);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_SUBSCRIPTIONS", setPM_PlanView);

        // PERMISSION_COMPANY_HANDLER
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_VALUES", setPM_PromiseView);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_PROCEDURES", setPM_ProcedureView);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_RECRUITHISTORY", setPM_RecruitmentHistoryView, navigate);
    }, [userStore.userData]);


    // START AT TOP OF PAGE ON LOAD
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <GuestRedirectLayout redirectSmallSubsciption>
            <LayoutCompany
                title={t("business_company_sidebar_history_title")}
                subtitle={t("business_company_history_subtitle")}
            >
                <div className="card mt-3">
                    {
                        !PM_RecruitmentHistoryView ? <div className="d-flex justify-content-center my-5"><NoData wise="cross" text={t("business_extra_permission_required")} /></div> :
                            companyHistoryIsRefetching || companyHistoryIsLoading ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                                isError ? <NoData error /> /*#VDG */ :
                                    <>
                                        <Form
                                            form={form}
                                            className="my-3 mx-2"
                                            name="form-company-history"
                                            layout="vertical"
                                            onFinish={() => handleSubmit()}
                                        >
                                            {
                                                !message ? null :
                                                    <AlertMessage Message={message} setMessage={setMessage} />
                                            }
                                            <div className="flex flex-row justify-content-start align-items-end flex-wrap mb-3">
                                                <Form.Item
                                                    label={t("business_main_gender")}
                                                    name="genderM"
                                                    style={{ width: "30%", minWidth: "160px" }}
                                                    className="px-1 mb-2 mobile-friendly"
                                                >
                                                    <Input
                                                        {...InputLocalProps}
                                                        onChange={(e) => editFormItem("genderM", e.target.value)}
                                                        prefix={<PrefixIcon value="icon-male" />}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name="genderF"
                                                    style={{ width: "30%", minWidth: "160px" }}
                                                    className=" px-1 mb-2 mobile-friendly"
                                                >
                                                    <Input
                                                        {...InputLocalProps}
                                                        onChange={(e) => editFormItem("genderF", e.target.value)}
                                                        prefix={<PrefixIcon value="icon-female" />}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name="genderX"
                                                    style={{ width: "30%", minWidth: "160px" }}
                                                    className=" px-1 mb-2 mobile-friendly"
                                                >
                                                    <Input
                                                        {...InputLocalProps}
                                                        onChange={(e) => editFormItem("genderX", e.target.value)}
                                                        prefix={<PrefixIcon value="icon-gender" />}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="d-flex flex-wrap">
                                                <Form.Item
                                                    label={t("business_company_history_panel_graduates_title")}
                                                    name="young"
                                                    className="w-50 mobile-friendly pr-2"
                                                >
                                                    <Input
                                                        {...InputLocalProps}
                                                        onChange={(e) => editFormItem("young", e.target.value)}
                                                        prefix={<PrefixIcon value="icon-young-grad" />}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label={t("business_main_degree")}
                                                    name="graduates"
                                                    className="w-50 mobile-friendly pr-2"
                                                >
                                                    <Input
                                                        {...InputLocalProps}
                                                        onChange={(e) => editFormItem("graduates", e.target.value)}
                                                        prefix={<PrefixIcon value="icon-graduate" />}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="d-flex flex-wrap">
                                                <Form.Item
                                                    label={t("business_company_history_panel_senior_title")}
                                                    name="seniors"
                                                    className="w-50 mobile-friendly pr-2"
                                                >
                                                    <Input
                                                        {...InputLocalProps}
                                                        onChange={(e) => editFormItem("seniors", e.target.value)}
                                                        prefix={<PrefixIcon value="icon-account" />}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label={t("business_company_history_panel_nationalities_title")}
                                                    name="nationalitySize"
                                                    className="w-50 mobile-friendly pr-2"
                                                >
                                                    <Select
                                                        options={allNationalitySizes}
                                                        onSelect={(value, option) => editFormItem("nationalitySize", option.value)}
                                                    />
                                                </Form.Item>
                                            </div>

                                            {
                                                !PM_RecruitmentHistoryEdit ? null :
                                                    <div className="btns-right mt-3">
                                                        <Button
                                                            htmlType="submit"
                                                            className="btn-primary btn mb-2 mr-2"
                                                            loading={mutateCompanyHistory.isLoading}
                                                        >
                                                            {t("business_main_save")}
                                                        </Button>
                                                        <div className="mb-2 mr-2">
                                                            <div onClick={() => companyHistoryRefetch()} className="btn-secondary btn ml-2 d-inline-block">{t("business_main_cancel")}</div>
                                                        </div>
                                                    </div>
                                            }
                                        </Form>
                                    </>
                    }
                </div>
            </LayoutCompany>
        </GuestRedirectLayout>
    );
}

const useEditCompanyHistory = (props) => {
    const userStore = useUserStore();
    return useMutation({
        mutationKey: ["mutateCompanyHistory"],
        mutationFn: () => axios.post("company/history/update", {
            CompanyId: userStore.companyData.id,
            GenderM: parseInt(props.formObject.genderM),
            GenderF: parseInt(props.formObject.genderF),
            Young: parseInt(props.formObject.young),
            Old: parseInt(props.formObject.seniors),
            Graduates: parseInt(props.formObject.graduates),
            // International: (props.international),
            NationalitySizeId: (props.formObject.nationalitySize)
        }),
        retry: false,
        onSuccess: (res) => handleRequestSuccess({ response: res, hasAlert: true, isSubmit: true }),
        onError: (res) => handleRequestError({ response: res, hasAlert: true, isSubmit: true }),
    })
}

const useGetCompanyHistory = (props) => {
    const userStore = useUserStore();
    return useQuery({
        queryKey: ["getCompanyHistory"],
        queryFn: () => axios.post("company/history", {
            CompanyId: userStore.companyData.id,
            Iso: userStore.clientData.language.isoCode
        }),
        refetchOnWindowFocus: false,
        retry: 2,
        enabled: !!userStore.companyData && !!props.allNationalitySizes,
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {
                var data = res.data.instance;
                props.setData(data);
                if (!data.genderF && !data.genderM) {
                    props.editFormItem("genderX", null, true);
                } else {
                    props.editFormItem("genderX", 100 - data.genderF - data.genderM, true);
                }
                props.editFormItem("genderM", data.genderM === undefined ? null : data.genderM, true);
                props.editFormItem("genderF", data.genderF === undefined ? null : data.genderF, true);
                props.editFormItem("seniors", data.old, true);
                props.editFormItem("graduates", data.graduates, true);
                props.editFormItem("young", data.young, true);
                var newNational = props.allNationalitySizes.filter(item => (item.id).toString() === (data.nationalitySizeId).toString());
                console.log(newNational, props.allNationalitySizes)
                props.editFormItem("nationalitySize", newNational[0].id, true);
            }
        }),
        onError: (res) => handleRequestError({ response: res })
    })
}
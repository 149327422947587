import { useMutation } from "@tanstack/react-query";
import { Button, Divider, Form, Modal, Switch } from "antd";
import axios from "axios";
import Loading from "components/animations/loading";
import NoData from "components/layouts/no-data/nodata";
import Panel, { defaultModalContent } from "components/modules/panel/panel";
import Rating from "components/modules/rating/rating";
import { handleRequestError, handleRequestSuccess, useMyMutation } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import { useEffect, useState } from "react";

export default function RatingAmountPanel(props) {
    // # STORES
    const userStore = useUserStore();

    // # SERVER STATES
    // const mutateSettingsToggle = useMyMutation({
    //     route: `v3/business/update/businesses/${userStore.companyData.id}/flags/:replace_flag/toggle`,
    //     method: "get",
    //     callback: () =>userStore.toggleFlagDataItem("ToggleScorePraise")
    // })

    const mutateSettingsToggle = useMutation({
        mutationKey: ["mutateSettingsToggle"],
        mutationFn: (propsFn) => axios.get(`v3/business/update/businesses/${userStore.companyData.id}/flags/${propsFn.flag}/toggle`),
        retry: 0,
        onError: (res) => handleRequestError({ response: res, hasAlert: true }),
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {
                console.log(res)
                userStore.toggleFlagDataItem(res.data.instance.replace("Toggle", "toggle"))
            }
        }),
    })

    // # CLIENT STATES
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(defaultModalContent);

    return <>

        <Modal
            closable
            onCancel={() => setIsModalOpen(false)}
            title={modalContent.titleKey ? t(modalContent.titleKey) : modalContent.title}
            open={isModalOpen}
            okButtonProps={{ style: { display: "none" } }}
            cancelButtonProps={{ style: { display: "none" } }}
        >
            {typeof modalContent.children === "function" ? modalContent.children({ userStore: userStore, mutateSettingsToggle: mutateSettingsToggle }) : modalContent.children}
        </Modal>
        <Panel
            {...props.panelProps}
            setIsModalOpen={setIsModalOpen}
            setModalContent={setModalContent}
            title={<>{t("business_dashboard_panel_total_rating_amount_title")}</>}
        >
            {
                props.data === undefined ? <Loading center /> :
                    <div style={{ height: "100%" }} className="flex align-items-center justify-content-center flex-column mb-3">
                        <h2 className="fs-h1 mb-2">{props.data && props.data.totalCount ? props.data.totalCount : "-"}</h2>
                        <p className="mb-1">{t("business_dashboard_total_score")}</p>
                        {/* <Rating rating={null} amount={props.data.totalCount} noamount /> */}
                        <Rating rating={!props.data ? 0 : !props.data.scoreGeneral ? null : props.data.scoreGeneral} noamount />
                        {/* ISSUE  1361 - remove this */}
                        {/* <Divider className="mb-3" />
                        <div className="mb-2">
                            <p className="mb-1 font-special">
                                {
                                    !props.data.lastXDaysCount || props.data.lastXDaysCount === 0 ?
                                        <span className="font-special">- {t("business_dashboard_past_month").toLowerCase()}</span> :
                                        props.data.lastXDaysCount > 0 ?
                                            <><span className={`color-lime fw-bold font-special`}>+{props.data.lastXDaysCount}</span> {t("business_dashboard_past_month").toLowerCase()}</> :
                                            <><span className={`color-red fw-bold font-special`}>-{props.data.lastXDaysCount}</span> {t("business_dashboard_past_month").toLowerCase()}</>
                                }
                            </p>
                            <p className="mb-1 font-special">
                                {
                                    !props.data.lastXDaysB4Count || props.data.lastXDaysB4Count === 0 ?
                                        <span className="font-special">- {t("business_dashboard_past_month").toLowerCase()}</span> :
                                        props.data.lastXDaysB4Count > 0 ?
                                            <><span className={`color-lime fw-bold font-special`}>+{props.data.lastXDaysB4Count}</span> {t("business_dashboard_compared_to_prev_month").toLowerCase()}</> :
                                            <><span className={`color-red fw-bold font-special`}>-{props.data.lastXDaysB4Count}</span> {t("business_dashboard_compared_to_prev_month").toLowerCase()}</>
                                }
                            </p>
                        </div> */}
                    </div>
            }
        </Panel>
    </>
}

export const settingsRatingAmountModalContent = {
    titleKey: "business_dashboard_panel_total_rating_amount_overlay_settings",
    children: (props) => {
        const userStore = props.userStore;
        const formItemClassName = "mb-3";

        return <>
            <div className="mb-2 mt-3">
                {

                    userStore.flagData.toggleScorePraiseVisible ?
                        <div className="mb-3">
                            <p className="mb-2">{t("business_dashboard_panel_total_rating_amount_overlay_settings_switch_claps")}:</p>
                            <Switch
                                checked={userStore.flagData.toggleScorePraise ? "checked" : false}
                                loading={props.mutateSettingsToggle.isLoading}
                                disabled={userStore.flagData.toggleScoreStar === false}
                                onClick={() => {
                                    // no click event - atleast one should be activated - people cant bypass by removing html in inspector
                                    userStore.flagData.toggleScoreStar === false ? console.warn("You cant just edit the HTML") :
                                        props.mutateSettingsToggle.mutate({ flag: "ToggleScorePraise" })
                                }} />

                        </div> : null
                }
                {
                    userStore.flagData.toggleScoreStarVisible ?
                        <div className="mb-3">
                            <p className="mb-2">{t("business_dashboard_panel_total_rating_amount_overlay_settings_switch_ratings")}:</p>
                            <Switch
                                checked={userStore.flagData.toggleScoreStar ? "checked" : false}
                                loading={props.mutateSettingsToggle.isLoading}
                                disabled={userStore.flagData.toggleScorePraise === false}
                                onClick={(checked) => {
                                    // no click event - atleast one should be activated - people cant bypass by removing html in inspector
                                    userStore.flagData.toggleScorePraise === false ? console.warn("You cant just edit the HTML") :
                                        props.mutateSettingsToggle.mutate({ flag: "ToggleScoreStar" })
                                }} />
                        </div>
                        : null
                }
            </div>

            {
            }
            {/* {
                    userStore.flagData.toggleScoreTextVisible ?
                        <Form.Item
                            className={formItemClassName}
                            name="tips"
                            label={t("business_dashboard_panel_total_rating_amount_overlay_settings_switch_tips") + ":"}
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item> : null
                } */}

        </>
    }
}
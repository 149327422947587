import { useMutation } from "@tanstack/react-query";
import { Button, Form, Input } from "antd";
import axios from "axios";
import PrefixIcon from "components/forms/input/prefix-icon";
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AlertMessage from "../../components/forms/alerts/alert-message";
import GuestRedirectLayout from "../../components/layouts/page-layouts/guest-redirect";
import LayoutAccount from "./layout-account";

export default function AccountSecurityPage(props) {
    // # STORES
    const userStore = useUserStore();

    // # CLIENT STATES
    const [form] = Form.useForm();
    const [message, setMessage] = useState(null);
    const [currentPass, setCurrentPass] = useState();
    const [newPass, setNewPass] = useState();
    const [newPass2, setNewPass2] = useState();

    // # UTILS
    const navigate = useNavigate();

    // # SERVER STATES
    const mutateAccountPassword = useEditAccountPassword({
        currentPass, newPass, form
    });

    // START AT TOP OF PAGE ON LOAD
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    var pasThresh = <div>       {t("business_extra_password_conditions_unmet")}
        <ul>
            <li>{t("business_extra_password_condition1")}</li>
            <li>{t("business_extra_password_condition2")}</li>
            <li>{t("business_extra_password_condition4")}</li>
            <li>{t("business_extra_password_condition3")}</li>
        </ul>
    </div>

    const submitReset = () => {
        if (newPass !== newPass2) {
            return toast.warn(t("business_password_reset_mismatch"))
        }
        if (!PasswordValidate(newPass)) {
            return toast.warn(pasThresh);
        }

        mutateAccountPassword.mutate();
    }

    function PasswordValidate(password) {
        var re = {
            'capital': /[A-Z]/,
            'digit': /[0-9]/,
            'special': /\W/,
        };
        return re.capital.test(password) &&
            re.digit.test(password) &&
            re.special.test(password) &&
            password.length >= 8;
    }

    return (
        <GuestRedirectLayout>
            <LayoutAccount
                title={t("business_account_security_title")}
                subtitle={t("business_account_security_title")}
            >

                <div className="mt-3 card">
                    <Form
                        form={form}
                        className="my-4 px-4"
                        name="form-account-security"
                        layout="vertical"
                        onFinish={() => submitReset()}
                    >
                        {
                            !message ? null :
                                <AlertMessage message={message} setMessage={setMessage} />
                        }
                        <Form.Item
                            label={t("business_password_current_password")}
                            rules={[{ required: true, message: false }]}
                            name="password"
                        >
                            <Input.Password
                                prefix={<PrefixIcon value="icon-lock fs-small" />}
                                onChange={(e) => setCurrentPass(e.target.value)}
                                placeholder={t("business_main_password")}
                            />
                        </Form.Item>
                        <Form.Item
                            label={t("business_password_reset_password_new")}
                            rules={[{ required: true, message: false }]}
                            name="passwordnew1"
                        >
                            <Input.Password
                                prefix={<PrefixIcon value="icon-lock fs-small" />}
                                onChange={(e) => setNewPass(e.target.value)}
                                placeholder={t("business_main_password")}
                            />
                        </Form.Item>
                        <Form.Item
                            label={t("business_password_confirm_new_password")}
                            rules={[{ required: true, message: false }]}
                            name="passwordnew2"
                        >
                            <Input.Password
                                prefix={<PrefixIcon value="icon-lock fs-small" />}
                                placeholder={t("business_main_password")}
                                onChange={(e) => setNewPass2(e.target.value)}
                            />
                        </Form.Item>
                        <div className="btns-right">
                            <Button
                                htmlType="submit"
                                className="btn btn-primary mb-2 mr-2"
                                loading={mutateAccountPassword.isLoading}
                            >
                                {t("business_main_save")}
                            </Button>
                            <div onClick={() => form.resetFields()} className="btn-secondary mb-2 btn d-inline-block">{t("business_main_cancel")}</div>
                        </div>

                    </Form>
                </div>

            </LayoutAccount>
        </GuestRedirectLayout>
    );
}

function handleEditPasswordStatus(status) {
    switch (parseInt(status)) {
        // 461 PASSWORD INCORRECT 
        case 283:
        case 484:
            throw toast.error(t("business_password_incorrect"));
        default: break;
    }
}

const useEditAccountPassword = (props) => {
    const userStore = useUserStore();
    return useMutation({
        mutationKey: ["mutateAccountPassword"],
        mutationFn: () => axios.post("account/password/update", {
            UserId: userStore.userData.id,
            UserOldPassword: props.currentPass,
            UserNewPassword: props.newPass
        }),
        retry: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, isSubmit: true, callback: () => {
                props.form.resetFields();
            }, customCallback: () => handleEditPasswordStatus(res.data.status),
            hasAlert: true,
            isSubit: true
        }),
        onError: (res) => handleRequestError({ response: res, isSubmit: true, customCallback: () => handleEditPasswordStatus(res.response.data.status) }),
    })
}
import { useQuery } from "@tanstack/react-query";
import Loading from "components/animations/loading";
import NoData from "components/layouts/no-data/nodata";
import GuestRedirectLayout from "components/layouts/page-layouts/guest-redirect";
import { sendAxiosPostRequest } from "components/utils/methods/myRequestHandlers";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import { useEffect, useState } from "react";
import ReactDOMServer from 'react-dom/server';
import labelImg from '../../assets/images/wise-label.png';
import AlertMessage from '../../components/forms/alerts/alert-message';
import InputCopy from "../../components/forms/input/input-copy";
import { hasPermission } from "../../components/utils/methods/MyMethods";
import envir from '../../env.json';
import WidgetCompany from "../widget";
import LayoutCompany from "./layout-company";

export default function CompanySharePage(props) {
    // # STORES
    const userStore = useUserStore();

    // # CLIENT STATES
    const [message, setMessage] = useState(null);
    const [allShareData, setAllShareData] = useState(null);
    // PERMISSION    
    const [PM_PlanView, setPM_PlanView] = useState(false);
    const [PM_VIEW_CEKEY, setPM_VIEW_CEKEY] = useState(false);
    const [PM_EDIT_CEKEY, setPM_EDIT_CEKEY] = useState(false);
    const [PM_PromiseView, setPM_PromiseView] = useState(false);
    const [PM_ProcedureView, setPM_ProcedureView] = useState(false);
    const [PM_RecruitmentHistoryView, setPM_RecruitmentHistoryView] = useState(false);

    // # SERVER STATES
    const { isError, isLoading } = useGetCompanyShareData({ setData: setAllShareData });

    useEffect(() => {
        if (!userStore.userData) { return }
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_SUBSCRIPTIONS", setPM_PlanView);

        // PERMISSION_COMPANY_HANDLER
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_VALUES", setPM_PromiseView);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_PROCEDURES", setPM_ProcedureView);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_RECRUITHISTORY", setPM_RecruitmentHistoryView);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_CEKEY", setPM_VIEW_CEKEY);
        hasPermission(userStore.userData.permissionsData, "PM_EDIT_CEKEY", setPM_EDIT_CEKEY);
    }, [userStore.userData]);

    // START AT TOP OF PAGE ON LOAD
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function getHtml(component) {
        var htmlOfComponent = ReactDOMServer.renderToStaticMarkup(component);
        return htmlOfComponent
    }

    return (
        <GuestRedirectLayout redirectSmallSubsciption>
            <LayoutCompany
                title={t("business_company_share_title")}
                subtitle={t("business_company_share_subtitle")}
            >

                <div className="card mt-3">
                    {
                        isLoading ? <Loading center /> :
                            isError ? <NoData error /> :
                                // companyAllRaw === false ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                                // !PM_CompanyInfoView ? <div className="d-flex justify-content-center my-5"><NoData wise="cross" text={t("business_extra_permission_required")} /></div> :
                                <div className="mx-4 my-3 mobile-friendly-container">
                                    {
                                        !message ? null :
                                            <AlertMessage message={message} setMessage={setMessage} />
                                    }
                                    {/**VINCE */}
                                    {
                                        // userStore.companyData.isDemo ? null :
                                        !PM_VIEW_CEKEY ? null : <div className="mb-10">
                                            <h3 className='mb-4 icon-width d-flex align-items-center'> <span className="icon-link icon round lime"></span> {t("business_company_share_ce_keys_title")}</h3>
                                            {allShareData === null || allShareData.urlAgencyKeys === undefined || allShareData.urlAgencyKeys === null
                                                ? null : allShareData.urlAgencyKeys.map(item => {
                                                    return <div className="mb-3">
                                                        <div className="flex flex-wrap align-items-center">
                                                            <p className="mb-2 mr-2">{item.agencyName}</p>
                                                        </div>
                                                        <InputCopy
                                                            value={item.urlSurvey}
                                                        />
                                                    </div>;
                                                })
                                            }
                                            {
                                                !PM_EDIT_CEKEY ? null :
                                                    <div className="my-4">
                                                        <a target="_blank" rel="noopener noreferrer" className="btn btn-primary d-inline-block" href={`mailto:${envir.Variables.WiseEmail}?subject=${t("business_cekey_mail_subject")}&body=${t("business_cekey_mail_body")}`}>{t("business_cekey_request")}</a>
                                                    </div>
                                            }
                                        </div>
                                    }
                                    <div className="mb-10">
                                        <h3 className='mb-4 icon-width d-flex align-items-center'> <span className="icon-link icon round lime"></span> {t("business_company_share_panel_link_title")}</h3>
                                        <div>
                                            <InputCopy
                                                value={allShareData === false || allShareData === null ? "-" : allShareData.urlCompany}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-10">
                                        <h3 className='mb-3 icon-width d-flex align-items-center'> <span className="icon-link icon round lime"></span> {t("business_company_share_panel_ats_title")}</h3>
                                        <p>{t("business_company_share_panel_ats_description")}</p>
                                        <div>
                                            <a target="_blank" rel="noopener noreferrer" className="btn btn-primary d-inline-block" href={(envir.Variables.WiseShareStorage) + "wisebusiness_voorbeeldmail-voor-ats.pdf"}>{t("business_company_share_panel_ats_link")}</a>
                                        </div>
                                    </div>
                                    <div>
                                        <h3 className='mb-4 icon-width d-flex align-items-center'> <span className="icon-wise fs-small icon round lime"></span> {t("business_company_share_panel_label_title")}</h3>
                                        <div className="pb-10">
                                            <label className="input-label">
                                                <div className="label-text">{t("business_company_share_panel_label_subtitle")}:</div>
                                                <InputCopy
                                                    value={allShareData === false || allShareData === null ? "-" :
                                                        // "<iframe src='" + allShareData.urlEmbed + "'></iframe>"
                                                        getHtml(<WidgetCompany urlCompany={allShareData.urlEmbed} />)
                                                    }
                                                />
                                            </label>
                                            <div className="d-flex justify-content-end">
                                                <div className="d-flex flex-row flex-wrap">
                                                    <a href="https://wisepeoplestorage.blob.core.windows.net/assets/public/wisebusiness_stappenplan.pdf" target={"_blank"} className="link mr-3 mb-2" rel="noreferrer">{t("business_company_share_panel_label_guide")}</a>
                                                    <a href={allShareData === null ? "#" : allShareData.urlZip === undefined ? "#" : allShareData.urlZip} target={"_blank"} download className="link mr-3 mb-2" rel="noreferrer">{t("business_company_share_panel_label_assets")}</a>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="my-4 mx-4">
                                            <div className="mt-8 mb-15">
                                                <div className="d-flex flex-row flex-wrap justify-content-between align-items-center">
                                                    <div className="pr-4 pb-4 w-50 lg-friendly d-flex justify-content-center">
                                                        <img src={labelImg} alt="wise-label" style={{ width: "90%", maxWidth: "250px" }} />
                                                    </div>
                                                    <div className="w-50 lg-friendly">
                                                        <h3>{t("business_company_share_panel_label_reason_title")}</h3>
                                                        <ul className='list-check mt-3 pl-1'>
                                                            <li className='mb-1 icon-width'><span className="icon-check icon-nospace pr-1 lime icon" /> {t("business_company_share_panel_label_reason1")}</li>
                                                            <li className='mb-1 icon-width'><span className="icon-check icon-nospace pr-1 lime icon" /> {t("business_company_share_panel_label_reason2")}</li>
                                                            <li className='mb-1 icon-width'><span className="icon-check icon-nospace pr-1 lime icon" /> {t("business_company_share_panel_label_reason3")}</li>
                                                        </ul>

                                                        <div className="d-flex justify-content-start">
                                                            <div className="d-flex flex-row flex-wrap">
                                                                <a href="https://wisepeoplestorage.blob.core.windows.net/assets/public/wisebusiness_stappenplan.pdf" target={"_blank"} className="link mr-3 mb-2" rel="noreferrer">{t("business_company_share_panel_label_guide")}</a>
                                                                <a href={allShareData === null ? "#" : allShareData.urlZip === undefined ? "#" : allShareData.urlZip} target={"_blank"} download className="link mr-3 mb-2" rel="noreferrer">{t("business_company_share_panel_label_assets")}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="my-10 mb-15">
                                                <div className="pb-3">
                                                    <h3>{t("business_company_share_panel_label_embed_title")}</h3>
                                                    <div>
                                                        <p>
                                                            {t("business_company_share_panel_label_embed_description")}
                                                        </p>
                                                        {/* <div>
                                                                                <div className="d-flex flex-row flex-wrap">
                                                                                    <a href="https://wisepeoplestorage.blob.core.windows.net/assets/public/wisebusiness_stappenplan.pdf" className="link mr-3 mb-2">{t("business_company_share_panel_label_guide")}</a>
                                                                                    <a href={allShareData === null ? "#" : allShareData.urlZip === undefined ? "#" : allShareData.urlZip} target={"_blank"} download className="link mr-3 mb-2">{t("business_company_share_panel_label_assets")}</a>
                                                                                </div>
                                                                            </div> */}
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="d-block">
                                                        {allShareData === false || allShareData === null ? "-" :
                                                            <WidgetCompany urlCompany={allShareData.urlEmbed} example />
                                                        }
                                                    </div>
                                                    {/* <NoData text={"Widget"} wise="notfound" /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                    }
                </div>
            </LayoutCompany>
        </GuestRedirectLayout>
    );
}

export const useGetCompanyShareData = (props) => {
    const userStore = useUserStore();
    return useQuery({
        queryKey: ["getCompanyShareData"],
        queryFn: () => sendAxiosPostRequest({
            route: "company/shares",
            body: {
                CompanyId: userStore.companyData.id,
            },
            callback: (res) => {
                if (props && props.setData) {
                    props.setData(res.data.instance);
                }
                return res.data.instance;
            }
        }),
        refetchOnWindowFocus: false,
        retry: 2,
        enabled: !!userStore.companyData,
    })
}
import { InfoCircleOutlined } from '@ant-design/icons';
import { useMutation } from "@tanstack/react-query";
import { Button, Form, Input, Modal, Popconfirm, Select, Tag, Tooltip } from "antd";
import ImgCrop from "antd-img-crop";
import Dragger from "antd/es/upload/Dragger";
import axios from "axios";
import chalk from "chalk";
import InputPhone from "components/forms/input/input-ant-phone";
import PrefixIcon from "components/forms/input/prefix-icon";
import { FileUploadSingleConditionalsCheck } from 'components/modules/upload-handlers/FileUploadSingle';
import useEditCompanyLogo from 'components/requests/company/useEditCompanyLogo';
import useGetCompanyData from "components/requests/company/useGetCompanyData";
import useGetCompanySizeOptions from "components/requests/data/useGetCompanySizeOptions";
import useGetCountryOptions from "components/requests/data/useGetCountryOptions";
import useGetSectorsOptions from "components/requests/data/useGetSectorsOptions";
import { handleTranslationLabel, handleUploadPicture } from "components/utils/methods/AppMethods";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import produce from "immer";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import defaultLogo from '../../assets/images/logo192.png';
import Loading from '../../components/animations/loading';
import AlertMessage from '../../components/forms/alerts/alert-message';
import NoData from "../../components/layouts/no-data/nodata";
import GuestRedirectLayout from "../../components/layouts/page-layouts/guest-redirect";
import { getBase64, handleRequestError, handleRequestSuccess, hasPermission, isValid } from "../../components/utils/methods/MyMethods";
import LayoutCompany from "./layout-company";
import { toast } from "react-toastify";

export default function CompanyInfoPage(props) {
    // # STORES
    const userStore = useUserStore()

    // # UTILS
    const navigate = useNavigate();
    const maxCompanyTags = 5;
    const editFormItem = (key, val, setInit) => {
        if (setInit) {
            form.setFieldsValue({
                [key]: val
            });
        }
        setFormObject(produce(draft => {
            draft[key] = val;
        }))
    }
    const editPhone = (val) => editFormItem("phone", val);

    // # CLIENT STATES
    const [form] = Form.useForm();
    const [message, setMessage] = useState(null);
    const [companyAllRaw, setCompanyAllRaw] = useState(false);
    const [rawDataIsHandled, setRawDataIsHandled] = useState(false);
    const [CountryGeneral, setCountryGeneral] = useState(false); // LIST VALUES
    const [IndustriesGeneral, setIndustriesGeneral] = useState(false); // LIST VALUES
    const [TeamSizeGeneral, setTeamSizeGeneral] = useState(false); // LIST VALUES
    const [formObject, setFormObject] = useState({
        companyName: "",
        companyVat: "",
        zipCode: "",
        jobSite: "",
        site: "",
        phone: "",
        email: "",
        address1: "",
        address2: "",
        city: "",
        logo: null,
        country: null,
        industry: null,
        jobContact: null,
        size: null
    });
    const [CompanyLogo, setCompanyLogo] = useState();
    // PERMISSIONS
    const [PM_CompanyLogoEdit, setPM_CompanyLogoEdit] = useState(false);
    const [PM_CompanyInfoEdit, setPM_CompanyInfoEdit] = useState(false);
    const [PM_CompanyInfoView, setPM_CompanyInfoView] = useState(false);
    const PM_CompanyContactEdit = hasPermission(userStore.userData.permissionsData, "PM_EDIT_BUSINESS_CONTACT_DETAILS")
    const [PM_PlanView, setPM_PlanView] = useState(false);

    const [PM_PromiseView, setPM_PromiseView] = useState(false);
    const [PM_ProcedureView, setPM_ProcedureView] = useState(false);
    const [PM_RecruitmentHistoryView, setPM_RecruitmentHistoryView] = useState(false);

    // # SERVER STATES
    const mutateCompanyInfo = useEditCompanyInfo({ formObject });
    const mutateCompanyLogo = useEditCompanyLogo({ setLogo: setCompanyLogo })
    const { data: companyData, refetch: companyDataRefetch, isLoading: companyDataIsLoading, isRefetching: companyDataIsRefetching } = useGetCompanyData({ setData: setCompanyAllRaw });
    const { } = useGetCompanySizeOptions({ setData: setTeamSizeGeneral });
    const { } = useGetCountryOptions({ setData: setCountryGeneral });
    const { } = useGetSectorsOptions({ setData: setIndustriesGeneral });

    const onFinish = (file) => {
        if (file) {
            handleUploadPicture(file, 10, 0, 0, "business/logo").then((result) => {
                mutateCompanyLogo.mutate({ logoUrl: result })
            }).catch(() => {
                toast.error(t("business_extra_general_error2"));
                return;
            });
        }
        else {
            toast.error(t("business_extra_general_error2"));
            return;
        }
    }

    useEffect(() => {
        if (!userStore.userData) { return }
        hasPermission(userStore.userData.permissionsData, "PM_EDIT_BUSINESS_DETAILS", setPM_CompanyLogoEdit);
        hasPermission(userStore.userData.permissionsData, "PM_EDIT_BUSINESS_DETAILS", setPM_CompanyInfoEdit);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_BUSINESS_DETAILS", setPM_CompanyInfoView);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_SUBSCRIPTIONS", setPM_PlanView);

        // PERMISSION_COMPANY_HANDLER
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_VALUES", setPM_PromiseView);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_PROCEDURES", setPM_ProcedureView);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_RECRUITHISTORY", setPM_RecruitmentHistoryView);
    }, [userStore.userData]);


    // START AT TOP OF PAGE ON LOAD
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // update translations on change
    useEffect(() => {
        setTeamSizeGeneral(handleTranslationLabel({ list: TeamSizeGeneral, langId: userStore.clientData.language.id }));
        setIndustriesGeneral(handleTranslationLabel({ list: IndustriesGeneral, langId: userStore.clientData.language.id }));
    }, [userStore.clientData.language])

    // HANDLER FOR RAW COMPANY INFO DATA
    useEffect(() => {
        if (!isValid([companyAllRaw, IndustriesGeneral, TeamSizeGeneral, CountryGeneral], [null, undefined, false])) { return }
        try {

            console.log(chalk.bgBlue("Effect handle companyAllRaw"))
            setRawDataIsHandled(false);
            let newTags = [];
            var data = companyAllRaw;

            // // HANDLE JOB CONTACT -- DEPRICATED
            // (data.jobContactUserOptionsData).forEach((person) => {
            //     newJobContacts = [...newJobContacts, { id: person.id, label: person.firstName + " " + person.lastName + " - " + person.email, data: person }];
            // });;
            // setJobContactsGeneral(newJobContacts);
            setCompanyLogo(data.logoUrl);

            // CURRENTS
            var selectedCountry = null;
            var selectedIndustry = null;
            var selectedTeamSize = null;

            if (data.locationData.countryId !== null) {
                selectedCountry = CountryGeneral.filter(country => country.id.toLowerCase() === data.locationData.countryId.toLowerCase())[0];
            }
            if (data.industryId !== null) {
                selectedIndustry = IndustriesGeneral.filter(item => (item.id).toLowerCase() === data.industryId.toLowerCase())[0];
            }
            if (data.teamSizeId !== null) {
                selectedTeamSize = TeamSizeGeneral.filter(item => (item.id.toString()).toLowerCase() === (data.teamSizeId.toString()).toLowerCase())[0];
            }

            editFormItem("country", selectedCountry, true);
            editFormItem("industry", selectedIndustry, true);
            editFormItem("size", selectedTeamSize, true);
            editFormItem("companyName", data.companyName, true);
            editFormItem("companyVat", data.registrationNumber, true);
            editFormItem("zipCode", data.locationData.zipCode, true);
            editFormItem("jobSite", data.jobsite, true);
            editFormItem("site", data.website, true);
            editFormItem("phone", data.phone, true);
            editFormItem("email", data.email, true);
            editFormItem("address1", data.locationData.address1, true);
            editFormItem("address2", data.locationData.address2, true);
            editFormItem("city", data.locationData.city, true);
            if (data.jobContactUserData !== null) {
                var jobContact = data.jobContactUserData;
                editFormItem("jobContact", { id: jobContact.id, label: jobContact.firstName + " " + jobContact.lastName + " - " + jobContact.email, data: jobContact });
            }
            setRawDataIsHandled(true);

        } catch (error) {
            throw Error(error)
        }

    }, [companyAllRaw, companyDataIsRefetching, IndustriesGeneral, TeamSizeGeneral, CountryGeneral]);


    useEffect(() => {
        setCompanyAllRaw(false);
    }, [userStore.clientData]);

    useEffect(() => {
        if (!userStore.clientData.checkedRememberMe) { return }
        if (userStore.companyData === null) { return }
        companyDataRefetch()

    }, [userStore.companyData, userStore.clientData]);

    function handleCompanyInfoSubmit() {
        // EDIT COMPANY INFO
        mutateCompanyInfo.mutate();
    }

    return (
        <GuestRedirectLayout redirectSmallSubsciption>
            <LayoutCompany
                title={t("business_company_sidebar_general_title")}
                subtitle={t("business_company_general_subtitle")}
            >
                <div className="mt-3 card">
                    {
                        !rawDataIsHandled || companyDataIsLoading || companyDataIsRefetching ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                            companyAllRaw === false ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                                // showCompanyInfo === false ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                                companyAllRaw.length <= 0 ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                                    !PM_CompanyInfoView ? <div className="d-flex justify-content-center my-5"><NoData wise="cross" text={t("business_extra_permission_required")} /></div> :
                                        <div
                                            className="px-4">
                                            {
                                                !message ? null :
                                                    <AlertMessage message={message} setMessage={setMessage} />
                                            }
                                            {
                                                PM_CompanyLogoEdit ?
                                                    <div className='mt-4 mb-5'>
                                                        <ImgCrop
                                                            aspect={1}
                                                            rotationSlider
                                                            beforeCrop={async (file, filelist) => {
                                                                const result = await FileUploadSingleConditionalsCheck({
                                                                    file: file,
                                                                    imageTypes: ["image/jpeg", "image/png"],
                                                                    maxSize: 1000,
                                                                    CompanyId: userStore.companyData.id,
                                                                });
                                                                console.log(result)
                                                                return result
                                                            }}
                                                            modalOk={t("business_main_save")}
                                                            modalCancel={t("business_main_cancel")}
                                                        >
                                                            <Dragger
                                                                name="file"
                                                                accept="image/png, image/jpeg"
                                                                className='upload-logo'
                                                                action={null}
                                                                onRemove={() => setCompanyLogo(undefined)}
                                                                multiple={false}
                                                                showUploadList={false}
                                                                // onPreview={onPreview}
                                                                beforeUpload={(file) => { onFinish(file); return false; }}
                                                            >
                                                                <div className="flex flex-row align-items-center justify-content-center">
                                                                    <div className="ant-upload-drag-icon logo-preview mx-4">

                                                                        {

                                                                            !CompanyLogo ? <img
                                                                                alt="company-logo"
                                                                                src={defaultLogo} /> : <img
                                                                                alt="company-logo"
                                                                                // onClick={() => mutateCompanyLogo.mutate({ logo: "" })}
                                                                                style={{ padding: "0px" }}
                                                                                className='my-2'
                                                                                src={CompanyLogo} />
                                                                        }
                                                                        {
                                                                            !CompanyLogo ? null :
                                                                                <div
                                                                                    onClick={(event) => event.stopPropagation()}
                                                                                >
                                                                                    <Popconfirm
                                                                                        title={t("business_company_logo_remove_overlay_title")}
                                                                                        description={t("business_company_logo_remove_overlay_description")}
                                                                                        onConfirm={() => {
                                                                                            mutateCompanyLogo.mutate({ logo: "" })
                                                                                        }}
                                                                                        okText={t("business_company_logo_remove_overlay_yes")}
                                                                                        cancelText={t("business_company_logo_remove_overlay_no")}
                                                                                        okButtonProps={{ className: "btn-primary btn d-inline-block", style: { minHeight: "15", minWidth: "auto", padding: "0px 15px" } }}
                                                                                        cancelButtonProps={{ className: "btn-secondary btn d-inline-block", style: { minHeight: "15", minWidth: "auto", padding: "0px 15px" } }}
                                                                                    >
                                                                                        <div
                                                                                            className='link-generic relative'
                                                                                            style={{ zIndex: 100, userSelect: "contain" }}
                                                                                            onClick={(event) => event.stopPropagation()}
                                                                                        >
                                                                                            {t("business_main_delete")}
                                                                                        </div>
                                                                                    </Popconfirm>
                                                                                </div>
                                                                        }

                                                                    </div>
                                                                    <div style={{ maxWidth: 500 }}>
                                                                        <p className="ant-upload-text mb-1" style={{ textAlign: "left" }}>{!CompanyLogo ? t("business_company_general_logo_upload") : t("business_company_general_logo_edit")}</p>
                                                                        <p className="ant-upload-hint mb-1" style={{ textAlign: "left" }}>{t("business_company_general_logo_allowed")}</p>
                                                                    </div>
                                                                </div>
                                                            </Dragger>
                                                        </ImgCrop>
                                                    </div>
                                                    :
                                                    <div className="d-flex flex-row flex-wrap align-items-center">
                                                        <div className="company-logo mb-4">
                                                            <div className="logo">
                                                                <img
                                                                    alt="company-logo"
                                                                    className={CompanyLogo === null ? "default" : ""}
                                                                    src={CompanyLogo === undefined ? defaultLogo : CompanyLogo === null ? defaultLogo : CompanyLogo.length === 0 ? defaultLogo : CompanyLogo} />
                                                            </div>
                                                        </div>
                                                        <div className="ml-2">
                                                            <h4>{!companyAllRaw ? null : companyAllRaw.companyName}</h4>
                                                        </div>
                                                    </div>
                                            }
                                            <Form
                                                form={form}
                                                layout="vertical"
                                                name="form-company-info"
                                                onFinish={() => handleCompanyInfoSubmit()}
                                                onFinishFailed={(err) => console.error(err)}
                                            >
                                                <Form.Item
                                                    name="companyName"
                                                    label={t("business_main_company_name")}
                                                    rules={[{ required: true, message: false }]}
                                                >
                                                    <Input
                                                        disabled={!PM_CompanyInfoEdit}
                                                        placeholder={t("business_main_company_name")}
                                                        onChange={(e) => editFormItem("companyName", e.target.value)}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name="companyVat"
                                                    label={t("business_main_vatnumber")}
                                                    rules={[{ required: true, message: false }]}
                                                >
                                                    <ReactTooltip place='bottom' className='tooltip' />
                                                    <div data-tip={t("business_tooltip_vatnumber")}>
                                                        <Input
                                                            value={formObject.companyVat}
                                                            disabled
                                                        />
                                                    </div>
                                                </Form.Item>
                                                <div className="d-flex flex-wrap justify-content-between">
                                                    <Form.Item
                                                        name="size"
                                                        className="w-50 mobile-friendly"
                                                        label={t("business_main_company_size")}
                                                        rules={[{ required: true, message: false }]}
                                                    >
                                                        <Select
                                                            disabled={!PM_CompanyInfoEdit}
                                                            placeholder={t("business_main_company_size")}
                                                            onSelect={(value, option) => editFormItem("size", option)}
                                                            options={TeamSizeGeneral}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="industry"
                                                        className="w-50 mobile-friendly"
                                                        label={t("business_main_industry")}
                                                        rules={[{ required: true, message: false }]}
                                                    >
                                                        <Select
                                                            disabled={!PM_CompanyInfoEdit}
                                                            placeholder={t("business_main_industry")}
                                                            onSelect={(value, option) => editFormItem("industry", option)}
                                                            options={IndustriesGeneral}
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className="d-flex flex-wrap justify-content-between">
                                                    <Form.Item
                                                        name="site"
                                                        label={t("business_main_website")}
                                                        className="w-50 mobile-friendly"
                                                    >
                                                        <Input
                                                            disabled={!PM_CompanyInfoEdit}
                                                            onChange={(e) => editFormItem("site", e.target.value)}
                                                            placeholder={t("business_extra_placeholder_website")}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="jobSite"
                                                        label={t("business_main_jobsite")}
                                                        className="w-50 mobile-friendly"
                                                    >
                                                        <Input
                                                            disabled={!PM_CompanyInfoEdit}
                                                            onChange={(e) => editFormItem("jobSite", e.target.value)}
                                                            placeholder={t("business_extra_placeholder_jobsite")}
                                                        />
                                                    </Form.Item>
                                                </div>
                                                {
                                                    !hasPermission(userStore.userData.permissionsData, "PM_VIEW_BUSINESS_CONTACT_DETAILS") ? null :
                                                        <>
                                                            <div className="mt-7">
                                                                <h3 className='mb-4 icon-width d-flex align-items-center'> <span className="icon-mark icon round lime"></span> {t("business_main_contact_info")}</h3>

                                                                <Form.Item
                                                                    name="email"
                                                                    label={t("business_main_email")}
                                                                    rules={[{ required: true, message: false, type: "email" }]}
                                                                >
                                                                    <Input
                                                                        disabled={!PM_CompanyContactEdit || !PM_CompanyInfoEdit}
                                                                        prefix={<PrefixIcon value="icon-mail fs-small" />}
                                                                        placeholder={t("business_extra_placeholder_email_business")}
                                                                        onChange={(e) => editFormItem("email", e.target.value)}
                                                                        htmlType="mail" />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="phone"
                                                                    label={t("business_main_phone")}
                                                                >
                                                                    <InputPhone
                                                                        name="phone"
                                                                        onChange={editPhone}
                                                                        disabled={!PM_CompanyContactEdit || !PM_CompanyInfoEdit}
                                                                        form={form}
                                                                        defaultValue={!companyData ? "" : companyData.data.instance.phone} />
                                                                </Form.Item>

                                                                <Form.Item
                                                                    name="country"
                                                                    label={t("business_main_country")}
                                                                    rules={[{ required: true, message: false }]}
                                                                >
                                                                    <Select
                                                                        optionFilterProp="label"
                                                                        showSearch
                                                                        disabled={!PM_CompanyContactEdit || !PM_CompanyInfoEdit}
                                                                        placeholder={t("business_main_country")}
                                                                        onSelect={(value, option) => editFormItem("country", option)}
                                                                        options={CountryGeneral}
                                                                    />
                                                                </Form.Item>
                                                                <div className="d-flex flex-wrap justify-content-between">
                                                                    <Form.Item
                                                                        name="zipCode"
                                                                        label={t("business_main_zipcode")}
                                                                        className="w-50 mobile-friendly"
                                                                        rules={[{ required: true, message: false }]}
                                                                    >
                                                                        <Input
                                                                            disabled={!PM_CompanyContactEdit || !PM_CompanyInfoEdit}
                                                                            placeholder={t("business_main_zipcode")}
                                                                            onChange={(e) => editFormItem("zipCode", e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        name="city"
                                                                        label={t("business_main_city")}
                                                                        className="w-50 mobile-friendly"
                                                                        rules={[{ required: true, message: false }]}
                                                                    >
                                                                        <Input
                                                                            disabled={!PM_CompanyContactEdit || !PM_CompanyInfoEdit}
                                                                            placeholder={t("business_main_city")}
                                                                            onChange={(e) => editFormItem("city", e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                                <Form.Item
                                                                    name="address1"
                                                                    label={t("discard_register_step_company_adres1")}
                                                                    rules={[{ required: true, message: false }]}
                                                                >
                                                                    <Input
                                                                        disabled={!PM_CompanyContactEdit || !PM_CompanyInfoEdit}
                                                                        placeholder={t("discard_register_step_company_adres1")}
                                                                        onChange={(e) => editFormItem("address1", e.target.value)}
                                                                    />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="address2"
                                                                    label={t("discard_register_step_company_adres2")}
                                                                >
                                                                    <Input
                                                                        disabled={!PM_CompanyContactEdit || !PM_CompanyInfoEdit}
                                                                        placeholder={t("discard_register_step_company_adres2")}
                                                                        onChange={(e) => editFormItem("address2", e.target.value)}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                        </>
                                                }
                                                {

                                                    PM_CompanyInfoEdit ?
                                                        <div className="btns-right mt-4">
                                                            <div className="mr-2 mb-2">
                                                                <Button
                                                                    htmlType='submit'
                                                                    className='btn btn-primary'
                                                                    onClick={(e) => setMessage(null)}
                                                                    loading={mutateCompanyInfo.isLoading}
                                                                >
                                                                    {t("business_main_save")}
                                                                </Button>
                                                            </div>
                                                            <div className="mr-2 mb-2">
                                                                <div onClick={() => companyDataRefetch()} className="btn-secondary btn d-inline-block ">{t("business_main_cancel")}</div>
                                                            </div>
                                                        </div>
                                                        : null
                                                }
                                            </Form>


                                        </div>
                    }
                </div>
            </LayoutCompany>
        </GuestRedirectLayout >
    );
}

const useEditCompanyInfo = (props) => {
    const userStore = useUserStore();
    return useMutation({
        mutationKey: ["mutateCompanyInfo"],
        mutationFn: () => {
            const countryId = props.formObject.country.id;
            const industryId = props.formObject.industry.id
            return axios.post("company/info/update", {
                CompanyId: userStore.companyData.id,
                CompanyName: props.formObject.companyName,
                PostalCode: props.formObject.zipCode,
                ZipCode: props.formObject.zipCode,
                CountryId: countryId,
                IndustryId: industryId,
                JobContactUserId: props.formObject.jobContact === undefined || props.formObject.jobContact === null || props.formObject.jobContact.data === undefined || props.formObject.jobContact.data === null ? null : props.formObject.jobContact.data.id,
                JobWebsite: props.formObject.jobSite,
                CompanyWebsite: props.formObject.site,
                SizeId: props.formObject.size.id,
                Phone: props.formObject.phone,
                Email: props.formObject.email,
                City: props.formObject.city,
                Adress1: props.formObject.address1,
                Adress2: props.formObject.address2
            })
        },
        onSuccess: (res) => handleRequestSuccess({ response: res, isSubmit: true, hasAlert: true }),
        onError: (res) => handleRequestError({ response: res, isSubmit: true, hasAlert: true }),
    })
}
import React, { useState } from 'react';
import { t } from 'i18next';
import { Input } from 'antd';

export default function InputCopy(props) {
  const [isCopied, setIsCopied] = useState(false);

  const checkSvg = <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="7" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8 0C3.58182 0 0 3.58182 0 8C0 12.4182 3.58182 16 8 16C12.4182 16 16 12.4182 16 8C16 3.58182 12.4182 0 8 0ZM11.4676 6.64727C11.5315 6.57429 11.5801 6.48927 11.6106 6.39721C11.6411 6.30516 11.6529 6.20794 11.6453 6.11126C11.6377 6.01459 11.6108 5.92041 11.5662 5.83428C11.5217 5.74814 11.4604 5.67179 11.3859 5.6097C11.3114 5.54762 11.2252 5.50106 11.1324 5.47277C11.0397 5.44448 10.9422 5.43503 10.8457 5.44497C10.7493 5.4549 10.6558 5.48403 10.5707 5.53064C10.4857 5.57725 10.4108 5.6404 10.3505 5.71636L7.22327 9.46836L5.60509 7.84946C5.46793 7.71698 5.28422 7.64367 5.09353 7.64533C4.90284 7.64699 4.72043 7.72347 4.58559 7.85831C4.45074 7.99316 4.37426 8.17557 4.3726 8.36625C4.37094 8.55694 4.44425 8.74065 4.57673 8.87782L6.75855 11.0596C6.83001 11.1311 6.91556 11.1868 7.00976 11.2233C7.10395 11.2598 7.20473 11.2763 7.30565 11.2717C7.40657 11.2672 7.50544 11.2416 7.59594 11.1967C7.68643 11.1518 7.76658 11.0885 7.83127 11.0109L11.4676 6.64727Z" fill="#18A89B" />
  </svg>;

  function copyLink(value) {

    setIsCopied(true);
    // COPY ACTION
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = value;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);

    setTimeout(() => {
      setIsCopied(false);
    }, 4000);
  }

  return (
    <div className={"input-text input-action"}>
      {
        isCopied ?
          <div className='action'>
            <span className="mr-2">
              {checkSvg}
            </span>
            {t("business_main_copied")}
          </div> :
          <div className='action active' onClick={() => copyLink(props.value)}>
            <span className="icon default icon-link icon-nospace pr-2">
            </span>
            {t("business_main_copy")}

          </div>
      }
      <Input
        value={props.value === null ? "" : props.value}
        type={"text"}
        disabled={true}
      />
    </div>
  );
}
import { Divider } from "antd";
import Loading from "components/animations/loading";
import Panel from "components/modules/panel/panel";
import Rating from "components/modules/rating/rating";
import { t } from "i18next";
import { Fragment } from "react";

export default function MilestonesPanel(props) {
    return <>
        <Panel
            {...props.panelProps}
            scroll
            scrollHeight={200}
            panelIndicator={{
                icon: <span className="icon icon-help-empty icon-nospace fs-xregular color-darkgrey help" />,
                placement: "right",
                tooltip: <div className="p-2">
                    <p className="mb-2 fw-semi-bold color-darkgrey" style={{ fontSize: "16px" }}>{t("business_dashboard_panel_candidate_milestones_tooltip_title")}</p>
                    <div className="d-flex flex-column">
                        <div className="d-flex flex-row align-items-center mb-1">
                            <MilestoneMedals medals={0} className="mr-2" />
                            <span>{t("business_dashboard_panel_candidate_milestones_tooltip_none")}</span>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-1">
                            <MilestoneMedals medals={1} className="mr-2" />
                            <span>{t("business_dashboard_panel_candidate_milestones_tooltip_one")}</span>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-1">
                            <MilestoneMedals medals={2} className="mr-2" />
                            <span>{t("business_dashboard_panel_candidate_milestones_tooltip_two")}</span>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-1">
                            <MilestoneMedals medals={3} className="mr-2" />
                            <span>{t("business_dashboard_panel_candidate_milestones_tooltip_three")}</span>
                        </div>
                    </div>
                </div>
            }}
            title={<>{t("business_dashboard_panel_candidate_milestones_title")}</>}
        >
            {
                !props.data ? <Loading type="circle" center /> :
                    props.data.sort((a, b) => a.sortIndex > b.sortIndex ? 1 : -1).map((milestone, i) => {
                        return <Fragment key={i} >
                            <MilestoneRow amount={milestone.reach} reached={milestone.medals === -1 ? false : true} medals={milestone.medals} />
                        </Fragment>
                    })
            }
        </Panel>
    </>
}

export function MilestoneMedals(props) {
    const emptyMedal = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5688 5.23656H8.95116L5.63218 0.476562H13.8878L10.5688 5.23656ZM16.9 0.476562C17.2285 0.476562 17.5353 0.538542 17.8204 0.6625C18.1055 0.786458 18.3596 0.956901 18.5827 1.17383C18.8058 1.39076 18.9763 1.64177 19.0941 1.92687C19.2118 2.21198 19.2738 2.52187 19.28 2.85656C19.28 3.27182 19.2211 3.6468 19.1033 3.98148C18.9856 4.31617 18.8089 4.65396 18.5734 4.99484L14.6316 10.8984C14.0675 10.1918 13.3951 9.63708 12.6141 9.23422C11.8332 8.83135 10.9872 8.60203 10.0761 8.54625L15.7007 0.476562H16.9ZM9.4439 8.54625C8.539 8.59583 7.69608 8.82206 6.91515 9.22492C6.13421 9.62779 5.45864 10.1856 4.88843 10.8984L0.937256 4.97625C0.714131 4.64156 0.543688 4.30687 0.425928 3.97219C0.308167 3.6375 0.246188 3.26562 0.23999 2.85656C0.23999 2.52807 0.301969 2.22128 0.425928 1.93617C0.549886 1.65107 0.720329 1.39695 0.937256 1.17383C1.15418 0.950703 1.4052 0.78026 1.6903 0.6625C1.97541 0.54474 2.2853 0.48276 2.61999 0.476562H3.81929L9.4439 8.54625ZM9.75999 9.99656C10.417 9.99656 11.0337 10.1205 11.6101 10.3684C12.1865 10.6164 12.6916 10.9541 13.1255 11.3818C13.5593 11.8095 13.9002 12.3146 14.1481 12.8972C14.396 13.4798 14.52 14.0996 14.52 14.7566C14.52 15.4135 14.396 16.0302 14.1481 16.6066C13.9002 17.183 13.5624 17.6882 13.1348 18.122C12.7071 18.5559 12.202 18.8968 11.6194 19.1447C11.0368 19.3926 10.417 19.5166 9.75999 19.5166C9.10301 19.5166 8.48632 19.3926 7.90991 19.1447C7.33351 18.8968 6.82838 18.559 6.39452 18.1313C5.96067 17.7037 5.61978 17.1985 5.37187 16.6159C5.12395 16.0333 4.99999 15.4135 4.99999 14.7566C4.99999 14.0996 5.12395 13.4829 5.37187 12.9065C5.61978 12.3301 5.95757 11.8249 6.38522 11.3911C6.81288 10.9572 7.31801 10.6164 7.90062 10.3684C8.48322 10.1205 9.10301 9.99656 9.75999 9.99656Z" fill="#DBDBDB" />
    </svg>;

    const fullMedal = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.9289 5.23656H9.31127L5.99229 0.476562H14.2479L10.9289 5.23656ZM17.2601 0.476562C17.5886 0.476562 17.8954 0.538542 18.1805 0.6625C18.4656 0.786458 18.7197 0.956901 18.9428 1.17383C19.166 1.39076 19.3364 1.64177 19.4542 1.92687C19.5719 2.21198 19.6339 2.52187 19.6401 2.85656C19.6401 3.27182 19.5812 3.6468 19.4635 3.98148C19.3457 4.31617 19.1691 4.65396 18.9335 4.99484L14.9917 10.8984C14.4276 10.1918 13.7552 9.63708 12.9742 9.23422C12.1933 8.83135 11.3473 8.60203 10.4362 8.54625L16.0608 0.476562H17.2601ZM9.804 8.54625C8.89911 8.59583 8.05619 8.82206 7.27525 9.22492C6.49432 9.62779 5.81874 10.1856 5.24854 10.8984L1.29736 4.97625C1.07424 4.64156 0.903796 4.30687 0.786035 3.97219C0.668275 3.6375 0.606296 3.26562 0.600098 2.85656C0.600098 2.52807 0.662077 2.22128 0.786035 1.93617C0.909993 1.65107 1.08044 1.39695 1.29736 1.17383C1.51429 0.950703 1.76531 0.78026 2.05041 0.6625C2.33551 0.54474 2.64541 0.48276 2.9801 0.476562H4.17939L9.804 8.54625ZM10.1201 9.99656C10.7771 9.99656 11.3938 10.1205 11.9702 10.3684C12.5466 10.6164 13.0517 10.9541 13.4856 11.3818C13.9194 11.8095 14.2603 12.3146 14.5082 12.8972C14.7561 13.4798 14.8801 14.0996 14.8801 14.7566C14.8801 15.4135 14.7561 16.0302 14.5082 16.6066C14.2603 17.183 13.9225 17.6882 13.4949 18.122C13.0672 18.5559 12.5621 18.8968 11.9795 19.1447C11.3969 19.3926 10.7771 19.5166 10.1201 19.5166C9.46312 19.5166 8.84643 19.3926 8.27002 19.1447C7.69361 18.8968 7.18848 18.559 6.75463 18.1313C6.32077 17.7037 5.97989 17.1985 5.73197 16.6159C5.48406 16.0333 5.3601 15.4135 5.3601 14.7566C5.3601 14.0996 5.48406 13.4829 5.73197 12.9065C5.97989 12.3301 6.31768 11.8249 6.74533 11.3911C7.17299 10.9572 7.67812 10.6164 8.26072 10.3684C8.84333 10.1205 9.46312 9.99656 10.1201 9.99656Z" fill="#FFC000" />
    </svg>;

    const Medals = (props) => {
        const amount = props.amount;
        const medals = [];
        for (let i = 0; i < 3; i++) {
            if (i >= amount) {
                medals.push(<span key={i} className="ml-1">{emptyMedal}</span>);
            } else {
                medals.push(<span key={i} className="ml-1">{fullMedal}</span>);
            }
        }
        return <div>{medals}</div>;
    };


    return <>
        <div className={`medals ${props.medals === -1 ? "disabled" : ""} ${props.className || ''}`}>
            <Medals amount={props.medals || 0} />
        </div>
    </>
}

function MilestoneRow(props) {
    // # UTILS
    const milestoneFlagReached = <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M34 17.3203C34 26.7092 26.3888 34.3203 17 34.3203C7.61116 34.3203 0 26.7092 0 17.3203C0 7.93147 7.61116 0.320312 17 0.320312C26.3888 0.320312 34 7.93147 34 17.3203Z" fill="#FFC000" />
        <path d="M12.2688 7.47656C12.4377 7.47656 12.5997 7.54891 12.7191 7.67768C12.8386 7.80645 12.9057 7.98111 12.9057 8.16322V9.85698L14.3664 9.54203C15.7683 9.23964 17.2215 9.38342 18.5489 9.95586L18.7222 10.03C20.0477 10.6018 21.5067 10.7088 22.8919 10.3358C23.0422 10.2953 23.199 10.2923 23.3505 10.3269C23.5019 10.3615 23.6441 10.4329 23.7662 10.5357C23.8882 10.6384 23.987 10.7698 24.0549 10.9198C24.1228 11.0698 24.1581 11.2346 24.1582 11.4015V18.1463C24.1582 18.7359 23.7853 19.2505 23.2546 19.3933L23.0728 19.4418C21.5701 19.847 19.9872 19.7313 18.5489 19.1113C17.2218 18.539 15.7689 18.3952 14.3672 18.6975L12.9057 19.0124V26.4741C12.9057 26.6562 12.8386 26.8309 12.7191 26.9597C12.5997 27.0884 12.4377 27.1608 12.2688 27.1608C12.0998 27.1608 11.9378 27.0884 11.8184 26.9597C11.6989 26.8309 11.6318 26.6562 11.6318 26.4741V8.16322C11.6318 7.98111 11.6989 7.80645 11.8184 7.67768C11.9378 7.54891 12.0998 7.47656 12.2688 7.47656Z" fill="#FFF2CC" />
    </svg>;

    const milestoneFlag = <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M34 17.8672C34 27.256 26.3888 34.8672 17 34.8672C7.61116 34.8672 0 27.256 0 17.8672C0 8.47835 7.61116 0.867188 17 0.867188C26.3888 0.867188 34 8.47835 34 17.8672Z" fill="#DBDBDB" />
        <path d="M12.2688 8.02344C12.4377 8.02344 12.5997 8.09578 12.7191 8.22456C12.8386 8.35333 12.9057 8.52798 12.9057 8.7101V10.4039L14.3664 10.0889C15.7683 9.78651 17.2215 9.9303 18.5489 10.5027L18.7222 10.5769C20.0477 11.1487 21.5067 11.2557 22.8919 10.8827C23.0422 10.8422 23.199 10.8392 23.3505 10.8738C23.5019 10.9084 23.6441 10.9798 23.7662 11.0825C23.8882 11.1853 23.987 11.3166 24.0549 11.4667C24.1228 11.6167 24.1581 11.7814 24.1582 11.9484V18.6932C24.1582 19.2828 23.7853 19.7973 23.2546 19.9402L23.0728 19.9887C21.5701 20.3939 19.9872 20.2782 18.5489 19.6582C17.2218 19.0859 15.7689 18.9421 14.3672 19.2444L12.9057 19.5593V27.021C12.9057 27.2031 12.8386 27.3778 12.7191 27.5065C12.5997 27.6353 12.4377 27.7076 12.2688 27.7076C12.0998 27.7076 11.9378 27.6353 11.8184 27.5065C11.6989 27.3778 11.6318 27.2031 11.6318 27.021V8.7101C11.6318 8.52798 11.6989 8.35333 11.8184 8.22456C11.9378 8.09578 12.0998 8.02344 12.2688 8.02344Z" fill="white" />
    </svg>;

    return <div className="flex align-items-center flex-row justify-content-between mb-2">
        <div className="flex align-items-center">
            <span className="mr-2">
                {
                    props.reached ?
                        milestoneFlagReached : milestoneFlag
                }
            </span>
            <span>{props.amount} {t("business_dashboard_ratings").toLowerCase()}</span>
        </div>
        <MilestoneMedals medals={props.medals} />
    </div>
}
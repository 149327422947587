import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";

const useGetNationalitySizeOptions = (props) => {
    const userStore = useUserStore();
    return useQuery({
        queryKey: ["getNationalitySizeOptions"],
        queryFn: () => axios.get("v2/generic/catalogues/SizesNationalityAmount"),
        retry: 2,
        refetchOnWindowFocus: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {
                var data = res.data.instance;
                let newArray = [];

                (data.sort((a, b) => a.sortIndex - b.sortIndex)).forEach((item) => {
                    return newArray.push({
                        value: item.sortIndex,
                        label: (item.nameTranslations).filter(translation => translation.languageId === userStore.clientData.language.id).length === 0 ?
                            item.name : (item.nameTranslations).filter(translation => translation.languageId === userStore.clientData.language.id)[0].text,
                        translations: item.nameTranslations,
                        data: item,
                        id: item.sortIndex
                    });
                });
                props.setData(newArray);
            }
        }),
        onError: (res) => handleRequestError({ response: res }),
    })
}

export default useGetNationalitySizeOptions;